import React, {useContext} from "react";
import {AccordionContext, Badge, Button, Col, Row, useAccordionButton} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {connect} from 'react-redux';
import classes from './ExpandableItem.module.css';

const ExpandableItem = (props) => {

  const {activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    props.eventKey,
    () => props.callback && props.callback(props.eventKey),
  );

  const isActiveEventKey = activeEventKey  === props.eventKey;

  const classList = [
    "text-white",
    isActiveEventKey ? "collapse-button" : "expand-button",
    "asset-selector-text",
    "me-auto",
    "text-left"
  ];

  let noSearch = props.alertFilter === null;


  switch (props.indent) {
    case 0: classList.push(classes.IndentLevel0);
            break;
    case 1: classList.push(classes.IndentLevel1);
            break;
    case 2: classList.push(classes.IndentLevel2);
            break;
    case 3: classList.push(classes.IndentLevel3);
      break;

  }

  return (

    <Row>
      <Col xs={9}>

        <Button
          type="button"
          variant="link"
          onClick={decoratedOnClick}
          className={classList}>
            <FontAwesomeIcon className="text-left" icon={isActiveEventKey ? faMinus : faPlus} />
        </Button>

        <Button className={classes.NoTextWrap  + " " + classes.Text + " " + props.linkClass} type="button" variant="link" onClick={decoratedOnClick}>{props.name}</Button>
      </Col>
      <Col xs="1" sm="1" className="align-content-center justify-content-center">
        {!isActiveEventKey && props.newAlerts > 0 && (noSearch || props.alertFilter === "new") ?  <Badge className="new-alerts-button-side align-self-end asset-selector-text align-items-center">{props.newAlerts}</Badge> : null}
      </Col>
      <Col xs="1" sm="1">
        {!isActiveEventKey && props.activeAlerts > 0 && (noSearch || props.alertFilter === "active") ? <Badge className="active-alerts-button-side asset-selector-text" >{props.activeAlerts}</Badge> : null}
      </Col>

    </Row>


  );

}

const mapStateToProps = (state) => {
  return {
    alertFilter: state.filter.alertFilter
  }
}

export default connect(mapStateToProps)(ExpandableItem);