import React, {useEffect} from 'react';
import Homepage from "../../Homepage/Homepage";
import DocumentContent from "./DocumentContent";
import {Redirect, useParams, withRouter} from "react-router-dom";
import * as actions from '../../../../store/actions';
import {connect} from 'react-redux';

const Documents = (props) => {

  const {companyId, assetId} = useParams();

  useEffect(() => {
    props.setPageTitle("Asset Documents");

    let navLinks = [];
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/asset/" + assetId, title: "Asset"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/asset/" + assetId + "/documents", title: "Documents"});

    props.setPageNavLinks(navLinks);

  }, []);

  if (props.authenticated) {
    return (<Homepage showAssetSelection={true}  showCompanyInfo={true}>
      <DocumentContent companyId={companyId} assetId={assetId}/>
    </Homepage>);
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapStateToProps = (state) => {

  return {
    data: state.filter.data,
    filteredData: state.filter.filteredData,
    searchFilter: state.filter.searchFilter,
    alertFilter: state.filter.alertFilter,
    buildingId: state.filter.buildingId,
    subId: state.filter.subId,
    pageNavLinks: state.page.pageNavLinks,
    assetData: state.assets.assetData,
    authenticated: state.authentication.authenticated
  };

}

const mapDispatchToProps = (dispatch) => {

  return {
    setBuildingFilter: (buildingId) => dispatch(actions.setBuildingFilter(buildingId)),
    setPageInfo: (buildingId, sub1Id, sub2Id, assetId) => dispatch(actions.setPageInfo(buildingId, sub1Id, sub2Id, assetId)),
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (navLinks) => dispatch(actions.setPageNavLinks(navLinks)),
    fetchAssetData: (id) => dispatch(actions.fetchAssetData(id))
  }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Documents));