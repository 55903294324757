import React, {useEffect, useState} from 'react';
import Homepage from '../../../Homepage/Homepage'
import {Container, Row, Col, Card, Table, Button, Alert} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../../../store/actions";
import Pagination from "react-js-pagination";
import classes from './AdminUsers.module.css';

const AdminUsers = (props) => {

  const [rows, setRows] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {

    let nav = [];
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId, title: "Dashboard"});
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin", title: "Admin"});
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/user", title: "User Admin"});
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/user/accounts", title: "User Accounts"});

    props.setPageNavLinks(nav);
    props.setPageTitle("User Administration");
    props.getAdminUserList(props.companyId, pageNo, pageSize);
    props.setAdminUpdateSuccess(null, null);
    initialiseFromQueryParams();
  },[]);

  useEffect(() => {
    initialiseFromQueryParams();
  }, [props.location])

  useEffect(() => {
    props.getAdminUserList(props.companyId, pageNo, pageSize);
  }, [pageNo, pageSize])

  const changePage = (pageNo) => {
    props.history.push("/company/" + props.companyId + "/admin/user/accounts/?pageNo=" + pageNo);
  }

  const initialiseFromQueryParams = () => {

    const params = new URLSearchParams(props.location.search);
    let pageNoFromParams = params.get("pageNo");

    if (pageNoFromParams === null || pageNoFromParams === undefined) {
      setPageNo(1);
    } else {
      setPageNo(parseInt(pageNoFromParams));
    }

  }

  useEffect(() => {

    if (props.userList && props.userList.length > 0) {
      let rowsTmp = props.userList.map(user => {

        return (<tr>
          <td data-title="Full name">
            {user.fullName}
          </td>
          <td data-title="Email">
            {user.email}
          </td>
          <td data-title="Role">
            {user.role}
          </td>
          <td data-title="Phone number">
            {user.phoneNumber === null ? "N/A" : user.phoneNumber}
          </td>
          <td data-title="Building Permission">
            <ul>
              {user.appUserBuildingPermissions.map(bp => <li>{bp.buildingName}</li>)}
            </ul>
          </td>

          <td data-title="Edit">
            <Button size="sm" className="bg-secondary" onClick={() => props.history.push("/company/" + props.companyId + "/admin/user/accounts/edit/" + user.id)}>Edit</Button>
          </td>
          <td data-title="Delete">
            <Button size="sm" className="bg-secondary" onClick={() => {
              props.deleteUser(props.companyId, user.id, !user.enabled);
            }}>{user.enabled ? "Delete" : "Undelete"}</Button>
          </td>

        </tr>)

      });

      setRows(rowsTmp);

    }

  }, [props.userList]);

  if (props.authenticated) {

    let alert = null;

    if (props.success !== null) {

      if (props.success) {
        alert = (<Alert variant="success"><h4>User updated</h4></Alert>);
      } else {
        alert = (<Alert variant="danger"><h4>Error: {props.backendErrorMessage}</h4></Alert>);
      }

    }

    let pagination = null;

    if (props.pagination) {

      pagination = (
        <Pagination
                    activePage={props.pagination.currentPage}
                    itemsCountPerPage={props.pagination.itemsPerPage}
                    totalItemsCount={props.pagination.totalItems}
                    pageRangeDisplayed={5}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={changePage}
        />
      );
    }

    return (
      <Homepage {...props} data={props.data} show={true} title={"Company Administration"}
                setAssetSelection={false} showCompanyInfo={true}>
        <Container fluid className="align-items-start h-100">
          <Row className="align-items-start h-100">
            <Col className="align-items-start h-100">
              <Card className="h-100 bg-primary w-100 text-white align-items-start">

                <Container fluid className="p-0 m-0">

                  {alert}

                  <div id="no-more-tables">
                    <Table striped className={"text-white p-0 " + classes.Table}>
                      <thead>
                      <tr>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>phone no</th>
                        <th>Building permissions</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                      </thead>
                      <tbody>
                      {rows}
                      </tbody>
                    </Table>
                  </div>

                </Container>

                <Container className="mt-auto">
                  <Row>
                    <Col className="d-flex justify-content-center">
                      {pagination}

                    </Col>
                  </Row>
                </Container>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
            </Col>
          </Row>
        </Container>

      </Homepage>
    );
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (nav) => dispatch(actions.setPageNavLinks(nav)),
    getAdminUserList: (companyId, pageNo, pageSize) => dispatch(actions.fetchAdminUserList(companyId, pageNo, pageSize)),
    deleteUser: (companyId, userId, deleteUser) => dispatch(actions.deleteUser(companyId, userId, deleteUser)),
    setAdminUpdateSuccess: (success, errorMessage) => dispatch(actions.setAdminUpdateSuccess(success, errorMessage))
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    selectedCompanyData: state.company.selectedCompanyData,
    companyId: state.page.companyId,
    userList: state.admin.userList,
    success: state.admin.success,
    backendErrorMessage: state.admin.errorMessage,
    pagination: state.admin.pagination

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminUsers));
