import {connect} from "react-redux";
import React from "react";
import SubGroup2Accordion from "./SubGroup2Accordion";

const SubGroup2Accordions = (props) => {

  let noSearch = props.alertFilter === null;

  if (!props.data) {
    return null;
  }

  return (<div>
    {props.data.map((subGroup, index) => {
      return (
        <SubGroup2Accordion subGroup={subGroup}
                            selectedSub2Id={props.selectedSub2Id}
                            noSearch={noSearch}
                            selectedAssetId={props.selectedAssetId}
                            alertFilter={props.alertFilter}
                            key={index}
        />
      );
    })

    }
  </div>)
}

const mapStateToProps = (state) => {

  return {
    selectedAssetId: state.page.selectedAssetId,
    alertFilter: state.filter.alertFilter,
    assetData: state.assets.assetData,
    selectedSub2Id: state.page.selectedSub2Id,
    companyId: state.page.companyId
  }
}

export default connect(mapStateToProps, null)(SubGroup2Accordions);