import React from 'react';
import moment from "moment";
import classes from './CustomTooltip.module.css';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {

    let dateTime = moment(label).format("DD/MM/YYYY HH:mm:ss");

    const labels = payload.map((item, index) => {
      return (<p className={"label " + classes.Text} style={{color: payload[index].stroke}}>
        {payload[index].name} : {parseFloat(payload[index].value).toFixed(2)} &deg;C
      </p>);
    })

    return (
      <div className={"custom-tooltip " + classes.CustomTooltip} style={{backgroundColor: "white", border: "1px solid black", padding: "5px"}}>
        <p className={"label " + classes.Text}>Date/Time: {dateTime}</p>

        {labels}

      </div>
    );
  }

  return null;
};

export default CustomTooltip;