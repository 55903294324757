import React, {useEffect, useState} from 'react';
import Homepage from '../../Company/Homepage/Homepage'
import {Container, Row, Col, Card, Table, Button} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import classes from './AdminCompanies.module.css';

const AdminCompanies = (props) => {
  const prettyBytes = require('pretty-bytes');

  const [rows, setRows] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    initialiseFromQueryParams();
  }, [props.location])

  useEffect(() => {

    if (pageNo !== null && pageSize !== null) {
      props.getAdminCompanyList(pageNo, pageSize);
    }

  }, [pageNo, pageSize])

  const changePage = (pageNo) => {
    props.history.push("/admin/company/?pageNo=" + pageNo);
  }

  const initialiseFromQueryParams = () => {

    const params = new URLSearchParams(props.location.search);
    let pageNoFromParams = params.get("pageNo");

    if (pageNoFromParams === null || pageNoFromParams === undefined) {
      setPageNo(1);
    } else {
      setPageNo(parseInt(pageNoFromParams));
    }

  }

  useEffect(() => {

    let nav = [];

    nav.push({url: process.env.PUBLIC_URL + "/#/admin/", title: "Admin"});
    nav.push({url: process.env.PUBLIC_URL + "/#/admin/company/", title: "Companies"});
    props.setPageNavLinks(nav);
    props.setPageTitle("Company Administration");

    initialiseFromQueryParams();

    props.getAdminCompanyList(pageNo, pageSize);

  },[]);

  useEffect(() => {

    if (props.companyList) {
      let rowsTmp = props.companyList.map(company => {
        return (<tr>
          <td data-title="Company ID">
            {company.id}
          </td>
          <td data-title="Company Name">
            {company.name}
          </td>
          <td data-title="File quota">
            {prettyBytes(company.fileQuota)}
          </td>

          <td data-title="Edit">
            <Button size="sm" className={"bg-secondary " + classes.Button} onClick={() => props.history.push("/admin/company/edit/" + company.id)}>Edit</Button>
          </td>
          <td data-title="Delete">
            <Button size="sm" className={"bg-secondary " + classes.Button} onClick={() => {
              props.deleteCompany(company.id, !company.enabled);
            }}>{company.enabled ? "Delete" : "Undelete"}</Button>
          </td>
        </tr>)

      });

      setRows(rowsTmp);

    }


  }, [props.companyList]);

  if (props.authenticated) {

    let pagination = null;

    if (props.pagination) {

      pagination = (
        <Pagination
          activePage={props.pagination.currentPage}
          itemsCountPerPage={props.pagination.itemsPerPage}
          totalItemsCount={props.pagination.totalItems}
          pageRangeDisplayed={5}
          itemClass="page-item"
          linkClass="page-link"
          onChange={changePage}
        />
      );
    }

    return (
      <Homepage {...props} data={props.data} show={true} title={"Company Administration"}
                setAssetSelection={false} showCompanyInfo={false}>
        <Container fluid className="align-items-start h-100 p-0 m-0">
          <Row className="align-items-start h-100 p-0 m-0">
            <Col className="align-items-start">
              <Card className="h-100 bg-primary w-100 text-white align-items-start p-0 m-0">

                <Container fluid className="p-0 m-0">
                  <Row className="p-0 m-0">
                    <Col className="my-2"><Button className="bg-secondary" onClick={() => {
                      props.history.push("/admin/company/create");
                    }}><FontAwesomeIcon className="me-2" icon={faPlus}/>Create</Button></Col>
                  </Row>
                </Container>


                <Container fluid className="p-0 m-0">
                  <Row className="p-0 m-0">
                    <Col className="p-0 m-0">
                      <div id="no-more-tables">
                        <Table striped id="no-more-tables" className={"table-sm table-condensed text-white p-2 sensor-settings-table scroll-table p-0 m-0 " + classes.Table}>
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Company Name</th>
                            <th>File Quota</th>
                            <th>Edit</th>
                            <th>Delete/Undelete</th>
                          </tr>
                          </thead>
                          <tbody>
                          {rows}
                          </tbody>
                        </Table>

                      </div>

                    </Col>
                  </Row>

                </Container>

                <Container>
                  <Row>
                    <Col className="d-flex justify-content-center">
                      {pagination}
                    </Col>
                  </Row>
                </Container>

              </Card>
            </Col>
          </Row>
        </Container>

      </Homepage>
    );
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (nav) => dispatch(actions.setPageNavLinks(nav)),
    getAdminCompanyList: (pageNo, pageSize) => dispatch(actions.fetchAdminCompanyList(pageNo, pageSize)),
    deleteCompany: (companyId, deleteAsset) => dispatch(actions.deleteCompany(companyId, deleteAsset))
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    selectedCompanyData: state.company.selectedCompanyData,
    companyId: state.page.companyId,
    assetId: state.page.assetId,
    assetData: state.admin.assetData,
    pagination: state.admin.pagination,
    companyList: state.admin.companyList
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminCompanies));
