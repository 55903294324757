import * as actionTypes from '../actions/actionTypes';

const initialState = {
  assetId: null,
  plotData: null,
  originalData: null,
  firstMeasurementDate: null,
  graphStartDate: null,
  graphEndDate: null,
  sensorMax: null,
  sensorMin: null,
  sensorAve: null,
  referenceLines: [],
  loading: false,
  custom: false
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.SET_PLOT_DATA:
      return {
        ...state,
        plotData: {...action.plotData},
        originalData: {...action.originalData},
        assetId: action.assetId,
        lastUpdateDateTime: action.lastUpdateDateTime,
        graphStartDate: action.graphStartDate,
        graphEndDate: action.graphEndDate,
        sensorMin: action.sensorMin,
        sensorMax: action.sensorMax,
        sensorAve: action.sensorAve,
        loading: false
      }
    case actionTypes.ADD_REFERENCE_LINE:

      let addReferenceLines = JSON.parse(JSON.stringify(state.referenceLines));

      addReferenceLines.push(action.referenceLine);

      return {
        ...state,
        referenceLines: addReferenceLines
      }
    case actionTypes.REMOVE_REFERENCE_LINE:
      let removeReferenceLines = JSON.parse(JSON.stringify(state.referenceLines));

      removeReferenceLines.splice(action.referenceLineIndex, 1);

      return {
        ...state,
        referenceLines: removeReferenceLines
      }
    case actionTypes.STORE_FIRST_MEASUREMENT_RECEIVED:

      return {
        ...state,
        firstMeasurementDate: action.firstMeasurementDate
      }
    case actionTypes.CLEAR_PLOT_DATA:
      return {
        ...state,
        plotData: null,
        originalData: null,
        firstMeasurementDate: null,
        sensorMax: null,
        sensorMin: null,
        sensorAve: null,
      };
    case actionTypes.SET_PLOT_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.SET_CUSTOM_GRAPH:
      return {
        ...state,
        custom: action.custom
      }
    default:
      return state;
  }

}

export default reducer;