import React, {useState} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faKey} from "@fortawesome/free-solid-svg-icons";

const RevealablePasswordField = (props) => {

  const [revealPassword, setRevealPassword] = useState(false);

  return (
    <InputGroup className="">
      <InputGroup.Text size="sm" id="basic-addon1">
        <FontAwesomeIcon icon={faKey} className="text-primary"/>
      </InputGroup.Text>

      <Form.Control size="sm" className="form-control form-control-md"
                    placeHolder={props.placeholderText}
                    type={revealPassword ? "text" : "password"}
                    value={props.value}
                    onChange={(e) => props.onClickFunction(e)}
                  {...props}
      />

      <InputGroup.Text size="sm" id="basic-addon1">
        <FontAwesomeIcon icon={revealPassword ? faEye : faEyeSlash} className="text-primary" onClick={
          () => {
            setRevealPassword(!revealPassword);
          }
        }/>
        </InputGroup.Text>

    </InputGroup>
  );


}

export default RevealablePasswordField;