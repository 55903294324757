import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import alertReducer from "./reducers/alerts";
import adminReducer from "./reducers/admin";
import assetReducer from "./reducers/asset";
import companyDataReducer from "./reducers/companyData";
import dashboardReducer from "./reducers/dashboard";
import filterReducer from "./reducers/filter";
import legacyReducer from "./reducers/reducer";
import pageReducer from "./reducers/page";
import plotReducer from "./reducers/plots";
import authenticationReducer from "./reducers/authentication";
import settingsReducer from "./reducers/settings";
import errorsReducer from "./reducers/errors";
import reportReducer from "./reducers/report"
import subscriptionsReducer from './reducers/subscriptions'
import fileReducer from './reducers/file';
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  admin: adminReducer,
  alerts: alertReducer,
  assets: assetReducer,
  company: companyDataReducer,
  dashboard: dashboardReducer,
  filter: filterReducer,
  legacy: legacyReducer,
  page: pageReducer,
  plots: plotReducer,
  authentication: authenticationReducer,
  settings: settingsReducer,
  errors: errorsReducer,
  report: reportReducer,
  subscriptions: subscriptionsReducer,
  file: fileReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;