import * as actions from "./index";
import * as actionTypes from "./actionTypes";
import agent from "../../app/api/agent";
import { saveAs } from 'file-saver';

export const fetchFileList = (companyId, assetId, pagingParams) => {

  return (dispatch) => {
    dispatch(actions.incrementPageLoading());

    agent.File.list(companyId, assetId, pagingParams).then(response => {
      dispatch(storeFileList(response.data, response.pagination));
      dispatch(actions.decrementPageLoading());
    });

  }

}

export const fetchCompanyFileList = (companyId, pagingParams) => {

  return (dispatch) => {
    dispatch(actions.incrementPageLoading());

    agent.File.listCompanyFiles(companyId, pagingParams).then(response => {
      dispatch(storeFileList(response.data, response.pagination));
      dispatch(actions.decrementPageLoading());
    });

  }

}

export const updateFile = (fileId, companyId, assetId, fileParams, pagingParams) => {

  return (dispatch) => {
    dispatch(actions.incrementPageLoading());

    agent.File.update(fileId, fileParams).then(response => {

      if (assetId) {
        dispatch(fetchFileList(companyId, assetId, pagingParams));
      } else {
        dispatch(fetchCompanyFileList(companyId, pagingParams));
      }

      dispatch(actions.decrementPageLoading());
    });

  }

}

export const deleteFile = (fileId, companyId, assetId, pagingParams) => {
  return (dispatch) => {
    dispatch(actions.incrementPageLoading());

    agent.File.delete(fileId).then(response => {

      if (assetId) {
        dispatch(fetchFileList(companyId, assetId, pagingParams));
      } else {
        dispatch(fetchCompanyFileList(companyId, pagingParams));
      }

      dispatch(actions.decrementPageLoading());
    });

  }
}

export const downloadFile = (fileId, fileName) => {
  return (dispatch) => {
    dispatch(actions.incrementPageLoading());

    agent.File.download(fileId).then(response => {
      saveAs(response, fileName);
      dispatch(actions.decrementPageLoading());
    });

  }
}

export const createFile = (companyId, assetId, fileParams, pagingParams) => {
  return (dispatch) => {
    dispatch(actions.incrementPageLoading());

    agent.File.create(companyId, assetId, fileParams).then(response => {
      dispatch(fetchFileList(companyId, assetId, pagingParams));
      dispatch(actions.decrementPageLoading());
    });

  }
}

export const createCompanyFile = (companyId, fileParams, pagingParams) => {
  return (dispatch) => {
    dispatch(actions.incrementPageLoading());

    agent.File.create(companyId, null, fileParams).then(response => {
      dispatch(fetchCompanyFileList(companyId, pagingParams));
      dispatch(actions.decrementPageLoading());
    });

  }
}

export const getFile = (fileId) => {
  return (dispatch) => {
    dispatch(actions.incrementPageLoading());

    agent.File.getFile(fileId).then(response => {
      dispatch(storeFileDetails(response));
      dispatch(actions.decrementPageLoading());
    });

  }
}

export const storeFileDetails = (fileDetails) => {
  return {
    type: actionTypes.SET_FILE_DETAILS,
    fileDetails: fileDetails,
  }
}

export const storeFileList = (fileList, pagination) => {
  return {
    type: actionTypes.SET_FILE_LIST,
    fileList: fileList,
    pagination: pagination
  }
}