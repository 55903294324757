import '../../../custom.scss';
import logo from '../../../assets/logo.png'
import informLogo from '../../../assets/inform-logo.png';
import frankeLogo from '../../../assets/franke-logo.png';
import {
  Button, Col, Container,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  Nav,
  Navbar, Row
} from "react-bootstrap";
import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions';
import classes from './Header.module.css';
import Breadcrumb from "./Breadcrumb";

const getNav = (isMobile, props) => {

  let branding = null;
  let showComponentClasses = "d-none d-sm-block m-0 mobile-header";

  let mainBanner = (
    <Container fluid className="d-none d-sm-flex flex-fill flex-column text-primary py-4 bg-light align-content-end desktop-header">

      <div className="d-inline-flex justify-content-start flex-fill flex-row">
        <img className={classes.dvsLogo + " my-auto float-start mx-5"} src={logo} alt="Dartvalley System Logo"/>
        <img className={classes.informLogo + " my-auto float-start mx-5"} src={informLogo} alt="Inform Application Logo"/>
        <img className={classes.frankeLogo + " my-auto ms-auto float-end"} src={frankeLogo} alt="Franke Company Logo"/>
      </div>

      <div className="d-inline-flex justify-content-end align-items-end">
        <Form className="d-flex">
          <Button size="sm" className={"text-white bg-primary mx-2 btn-small mt-auto " + classes.HomeButton} onClick={() => {
            props.setAlertFilter(null);
            props.history.push('/#/main')
          }}>Home</Button>

          <FormControl size="sm" as="select" className={"mx-2 text-white bg-primary text-center mt-auto " + classes.EnvironmentSelect} style={{textAlignLast:"center"}}>
            <option>Water</option>
            <option>Environment</option>
          </FormControl>

          <DropdownButton variant="primary" size="sm" className={"mx-2 mt-auto dropdown-select " + classes.UserOptions} id="userprofile-menu" title={props.user}>
            <Dropdown.Item href="/#/manage-subscriptions">Manage Subscriptions</Dropdown.Item>

            <Dropdown.Item href="/#/change-password">Change Password</Dropdown.Item>
            <Dropdown.Item onClick={() => {
              props.logout();
            }}>Logout</Dropdown.Item>

          </DropdownButton>

        </Form>
      </div>
    </Container>

  );

  if (isMobile) {

    showComponentClasses = "d-block d-sm-none fixed-top mobile-header p-0";



    branding = ([(
       <Navbar.Brand className="m-0 me-2" key="navbrand_dvs_mobile">
         <img src={logo} height="40" className=""/>
       </Navbar.Brand>),
       (<Navbar.Brand className="m-0 me-2" key="navbrand_inform_mobile">
         <img className={classes.informLogo + " my-auto float-start mx-5"} src={informLogo}/>
       </Navbar.Brand>),
        (<Navbar.Brand className="m-0" key="navbrand_franke_mobile">
        <img src={frankeLogo} height="30" className="m-0 me-2 p-0"/>
      </Navbar.Brand>)]
     );

  }

  return (
    <Container fluid>
      {mainBanner}
      <Row className={showComponentClasses}>
        <Col sx="12" className="align-baseline">
          <Navbar bg="light" variant="light" collapseOnSelect expand="lg" className="align-items-center">
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            {branding}
            <Navbar.Collapse id="main-nav-menu">

              <Nav>
                <Link to={"/#/main"}>
                  <Nav.Link href="#" onClick={() => {
                    props.history.push('/main')
                  }}>Home</Nav.Link>

                </Link>
                <Nav.Link href="/#/manage-subscriptions">Manage Subscriptions</Nav.Link>
                <Nav.Link href="/#/change-password">Change Password</Nav.Link>
                <Nav.Link onClick={() => {
                  props.logout();
                }}>Logout</Nav.Link>
              </Nav>

              <Form inline className="p-0 m-0">

                <FormControl as="select" className="text-white bg-secondary select-menu">
                  <option>Water</option>
                </FormControl>

                {/*<InputGroup>*/}
                {/*  <FormControl type="text" placeholder="Search" id="search" className="search-menu"/>*/}
                {/*  <InputGroup.Append>*/}
                {/*    <Button variant="outline-primary"><FontAwesomeIcon icon={faSearch}/></Button>*/}
                {/*  </InputGroup.Append>*/}
                {/*</InputGroup>*/}

              </Form>

            </Navbar.Collapse>

          </Navbar>
          <Breadcrumb fixedHeader={true}/>

        </Col>

      </Row>

    </Container>
  );

}

const header = (props) => {


  return (
    <div className="bg-light">
      {getNav(true, props)}
    </div>);


}

const mapDispatchToProps = dispatch => {
  return {
    setAlertFilter: (alertFilter) => dispatch(actions.setAlertFilter(alertFilter)),
    logout: () => dispatch(actions.logout())
  }
}

const mapStateToProps = state => {
  return {
    user: state.authentication.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(header));