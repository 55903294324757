import tapIcon from '../../../../assets/Inform icons_TapX25.png';
import showerIcon from '../../../../assets/Inform icons_ShowerX25.png';
import calorifierIcon from '../../../../assets/Inform icons_CalorifierX25.png';
import coldWaterTankIcon from '../../../../assets/Inform icons_Cold water tankX25.png';
import returnIcon from '../../../../assets/Inform icons_ReturnX25.png';
import basinIcon from '../../../../assets/Inform icons_BasinX25.png';
import classes from './AssetIcon.module.css';
import bathIcon from '../../../../assets/Inform icons_BathX25.png';
import coldWaterReturn from '../../../../assets/Inform icons_Cold water supplyX25.png';

const AssetIcon = (props) => {

  let icon = null;
  let text = "";

  if (props.iconType === "tap") {
    icon = tapIcon;
    text = "tap asset icon";
  } else if (props.iconType === "shower") {
    icon = showerIcon;
    text = "shower asset icon";
  } else if (props.iconType === "calorifier") {
    icon = calorifierIcon;
    text = "calorifier asset icon";
  } else if (props.iconType === "cold water tank") {
    icon = coldWaterTankIcon;
    text = "cold water tank asset icon";
  } else  if (props.iconType === "return") {
    icon = returnIcon;
    text = "return asset icon";
  } else if (props.iconType === "basin") {
    icon = basinIcon;
    text = "basin asset icon";
  } else if (props.iconType === "bath") {
    icon = bathIcon;
    text = "bath asset icon";
  } else if (props.iconType === "cold water return") {
    icon = coldWaterReturn;
    text = "cold water return";
  }
  return (

    <img src={icon} alt={text} className={props.className + " " + classes.AssetIcon}/>

  );
}

export default AssetIcon;