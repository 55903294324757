import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error: null,
  isError: false,
  asset: null,
  building: null,
  sub1: null,
  sub2: null,
  title: '',
  loading: false,
  loadingCounter: 0,
  selectedAssetId: null,
  selectedBuildingId: null,
  selectedSubId: null,
  companyId: null,
  assetId: null,
  forcedPasswordChanged: null
}

interface IAction {

  type: string,
  name?: string,
  id?: string,
  identifier?: string,
  pageTitle?: string,
  pageNavLinks?: Array<string>,
  selectedBuildingId?: number,
  selectedSub1Id?: number,
  selectedSub2Id?: number,
  selectedAssetId?: number,
  companyId?: number,
  assetId?: number,
  forcedPasswordChanged: boolean
}

const reducer = (state = initialState, action: IAction) => {

  switch (action.type) {

    case actionTypes.SET_PAGE_COMPANY_ID:

      return {
        ...state,
        companyId: action.companyId
      }

    case actionTypes.SET_PAGE_ASSET_ID:
      return {
        ...state,
        assetId: action.assetId
      }

    case actionTypes.CLEAR_LOADING:
      return {
        ...state,
        loadingCounter: 0,
        loading: false
      }
    case actionTypes.INCREMENT_LOADING:
      const incrementLoadingCounter = state.loadingCounter + 1;

      return {
        ...state,
        loadingCounter: incrementLoadingCounter,
        loading: incrementLoadingCounter > 0
      }

    case actionTypes.DECREMENT_LOADING:

      const decrementLoadingCounter = state.loadingCounter - 1;

      return {
        ...state,
        loadingCounter: decrementLoadingCounter,
        loading: decrementLoadingCounter > 0
      }

    case actionTypes.SET_PAGE_ASSET_DETAILS:
      return {
        ...state,
        asset: {id: action.id, name: action.name, identifier: action.identifier}
      }
    case actionTypes.SET_PAGE_BUILDING_DETAILS:
      return {
        ...state,
        building: {id: action.id, name: action.name}
      }
    case actionTypes.SET_PAGE_SUB_DETAILS:
      return {
        ...state,
        sub: {id: action.id, name: action.name}
      }
    case actionTypes.SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.pageTitle
      }
    case actionTypes.SET_PAGE_NAV_LINKS:
      console.log(action.pageNavLinks);
      return {
        ...state,
        pageNavLinks: [...action.pageNavLinks!]
      }
    case actionTypes.SET_PAGE_INFO:
      return {
        ...state,
        selectedBuildingId: action.selectedBuildingId,
        selectedSub1Id: action.selectedSub1Id,
        selectedSub2Id: action.selectedSub2Id,
        selectedAssetId: action.selectedAssetId
      }
    case actionTypes.SET_FORCED_PASSWORD_CHANGED:
      return {
        ...state,
        forcedPasswordChanged: action.forcedPasswordChanged
      }
    default:
      return state;
  }

}

export default reducer;