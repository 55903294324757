import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import classes from './PasswordRequirements.module.css';

const PasswordRequirements = (props) => {

  let tick = (<FontAwesomeIcon className="ms-3 me-2" icon={faCheck} style={{color: "green"}}/>);
  let cross = (<FontAwesomeIcon className="ms-3 me-2" icon={faTimes} style={{color: "red"}}/>);

  return (
    <Card.Body>
      <h4 className={classes.Heading}>Password Requirements: -</h4>
      {props.alert}
      <ul className={props.untouched ? null : classes.UnorderedListNoBullet}>
        <li className={classes.Text}>
          {props.untouched ? null : (props.correctLength ? tick : cross)}
          Must be between 8 - 16 characters.
        </li>
        <li className={classes.Text}>
          {props.untouched ? null : (props.containsLowercase ? tick : cross)}
          Must contain at least one lower case letter.
        </li>
        <li className={classes.Text}>
          {props.untouched ? null : (props.containsUppercase ? tick : cross)}
          Must contain at least one upper case letter.
        </li>
        <li className={classes.Text}>
          {props.untouched ? null : (props.containsNumber ? tick : cross)}
          Must contain at least one number.
        </li>
        <li className={classes.Text}>
          {props.untouched ? null : (props.containsSymbol ? tick : cross)}
          Must contain at least one symbol (E.g !, @, #, $, etc)
        </li>
        {!props.confirmPassword ? null :
          <li className={classes.Text}>
            {props.untouched ? null : (props.differentPassword ? tick : cross)}
            Must differ from the old password
          </li>}

      </ul>
    </Card.Body>

  );


}

export default PasswordRequirements;