import * as actionTypes from '../actions/actionTypes';

const initialState = {
  data: null,
  pagination: null
}

const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.STORE_REPORT:

      return {
        ...state,
          data: action.data,
          pagination: action.pagination
      }

    default:
      return state;

  }
}

export default reducer;