import {Accordion, AccordionCollapse} from "react-bootstrap";
import React, {useState, useEffect} from 'react';
import ExpandableItem from "./ExpandableItem";
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import SubGroup3Accordions from "./SubGroup3Accordions";
import AssetItem from "./AssetItem";

const SubGroup2Accordion = (props) => {
  const [active, setActive] = useState(
    props.subGroup.id === props.selectedSub2Id ? "sub2_" + props.selectedSub2Id : null
  );

  const [onlyOnce, setOnlyOnce] = useState(false);

  useEffect(() => {

    if (!onlyOnce) {
      if (props.subGroup && props.subGroup.id === props.selectedSub2Id && active !== "sub2_" + props.selectedSub2Id) {
        setActive("sub2_" + props.selectedSub2Id);
        setOnlyOnce(true);
      }

    }


  }, [active, props.subGroup, props.subGroup.id, props.selectedSub2Id, onlyOnce]);


  if (!onlyOnce && active !== null) {
    setOnlyOnce(true);
  }

  return (
    <Accordion className="text-white"
               key={"sub2_" + props.subGroup.id}
               activeKey={active}
               onSelect={e => setActive(e)}>

      <ExpandableItem name={props.subGroup.name} newAlerts={props.subGroup.newAlerts}
                      activeAlerts={props.subGroup.activeAlerts}
                      eventKey={"sub2_" + props.subGroup.id} indent={2} search={props.search}
                      linkClass={props.selectedSub2Id === props.subGroup.id ? "asset-selector-text text-white active-asset"
                        : "selector-text text-white"}
      />

      <AccordionCollapse eventKey={"sub2_" + props.subGroup.id}>

        <div>

          {props.subGroup.buildingSubGroup3 != null ? <SubGroup3Accordions data={props.subGroup.buildingSubGroup3}
                                                                           search={props.search}
                                                                           selectedAsset={props.selectedAsset}
                                                                           selectedSub={props.selectedSub}
                                                                           selectedBuilding={props.selectedSub}
          /> : null}



          {
            props.subGroup.assets.map(
              (item, index) => {
                return (<AssetItem name={item.name} id={item.id}
                                   isSentinelPoint={item.sentinelPoint}
                                   isSelected={item.id === props.selectedAssetId}
                                   url={"/company/" + props.companyId + "/asset/" + item.id}
                                   newAlertsCount={item.newAlerts}
                                   activeAlertsCount={item.activeAlerts}
                                   alertFilter={props.alertFilter}
                                   noSearch={props.noSearch}
                                   indentationLevel={3}
                                   iconType={item.iconType}
                />);

                  // return (
                  // <Row key={item.id} style={{height: "40px"}}>
                  //   <Col xs="8">
                  //     <AssetIcon iconType={item.iconType} className={"asset-selector-text " + classes.IndentLevel2 + (props.selectedAssetId === item.id ? " active-asset" : "")}/>
                  //     <Button variant="link" type="button"
                  //       className={classes.Text + " asset-selector-text text-white" + (props.selectedAssetId === item.id ? " active-asset" : "")}
                  //       onClick={() => {
                  //         props.history.push("/company/" + props.companyId + "/asset/" + item.id + "/" + "?name=" + item.name + "&assetIdentifier=" + item.assetIdentifier +
                  //           "&buildingId=" + item.buildingId +
                  //           "&subId=" + item.subId);
                  //       }}>
                  //       {item.name}
                  //     </Button>
                  //   </Col>
                  //
                  //   <Col xs="1" sm="1" className="align-items-center">
                  //     {item.sentinelPoint ? <FontAwesomeIcon icon={faStar}/> : null}
                  //   </Col>
                  //
                  //
                  //   <Col xs="1" sm="1" className="align-items-center">
                  //     {item.newAlerts > 0 && (props.noSearch || props.alertFilter === "new") ?
                  //       <Badge className="new-alerts-button-side">{item.newAlerts}</Badge> : null}
                  //   </Col>
                  //   <Col xs="1" sm="1" className="align-items-center">
                  //     {item.activeAlerts > 0 && (props.noSearch || props.alertFilter === "active") ?
                  //       <Badge className="active-alerts-button-side">{item.activeAlerts}</Badge> : null}
                  //   </Col>
                  //
                  // </Row>
              }
            )
          }

        </div>
      </AccordionCollapse>

    </Accordion>
  );

}

const mapStateToProps = (state) => {

  return {
    selectedAssetId: state.page.selectedAssetId,
    alertFilter: state.filter.alertFilter,
    assetData: state.assets.assetData,
    selectedSub2Id: state.page.selectedSub2Id,
    companyId: state.page.companyId
  }
}

export default connect(mapStateToProps, null)(withRouter(SubGroup2Accordion));