import React from 'react';
import {Badge, Card, Col} from "react-bootstrap";
import classes from './MainCard.module.css';

const MainCard = (props) => {

  let counter = null;

  if (props.counter !== undefined) {

    counter = (
      <div className="panel-footer justify-content-center d-flex align-items-center lead">
        <Badge className={props.badgeClass + " " + classes.Badge}>{props.counter}</Badge>
      </div>
    );

  }

  return (

    <Col className="m-0 p-0 d-flex justify-content-center alerts-desktop">

        <Card className={(props.selected ? classes.MainCardSelected : classes.MainCard) + " text-white justify-content-center d-flex"} onClick={props.onClick}>
          <Card.Body>
            <Card.Title>
              <p className={classes.Icon + " text-center"}>
                {props.children}
              </p>
              <p className={classes.Icon + " text-center"}>{props.title}</p>
            </Card.Title>
          </Card.Body>
          {counter}
        </Card>
    </Col>


  );

}

export default MainCard;