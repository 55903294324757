import * as actionTypes from '../actions/actionTypes';
import {lowerCase} from "lodash";

const initialState = {
  user: null,
  authenticated: false,
  role: null,
  tokenExpiry: null,
  companyId: null,
  passwordChanged: null,
  passwordResetRequested: null,
  forcePasswordChange: null
}

const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.SET_AUTH_DETAILS:
      return {
        ...state,
        user: action.username,
        token: action.token,
        authenticated: true,
        tokenExpiry: action.tokenExpiry,
        role: action.role,
        companyId: parseInt(action.companyId),
        forcePasswordChange: lowerCase(action.forcePasswordChange) === "true"
      }

    case actionTypes.SET_LOGIN:
      return {
        ...state,
        user: action.user,
        authenticated: true
      };

      break;
    case actionTypes.LOGOUT:
      return {
        ...state,
        user: null,
        authenticated: false
      }
    case actionTypes.SET_PASSWORD_CHANGED:
      return {
        ...state,
        passwordChanged: action.passwordChanged
      }
    case actionTypes.SET_PASSWORD_RESET_REQUESTED:
      return {
        ...state,
        passwordResetRequested: action.passwordResetRequested
      }


    default:
      return state;
  }

}

export default reducer;