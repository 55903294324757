import React, {useEffect, useState} from 'react';
import Homepage from '../Homepage/Homepage';
import {Container, Row, Col, Card, FormControl, Button, Modal, Table} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {useParams, withRouter} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import * as actions from "../../../store/actions";
import {connect} from 'react-redux';
import {faPlus, faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AssetAlerts = (props) => {

  const {companyId, assetId, alertId} = useParams();
  const [editMode, setEditMode] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reminderDate, setReminderDate] = useState(null);
  const [addEngineerReadings, setAddEngineerReadings] = useState(false);
  const [readings, setReadings] = useState(["", "", "", ""])
  const [engineerComment, setEngineerComment] = useState("");

  useEffect(() => {
    setAllowSubmit(engineerComment.length > 0);
  }, [engineerComment]);

  useEffect(() => {

    props.fetchAssetData(assetId);
    props.fetchAlert(alertId);
    props.fetchAlertHistory(alertId);
  }, []);

  useEffect(() => {

    setEngineerComment("");
    props.fetchAssetData(assetId);
    props.fetchAlert(alertId);
    props.fetchAlertHistory(alertId);
  }, [props.location]);

  useEffect(() => {
    if (props.assetAlert && props.assetAlert.engineerComment) {
      setEngineerComment(engineerComment);
    }
  }, [props.assetAlert]);

  useEffect(() => {

    if (props.assetData && props.assetAlert) {
      props.setPageTitle(props.assetAlert.alertTypeDisplayName + " for Asset " + props.assetData.name);

      let navLinks = [];
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/asset/" + assetId, title: props.assetData.name});
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId +  "/asset/" + assetId + "/alert/" + alertId, title: "Alert"});

      props.setPageNavLinks(navLinks)

      if (engineerComment && engineerComment.length === 0 && props.assetAlert.engineerComment.length > 0 && engineerComment !== props.assetAlert.engineerComment) {
        setEngineerComment(props.assetAlert.engineerComment);
      }

    }


  }, [props.assetData, assetId, props.assetAlert]);

  const getDivClass = (temp) => {

    let tempDouble = parseFloat(temp);

    if (tempDouble > parseFloat(props.assetData.settings.alertLimitSettings.scald.limit)) {
      return "hot-temp-div";
    }

    if (tempDouble < parseFloat(props.assetData.settings.alertLimitSettings.freeze.limit)) {
      return "cold-temp-div";
    }

    if (tempDouble > parseFloat(props.assetData.settings.alertLimitSettings.bacterial.lowerLimit) &&
      tempDouble < parseFloat(props.assetData.settings.alertLimitSettings.bacterial.upperLimit)) {
      return "bacterial-temp-div";
    }

    if (tempDouble < parseFloat(props.assetData.settings.alertLimitSettings.targetTempNotAchieved)) {
      return "legionella-hot-div";
    }

    if (tempDouble > props.assetData.settings.alertLimitSettings.targetTempExceeded) {
      return "legionella-cold-div";
    }

    return "";

  }

  const validateEngineeringReadings = () => {

    let valid = true;

    props.assetData.settings.sensorSettings.forEach((sensor, index) => {

      if (sensor.enabled === true) {

        if (readings[index].trim() === "") {
          valid = false;
        }

      }

    });

    return valid;

  }

  const modalDatePicker = () => {

    let startDate = moment(new Date()).add(1, 'days').toDate();
    let endDate = moment(new Date()).add(28, 'days').toDate();

    return (
      <Modal show={true}>
        <Modal.Header closeButton>
          <Modal.Title>Select date for reminder</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-center">
          <Container className="">

            <Row>
              <Col className="d-flex justify-content-center">
                <DatePicker inline
                            selected={reminderDate}
                            minDate={startDate}
                            maxDate={endDate}
                            onChange={(date) => {
                              setReminderDate(date);
                            }}
                            dateFormat="dd/MM/yyyy"

                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <Button disabled={reminderDate === null} className="me-3" onClick={() => {

                  let updateObject = {
                    newState: "active",
                    reminderDate: reminderDate.toISOString(),
                    engineerComments: engineerComment
                  }

                  props.saveAlert(assetId, alertId, updateObject);
                  setShowModal(false);
                  setEditMode(false);
                }}>OK</Button>
                <Button onClick={() => setShowModal(false)}>Cancel</Button>
              </Col>
            </Row>

          </Container>

        </Modal.Body>
      </Modal>

    );

  }

  if (props.authenticated) {
    let tempSensors = [];

    if (!props.assetData || !props.assetAlert) {
      return null;
    }

    if (props.assetAlert.readings) {
      for (let i = 0; i < props.assetData.settings.sensorSettings.length; i++) {
        if (props.assetData.settings.sensorSettings[i].enabled === true) {

          let readings = [];

          if (props.assetAlert.setCausedAlert === "min") {
            readings = props.assetAlert.readings.minimumReadings;
          } else if (props.assetAlert.setCausedAlert === "max") {
            readings = props.assetAlert.readings.maximumReadings;
          } else {
            readings = props.assetAlert.readings.averageReadings;
          }


          tempSensors.push(
            <Col>

              <FormControl
                type="text" style={{height: 80, fontWeight: "bold", fontSize: "32px"}}
                className={"text-center " + getDivClass(readings[i])}
                value={
                  readings[i]
                }/>
            </Col>
          );
        }
      }
    }

    let tempEngineer = [];

    for (let i = 0; i < props.assetData.settings.sensorSettings.length; i++) {

      if (props.assetData.settings.sensorSettings[i].enabled === true) {
        tempEngineer.push(
          <Col>
            <FormControl type="number"
                         style={{height: 80, fontWeight: "bold", fontSize: "32px"}} className="text-center"
                         value={readings[i]}
                         className={"text-center " + getDivClass(readings[i])}
                         onChange={(e) => {
                           let readingsCopy = [...readings];
                           readingsCopy[i] = e.target.value;
                           setReadings(readingsCopy);
                         }}/>
          </Col>
        );
      }
    }

    let saveButton = null;
    let cancelButton = null;

    if (addEngineerReadings) {
      saveButton = (<Button className="bg-secondary" disabled={!validateEngineeringReadings()} onClick={() => {

        props.saveEngineerReading(props.assetAlert.id, readings);
        setAddEngineerReadings(false);
        setReadings(["", "", "", ""]);
      }}><FontAwesomeIcon icon={faSave}/> Save Reading</Button>);

      cancelButton = (<Button className="bg-secondary" onClick={() => {
        setAddEngineerReadings(false);
        setReadings(["", "", "", ""]);
      }}><FontAwesomeIcon icon={faTimes}/> Cancel Reading</Button>);


    }

    let addReading = null;

    if (editMode && (!addEngineerReadings && (!props.assetAlert.engineerReading || props.assetAlert.engineerReading.length < 3))) {
      addReading = (<Button className="bg-secondary" disabled={addEngineerReadings} onClick={() => {
        setAddEngineerReadings(true);
      }}><FontAwesomeIcon icon={faPlus}/> Add Reading</Button>);
    }

    let date = new Date(parseInt(props.assetAlert.datetime));

    let tempReadings = null;

    if (props.assetAlert.readings) {

      tempReadings = (
        <Col xl={9} className="">

          <Container>
            <Row>
              <Col xs={4} className="text-center"></Col>

              {props.assetData.settings.sensorSettings.filter(sensor => sensor.enabled === true).map(sensor => {
                return (
                  <Col className="text-center">{sensor.name}</Col>);
              })}

            </Row>

            <Row>
              <Col xs={4} className="text-center"></Col>

              {props.assetData.settings.sensorSettings.map((sensor, index) => {

                if (sensor.enabled === true) {
                  return (<Col className="text-center">Sensor {index + 1}</Col>);
                } else {
                  return null;
                }

              })}

            </Row>

            <Row className="p-3">
              <Col xs={4} className="justify-content-end d-flex">Temp at time of alert</Col>
              {tempSensors}
            </Row>

            {!props.assetAlert.engineerReading ? null : props.assetAlert.engineerReading.map((reading, readingIndex) => {

              let readings = reading.readings.map((sensorReading, index) => {

                if (props.assetData.settings.sensorSettings[index].enabled !== true) {
                  return null;
                }

                return (<Col><FormControl type="number"
                                          style={{height: 80, fontWeight: "bold", fontSize: "32px"}}
                                          className="text-center"
                                          value={sensorReading}/></Col>);

              });

              let labels = ["1st", "2nd", "final"];

              return (<Row className="p-3">
                <Col xs={4} className="justify-content-end d-flex">
                  <Container>
                    <Row>
                      <Col className="d-flex justify-content-end">{labels[readingIndex]} Engineers
                        Reading</Col>
                    </Row>
                    <Row>
                      <Col
                        className="d-flex justify-content-end">{moment(reading.datetime).toDate().toLocaleDateString()} {moment(reading.datetime).toDate().toLocaleTimeString()}</Col>
                    </Row>
                  </Container>
                </Col>{readings}</Row>);

            })}

            {addEngineerReadings ?
              <Row className="p-3"><Col xs={4} className="justify-content-end d-flex">
                Engineering Reading
              </Col>{tempEngineer}</Row> : null}

            <Row>
              <Col xs={4}/>
              <Col xs={8} className="d-flex justify-content-center">
                {addReading}
                {saveButton}
                {cancelButton}
              </Col>
            </Row>
          </Container>

        </Col>
      );

    }

    let buttons = [];

    if (editMode) {
      buttons = ([
        <Button className="keep-active-alert-button"
                disabled={!allowSubmit || props.assetAlert.state === "closed"}
                style={{width: "150px"}}
                onClick={() => {
                  setShowModal(true);
                }}>
          Keep Active</Button>,
      <Button className="close-alert-button"
              disabled={!allowSubmit || props.assetAlert.state === "closed"}
              style={{width: "150px"}}
              onClick={() => {
                let updateObject = {
                  newState: "closed",
                  reminderDate: null,
                  engineerComments: engineerComment
                }

                props.saveAlert(assetId, alertId, updateObject);
                setEditMode(false);
              }}>Close Alert</Button>,
          <Button className="bg-secondary"
                  style={{width: "150px"}}
                  onClick={() => {
                    setEditMode(false);
                  }}>Cancel</Button>

        ]
      )
    } else {
      buttons = ([<Button className="bg-secondary"
              style={{width: "150px"}}
              onClick={() => {
                setEditMode(true);
              }}>
        Edit</Button>]);
    }

    return ([showModal ? modalDatePicker() : null,
        <Homepage {...props} data={props.data} show={false} title={"Asset Alert"}
                  setAlertFilter={null} setAssetSelection={false} showCompanyInfo={true}>
          <Container fluid className="align-self-start h-100">
            <Row className="h-100">
              <Col className="h-100">
                <Container fluid className="h-100">

                  <Card className="bg-primary w-100 text-white h-100">
                    <Row className="h-100">
                      <Col xl={3} className="d-flex flex-fill align-content-center">

                        <Container className="m-5">

                          <Row>
                            <Col style={{fontWeight: "bold"}}>Alert:</Col>
                            <Col>{props.assetAlert.alertTypeDisplayName}</Col>
                          </Row>
                          <Row>
                            <Col style={{fontWeight: "bold"}}>Date:</Col>
                            <Col>{moment(props.assetAlert.creationDateTime, "DD/MM/YYYY H:mm:ss").toDate().toLocaleDateString()}
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{fontWeight: "bold"}}>Time:</Col>
                            <Col>{moment(props.assetAlert.creationDateTime, "DD/MM/YYYY H:mm:ss").toDate().toLocaleTimeString()}
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{fontWeight: "bold"}}>State:</Col>
                            <Col>{props.assetAlert.state}</Col>
                          </Row>
                          <Row>
                            <Col style={{fontWeight: "bold"}}>Reminder Date:</Col>
                            <Col>
                              {props.assetAlert.reminderDateTime ? moment(props.assetAlert.reminderDateTime).toDate().toLocaleDateString() : ""}
                            </Col>
                          </Row>

                        </Container>
                      </Col>
                      {tempReadings}
                    </Row>

                    {props.alertHistory ?
                      (<Row>
                      <Col xs={12} style={{}} className="px-4">

                        <div style={{height: "200px", overflow: "auto"}}>
                          <Table responsive className="text-white">
                            <thead>
                            <tr>
                              <th>User</th>
                              <th>Date/Time</th>
                              <th>Change Comment</th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.alertHistory.map(ah => {

                              return (
                                <tr>
                                  <td>{ah.userName}</td>
                                  <td>{ah.updateDateTime}</td>
                                  <td>{ah.changeSummary}</td>
                                </tr>
                              )

                            })}
                            </tbody>
                          </Table>

                        </div>

                      </Col>
                    </Row>) : null}
                    {editMode ? <Row className="p-3">
                      <Col xs={12}>
                        <FormControl value={engineerComment}
                                     as="textarea"
                                     rows={3}
                                     placeHolder="Engineering Comments"
                                     disabled={props.assetAlert.state === "closed"}
                                     onChange={(e) => {
                                       setEngineerComment(e.target.value);
                                     }
                                     }/>
                      </Col>
                    </Row> :  null}
                    <Row>
                      <Col className="justify-content-center d-flex mb-2" xs={12}>
                        {buttons}
                      </Col>
                    </Row>
                  </Card>
                </Container>
              </Col>
            </Row>

          </Container>
        </Homepage>]
    );
  } else {
    return (<Redirect to="/login"/>);
  }


}

const mapDispatchToProps = dispatch => {

  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (links) => dispatch(actions.setPageNavLinks(links)),
    fetchAssetData: (id) => dispatch(actions.fetchAssetData(id)),
    fetchAlertList: (id) => dispatch(actions.fetchAlertList(id)),
    fetchAlertHistory: (id) => dispatch(actions.fetchAlertHistory(id)),
    fetchAlert: (id) => dispatch(actions.fetchAlert(id)),
    saveEngineerReading: (assetId, readings) => dispatch(actions.saveEngineerReading(assetId, readings)),
    saveAlert: (assetId, index, alert) => dispatch(actions.saveAlert(assetId, index, alert)),
  }

};

const mapStateToProps = state => {
  return {
    assetData: state.assets.assetData,
    assetAlert: state.alerts.assetAlert,
    alertHistory: state.alerts.alertHistory,
    authenticated: state.authentication.authenticated,
    index: state.alerts.index,
    assetId: state.page.assetId,
    companyId: state.page.companyId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssetAlerts));
