const initialState = {
  companyData: [],
  data: [],
  selectedCompanyData: [],
  selectedData: {},
  plot: [],
  pageTitle: "",
  pageNavLinks: []
}

const reducer = (state = initialState, action) => {

  if (action.type === "SET_DATA") {
    return {
      ...state,
      selectedData: {...action.data}
    }

  } else if (action.type === "SET_PLOT") {

    return {
      ...state,
      plot: {...action.plot}
    }

  } else if (action.type === "SET_COMPANY_DATA") {
    return {
      ...state,
      companyData: [...action.companyData],
      selectedCompanyData: {...action.companyData[0]}
    }
  } else if (action.type === "SELECT_COMPANY_DATA") {
    return {
      ...state,
      selectedCompanyData: {...state.companyData[action.selectedIndex]}
    }
  } else if (action.type === "SET_PAGE_NAV_LINKS") {
    return {
      ...state,
      pageNavLinks: [...action.pageNavLinks]
    }

  } else if (action.type === "PUSH_PAGE_NAV_LINK") {

    let pageNavLinks = [];

    for (let i = 0; i < state.pageNavLinks.length; i++) {

      if (state.pageNavLinks[i].to === action.pageNavLink.to) {
        break;
      } else {
        pageNavLinks.push({...state.pageNavLinks});
      }

    }
    pageNavLinks.push({...action.pageNavLinks});

    return {
      ...state,
      pageNavLinks: [...pageNavLinks]
    }

  } else if (action.type === "SET_SELECTED_COMPANY_DATA") {

    return {
      ...state,
      selectedCompanyData: {...action.selectedCompanyData}
    }

  }


  return state;
}

export default reducer;