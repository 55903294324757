import React from 'react';
import Homepage from '../Homepage/Homepage';
import {Container, Row, Col} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {useParams, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import NavCard from "../Dashboard/NavCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faThermometerQuarter, faBuilding} from "@fortawesome/free-solid-svg-icons";

const Reports = (props) => {

  const {companyId} = useParams();

  let navLinks = [];
  navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
  navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/", title: "Reports"});


  props.setPageNavLinks(navLinks);

  if (props.authenticated) {

    return (
        <Homepage {...props} data={props.data} show={true} title="Reports" showCompanyInfo={true}
                  setAssetSelection={false}>
          <Container fluid className="align-self-start h-100">
            <Row className="h-100">
              <Col className="h-100">
                <Container fluid className="h-100">
                  <Row className="d-flex flex-row h-100 w-100 row-cols-1 row-cols-md-2 row-cols-xl-3 align-content-between mx-auto">
                    <NavCard clickFunction={() => props.history.push({pathname: "/company/" + props.companyId + "/reports/alertHistory"})}>
                      <FontAwesomeIcon icon={faBell} className="me-2"/>
                      Alert History
                    </NavCard>


                    <NavCard clickFunction={() => props.history.push({pathname: "/company/" + props.companyId + "/reports/rawData"})}>
                    <FontAwesomeIcon icon={faThermometerQuarter} className="me-2"/>
                      Raw Data
                    </NavCard>
                    <NavCard clickFunction={() => props.history.push({pathname: "/company/" + props.companyId + "/reports/siteStatusReport"})}>
                      <FontAwesomeIcon icon={faBuilding} className="me-2"/>
                      Site Status
                    </NavCard>

                  </Row>
                </Container>

              </Col>
            </Row>
          </Container>
        </Homepage>
      );
    } else {
      return (<Redirect to="/login"/>);
    }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageNavLinks: (nav) => dispatch(actions.setPageNavLinks(nav))
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    selectedCompanyData: state.company.selectedCompanyData,
    companyId: state.page.companyId,
    assetId: state.page.assetId

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reports));
