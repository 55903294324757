import React from 'react';
import {HashRouter as Router, Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import * as actions from '../../../store/actions';
import AdminMainPanel from "./AdminMainPanel";
import AdminAssets from "./Assets/AdminAssets";
import AdminAssetEdit from "./Assets/AdminAssetEdit";
import AdminUsers from "./Users/UserAccounts/AdminUsers";
import AdminUserEdit from "./Users/UserAccounts/AdminUserEdit";
import AdminBuildings from "./Buildings/AdminBuildings";
import RoutingConfig from "../../../RoutingConfig";
import UserAdminMainPanel from "./Users/UserAdminMainPanel";
import AdminUsersInvites from "./Users/UserInvitations/AdminUsersInvites";
import AdminUserInvitationEdit from "./Users/UserInvitations/AdminUserInvitationEdit";

const CustomerAdminRoutingConfig = (props) => {

  if (props.role !== "CustomerAdmin" && props.role !== "SiteAdmin" && props.role !== "ResellerAdmin") {
    return <Redirect to="/error/forbidden"/>
  }

  if (props.role === "CustomerAdmin" && props.companyId !== props.userCompanyId) {
    return <Redirect to="/error/forbidden"/>
  }

  return (
    <Router forceRefresh={true}>
      <Switch>
        <Route path={"/company/:companyId/admin/building"}><AdminBuildings createMode={false} {...props}/></Route>
        <Route path={"/company/:companyId/admin/user/accounts/edit/:userId"}><AdminUserEdit createMode={false} {...props}/></Route>
        <Route path={"/company/:companyId/admin/user/accounts/create"}><AdminUserEdit createMode={true} {...props}/></Route>
        <Route path={"/company/:companyId/admin/user/invitations/create"}><AdminUserInvitationEdit createMode={true} {...props}/></Route>
        <Route path={"/company/:companyId/admin/user/accounts"}><AdminUsers {...props}/></Route>
        <Route path={"/company/:companyId/admin/user/invitations"}><AdminUsersInvites {...props}/></Route>
        <Route path={"/company/:companyId/admin/user"}><UserAdminMainPanel {...props}/></Route>
        <Route path={"/company/:companyId/admin/asset/edit/:assetId"}><AdminAssetEdit createMode={false} {...props}/></Route>
        <Route path={"/company/:companyId/admin/asset/create/"}><AdminAssetEdit createMode={true} {...props}/></Route>
        <Route path={"/company/:companyId/admin/asset"}><AdminAssets  {...props}/></Route>
        <Route path={"/company/:companyId/admin"}><AdminMainPanel  {...props}/></Route>
        <Route path={"/"}><RoutingConfig/></Route>
      </Switch>
    </Router>
  );

}

const mapDispatchToProps = (dispatch) => {
  return {

    setPageAssetId: (assetId) => dispatch(actions.setPageAssetId(assetId))

  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    companyId: state.page.companyId,
    role: state.authentication.role,
    userCompanyId: state.authentication.companyId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerAdminRoutingConfig));
