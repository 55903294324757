import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import Homepage from '../../Homepage/Homepage';
import {Container, Row, Col, Card, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import AssetGraphComponent from "./AssetGraphComponent";
import {useParams, withRouter} from 'react-router-dom';
import * as actions from '../../../../store/actions';
import classes from './AssetGraph.module.css';
import AssetGraphComponents from "./AssetGraphComponents";

const AssetGraph = (props) => {

  const {companyId, assetId} = useParams()
  const [showPlot, setShowPlot] = useState([true, true,true,true])
  const [plotType, setPlotType] = useState(["max", "max", "max", "max"]);
  const [plotColours, setPlotColours] = useState(["#ff8f26", "#000000", "#00ff00", "#a64e9c"]);
  const timeoutLocal = useRef(null);
  const [custom, setCustom] = useState(false);
  const [showModal, setShowModal] = useState(false);
  let plotNames = [];

  const toggleShowPlot = (index) => {
    let plots = [...showPlot];
    plots[index] = !plots[index]
    setShowPlot(plots);
  }

  // useEffect(() => {
  //   console.log("timeoutLocal:", timeoutLocal);
  // }, [timeoutLocal]);

  const changePlotType = (index, type) => {
    let plots = [...plotType];
    plots[index] = type;
    setPlotType(plots)
  }

  const updateGraph = (startDate, endDate) => {

    console.log(startDate);
    console.log(endDate);

    let startDateDate = moment(startDate).utcOffset(0).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toDate()
    let endDateDate = moment(endDate).utcOffset(0).set({hour: 23, minute: 59, second: 59, millisecond: 999}).toDate()

    props.getPlotData(assetId, startDateDate, endDateDate);
    setCustom(true);
  }

  const resetGraph = () => {
    setCustom(false);
  }

  const graphModal = () => {
    return (
      <Modal dialogClassName="my-modal" show={showModal} onHide={() => setShowModal(false)} centered style={{backgroundColor: "white"}}>
        <Modal.Header closeButton>
          <Modal.Title>Graph for Asset {props.assetData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-primary">

          <Container fluid>
            <AssetGraphComponents
              assetData={props.assetData}
              plotData={props.plotData}
              plotColours={plotColours}
              plotColour={plotColours}
              ticks={props.plotData ? props.plotData.ticks : null}
              plotType={plotType}
              showPlot={showPlot}
              plotNames={plotNames}
              updateGraph={updateGraph}
              resetGraph={resetGraph}
              custom={custom}
              changePlotType={changePlotType}
              toggleShowPlot={toggleShowPlot}
              sensorMax={props.sensorMax ? [...props.sensorMax] : null}
              sensorMin={props.sensorMin ? [...props.sensorMin] : null}
              sensorAve={props.sensorAve ? [...props.sensorAve] : null}
              popout={true}
              graphStartDate={props.graphStartDate}
              graphEndDate={props.graphEndDate}
            />
          </Container>

        </Modal.Body>
      </Modal>);
  }

  const incrementalUpdate = () => {
    console.log("Fetching incremental update");
    props.incrementalUpdate(assetId);
  }

  const cleanUp = () => {
    console.log("timeout cleanup called()");
    clearTimeout(timeoutLocal.current);
    timeoutLocal.current = null;
    //setTimeoutLocal(null);
  }

  const scheduleTimeout = () => {

    console.log("Timeout scheduled");

    if (timeoutLocal.current !== null) {
      console.log("timeout is currently set - need to clear before scheduling another timeout");
      cleanUp();
    }

    timeoutLocal.current = (setTimeout(() => {

      console.log("Running timer function.");
      console.log("custom:", custom);

      if (!custom) {
        console.log("custom is not set - performing incremental update");
        incrementalUpdate();
        scheduleTimeout();
      } else {
        console.log("custom is set - noop in timer function.");
      }
    }, 60000));

  }

  useEffect(() => {

    console.log("timeoutLocal:", timeoutLocal.current);

    // setCustom(false);
    scheduleTimeout();

    // if (assetId) {
    //   props.getMeasurementLast12Hours(assetId);
    //   props.fetchAssetData(assetId);
    // }

    return function cleanup() {
      console.log("Cleaning up from useEffect.");
      cleanUp();
    };

  }, []);

  useEffect(() => {

    console.log("Custom changed", custom);

    if (timeoutLocal.current === null && !custom) {
      props.getMeasurementLast12Hours(assetId);
      scheduleTimeout();
    } else if (custom) {
      console.log("Clearing timeout");
      clearTimeout(timeoutLocal.current);
      timeoutLocal.current = null;
    }

  }, [custom]);


  useEffect(() => {

    console.log(assetId);
    console.log(props.assetData);

    if (!props.assetData || props.assetData.id !== parseInt(assetId)) {
      props.fetchAssetData(assetId);
    }

    if (props.assetData !== null) {
      props.setPageTitle("Asset Graph for " + props.assetData.name);
    }

    if (!props.plotData || (props.assetIdPlotData !== assetId)) {
      props.getMeasurementLast12Hours(assetId);
    }

    if (!props.firstMeasurementDate || props.assetIdPlotData !== assetId) {
      props.getFirstMeasurementReceived(assetId);
    }

    if (props.assetData !== null) {
      props.setPageTitle("Asset graph for " + props.assetData.name);
      let navLinks = [];

      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"})
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/asset/" + assetId, title: props.assetData.name})
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/asset/" + assetId + "/graphics/", title: "Graphics"})
      props.setPageNavLinks(navLinks)
    }

  }, [props.plotData, props.assetIdPlotData, props.location, props.firstMeasurementDate]);


  if (props.authenticated) {
    let graph = null;

    if (!props.assetData || !props.assetData.settings || !props.plotData) {
      return null;
    }
      

    for (let i = 0; i < props.assetData.settings.sensorSettings.length; i++ ) {

      let sensor = props.assetData.settings.sensorSettings[i];
      plotNames.push(sensor.sensorDescription + " (" + plotType[i] + ")");

    }

    props.assetData.settings.sensorSettings.map( (sensors, index) => {
      return sensors.sensorDescription + " (" + plotType[index] + ")";
    });

    let recordedTime = "";
    let modal = graphModal();

    if (props.lastUpdateDateTime) {

      let now = moment(new Date());
      let lastUpdate = moment(props.lastUpdateDateTime);
      recordedTime = " - Recorded " + now.diff(lastUpdate, 'minutes') + " minute(s) ago";
    }



    if (props.plotData !== null) {
      graph =  (
        <AssetGraphComponent data={props.plotData ? props.plotData.data : null}
                                     plotColour={plotColours}
                                     ticks={props.plotData ? props.plotData.ticks : null}
                                     plotType={plotType}
                                     showPlot={showPlot}
                                     plotNames={plotNames}
                                     updateGraph={updateGraph}
                                     resetGraph={resetGraph}
                                     custom={custom}
                                     title={!custom ? "Temperatures in the last 12 hours" :
                                     "Temperatures between " + moment(props.graphStartDate).format("DD/MM/YYYY") + " and " + moment(props.graphEndDate).format("DD/MM/YYYY")}
        />);
    }

    return (<Homepage {...props} show={props.show} showAssetSelection={true} showCompanyInfo={true}>
          <Container fluid className="align-items-start h-100">
            <Row className="align-items-start h-100">
              <Col className="align-items-start h-100">
                <Card className="h-100 bg-primary w-100 text-white align-items-start p-0">
                  <Container fluid className="p-0">
                    <Row className="p-0">
                      {modal}
                      <Col xs={12}>
                        <h3 className={classes.graphTitle + " text-white"}>
                          Asset details {props.assetData.name} {recordedTime}
                        </h3>

                      </Col>
                    </Row>

                    <AssetGraphComponents
                      assetData={props.assetData}
                      plotData={props.plotData}
                      plotColours={plotColours}
                      plotColour={plotColours}
                      ticks={props.plotData ? props.plotData.ticks : null}
                      plotType={plotType}
                      showPlot={showPlot}
                      plotNames={plotNames}
                      updateGraph={updateGraph}
                      resetGraph={resetGraph}
                      custom={custom}
                      changePlotType={changePlotType}
                      toggleShowPlot={toggleShowPlot}
                      sensorMax={props.sensorMax ? [...props.sensorMax] : null}
                      sensorMin={props.sensorMin ? [...props.sensorMin] : null}
                      sensorAve={props.sensorAve ? [...props.sensorAve] : null}
                      popout={false}
                      graphStartDate={props.graphStartDate}
                      graphEndDate={props.graphEndDate}
                      setShowModal={setShowModal}

                    />

                  </Container>

                </Card>
              </Col>
            </Row>
          </Container>
        </Homepage>);
    } else {
      return (<Redirect to="/login"/>);
    }


}

const mapStateToProps = (state) => {

  return {
    assetIdPlotData: state.plots.assetId,
    plotData: state.plots.plotData,
    lastUpdateDateTime: state.plots.lastUpdateDateTime,
    assetData: state.assets.assetData,
    pageTitle: state.page.pageTitle,
    authenticated: state.authentication.authenticated,
    firstMeasurementDate: state.plots.firstMeasurementDate,
    graphStartDate: state.plots.graphStartDate,
    graphEndDate: state.plots.graphEndDate,
    sensorMax: state.plots.sensorMax,
    sensorMin: state.plots.sensorMin,
    sensorAve: state.plots.sensorAve,
    companyId: state.page.companyId,
    assetId: state.page.assetId,
    custom: state.plots.custom
  };

}

const mapDispatchToProps = dispatch => {

  return {
    setPageTitle: (pageTitle) => dispatch(actions.setPageTitle(pageTitle)),
    fetchAssetData: (id) => dispatch(actions.fetchAssetData(id)),
    setPageNavLinks: (navLinks) => dispatch(actions.setPageNavLinks(navLinks)),
    setAssetData: (plotData) => dispatch(actions.setAssetData(plotData)),
    getFirstMeasurementReceived: (assetId) => dispatch(actions.getFirstMeasurement(assetId)),
    getMeasurementLast12Hours: (assetId) => dispatch(actions.getMeasurementsLast12Hours(assetId)),
    getPlotData: (assetId, startDate, endDate) => dispatch(actions.getPlotData(assetId, startDate, endDate)),
    incrementalUpdate: (assetId) => dispatch(actions.incrementalUpdate(assetId)),
    setCustomGraph: (custom) => dispatch(actions.setCustomGraph(custom))
  };

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)((AssetGraph)));
