export const collapseAll = () => {

  let allCollapsed = document.getElementsByClassName("collapse-button").length === 0;
  let counter = 0;

  while (!allCollapsed) {

    const buttons = document.getElementsByClassName("collapse-button");

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].click();
    }

    let allCollapsed = document.getElementsByClassName("collapse-button").length === 0;

    if (counter++ > 5) {
      break;
    }

  }

}

export const expandAll = () => {

  let allExpanded = document.getElementsByClassName("expand-button").length === 0;
  let counter = 0;

  while (!allExpanded) {

    const buttons = document.getElementsByClassName("expand-button");

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].click();
    }

    let allExpanded = document.getElementsByClassName("expand-button").length === 0;

    if (counter++ > 5) {
      break;
    }

  }

}
