import axios from 'axios';
import {connect} from 'react-redux';
import {Component} from "react";

class DataService extends Component {

  render = () => {
    return (this.props.children);
  }

  createData = () => {
    let data = [{
      id: 1,
      name: "NHS Lancashire & SC",
      logo: "logo.svg",
      data: this.createRandomData(1)
    },
      {
        id: 2,
        name: "NHS University Hospitals Bristol",
        logo: "nhsbristol.jpg",
        data: this.createRandomData(2)
      }];

    //////console.log(data);
    this.recalculateTotals(data);
    return data;

  }

  getData = (id) => {
    return this.state.companyData.filter((data) =>
      data.id === id
    );
  }

  createAsset = (id, name, assetId, newAlerts, activeAlerts, closedAlerts) => {
    return     ({
      "id": id,
      "name": name,
      "assetIdentifier": assetId,
      "newAlerts": newAlerts,
      "activeAlerts": activeAlerts,
      "closedAlerts": closedAlerts
    });
  }
  getCompanies = () => {
    return [{id: 1, name: "NHS Lancashire & SC"}, {id: 2, name: "NHS University Hospitals Bristol"}];
  }

  recalculateTotals = (data) => {

    data.newAlerts = 0;
    data.closedAlerts = 0;
    data.activeAlerts = 0;

    data.forEach((companies) => {

      companies.newAlerts = 0;
      companies.closedAlerts = 0;
      companies.activeAlerts = 0;

      //////console.log(companies);

      companies.data.forEach((building) => {
        companies.newAlerts += building.newAlerts;
        companies.closedAlerts += building.closedAlerts;
        companies.activeAlerts += building.activeAlerts;

      })

      data.newAlerts = companies.newAlerts;
      data.closedAlerts = companies.closedAlerts;
      data.activeAlerts = companies.activeAlerts;


    });

  }

  recalculateAlerts = (filteredData) => {

    filteredData.forEach((building, bldIndex) => {

      building.newAlerts = 0;
      building.closedAlerts = 0;
      building.activeAlerts = 0;

      building.subData.forEach((subGroup, subIndex) => {

        building.subData[subIndex].newAlerts = 0;
        building.subData[subIndex].activeAlerts = 0;
        building.subData[subIndex].closedAlerts = 0;

        subGroup.data.forEach((asset, assetIndex) => {

          building.subData[subIndex].newAlerts += parseInt(asset.newAlerts);
          building.subData[subIndex].activeAlerts += parseInt(asset.activeAlerts);
          building.subData[subIndex].closedAlerts += parseInt(asset.closedAlerts);

        });

        building.newAlerts += parseInt(subGroup.newAlerts);
        building.activeAlerts += parseInt(subGroup.activeAlerts);
        building.closedAlerts += parseInt(subGroup.closedAlerts);

      });

    });
  }

  createRandomData = (id) => {

    let assetCounter = 1;
    let showerCounter = 1;
    let tapCounter = 1;
    let subGroupCounter = 1;
    let buildingCounter = 1;

    const numberOfBuildings = Math.floor((Math.random() * 10) + 1);

    let data = []

    for (let bldIndex = 0; bldIndex < numberOfBuildings; bldIndex++) {

      const numberOfSub = Math.floor((Math.random() * 5) + 1);

      let building = {
        id: "building" + buildingCounter,
        name: 'Building ' + buildingCounter++,
        subData: [],
        data: [],
        newAlerts: 0,
        activeAlerts: 0,
        closedAlerts: 0

      }

      for (let subIndex = 0; subIndex < numberOfSub; subIndex++) {

        let subGroup = {
          id: "subGroup" + subGroupCounter++,
          name: "Floor " + (subIndex + 1),
          data: [],
          newAlerts: 0,
          activeAlerts: 0,
          closedAlerts: 0
        };

        const numberOfAssets = Math.floor((Math.random() * 5) + 1);

        for (let assetIndex = 0; assetIndex < numberOfAssets; assetIndex++) {

          let assetType = Math.floor((Math.random() * 2))

          subGroup.data.push(
            this.createAsset(assetCounter++,
              assetType === 0 ? "Shower " + showerCounter++ : "Tap " + tapCounter++,
              "Asset " + assetCounter++,
              Math.floor((Math.random() * 11)),
              Math.floor((Math.random() * 11)),
              Math.floor((Math.random() * 11))
            )
          );

        }

        building.subData.push(subGroup);

      }

      data.push(building);
    }

    this.recalculateAlerts(data);
    return data;

  }

  getMergedData = (id) => {

    let companyData = null;

    axios.get("https://dvs-inform-default-rtdb.europe-west1.firebasedatabase.app/companyData/"  +  id +  ".json").then(responseCompanies => {

      //console.log(responseCompanies);

      companyData = responseCompanies.data;

      //console.log(companyData);

      axios.get("https://dvs-inform-default-rtdb.europe-west1.firebasedatabase.app/assets.json").then(responseAssets => {

        companyData.data.forEach((building, buildingIndex) => {

            building.subData.forEach((sub, subIndex) => {

              sub.data.forEach((asset, assetIndex) => {
                companyData.data[buildingIndex].subData[subIndex].data[assetIndex] = {...responseAssets.data[asset.id]};
              })

            })

        });

        this.props.setSelectedCompanyData(companyData);

      });



    });

  }

}

const mapDispatchToProps = dispatch => {

  return {
    setSelectedCompanyData: (selectedCompanyData) => dispatch({type: 'SET_SELECTED_COMPANY_DATA', selectedCompanyData: selectedCompanyData})
  };

};
export default connect(null, mapDispatchToProps)(DataService);