import React, {useEffect, useState} from 'react';
import Homepage from '../../Homepage/Homepage'
import {Container, Row, Col, Card, Table, Button, Form} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";

import classes from './AdminAssets.module.css';

const AdminAssets = (props) => {

  const [rows, setRows] = useState(null);
  const [pageNo, setPageNo] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [showDeleted, setShowDeleted] = useState(false);

  useEffect(() => {
    initialiseFromQueryParams();
  }, [props.location])

  useEffect(() => {

    if (pageNo !== null && pageSize !== null) {
      props.getAdminCompanyAssets(props.companyId, showDeleted, pageNo, pageSize);
    }

  }, [pageNo, pageSize, showDeleted])

  const changePage = (pageNo) => {
    props.history.push("/company/" + props.companyId + "/admin/asset/?pageNo=" + pageNo);
  }

  const initialiseFromQueryParams = () => {

    const params = new URLSearchParams(props.location.search);
    let pageNoFromParams = params.get("pageNo");

    if (pageNoFromParams === null || pageNoFromParams === undefined) {
      setPageNo(1);
    } else {
      setPageNo(parseInt(pageNoFromParams));
    }

  }

  useEffect(() => {

    let nav = [];
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId, title: "Dashboard"});
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin", title: "Admin"});
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/asset", title: "Assets"});

    props.setPageNavLinks(nav);
    props.setPageTitle("Asset Administration");

    if (pageNo !== null && pageSize !== null) {
      props.getAdminCompanyAssets(props.companyId, showDeleted, pageNo, pageSize);
    }

    initialiseFromQueryParams();

  },[]);

  useEffect(() => {

    if (props.assetData) {
      let rowsTmp = props.assetData.map(asset => {
        return (<tr>
          <td>
            {asset.name}
          </td>
          <td>
            {asset.imei}
          </td>
          <td>
            <FontAwesomeIcon icon={asset.sentinelPoint ? faCheck : faTimes}/>
          </td>


          <td>
            <Button className="bg-secondary" onClick={() => props.history.push("/company/" + props.companyId + "/admin/asset/edit/" + asset.id)}>Edit</Button>
          </td>
          <td>
            <Button className="bg-secondary" style={{width: 120}} onClick={() => {
              props.deleteAsset(props.companyId, asset.id, !asset.enabled);
            }}>{asset.enabled ? "Delete" : "Undelete"}</Button>
          </td>

        </tr>)

      });

      setRows(rowsTmp);

    }


  }, [props.assetData]);

  if (props.authenticated) {

    let pagination = null;

    if (props.pagination) {

      pagination = (
        <Pagination
          activePage={props.pagination.currentPage}
          itemsCountPerPage={props.pagination.itemsPerPage}
          totalItemsCount={props.pagination.totalItems}
          pageRangeDisplayed={5}
          itemClass="page-item"
          linkClass="page-link"
          onChange={changePage}
        />
      );
    }

    return (
      <Homepage {...props} data={props.data} show={true} title={"Company Administration"}
                setAssetSelection={false} showCompanyInfo={true}>
        <Container fluid className="align-items-start h-100">
          <Row className="align-items-start h-100">
            <Col className="align-items-start h-100">
              <Card className="h-100 bg-primary w-100 text-white align-items-start m-0 p-0">
                <Container fluid className="p-0 m-0">
                  <Row className="p-0 m-0">
                    <Col className="d-flex justify-content-end">
                      <Form.Check checked={showDeleted} type="checkbox" label="Show Deleted"
                                  onClick={() => setShowDeleted(!showDeleted)}/>
                    </Col>
                  </Row>
                </Container>

                <Container fluid className="m-0 p-0">
                  <Row className="m-0 p-0">
                    <Col className="my-2"><Button className="bg-secondary" onClick={() => {
                      props.history.push("/company/" + props.companyId + "/admin/asset/create");
                    }}><FontAwesomeIcon className="me-2" icon={faPlus}/>Create</Button></Col>
                  </Row>
                </Container>


                <Container fluid className="m-0 p-0">
                  <Table responsive striped className={"text-white p-2 sensor-settings-table" + classes.TableText}>
                    <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>IMEI</th>
                      <th>Sentinel Point</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                  </Table>

                </Container>

                <Container>
                  <Row>
                    <Col className="d-flex justify-content-center">
                      {pagination}
                    </Col>
                  </Row>
                </Container>

              </Card>
            </Col>
          </Row>
        </Container>

      </Homepage>
    );
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (nav) => dispatch(actions.setPageNavLinks(nav)),
    getAdminCompanyAssets: (companyId, showDeleted, pageNo, pageSize) => dispatch(actions.fetchAdminAssetData(companyId, showDeleted, pageNo, pageSize)),
    deleteAsset: (companyId, assetId, deleteAsset) => dispatch(actions.deleteAsset(companyId, assetId, deleteAsset))
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    selectedCompanyData: state.company.selectedCompanyData,
    companyId: state.page.companyId,
    assetId: state.page.assetId,
    assetData: state.admin.assetData,
    pagination: state.admin.pagination
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminAssets));
