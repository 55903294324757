import {Button, Col, Container, Row} from "react-bootstrap";
import SensorCard from "./SensorCard";
import React from "react";
import AssetGraphComponent from "./AssetGraphComponent";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExpandArrowsAlt} from "@fortawesome/free-solid-svg-icons";

const AssetGraphComponents = (props) => {

  if (!props.plotData) {
    return null;
  }

  let graph = null;

  // if (props.plotData !== null) {
  graph =  (
      <AssetGraphComponent data={props.plotData.data}
                           plotColours={props.plotColours}
                           plotColour={props.plotColours}
                           ticks={props.plotData.ticks}
                           plotType={props.plotType}
                           showPlot={props.showPlot}
                           plotNames={props.plotNames}
                           updateGraph={props.updateGraph}
                           resetGraph={props.resetGraph}
                           custom={props.custom}
                           popout={props.popout}
                           title={!props.custom ? "Temperatures in the last 12 hours" :
                             "Temperatures between " + moment(props.graphStartDate).format("DD/MM/YYYY") + " and " + moment(props.graphEndDate).format("DD/MM/YYYY")}
      />);
  // }

  return (
    <Container fluid className="align-items-start h-100 p-0">

    <div className="d-flex justify-content-center text-white">

      {props.assetData.settings.sensorSettings.map( (sensor, index) => {

        if (sensor.enabled === true) {

          let tempToShow = "NONE";

          if (props.plotData.data && props.plotData.data.length > 0) {
            //let lastIndex = props.plotData.data.length - 1;
            //lastTemp = props.plotData.data[lastIndex][plotType[index] + "_" + (index + 1)];
            //lastTemp = Math.round(parseFloat(lastTemp));

            if (props.plotType[index] === "max") {
              tempToShow = props.sensorMax[index];
            } else if (props.plotType[index] === "min") {
              tempToShow = props.sensorMin[index];
            } else {
              tempToShow = props.sensorAve[index];
            }

            tempToShow = Math.round(parseFloat(tempToShow));


          }


          return (
            <SensorCard sensorName={sensor.sensorDescription}
                        latestTemp={tempToShow}
                        sensorBorderColour={props.plotColours[index]}
                        onClickPlotType={props.changePlotType}
                        onClickShowPlot={props.toggleShowPlot}
                        showPlot={props.showPlot[index]}
                        plotType={props.plotType[index]}
                        plotIndex={index}/>
          );

        } else {
          return null;
        }

      })
      }

      <div className="d-flex float-right">
        <Button size="sm" className="bg-secondary ms-auto mb-auto" onClick={() => {
          props.setShowModal(true);
        }}><FontAwesomeIcon icon={faExpandArrowsAlt}/>
        </Button>

      </div>



    </div>
    <Row>
      <Col>
        <Container fluid className="p-0">
          <Row className="m-0 r-0 h-100">
            <Col className="justify-content-start d-flex flex-fill h-100">{graph}</Col>
          </Row>
        </Container>
      </Col>
    </Row>

    </Container>


  );

}

export default AssetGraphComponents;