import React, {forwardRef, useEffect} from 'react';
import Homepage from '../Homepage/Homepage';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Form,
  InputGroup
} from 'react-bootstrap';
import {Redirect} from 'react-router';
import Pagination from 'react-js-pagination';

import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faSortNumericDown,
  faSortNumericUp
} from "@fortawesome/free-solid-svg-icons";
import classes from './ReportTable.module.css';
import DatePicker from "react-datepicker";
import moment from "moment";

const ReportTable = (props) => {

  useEffect(() => {
    props.setPageTitle(props.title)
  }, [props.title]);

  const handleClick = () => {
    props.downloadCsv();
  }


    if (props.authenticated) {

      let table = (<Container fluid className="d-flex flex-fill w-100 justify-content-center">
        <Row className="d-flex justify-content-center w-100">
          <Col xs={12} className="d-flex justify-content-center w-100">
            <h1>{props.loading ? "Loading Data" : "No data available"}</h1>
          </Col>
        </Row>
      </Container>);

      let pagination = null;

      if (props.data && props.data.length > 0) {

        if (props.pagination.totalItems > props.pagination.itemsPerPage) {
          pagination = (
            <Pagination activePage={props.pagination.currentPage}
                        itemsCountPerPage={props.pagination.itemsPerPage}
                        totalItemsCount={props.pagination.totalItems}
                        pageRangeDisplayed={5}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={props.changePage}

            />
          );

        }

        table = (
            <Table responsive className={classes.Table + " text-white scroll-table"}>
              <thead>
              <tr>
                {Object.keys(props.data[0]).map(heading => (<th id={"heading_" + heading}>{
                  heading.replace(/([A-Z])/g, ' $1')
                    .replace(/^./, function(str){ return str.toUpperCase(); })

                  }</th>))}
              </tr>
              </thead>
              <tbody>

              {props.data.map(row => {
                return (<tr id={row.id}>
                  {Object.keys(row).map(dataItem => {
                    return (<td id={dataItem.id}>{row[dataItem]}</td>)
                  })}
                </tr>)

              })}

              <tr>
              </tr>
              </tbody>
            </Table>
        );
      }

      const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (

        <Button className="bg-secondary" onClick={onClick} ref={ref}>
          {value}
        </Button>
      ));

      return (
        <Homepage {...props} data={props.data} show={true} title={props.title}  showCompanyInfo={true}
                  setAssetSelection={false}>



            <Container fluid className="h-100 d-flex flex-fill p-0 m-0">
              <Row className="d-flex flex-fill p-0 m-0">
                <Col className="d-flex flex-fill d-flex-row align-content-between">
                  <Card className="bg-primary text-white h-100 w-100 p-0 m-0">

                    <Container fluid className="p-0 m-0">

                      <Row className="p-0 m-0">
                        <Col className="p-0 m-0 w-100 d-flex justify-content-start align-items-center">


                          <Form className="mx-2 inline">
                            <InputGroup >
                              <InputGroup.Text size={"sm"} id="basic-addon1">Page</InputGroup.Text>
                              <Form.Control size={"sm"}  as="select" onChange={props.changePageSize} value={props.pageSize} id="pageSizeSelect">

                                <option>10</option>
                                <option>20</option>
                                <option>50</option>

                              </Form.Control>

                            </InputGroup>

                          </Form>

                          {!props.showDateOption ? null : (props.useDatePicker ?




                            <DatePicker id="startDate"
                                        selected={props.selectedDate}
                                        minDate={moment("2/2/2021 00:00:00").toDate()}
                                        maxDate={moment().toDate()}
                                        onChange={(date) => {
                                          props.updateDate(date);
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        customInput={<ExampleCustomInput />}
                            /> :

                            <Form className="mx-2 inline">
                              <InputGroup >
                                <InputGroup.Text id="basic-addon1">Date</InputGroup.Text>
                                <Form.Control size={"sm"} as="select" onChange={props.changeMonthYear} value={props.monthYear} id="monthYearSelect">
                                  {props.monthYearOptions}
                                </Form.Control>

                              </InputGroup>

                            </Form>)

                          }







                          <Button className="bg-secondary mx-2" onClick={props.toggleDescending}>
                            <h4><FontAwesomeIcon icon={props.descending ? faSortNumericUp : faSortNumericDown}/></h4>
                          </Button>


                          <Button className="bg-secondary mx-2" onClick={handleClick}>
                            <h4><FontAwesomeIcon icon={faFileCsv}/></h4>
                          </Button>

                        </Col>
                      </Row>
                    </Container>


                    <Container fluid className="p-0 m-0">
                      <Row className="p-0 m-0">
                        <Col className="p-0 m-0">
                          {table}
                        </Col>
                      </Row>
                    </Container>
                    <Container>
                      <Row>
                        <Col className="p-0 m-0">
                        </Col>
                      </Row>

                    </Container>
                    <Container className="mt-auto">
                      <Row>
                        <Col className="d-flex justify-content-center">
                          {pagination}
                        </Col>
                      </Row>

                    </Container>
                  </Card>



                </Col>
              </Row>
            </Container>



        </Homepage>
      );
    } else {
    return (<Redirect to="/login"/>);
    }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (links) => dispatch(actions.setPageNavLinks(links))
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    selectedCompanyData: state.company.selectedCompanyData,
    data: state.report.data,
    pagination: state.report.pagination,
    loading: state.page.loading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportTable));
