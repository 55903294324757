import React from 'react';
import {HashRouter as Router, Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import * as actions from '../../store/actions';
import SiteAdminMainPanel from "./SiteAdminMainPanel";
import AdminCompanies from "./Companies/AdminCompanies";
import AdminCompanyEdit from "./Companies/AdminCompanyEdit";
import RoutingConfig from "../../RoutingConfig";
import Initialisation from "./Initialisation/Initialisation";

const AdminRoutingConfig = (props) => {

  if (props.role !== "SiteAdmin" && props.role !== "ResellerAdmin") {
    props.setError(403, "Admin access restricted", "SiteAdmin");
    return <Redirect to="/main"/>
  }

  return (
    <Router forceRefresh={true}>
      <Switch>
        <Route path={"/admin/company/edit/:companyId"} exact><AdminCompanyEdit createMode={false}/></Route>
        <Route path={"/admin/company/create/"} exact><AdminCompanyEdit createMode={true}/></Route>
        <Route path={"/admin/initialisation"} exact><Initialisation {...props}/></Route>
        <Route path={"/admin/company"} exact><AdminCompanies {...props}/></Route>
        <Route path={"/admin/"} exact><SiteAdminMainPanel {...props}/></Route>
        <RoutingConfig/>
      </Switch>
    </Router>
  );

}

const mapDispatchToProps = (dispatch) => {
  return {

    setPageAssetId: (assetId) => dispatch(actions.setPageAssetId(assetId)),
    setError: (errorCode, errorMessage, operation) => dispatch(actions.setError(errorCode, errorMessage, operation))
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    companyId: state.page.companyId,
    role: state.authentication.role,
    userCompanyId: state.authentication.companyId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminRoutingConfig));
