import * as actionTypes from '../actions/actionTypes';
import {SET_ADMIN_BULK_STATUS} from "../actions/actionTypes";

const initialState = {
  assetData: null,
  buildings: null,
  asset: null,
  subGroup1s: null,
  subGroup2s: null,
  subGroup3s: null,
  iconList: null,
  resellersList: null,
  errorMessage: null,
  roles: null,
  success: null,
  userList: null,
  userInviteList: null,
  user: null,
  editBuildingList: null,
  companyList: null,
  company: null,
  bulkStatus: null
}


const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.INIT_ADMIN_BULK_STATUS:

      let bulkStatus = Array(action.numberOfAssets);

      for (let i = 0; i < action.numberOfAssets; i++) {
        bulkStatus[i] = {success: null, status: "Awaiting processing"};
      }

      return {
        ...state,
        bulkStatus: bulkStatus
      }
    case SET_ADMIN_BULK_STATUS:
      let bulkStatusCopy = [...state.bulkStatus]



      bulkStatusCopy[action.index] = {success: action.success, status: action.status}

      return {
        ...state,
        bulkStatus: bulkStatusCopy
      }

    case actionTypes.SET_ADMIN_COMPANY:
      return {
        ...state,
        company: action.company
      }
    case actionTypes.SET_ADMIN_COMPANY_LIST:
      return {
        ...state,
        companyList: action.companyList,
        pagination: action.pagination
      }
    case actionTypes.SET_ADMIN_ASSET_DATA:
      return {
        ...state,
        assetData: action.assetData,
        pagination: action.pagination
      }
    case actionTypes.SET_ADMIN_ASSET:
      return {
        ...state,
        asset: action.asset
      }
    case actionTypes.SET_ADMIN_BUILDING_LIST:
      return {
        ...state,
        buildings: action.buildings
      }
    case actionTypes.SET_ADMIN_SUBGROUP1_LIST:
      return {
        ...state,
        subGroup1s: action.subGroup1s
      }

    case actionTypes.SET_ADMIN_SUBGROUP2_LIST:
      return {
        ...state,
        subGroup2s: action.subGroup2s
      }
    case actionTypes.SET_ADMIN_SUBGROUP3_LIST:
      return {
        ...state,
        subGroup3s: action.subGroup3s
      }
    case actionTypes.SET_ADMIN_ICONS_LIST:
      return {
        ...state,
        iconList: action.iconList
      }
    case actionTypes.SET_ADMIN_RESELLERS_LIST:

      return {
        ...state,
        resellersList: action.resellersList
      }
    case actionTypes.SET_ADMIN_UPDATE_SUCCESS:
      return {
        ...state,
        success: action.success,
        errorMessage: action.errorMessage
      }
    case actionTypes.SET_ADMIN_USER_LIST:
      return {
        ...state,
        userList: action.userList,
        pagination: action.pagination
      }
    case actionTypes.SET_ADMIN_USER_INVITE_LIST:
      return {
        ...state,
        userInviteList: action.userInviteList,
        pagination: action.pagination
      }
    case actionTypes.SET_ADMIN_BUILDING_EDIT_LIST:
      return {
        ...state,
        editBuildingList: action.editBuildingList,
        pagination: action.pagination
      }


    case actionTypes.SET_ADMIN_USER:
      return {
        ...state,
        user: action.user
      }
    case actionTypes.SET_ADMIN_ROLES:
      return {
        ...state,
        roles: action.roles
      }
    default:
      return state;
  }

}

export default reducer;