import React from 'react';
import {Col, Form, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faBellSlash, faClock, faThermometer} from "@fortawesome/free-solid-svg-icons";
import {connect} from 'react-redux';
import Switch from "react-switch";
import * as actions from '../../../../store/actions';
import classes from './AlertTempSingleComponent.module.css';
const AlertTempSingleComponent = (props) => {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );


  let keyName = props.keyName;

  if (!props.settings) {
    return null;
  }

  if (props.keyName.startsWith("targetTemp")) {
    keyName = "targetTemp";
  }

  const alertObject = props.settings.alertSettings.filter(as => as.keyName === keyName)[0];

  if (props.editMode && alertObject.enabled === true) {

    let sensors = null;

    if (props.editValues.sensors) {

      console.log(props.editValues);

      sensors = (
        <Row>
          <Col xs={12} className="d-flex align-items-center justify-content-around my-2">
            <OverlayTrigger placement="bottom" delay={{show: 250, hide: 400}}
                            overlay={<Tooltip id="switch-tooltip">{props.settings.sensorSettings[0].sensorDescription}</Tooltip>}>
              <label>
                <Switch className="react-switch"
                        checked={props.editValues.sensors[0]} id={"asset-switch_0"}
                        onChange={(checked) => props.updateAlertLimitSensorValue(0, props.keyName, checked)}

                />
              </label>
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" delay={{show: 250, hide: 400}}
                            overlay={<Tooltip id="switch-tooltip">{props.settings.sensorSettings[1].sensorDescription}</Tooltip>}>

            <label>
              <Switch className="react-switch"
                      checked={props.editValues.sensors[1]} id={"asset-switch_1"}
                      onChange={(checked) => props.updateAlertLimitSensorValue(1, props.keyName, checked)}
              />
            </label>
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" delay={{show: 250, hide: 400}}
                          overlay={<Tooltip id="switch-tooltip">{props.settings.sensorSettings[2].sensorDescription}</Tooltip>}>
            <label>
              <Switch className="react-switch"
                      checked={props.editValues.sensors[2]} id={"asset-switch_2"}
                      onChange={(checked) => props.updateAlertLimitSensorValue(2, props.keyName, checked)}
              />
            </label>
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" delay={{show: 250, hide: 400}}
                          overlay={<Tooltip id="switch-tooltip">{props.settings.sensorSettings[3].sensorDescription}</Tooltip>}>
            <label>
              <Switch className="react-switch"
                      checked={props.editValues.sensors[3]} id={"asset-switch_3"}
                      onChange={(checked) => props.updateAlertLimitSensorValue(3, props.keyName, checked)}
              />
            </label>
          </OverlayTrigger>
          </Col>
        </Row>

      );
    }



    return (
      <Col className={props.className + " m-1"} style={{border: "1px solid black"}}>
        <h3 className={classes.Icon}><FontAwesomeIcon icon={props.icon} className="me-2"/>{props.name}</h3>
        <Form.Group>

          <Row>
            <Form.Label className={classes.LabelText} column sm={3}>Temp &deg;C</Form.Label>
            <Col>
              <Form.Control size="sm"
                            className="form-control font-weight-bold"
                            placeholder="Temperature"
                            type="number" value={props.editValues.limit}
                            onChange={(e) => props.changeValue(e.target.value, props.keyName, "limit")}
              />

            </Col>

          </Row>
          <Row>
            <Form.Label className={classes.LabelText} column sm={3}>Duration</Form.Label>
            <Col>
              <Form.Control size="sm"
                            className="form-control font-weight-bold"
                            placeholder="Duration"
                            type="number" value={props.editValues.duration}
                            onChange={(e) => props.changeValue(e.target.value, props.keyName, "duration")}
              />

            </Col>
            <Col>
              <Form.Control size="sm"
                            className="form-control font-weight-bold"
                            placeholder="Duration"
                            as="select" value={props.editValues.durationUnits}
                            onChange={(e) => props.changeValue(e.target.value, props.keyName, "durationUnits")}
              >

                <option value="minutes">Minutes</option>
                <option value="hours">Hours</option>
                <option value="days">Days</option>

              </Form.Control>
            </Col>
          </Row>
          {sensors}

        </Form.Group>
      </Col>
    );

  } else {

    let className = alertObject.enabled === true ? props.className : "alert-disabled-div";

    let sensorInfo = null;

    if (props.sensorSelection) {

      console.log(props.values);

      let sensorInfoSpans = props.values.sensors.map((sensor, index) => {
        if (sensor === true) {
          return (
            <OverlayTrigger placement="bottom" delay={{show: 250, hide: 400}}
                            overlay={<Tooltip id="switch-tooltip">{props.settings.sensorSettings[index].sensorDescription}</Tooltip>}>

              <span className={"text-white lead m-4 " + classes.LabelText} style={{fontWeight: "bold"}}>
                S{index+1}
              </span>
            </OverlayTrigger>);
        } else {
          return (
            <OverlayTrigger placement="bottom" delay={{show: 250, hide: 400}}
                            overlay={<Tooltip id="switch-tooltip">{props.settings.sensorSettings[index].sensorDescription}</Tooltip>}>

              <span className={"text-muted lead m-4 " + classes.LabelText} style={{fontWeight: "bold"}}>
                S{index+1}
              </span>
            </OverlayTrigger>);
        }
        className="lead"
        return null;
      });

      sensorInfo = (<p>
        {sensorInfoSpans}
      </p>);

    }

    return (
      <Col className={className + " m-1"} style={{border: "1px solid black"}}>
        <h3 className={classes.Icon}><FontAwesomeIcon icon={props.icon} className="me-xxl-2"/>{props.name}</h3>
        <p className={"lead " + classes.LabelText} style={{fontWeight: "bold"}}>
          <FontAwesomeIcon icon={faThermometer} className="me-xxl-2"/>{props.values.limit} &deg;C</p>
        <p className={"lead " + classes.LabelText} style={{fontWeight: "bold"}}>
          <FontAwesomeIcon icon={faClock} className="me-xxl-2"/>{props.values.duration}
          &nbsp;{props.values.durationType}</p>
        {sensorInfo}
      </Col>

    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAlertLimitsSettings: (editSettings) => dispatch(actions.updateAlertLimitsSettings(editSettings))
  }
}

const mapStateToProps = (state) => {
  return {

    settings: state.settings.settings,

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertTempSingleComponent);