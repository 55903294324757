import * as actionTypes from './actionTypes';
import * as actions from './index';
import agent from "../../app/api/agent";
import {IAlertLimitSettings, IAlertSettings, ISensorSetting, ISettings} from "../../app/models/Settings";
import {ThunkAction} from "redux-thunk";

export const fetchAssetSettings = (id: number) : ThunkAction<void, any, any, any> =>

  async dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Asset.get(id)
      .then(data => {
        dispatch(storeAssetSettings(id, {...data.settings}));
        dispatch(actions.decrementPageLoading());
      });

  }

export const storeAssetSettings = (id: number, settings: any) => {

  return {
    type: actionTypes.STORE_ASSET_SETTINGS,
    settings: settings,
    assetId: id
  }

}

export const updateAssetSettings = (id: number, settings: ISettings) : ThunkAction<Promise<void>, any, any, any> =>
  async dispatch => {

        dispatch(actions.incrementPageLoading());

        agent.Asset.updateSettings(id, settings).then(response => {
              dispatch(fetchAssetSettings(id));
              dispatch(actions.decrementPageLoading());
            }

        );


  };


export const updateAlertSettings = (alerts: Array<IAlertSettings>) => {

  return {
    type: actionTypes.UPDATE_ALERT_SETTINGS,
    editAlert: alerts
  }

}

export const updateSensorSettings = (sensor: Array<ISensorSetting>) => {
  return {
    type: actionTypes.UPDATE_SENSOR_SETTINGS,
    editSensor: sensor
  }
}

export const updateAlertLimitsSettings = (editAlertLimits: IAlertLimitSettings) => {

  return {
    type: actionTypes.UPDATE_ALERT_LIMITS_SETTINGS,
    editAlertLimits: editAlertLimits
  }

}

export const restoreAlertSettings = () => {
  return {
    type: actionTypes.RESTORE_ALERT_SETTINGS
  }
}

export const restoreSensorSettings = () => {
  return {
    type: actionTypes.RESTORE_SENSOR_SETTINGS
  }
}

export const restoreAlertLimitsSettings = () => {
  return {
    type: actionTypes.RESTORE_ALERT_LIMITS_SETTINGS
  }
}