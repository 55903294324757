import React from "react";
import {Card, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faBellSlash, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import CounterCardRow from "./CounterCardRow";

const ThreeCounterCard = (props) => {

  return (

    <Col className="col-12 alerts-mobile p-0 m-0">
      <Card className="bg-primary text-white card-gradient alerts-mobile w-100">
        <Card.Body>

          <CounterCardRow icon={(<FontAwesomeIcon icon={faBell} className="me-2" size="1x"></FontAwesomeIcon>)}
                          title="New Alerts"
                          badgeClass="new-alerts-button-mobile"
                          counter={props.newAlerts}
                          onClick={props.newAlertsOnClick}

          />

          <CounterCardRow icon={(<FontAwesomeIcon icon={faExclamationTriangle} className="me-2" size="1x"></FontAwesomeIcon>)}
                          title="Active Alerts"
                          badgeClass="active-alerts-button-mobile"
                          counter={props.activeAlerts}
                          onClick={props.activeAlertsOnClick}
          />

          <CounterCardRow icon={(<FontAwesomeIcon icon={faBellSlash} className="me-2" size="1x"></FontAwesomeIcon>)}
                          title="Closed Alerts"
                          badgeClass="closed-alerts-button-mobile"
                          counter={props.closedAlerts}
                          onClick={props.closedAlertsOnClick}

          />

        </Card.Body>

      </Card>
    </Col>

  );

}

export default ThreeCounterCard;