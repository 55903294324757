import React from 'react';
import {Card, Col} from "react-bootstrap";
import classes from './NavCard.module.css';

const NavCard = (props) => {

  return (
    <Col className="m-0 p-0 justify-content-center d-flex p-0 m-0">
      <Card className={classes.NavCard + " text-white align-self-center m-1 p-0"} onClick={props.clickFunction}>
        <Card.Body className="d-flex flex-fill">
          <Card.Title className="align-items-center justify-content-start d-flex flex-fill">

            <p className={classes.Icon}>
              {props.children}
            </p>

          </Card.Title>
        </Card.Body>

      </Card>

    </Col>

  );

}

export default NavCard;