import React, {useLayoutEffect, useEffect, useState} from 'react';
import {CartesianGrid, Label, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import CustomTooltip from "./CustomTooltip";
import moment from "moment";
import classes from './RechartGraphComponent.module.css';
import * as d3 from 'd3';

const useWindowSize = () => {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateSize() {
      var width = document.getElementById('graph-component').clientWidth;
      setWidth(width);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  console.log(width);

  return width;
}


const RechartGraphComponent = (props) => {

  const [width, setWidth] = useState([0]);

  useEffect(() => {
    var widthLocal = document.getElementById(props.popout ? 'graph-component-popout' : 'graph-component').clientWidth;
    setWidth(widthLocal);
    console.log("widthLocal:" + widthLocal);
  }, []);

  if (!props.graphEndDate || !props.graphStartDate) {
    console.log("graph dates invalid");
    return null;
  }

  const format24HoursXAxis = (tickItem)  => {
    return moment(tickItem).format('HH:mm')
  }

  const formatCustomAxis = (tickItem)  => {
    return moment(tickItem).format('DD/MM')
  }

  var begin = 0;
  var end = 0;

  var tickArray = [];

  if (props.data.data && props.data.data.length > 0) {
    begin = props.data.data[0].x;
    end = props.data.data[props.data.data.length - 1].x;

    if (props.custom) {

      var nextDate = moment(begin);

      while (nextDate.isBefore(moment(end))) {
        tickArray.push(nextDate.valueOf());
        nextDate = nextDate.add(1,'day');
      }

    } else {
      var nextDate = moment(begin);

      while (nextDate.isBefore(moment(end))) {
        tickArray.push(nextDate.valueOf());
        nextDate = nextDate.add(1,'hour');
      }

    }

  }

  return (
    <div id={props.popout ? "graph-component-popout" : "graph-component"} className={props.popout ? classes.RechartContainerPopout : classes.RechartContainer}>
      <ResponsiveContainer className={"p-0 " + (props.popout ? classes.RechartContainerPopout : classes.RechartContainer)}>
        <LineChart className={props.popout ? classes.RechartContainerPopout : classes.RechartContainer}
          data={props.data.data}
          margin={{
            top: 5, right: 50, left: 15, bottom: 30,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" tickFormatter={time => props.custom ? formatCustomAxis(time) : format24HoursXAxis(time)}
            scale='time'
            type='number'
            ticks={tickArray}
            domain={[
            begin,
            end
          ]}>
            <Label value="Time" offset={-10} position="insideBottomRight" />
          </XAxis>
          <YAxis label={{value: "Temp", position: "left", angle: -90}}/>
          {/*<Legend />*/}
          {props.lines}
          <Tooltip
            position={{x: width + 5, y:0}}
            content={
              <CustomTooltip/>} />
          {props.referenceLines}
        </LineChart>
      </ResponsiveContainer>
    </div>

  );




}

export default RechartGraphComponent;