import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import {useParams, withRouter} from "react-router-dom";
import ReportController from "../../Reports/ReportController";

const AssetRawDataReport = (props) => {
  const [pageParams, setPageParams] = useState(null);

  const {companyId, assetId} = useParams();

  useEffect(() => {
    props.fetchAssetData(assetId);
  }, [])

  useEffect(() => {

    if (props.assetData) {
      let navLinks = [];
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/asset/" + assetId, title: "Asset"});
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/asset/" + assetId + "/reports", title: "Reports"});
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/rawData", title: "Raw Data Report - " + props.assetData.name});
      props.setPageNavLinks(navLinks)
    }

  }, [props.assetData])

  const handleUpdateLink = (companyId, pageNo, pageSize, descending, month, year) => {
    props.history.push("/company/" + props.companyId + "/asset/"  +props.assetId + "/reports/rawData?pageNo=" + pageNo + " &pageSize=" + pageSize + "&descending=" + descending + "&month=" + month + "&year=" + year);
  }

  const handleUpdateData = (params) => {
    params["assetId"] = props.assetId;
    props.rawAssetMeasurements(params);
  }

  const handleCsvDownload = (value, month, year) => {
    let params = {assetId: props.assetId, month: month, year: year}
    props.fetchAssetRawDataCsv(params);
  }

  const updatePageParams = (pageParams) => {
    setPageParams(pageParams);
  }

  return (<ReportController
    pageTitle="Raw Data Report"
    csvDownload={handleCsvDownload}
    updateLink={handleUpdateLink}
    updateData={handleUpdateData}
    updatePageParams={updatePageParams}
    showDateOption={true}
    useDatePicker={false}

  />);

}


const mapDispatchToProps = (dispatch) => {

  return {
    rawAssetMeasurements: (params) => dispatch(actions.fetchAssetRawData(params)),
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (links) => dispatch(actions.setPageNavLinks(links)),
    fetchAssetRawDataCsv: (params) => dispatch(actions.fetchAssetRawDataCsv(params)),
    fetchAssetData: (assetId) => dispatch(actions.fetchAssetData(assetId))
  }

}

const mapStateToProps = (state) => {

  return {
    reportData: state.report.data,
    pagination: state.report.pagination,
    companyId: state.page.companyId,
    assetId: state.page.assetId,
    assetData: state.assets.assetData
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssetRawDataReport));