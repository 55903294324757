import React, {useState} from "react";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import ReportController from "../../Reports/ReportController";
import {useParams, withRouter} from "react-router-dom";

const AssetAlertHistoryReport = (props) => {
  const [pageParams, setPageParams] = useState(null);

  const {companyId, assetId} = useParams();

  const updatePageParams = (pageParams) => {
    setPageParams(pageParams);
  }

  const handleCsvDownload = (companyId, month, year) => {

    let params = {companyId: companyId, month: month, year: year, assetId: assetId}
    props.fetchAlertHistoryReportCsv(params);
  }

  const handleUpdateLink = (companyId, pageNo, pageSize, descending, month, year, date) => {
    props.history.push("/company/" + props.companyId + "/asset/" + assetId + "/reports/alertHistory?pageNo=" + pageNo + " &pageSize=" + pageSize + "&descending=" + descending + "&month=" + month + "&year=" + year + "&date=" + date);
  }

  const handleUpdateData = (params) => {

    let navLinks = [];
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/asset/" + assetId, title: "Asset"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports", title: "Reports"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/alertHistory", title: "Alert History"});
    props.setPageNavLinks(navLinks)

    params["assetId"] = assetId;
    props.fetchAlertHistoryReport(params);
  }

  return (<ReportController
    pageTitle="Alert History Report"
    csvDownload={handleCsvDownload}
    downloadOptions={null}
    urlTemplate="/reports/:companyId/alertHistory"
    updateLink={handleUpdateLink}
    updateData={handleUpdateData}
    updatePageParams={updatePageParams}
    showDateOption={true}
    useDatePicker={false}
  />);
};

const mapDispatchToProps = (dispatch) => {

  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (links) => dispatch(actions.setPageNavLinks(links)),
    fetchAlertHistoryReportCsv: (params) => dispatch(actions.fetchAlertHistoryReportCsv(params)),
    fetchAlertHistoryReport: (params) => dispatch(actions.fetchAlertHistoryReport(params))
  }

}

const mapStateToProps = (state) => {

  return {
    reportData: state.report.data,
    pagination: state.report.pagination,
    companyId: state.page.companyId,
    assetId: state.page.assetId,

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssetAlertHistoryReport));