import React from 'react';
import AlertTempMultipleComponent from "./AlertTempMultipleComponent";
import AlertTempSingleComponent from "./AlertTempSingleComponent";

const AlertTempComponent = (props) => {

  let component = null;

  console.log(props);

  if (props.type === "multiple") {
    component =  (<AlertTempMultipleComponent {...props} />);
  } else {
    component = (<AlertTempSingleComponent {...props} />);
  }

  return component;

}

export default AlertTempComponent;