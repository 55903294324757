import '../../../custom.scss';
import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import Header from './Header';
import {Alert, Col, Container, Modal, Row, Spinner,} from "react-bootstrap";
import './Homepage.css';
import AssetSelection from './AssetSelector/AssetSelection';
import CompanyInfo from "./CompanyInfo";
import BreadCrumb from "./Breadcrumb";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../store/actions';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBomb, faDoorClosed, faExclamation, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

class Homepage extends Component {

  constructor(props) {
    super(props);
    this.state ={
      companyId: null,
      search: {
        buildingId: null,
        assetId: null
      },

    };
  }

  errorModal = (props) => {

    let icon = null;
    let text = null;

    switch (props.errorCode) {

      case 1:
        icon = <FontAwesomeIcon icon={faBomb} className="me-2"/>;
        text = "ERR";
        break;
      case 403:
        icon = <FontAwesomeIcon icon={faDoorClosed} className="me-2"/>;
        text = "403";
        break;
      case 404:
        icon = <FontAwesomeIcon icon={faExclamationTriangle} className="me-2"/>;
        text = "404";
        break;
      case 500:
        icon = <FontAwesomeIcon icon={faExclamation} className="me-2"/>;
        text = "500";
        break;
      default:
        break;
    }

    return (
      <Modal show={true} centered onHide={() => props.clearError()}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">An error occurred</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Container>

            <Row>
              <Col>
                <Alert variant="danger">
                  {props.errorMessage}
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col xs={3} className="error-icon-error-page d-flex align-content-center my-auto justify-content-between text-secondary">
                  {icon}
              </Col>
              <Col xs={7} className="error-code-error-page d-flex align-content-center my-auto justify-content-end text-secondary">
                {text}
              </Col>

            </Row>

          </Container>

        </Modal.Body>
      </Modal>
    );
  }

  changeSelected = (e) => {

    this.setState({filter: ''}, () => {
      const buttons = document.getElementsByClassName("collapse-button");

      if (buttons !== null) {
        for (let i = buttons.length - 1; i >= 0; i--) {
          buttons[i].click();
        }
      }

    })

    setTimeout(() => {
      this.setState({loading: false}, () => {

        this.props.onSelectedChange(e.target.value);
      })
    }, 2000);

    this.setState({loading: true}, () => {

    })

  }

  componentDidMount() {
    this.props.initCompanyData();
    this.props.setAlertFilter(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.location.search !== prevProps.location.search) {

      if (this.props.location.search !== undefined && this.props.location.search.length > 0) {
        const params = new URLSearchParams(this.props.location.search);

        if (params["alertFilter"]) {

          switch(params["alertFilter"]) {
            case "new":
              this.props.setAlertFilter("new");
              break;
            case "active":
              this.props.setAlertFilter("active");
              break;
            case "closed":
              this.props.setAlertFilter("closed");
              break;
            default:
              this.props.setAlertFilter(null);
              break;
          }

        }

      }

    }

  }

  render() {

    if (this.props.authenticated) {

      let spinner = null;

      if (this.props.loading) {
        spinner = (
          <div className="d-flex overlay align-items-center justify-content-center main-spinner">
            <Spinner animation="border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>

          </div>
        );
      }

      let contentColumnWidth = 12;
      let assetSelection = null;

      if (this.props.showAssetSelection) {
        contentColumnWidth -= 3;

        assetSelection = (
          <Col  className="h-100 p-0 pe-xl-2 mb-auto col-xl-3 col-12">
            <AssetSelection selectedAsset={this.props.selectedAsset}
                            selectedSub={this.props.selectedSub}
                            selectedBuilding={this.props.selectedBuilding}
            />
          </Col>
        );

      }

      let companyInfo = null;

      if (this.props.showCompanyInfo) {
        contentColumnWidth -= 2;

        companyInfo = (
          <Col xl="2" className="col-12 d-flex flex-fill">
            <CompanyInfo changeCompany={this.changeSelected}/>
          </Col>
        );


      }

      let modalError = null;

      if (this.props.isError && !this.props.errorShown) {
        modalError = this.errorModal(this.props);
      }

      let contentColSetting = "col-12"

      if (this.props.showAssetSelection && this.props.showCompanyInfo) {
        contentColSetting = "col-12 col-xl-7"
      } else if (this.props.showAssetSelection) {
        contentColSetting = "col-12 col-xl-9"
      } else if (this.props.showCompanyInfo) {
        contentColSetting = "col-12 col-xl-10"
      }

      console.log(contentColSetting);

      return (
          <Container fluid className="w-100 h-100 p-0 m-0 d-block">
            {spinner}
            {modalError}
            <Header setAlertFilter={this.props.setAlertFilter}/>
            <BreadCrumb fixedHeader={false}/>

            <Row className="m-0 content-section mt-5 mb-auto h-100 p-0">
                {companyInfo}

                <Col className={"d-flex flex-fill p-0 align-items-start m-0 " + contentColSetting}>
                  {this.props.children}
                </Col>
                {assetSelection}

            </Row>
          </Container>);
    } else {
      return (<Redirect to="/login"/>);
    }
  }

}

const mapStateToProps = (state) => {
  return {
    data: state.company.data,
    companyData: state.company.companyData,
    alertFilter: state.filter.alertFilter,
    searchFilter: state.filter.searchFilter,
    filteredData: state.filter.filteredData,
    loading: state.page.loading,
    authenticated: state.authentication.authenticated,
    companyId: state.page.companyId,
    isError: state.errors.isError,
    errorCode: state.errors.errorCode,
    errorShown: state.errors.errorShown,
    errorMessage: state.errors.errorMessage
  }
}

const mapDispatchToProps = dispatch => {

  return {
    setAlertFilter: (alertFilter) => dispatch(actions.setAlertFilter(alertFilter)),
    initCompanyData: () => dispatch(actions.loadCompanyData()),
    initSelectedData: () => dispatch(actions.loadSelectedCompanyData(this.props.companyId)),
    onSelectedChange: (selectedIndex) => dispatch({type: 'SET_SELECTED', selectedIndex: selectedIndex}),
    setCompanyData: (companyData) => dispatch({type: 'SET_COMPANY_DATA', companyData: companyData}),
    setData: (data) => dispatch({type: 'SET_DATA', data: data}),
    clearError: () => dispatch(actions.clearError())
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Homepage));
