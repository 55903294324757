import React, {useEffect, useState} from 'react';
import Homepage from '../../Company/Homepage/Homepage'
import {Container, Row, Col, Card, Button, Form, Alert} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {useParams, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import {faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImageUploader from 'react-images-upload';
import classes from './AdminCompanyEdit.module.css';

const AdminCompanyEdit = (props) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [companyIconFile, setCompanyIconFile] = useState(null);
  const [contactDetails, setContactDetails] = useState(null);
  const [companyAddress, setCompanyAddress] = useState(null);
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState(null);
  const [resellerOptions, setResellerOptions] = useState(null);
  const [resellerId, setResellerId] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [img, setImg] = useState(null);
  const [fileQuota, setFileQuota] = useState(null);

  const {companyId} = useParams();

  useEffect(() => {
    props.setAdminUpdateSuccess(null, null);
    props.setAdminUpdateSuccess(false, null);
    props.setPageTitle("Company Administration");

    if (!props.createMode) {
      props.getAdminCompany(companyId);
    }

    if (props.role === "SiteAdmin") {
      props.getResellersList();
    }

  },[]);

  useEffect(() => {

    if (props.resellersList) {
      let options = [];
      options.push(<option value={null}>No reseller</option> );

      options.push(
        props.resellersList.map(reseller => <option value={reseller.id}>{reseller.name}</option>)
      );

      setResellerOptions(options);
    }

  }, [props.resellersList])

  useEffect(() => {

    let localValid = true;
    let errorMessages = []

    if (!companyName || companyName.length === 0) {
      localValid = false;
      errorMessages.push("Company name must be supplied");
    }

    if (!contactDetails || contactDetails.length === 0) {
      localValid = false;
      errorMessages.push("Contact name must be supplied");
    }

    if (!companyAddress || companyAddress.length === 0) {
      localValid = false;
      errorMessages.push("Company address must be supplied");
    }

    if (!companyPhoneNumber || companyPhoneNumber.length === 0) {
      localValid = false;
      errorMessages.push("Company phone number must be supplied");
    }

    if (!fileQuota || fileQuota.length === 0) {
      localValid = false;
      errorMessages.push("File quota must be supplied");
    }

    setErrorMessage(errorMessages);
    setValid(localValid);

  }, [companyAddress, contactDetails, companyPhoneNumber, companyIconFile, companyName, img, fileQuota]);

  useEffect(() => {
    if (props.createMode) {

      setCompanyName("");
      setCompanyIconFile("");
      setContactDetails("");
      setCompanyAddress("");
      setCompanyPhoneNumber("");
      setFileQuota("1073741824");

      let nav = [];
      nav.push({url: process.env.PUBLIC_URL + "/#/admin", title: "Admin"});
      nav.push({url: process.env.PUBLIC_URL + "/#/admin/company", title: "Companies"});
      nav.push({url: process.env.PUBLIC_URL + "/#/admin/company/create", title: "Create Company"});
      props.setPageNavLinks(nav);

    } else {

      if (props.company) {
        setCompanyName(props.company.name);
        setCompanyIconFile(props.company.imageUrl);
        setContactDetails(props.company.contactDetails);
        setCompanyAddress(props.company.companyAddress);
        setCompanyPhoneNumber(props.company.companyPhoneNumber);
        setFileQuota(props.company.fileQuota);
      }

      let nav = [];

      nav.push({url: process.env.PUBLIC_URL + "/#/admin", title: "Admin"});
      nav.push({url: process.env.PUBLIC_URL + "/#/admin/company", title: "Companies"});
      nav.push({url: process.env.PUBLIC_URL + "/#/admin/company/edit"  + companyId, title: companyName});
      props.setPageNavLinks(nav);
    }

  }, [props.company]);

  const onDrop = (picture) => {

    if (picture && picture.length === 0) {
      setImg(null);
      return;
    }

    let reader = new FileReader();

    reader.addEventListener("load", function () {
      setImg(reader.result);
    }, false);

    reader.readAsDataURL(picture[0]);
  }


  if (props.authenticated) {
    let errors = null;

    if (formSubmitted && (props.backendErrorMessage || (errorMessage && errorMessage.length > 0)) && props.loadingCounter === 0) {
      let errorOptions = errorMessage.map(message => <li>{message}</li>);

      if (props.backendErrorMessage) {
        errorOptions.push([<li>{props.backendErrorMessage}</li>]);
      }

      errors = (<Row>
        <Col xs={12}>
          <Alert variant="danger">
            <h4>The following errors exist: -</h4>
            <ul>
              {errorOptions}
            </ul>
          </Alert>
        </Col>
      </Row>)
    } else if (props.success) {
      errors = (<Row>
        <Col xs={12}>
          <Alert variant="success">
            <h4>Company {props.createMode ? "created" : "updated"}</h4>
          </Alert>
        </Col>
      </Row>)

    }

    let imageComponent = null;
    let imageUploader = (

      <Container>
        <Row>
          <Col className={props.createMode ? 12 : 11}>
            <Form.Group>
              <ImageUploader
                withIcon={true}
                buttonText='Choose Company Logo'
                onChange={onDrop}
                imgExtension={['.jpg', '.png']}
                maxFileSize={200000}
                singleImage={true}
                withPreview={true}
              />
            </Form.Group>

          </Col>

          {props.createMode ? null : <Col xs={1} className="col-auto">
            <FontAwesomeIcon className="text-white" icon={faTimes} onClick={() => {
              setShowImageUpload(false);
            }}/>
          </Col>}
        </Row>
      </Container>

    );

    if (props.createMode) {
      imageComponent = imageUploader;
    } else {

      if (showImageUpload || !companyIconFile) {
        imageComponent = imageUploader
      } else if (props.company && companyIconFile) {
        imageComponent = (<Container>
          <Row className="mb-2">

            <Col xs={12} className="d-flex justify-content-center">
              Company Logo (Click image to change)
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="d-flex justify-content-center">
              <img src={process.env.REACT_APP_COMPANY_LOGOS + "/" + companyIconFile} alt="Company Logo Image" onClick={() => {
                setShowImageUpload(true);
                setImg(null);
              }}/>
            </Col>
          </Row>

        </Container>);
      }

    }

    return (
      <Homepage {...props} data={props.data} show={true} title={"Company Administration"}
                showAssetSelection={false} showCompanyInfo={false}>
        <Container fluid className="align-items-start h-100">
          <Row className="align-items-start h-100">
            <Col className="align-items-start h-100">
              <Card className="h-100 bg-primary w-100 text-white align-items-start">
                <Container className="h-100">
                  {errors}
                  <Row>
                    <Col>
                      <Form.Group controlId="adminAsset.AssetNameControl">
                        <Form.Label>Company name</Form.Label>
                        <Form.Control value={companyName} type="text" placeholder="Company Name" onChange={(e) => setCompanyName(e.target.value)}
                        className={(!companyName || companyName.length === 0 ? classes.FormControlError : "")}

                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="adminAsset.AssetNameControl">
                        <Form.Label>Contact Name</Form.Label>
                        <Form.Control value={contactDetails} type="text" placeholder="Contact Name" onChange={(e) => setContactDetails(e.target.value)}
                                      className={(!contactDetails || contactDetails.length === 0 ? "form-control-error" : "")}/>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="adminAsset.fileQuota">
                        <Form.Label>File Quota</Form.Label>
                        <Form.Control value={fileQuota} type="text" placeholder="File Quota"
                                      className={(!fileQuota || fileQuota.length === 0 ? classes.FormControlError : "")}


                                      onChange={(e) => {

                          if (e.target.value === "" || /^\d+$/.test(e.target.value)) {
                            setFileQuota(e.target.value)
                          }

                        }}/>
                      </Form.Group>
                    </Col>


                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="adminAsset.AssetNameControl">
                        <Form.Label>Company Address</Form.Label>
                        <Form.Control as="textarea" value={companyAddress} type="text" placeholder="Company Address" onChange={(e) => setCompanyAddress(e.target.value)}
                                      className={(!companyAddress || companyAddress.length === 0 ? classes.FormControlError : "")}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="adminAsset.AssetNameControl">
                        <Form.Label>Company Phone Number</Form.Label>
                        <Form.Control value={companyPhoneNumber} type="text" placeholder="Company Phone Number" onChange={(e) => setCompanyPhoneNumber(e.target.value)}
                                      className={(!companyPhoneNumber || companyPhoneNumber.length === 0 ? classes.FormControlError : "")}


                        />
                      </Form.Group>
                    </Col>

                    {props.role === "SiteAdmin" ?
                    <Col>
                      <Form.Group controlId="adminAsset.AssetNameControl">
                        <Form.Label>Reseller</Form.Label>
                        <Form.Control as="select" value={resellerId} type="text" placeholder="Company Phone Number" onChange={(e) => setResellerId(e.target.value)}>
                          {resellerOptions}
                        </Form.Control>
                      </Form.Group>
                    </Col> : null}


                  </Row>
                  <Row>
                    <Col>
                      {imageComponent}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs={12} className="d-flex justify-content-center mt-auto flex-auto">
                      <Button style={{width: 100}} className="text-left bg-success me-3" disabled={!valid}
                              onClick={() => {

                                setFormSubmitted(true);

                                let companyData =  {
                                  companyName: companyName,
                                  contactDetails: contactDetails,
                                  companyAddress: companyAddress,
                                  companyPhoneNumber: companyPhoneNumber,
                                  fileQuota: fileQuota,
                                  resellerId: resellerId,
                                };

                                if (props.createMode) {
                                  companyData['imageData'] = img;
                                  props.createCompany(companyData);
                                } else {

                                  if (img === null) {
                                    companyData['imageUrl'] = companyIconFile;
                                  } else {
                                    companyData['imageData'] = img;
                                  }

                                  props.updateCompany(companyId, companyData);
                                }
                              }}

                      >
                        <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>&nbsp;Save
                      </Button>
                      <Button style={{width: 100}} className="text-left bg-danger" onClick={() => {
                        props.history.push("/admin/company/");
                      }}>
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>&nbsp;Cancel
                      </Button>

                    </Col>
                  </Row>
                </Container>
              </Card>
            </Col>
          </Row>

        </Container>

      </Homepage>
    );
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (nav) => dispatch(actions.setPageNavLinks(nav)),
    getIconList: () => dispatch(actions.fetchIcons()),
    setAdminUpdateSuccess: (success, errorMessage) => dispatch(actions.setAdminUpdateSuccess(success, errorMessage)),
    getAdminCompany: (companyId) => dispatch(actions.getAdminCompany(companyId)),
    getResellersList: () => dispatch(actions.getResellersList()),
    updateCompany: (companyId, companyData) => dispatch(actions.updateCompany(companyId, companyData)),
    createCompany: (companyData) => dispatch(actions.createCompany(companyData))
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    role: state.authentication.role,
    loadingCounter: state.page.loadingCounter,
    success: state.admin.success,
    backendErrorMessage: state.admin.errorMessage,
    company: state.admin.company,
    resellersList: state.admin.resellersList
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminCompanyEdit));
