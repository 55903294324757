import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Homepage from '../Homepage/Homepage';
import {Container, Row, Col, Card, Table} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import {useParams, withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine, faCogs, faFile, faFileExcel} from "@fortawesome/free-solid-svg-icons";
import * as actions from '../../../store/actions';
import classes from './Asset.module.css';

const Asset = (props) => {

  const {companyId, assetId} = useParams();

  useEffect(() => {
    console.log("location changed");

    props.overridePageCompanyId(companyId);
    props.fetchAssetData(assetId);
    props.fetchAlertList(assetId);
  }, [props.location]);

  useEffect(() => {

    if (props.assetData) {
      props.setPageTitle("Asset details for " + props.assetData.name + " - " + props.assetData.imei);
      let navLinks = [];

      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"})
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/asset/" + assetId, title: props.assetData.name})
      props.setPageNavLinks(navLinks)

    }

  }, [props.assetData]);


    if (props.authenticated) {

      let tableRows = null;

      if (props.alertList !== null) {

          tableRows = props.alertList.map((alert, index) => {

            return (<tr className="alert-summary-table-row" onClick={
              () => {
                props.history.push("/company/"  + companyId + "/asset/" + assetId + "/alert/" + alert.id);
              }
            }>
              <td>{index + 1}</td>
              <td className="text-capitalize">
                <span className={ alert.status + "-status-badge-table-row"}>&nbsp;</span>
                {alert.state}
              </td>
              <td>{alert.alertTypeDisplayName}</td>
              <td>{moment(alert.creationDateTime).toDate().toLocaleDateString()} {moment(alert.creationDateTime).toDate().toLocaleTimeString()}</td>
            </tr>);

        }
        );

      }

      return (<Homepage {...props} data={props.data} show={props.show}
                        setAlertFilter={props.setAlertFilter} showAssetSelection={true}
                        selectedAsset={assetId} selectedSub={props.subId}
                        selectedBuilding={props.buildingId} showCompanyInfo={true}>
        <Container fluid className="h-100 d-flex flex-fil p-0 m-0">
          <Row className="d-flex flex-fill p-0 m-0">
            <Col className="d-flex flex-fill d-flex-row align-content-between p-0 m-0">
              <Card className="bg-primary w-100 text-white h-100">
                <Container fluid className="m-0 p-0">
                  <Row className="p-0 m-0">
                    <Col xs={12}><h3>Alert summary</h3></Col>
                    <Col xs={12} className="p-0 m-0" style={{height: 200, "overflow": "auto"}}>

                        <Table responsive striped className="text-white">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>status</th>
                            <th>Alert</th>
                            <th>Date/time</th>
                          </tr>
                          </thead>
                          <tbody>

                          {tableRows}

                          <tr>
                          </tr>
                          </tbody>
                        </Table>

                    </Col>
                  </Row>
                </Container>
              </Card>
            </Col>
            <Col className="mt-3 d-flex align-content-end">
              <Container fluid className="d-flex flex-column justify-content-around align-content-end flex-row m-0 p-0">

                {/*<Container fluid className="d-flex flex-row justify-content-between align-content-end m-0 p-0 mt-auto">*/}
                  <Row className="d-flex flex-row h-100 w-100 row-cols-1 row-cols-md-5 row-cols-xl-5 align-content-end justify-content-between mx-auto">

                  <Card className={classes.AssetNavCard + " bg-primary text-white"} onClick={() => {



                    props.history.push(
                      {
                        pathname: "/company/"  +  companyId + "/asset/" + assetId + "/graphics",
                        search: "?name=" + props.assetData.name + "&assetIdentifier="
                          + props.assetData.assetIdentifier + "&buildingId=" + props.assetData.buildingId
                          + "&subId=" + props.assetData.subId
                      });

                  }}>
                    <Card.Body className="d-flex flex-fill">
                      <p className={"lead " + classes.AssetNavCardText}><FontAwesomeIcon icon={faChartLine} className="me-2"/>Graphics</p>
                    </Card.Body>
                  </Card>

                  <Card className={"bg-primary text-white " + classes.AssetNavCard} onClick={() => {

                    props.history.push(
                      {
                        pathname: "/company/"  +  companyId + "/asset/" + assetId + "/documents"
                      })}}>
                    <Card.Body>
                      <p className={"lead " + classes.AssetNavCardText}><FontAwesomeIcon icon={faFile} className="me-2"/>Documents</p>
                    </Card.Body>
                  </Card>

                  <Card className={"bg-primary text-white " + classes.AssetNavCard} onClick={() => {
                      props.history.push("/company/" + companyId + "/asset/" + assetId + "/reports")}}>
                    <Card.Body>
                      <p className={"lead " + classes.AssetNavCardText}><FontAwesomeIcon icon={faFileExcel} className="me-2"/>Reports</p>
                    </Card.Body>
                  </Card>

                  <Card className={"bg-primary text-white " + classes.AssetNavCard} onClick={() => {
                    props.history.push("/company/" + companyId + "/asset/" + assetId + "/settings")}}>
                    <Card.Body>
                      <p className={"lead " + classes.AssetNavCardText}><FontAwesomeIcon icon={faCogs} className="me-2"/>Settings</p>
                    </Card.Body>
                  </Card>

                  </Row>
                {/*</Container>*/}

              </Container>

            </Col>
          </Row>

        </Container>
      </Homepage>);
    } else {
      return (<Redirect to="/login"/>);
    }

}

const mapStateToProps = (state) => {

  return {
    data: state.plot,
    assetData: state.assets.assetData,
    alertList: state.alerts.alertList,
    authenticated: state.authentication.authenticated,
    companyId: state.page.companyId,
    assetId: state.page.assetId
  };

}

const mapDispatchToProps = dispatch => {

  return {
    fetchAlertList: (id) => dispatch(actions.fetchAlertList(id)),
    fetchAssetData: (id) => dispatch(actions.fetchAssetData(id)),
    setPageTitle: (pageTitle) => dispatch(actions.setPageTitle(pageTitle)),
    setPageNavLinks: (pageNavLinks) => dispatch({type: 'SET_PAGE_NAV_LINKS', pageNavLinks: pageNavLinks}),
    overridePageCompanyId: (companyId) => dispatch(actions.setPageCompanyId(companyId))
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Asset));