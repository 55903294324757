import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Col, Container, Modal, Row, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {faTimes, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FileBase64 from 'react-file-base64';
import * as actions from '../../../store/actions';

const CreateModal = (props) => {

  let fileReader;

  const [fileDescription, setFileDescription] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [valid, setValid] = useState(false);

  const handleFileRead = (e) => {
    const content = fileReader.result;
    // … do something with the 'content' …
  };

  const getFiles = (files) => {
    setFileData(files.base64);
    setFileName(files.name);
  }

  useEffect(() => {

    let isValid = true;

    if (!fileName || fileName.length === 0) {
      isValid = false;
    }

    if (!fileDescription || fileDescription.length === 0) {
      isValid = false;
    }

    if (!fileData || fileData.length === 0) {
      isValid = false;
    }

    setValid(isValid);

  }, [fileName, fileData, fileDescription, ])

  return (
    <Modal show={props.showModal} centered onHide={() => props.setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>

      <Modal.Body className="d-flex justify-content-center">
        <Container fluid>

          <Row>
            <Col className="d-flex justify-content-start" xs={12}>
              <Form.Label>File Description</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start" xs={12}>
              <Form.Control as="textarea" placeholder="File Description" value={fileDescription} onChange={(e) => {
                setFileDescription(e.target.value);
              }}/>
            </Col>


          </Row>
          <Row className="mt-4">
            <Col className="d-flex justify-content-center">

              <FileBase64
                multiple={ false }
                onDone={ getFiles } />

            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="d-flex justify-content-center" xs={12} >
              <Button disabled={!valid} className="bg-success me-2 text-left" style={{width: 120}} onClick={() => {

                props.setShowModal(false);

                let fileParams = {
                  companyId: props.companyId,
                  assetId: null,
                  fileData: fileData,
                  fileDescription: fileDescription,
                  fileName: fileName
                };

                props.createFile(props.companyId, fileParams, {pageNumber: props.pageNo, pageSize: props.pageSize});
              }}>
                <FontAwesomeIcon icon={faUpload} className="me-2"/>Upload</Button>
              <Button className="bg-danger bg-secondary text-left" style={{width: 120}} onClick={() => props.setShowModal(false)}>
                <FontAwesomeIcon icon={faTimes} className="me-2"/>Cancel</Button>
            </Col>
          </Row>
        </Container>

      </Modal.Body>
    </Modal>
  );


}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createFile: (companyId, fileParams, pagingParams) => dispatch(actions.createCompanyFile(companyId, fileParams, pagingParams)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateModal));