import React, {useEffect} from 'react';
import Homepage from "../Homepage/Homepage";
import DashboardContent from "./DashboardContent";
import {Redirect, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import DataService from "../../../dataService";
import * as actions from '../../../store/actions';
import {useParams} from "react-router-dom";

const Dashboard = (props) => {

  const {companyId} = useParams();

  const setFilter = (search) => {

    const params = new URLSearchParams(props.location.search);
    const alertFilter = params.get("alertFilter");

    console.log("alertFilter", alertFilter);

    let title = "Dashboard";

    let navLinks = [{url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"}];

    props.setPageInfo(null, null, null, null);

    if (!alertFilter) {
      title = "Dashboard";
      console.log("clearFilters()");
      props.setAlertFilter(null);
    } else if (alertFilter === "active") {
      title += ": Active Alerts";

      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/?alertFilter=active", title: "Active Alerts"});
      props.setAlertFilter("active");
    } else if (alertFilter === "new") {
      title += ": New Alerts";
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/?alertFilter=new", title: "New Alerts"});
      props.setAlertFilter("new");
    } else if (alertFilter === "closed") {
      title += ": Closed Alerts";
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/?alertFilter=closed", title: "Closed Alerts"});
      props.setAlertFilter("closed");
    }

    props.setPageTitle(title);
    props.setPageNavLinks(navLinks);

  }

  useEffect(() => {
    if (props.alertFilter) {
      const buttons = document.getElementsByClassName("expand-button");

      for (let i = 0; i < buttons.length; i++) {
        buttons[i].click();
      }
    }  else {
      const buttons = document.getElementsByClassName("collapse-button");

      for (let i = 0; i < buttons.length; i++) {
        buttons[i].click();
      }
    }

  }, [props.alertFilter]);

  useEffect(() => {
    console.log("search", props.location.search);
    // props.setAssetData(null);
    setFilter(props.location.search);
  }, [props.location.search])

  useEffect(() => {
    // props.setAssetData(null);
  }, []);

  if (props.authenticated) {
    setFilter(props.location.search);

    return (<DataService>
      <Homepage {...props} data={props.data}
                search={props.location.search}
                showAssetSelection={true} showCompanyInfo={true}>
        <DashboardContent data={props.data} companyId={companyId} selectedAlertType={props.alertFilter}/>
      </Homepage>
    </DataService>);
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapStateToProps = (state) => {

  return {
    data: state.company.data,
    alertFilter: state.filter.alertFilter,
    authenticated: state.authentication.authenticated,
    companyId: state.page.companyId
  };

}

const mapDispatchToProps = dispatch => {

  return {
    setAlertFilter: (filter) => dispatch(actions.setAlertFilter(filter)),
    setPageTitle: (pageTitle) => dispatch({type: 'SET_PAGE_TITLE', pageTitle: pageTitle}),
    setPageNavLinks: (navLinks) => dispatch({type: 'SET_PAGE_NAV_LINKS', pageNavLinks: navLinks}),
    setAssetData: (data) => dispatch(actions.setAssetData(data)),
    clearFilters: () => dispatch(actions.clearFilters()),
    setPageInfo: (bId, s1Id, s2Id, assetId) => dispatch(actions.setPageInfo(bId, s1Id, s2Id, assetId))
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));