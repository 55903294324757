import React from 'react';
import Homepage from '../../Homepage/Homepage';
import {Container, Row, Col} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import NavCard from "../../Dashboard/NavCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faThermometerQuarter} from "@fortawesome/free-solid-svg-icons";

const AssetReports = (props) => {

  if (props.authenticated) {

    return (
      <Homepage {...props} data={props.data} show={true} title={"Company Reports"}
                setAssetSelection={false} showCompanyInfo={true}>
        <Container fluid className="align-self-start h-100">
          <Row className="h-100">
            <Col className="h-100">
              <Container fluid className="h-100">
                <Row
                  className="d-flex flex-row h-100 w-100 row-cols-1 row-cols-md-2 row-cols-xl-3 align-content-between mx-auto">
                  <NavCard clickFunction={() => props.history.push({pathname: "/company/" + props.companyId + "/asset/" + props.assetId + "/reports/alertHistory"})}>
                    <FontAwesomeIcon icon={faBell} className="me-2"></FontAwesomeIcon>
                    Alert History
                  </NavCard>
                  <NavCard
                    clickFunction={() => props.history.push({pathname: "/company/" + props.companyId + "/asset/"
                        + props.assetId + "/reports/rawData"})}>
                    <FontAwesomeIcon icon={faThermometerQuarter} className="me-2"></FontAwesomeIcon>
                    Raw Data
                  </NavCard>

                </Row>
              </Container>

            </Col>
          </Row>
        </Container>
      </Homepage>
    );
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    selectedCompanyData: state.company.selectedCompanyData,
    companyId: state.page.companyId,
    assetId: state.page.assetId
  }
}

export default connect(mapStateToProps)(withRouter(AssetReports));
