import React, {useEffect, useState} from 'react';
import Homepage from '../../Homepage/Homepage'
import {Container, Row, Col, Card, Button, Form, Alert} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {matchPath, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import CreatableSelect from "react-select/creatable";
import {setBuildingFilter} from "../../../../store/actions";
import {faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setAdminUpdateSuccess} from "../../../../store/actions/admin";
import classes from './AdminAssetEdit.module.css';

const AdminAssetEdit = (props) => {
  const [valid, setValid] = useState(false);
  const [iconId, setIconId] = useState(null);
  const [icon, setIcon] = useState(null);
  const [assetName, setAssetName] = useState(null);
  const [imei, setImei] = useState(null);
  const [buildingId, setBuildingId] = useState(null);
  const [buildingName, setBuildingName] = useState(null);
  const [buildingSubId, setBuildingSubId] = useState(null);
  const [buildingSubName, setBuildingSubName] = useState(null);
  const [buildingSub2Id, setBuildingSub2Id] = useState(null);
  const [buildingSub2Name, setBuildingSub2Name] = useState(null);
  const [buildingSub3Id, setBuildingSub3Id] = useState(null);
  const [buildingSub3Name, setBuildingSub3Name] = useState(null);
  const [buildingOptions, setBuildingOptions] = useState(null);
  const [subGroup1Options, setSubgroup1Options] = useState(null);
  const [subGroup2Options, setSubgroup2Options] = useState(null);
  const [subGroup3Options, setSubgroup3Options] = useState(null);
  const [iconOptions, setIconOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [sentinelPoint, setSentinelPoint] = useState(null);

  const getParams = pathname => {
    const matchAsset = matchPath(pathname, {
      path: "/company/:companyId/admin/asset/edit/:assetId",
      exact: false
    });

    return (matchAsset && matchAsset.params) || {};
  }

  useEffect(() => {

    props.getAdminBuildingList(props.companyId);

    if (buildingId !== null) {
      props.getAdminSubgroup1List(props.companyId, buildingId);
    }

    if (buildingSubId !== null) {
      props.getAdminSubgroup2List(props.companyId, buildingSubId);
    }

    if (buildingSub2Id !== null) {
      props.getAdminSubgroup3List(props.companyId, buildingSub2Id);
    }




  }, [props.success])

  const getAssetId = () => {
    let {assetId} = getParams(props.location.pathname);
    return parseInt(assetId);
  }

  useEffect(() => {

    setAdminUpdateSuccess(null, null);
    props.setAdminUpdateSuccess(false, null);
    props.setPageTitle("Company Administration");

    if (!props.createMode) {
      props.getAdminAsset(getAssetId());
    } else {
      props.setAdminAsset(null);
    }

    props.getAdminBuildingList(props.companyId);
    props.getIconList();

  },[]);

  useEffect(() => {

    let localValid = true;
    let errorMessages = []

    if (!assetName || assetName.length === 0) {
      localValid = false;
      errorMessages.push("Asset Name must be supplied");
    }

    if (!buildingName || buildingName > 0) {
      localValid = false;
      errorMessages.push("Building must be selected or entered");
    }

    if (!iconId || iconId === 0) {
      localValid = false;
      errorMessages.push("Icon Type must be selected");
    }

    if (!imei || imei.length === 0) {
      localValid = false;
      errorMessages.push("IMEI must be provided");
    }

    setErrorMessage(errorMessages);
    setValid(localValid);

  }, [iconId, buildingName, buildingSubName, buildingSub2Name, buildingSub3Name, assetName, imei]);

  useEffect(() => {
    if (props.buildings) {
      setBuildingOptions(props.buildings.map(building => {
        return {value: building.id, label: building.name};
      }));
    }

  }, [props.buildings]);

  useEffect(() => {

    if (props.iconList) {
      let newIconOptions = props.iconList.map(icon => <option value={icon.id}>{icon.name}</option>);
      setIconOptions(newIconOptions);

      if (props.createMode) {
        setIconId(props.iconList[0].id);
        setIcon(props.iconList[0].name);
      }

    }

  }, [props.iconList]);

  useEffect(() => {

    if (props.subGroup1s) {
      setSubgroup1Options(props.subGroup1s.map(group => {
        return {value: group.id, label: group.name};
      }));
    }

  }, [props.subGroup1s]);

  useEffect(() => {

    if (props.subGroup2s) {
      setSubgroup2Options(props.subGroup2s.map(group => {
        return {value: group.id, label: group.name};
      }));
    }

  }, [props.subGroup2s]);

  useEffect(() => {

    if (props.subGroup3s) {
      setSubgroup3Options(props.subGroup3s.map(group => {
        return {value: group.id, label: group.name};
      }));
    }

  }, [props.subGroup3s]);

  useEffect(() => {

    if (props.asset) {
      setAssetName(props.asset.name);
      setImei(props.asset.imei);
      setBuildingId(props.asset.buildingId);
      setBuildingName(props.asset.buildingName);
      setBuildingSubId(props.asset.subGroup1Id);
      setBuildingSubName(props.asset.subGroup1Name);
      setBuildingSub2Id(props.asset.subGroup2Id);
      setBuildingSub2Name(props.asset.subGroup2Name);
      setBuildingSub3Id(props.asset.subGroup3Id);
      setBuildingSub3Name(props.asset.subGroup3Name);
      setIcon(props.asset.iconName);
      setIconId(props.asset.iconId);
      setSentinelPoint(props.asset.sentinelPoint);

      if (props.asset.buildingId !== null) {
        props.getAdminSubgroup1List(props.companyId, props.asset.buildingId);
      }

      if (props.asset.subGroup1Id !== null) {
        props.getAdminSubgroup2List(props.companyId, props.asset.subGroup1Id);
      }

      if (props.asset.subGroup2Id !== null) {
        props.getAdminSubgroup3List(props.companyId, props.asset.subGroup2Id);
      }

      let nav = [];
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId, title: "Dashboard"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin", title: "Admin"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/asset", title: "Asset"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/asset/edit/" + getAssetId(), title: props.asset.name});
      props.setPageNavLinks(nav);

    } else {
      setAssetName("");
      setImei("");
      setBuildingId(null);
      setBuildingName(null);
      setBuildingSubId(null);
      setBuildingSubName(null);
      setBuildingSub2Id(null);
      setBuildingSub2Name(null);
      setBuildingSub3Id(null);
      setBuildingSub3Name(null);
      setIcon(null);
      setSentinelPoint(false);

      let nav = [];

      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId, title: "Dashboard"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin", title: "Admin"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/asset", title: "Asset"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/asset/create", title: "Create Asset"});
      props.setPageNavLinks(nav);

    }

  }, [props.asset])

  if (props.authenticated) {

    let errors = null;

    if (formSubmitted && (props.backendErrorMessage || (errorMessage && errorMessage.length > 0)) && props.loadingCounter === 0) {

      let errorOptions = errorMessage.map(message => <li>{message}</li>);

      if (props.backendErrorMessage) {
        errorOptions.push([<li>{props.backendErrorMessage}</li>]);
      }

      errors = (<Row>
        <Col xs={12}>
          <Alert variant="danger">
            <h4>The following errors exist: -</h4>
            <ul>
              {errorOptions}
            </ul>
          </Alert>
        </Col>
      </Row>)
    } else if (props.success) {
      errors = (<Row>
        <Col xs={12}>
          <Alert variant="success">
            <h4>Asset {props.createMode ? "created" : "updated"}</h4>
          </Alert>
        </Col>
      </Row>)

    }

    return (
      <Homepage {...props} data={props.data} show={true} title={"Company Administration"}
                setAssetSelection={false} showCompanyInfo={true}>
        <Container fluid className="align-items-start h-100">
          <Row className="align-items-start h-100">
            <Col className="align-items-start h-100">
              <Card className="h-100 bg-primary w-100 text-white align-items-start">
                <Container className="h-100">
                  {errors}
                  <Row>
                    <Col xs={12} className="col-sm-6">
                      <Form.Group controlId="adminAsset.AssetNameControl">
                        <Form.Label>Asset Name</Form.Label>
                        <Form.Control value={assetName} type="text" placeholder="Asset name" onChange={(e) => setAssetName(e.target.value)}
                        className={!assetName || assetName.length === 0 ? classes.FormControlError : ""}/>
                      </Form.Group>
                    </Col>
                    <Col xs={12} className="col-sm-6">

                      <Container fluid className="m-0 p-0">
                        <Row>
                          <Col className="col-sm-8">
                            <Form.Group controlId="adminAsset.AssetNameControl">
                              <Form.Label>Icon</Form.Label>
                              <Form.Control as="select" value={iconId} type="text" placeholder="Icon"
                                            className={!icon ? classes.FormControlError : ""}
                                            onChange={(e) => {
                                              let index = e.nativeEvent.target.selectedIndex;
                                              let label = e.nativeEvent.target[index].text;
                                              setIcon(label);
                                              setIconId(e.target.value);
                                            }
                                            }>
                                {iconOptions}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="col-sm-4">
                            <Form.Group controlId="adminAsset.AssetNameControl">
                              <Form.Label>Sentinel Point</Form.Label>
                              <Form.Check className="" type="checkbox" checked={sentinelPoint} onChange={(e) => {
                                setSentinelPoint(!sentinelPoint);
                              }}/>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Container>




                    </Col>


                  </Row>
                  <Row>
                    <Col xs={12} className="col-sm-6">
                      <Form.Group controlId="adminAsset.AssetIMEI">
                        <Form.Label>IMEI</Form.Label>
                        <Form.Control value={imei} type="text" placeholder="IMEI"
                                      className={!imei || imei.length === 0 ? classes.FormControlError : ""}
                                      onChange={(e) => setImei(e.target.value)}/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="col-sm-3">
                      <Form.Group controlId="exampleForm.ControlSelect2">
                        <Form.Label>Building</Form.Label>
                        <CreatableSelect className={"text-dark " +  (!buildingId ? classes.FormControlError : "")} style={{height: "48px"}}
                            isClearable
                            onChange={(newValue, actionMeta) => {
                              if (actionMeta.action === "select-option") {
                                setBuildingId(newValue.value);
                                setBuildingName(newValue.label);
                                setBuildingSubId(null);
                                setBuildingSubName(null);
                                setBuildingSub2Name(null);
                                setBuildingSub2Id(null);
                                setBuildingSub3Name(null);
                                setBuildingSub3Id(null);
                                props.getAdminSubgroup1List(props.companyId, newValue.value);
                              } else if (actionMeta.action === "clear") {
                                setBuildingName(null);
                                setBuildingId(null);
                                setBuildingSubId(null);
                                setBuildingSubName(null);
                                setBuildingSub2Name(null);
                                setBuildingSub2Id(null);
                                setBuildingSub3Name(null);
                                setBuildingSub3Id(null);

                              } else if (actionMeta.action === "create-option") {
                                setBuildingFilter(buildingOptions.push({value: null, label: newValue.value}));
                                setBuildingId(null);
                                setBuildingName(newValue.value);
                              }

                              }}
                            onInputChange={(inputValue, actionMeta) => {
                                                                            // handleInputChange(inputValue, actionMeta);
                              if (actionMeta.action === "input-change") {
                                setBuildingName(inputValue);
                              }
                            }}
                            isValidNewOption  = {() => { return true}}
                            options={buildingOptions}
                            value={{value: buildingId, label: buildingName}}
                    />
                      </Form.Group>

                    </Col>

                    <Col xs={12} className="col-sm-3">
                      <Form.Group controlId="exampleForm.ControlSelect2">
                        <Form.Label>Building Sub group 1</Form.Label>
                        <CreatableSelect className={"text-dark " +  (!buildingSubId ? classes.FormControlError : "")} style={{height: "48px"}}
                                         isClearable
                                         onChange={(newValue, actionMeta) => {

                                           if (actionMeta.action === "select-option") {
                                             setBuildingSubId(newValue.value);
                                             setBuildingSubName(newValue.label);
                                             setBuildingSub2Name(null);
                                             setBuildingSub2Id(null);
                                             setBuildingSub3Name(null);
                                             setBuildingSub3Id(null);

                                             props.getAdminSubgroup2List(props.companyId, newValue.value);
                                           } else if (actionMeta.action === "clear") {
                                             setBuildingSubId(null);
                                             setBuildingSubName(null);
                                             setBuildingSub2Name(null);
                                             setBuildingSub2Id(null);
                                             setBuildingSub3Name(null);
                                             setBuildingSub3Id(null);

                                           }
                                         }}
                                         onInputChange={(inputValue, actionMeta) => {

                                           // handleInputChange(inputValue, actionMeta);
                                           if (actionMeta.action === "input-change") {
                                             setBuildingSubId(null);
                                             setBuildingSubName(inputValue);
                                           } else if (actionMeta.action === "create-option") {
                                             setSubgroup1Options(subGroup1Options.push({value: null, label: inputValue}));
                                             setBuildingSubId(null);
                                             setBuildingSubName(inputValue);
                                             setBuildingSub2Id(null);
                                             setBuildingSub2Name(null);
                                             setBuildingSub3Id(null);
                                             setBuildingSub3Name(null);

                                           }
                                         }}
                                         isValidNewOption  = {() => { return true}}
                                         // getNewOptionData = {(inputValue, optionLabel) => optionLabel}
                                         options={subGroup1Options}
                                         value={{value: buildingSubId, label: buildingSubName}}
                                         isDisabled={buildingName === null || buildingName === ""}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} className="col-sm-3">
                      <Form.Group controlId="exampleForm.ControlSelect2">
                        <Form.Label>Building Sub group 2</Form.Label>
                        <CreatableSelect className="text-dark" style={{height: "48px"}}
                                         isClearable
                                         onChange={(newValue, actionMeta) => {
                                           if (actionMeta.action === "select-option") {
                                             setBuildingSub2Id(newValue.value);
                                             setBuildingSub2Name(newValue.label);
                                             props.getAdminSubgroup3List(props.companyId, newValue.value);
                                           } else if (actionMeta.action === "clear") {
                                             setBuildingSub2Name("");
                                           }
                                         }}
                                         onInputChange={(inputValue, actionMeta) => {
                                           // handleInputChange(inputValue, actionMeta);
                                           if (actionMeta.action === "input-change") {
                                             setBuildingSub2Name(inputValue);
                                           } else if (actionMeta.action === "create-option") {
                                             setBuildingFilter(buildingOptions.push({value: null, label: inputValue}));
                                             setBuildingSub2Id(null);
                                             setBuildingSub2Name(inputValue.label);
                                           }
                                         }}
                                         isValidNewOption  = {() => { return true}}
                                         // getNewOptionData = {(inputValue, optionLabel) => optionLabel}
                                         options={subGroup2Options}
                                         value={{value: buildingSub2Id, label: buildingSub2Name}}
                                         isDisabled={buildingSubName === null}
                        />
                      </Form.Group>
                    </Col>
                      <Col xs={12} className="col-sm-3">
                        <Form.Group controlId="exampleForm.ControlSelect2">
                          <Form.Label>Building Sub group 3</Form.Label>
                          <CreatableSelect className="text-dark" style={{height: "48px"}}
                                           isClearable
                                           onChange={(newValue, actionMeta) => {

                                             if (actionMeta.action === "select-option") {
                                               setBuildingSub3Id(newValue.value);
                                               setBuildingSub3Name(newValue.label);
                                               // updateSettingName(index, newValue.value);
                                             } else if (actionMeta.action === "clear") {
                                               setBuildingSub3Name("");
                                             }
                                           }}
                                           onInputChange={(inputValue, actionMeta) => {
                                             // handleInputChange(inputValue, actionMeta);
                                             if (actionMeta.action === "input-change") {
                                               setBuildingSub3Name(inputValue);
                                             } else if (actionMeta.action === "create-option") {
                                               setBuildingFilter(buildingOptions.push({value: null, label: inputValue}));
                                               setBuildingSub3Id(null);
                                               setBuildingSub3Name(inputValue.label);
                                             }
                                           }}
                                           isValidNewOption  = {() => { return true}}
                            // getNewOptionData = {(inputValue, optionLabel) => optionLabel}
                                           options={subGroup3Options}
                                           value={{value: buildingSub3Id, label: buildingSub3Name}}
                                           isDisabled={buildingSubName === null}
                          />
                        </Form.Group>
                      </Col>

                  </Row>

                  <Row>
                    <Col xs={12} className="d-flex justify-content-center mt-auto flex-auto">
                      <Button style={{width: 100}} className="text-left bg-success me-3" disabled={!valid}
                        onClick={() => {

                          setFormSubmitted(true);

                          let assetData =  {
                            imei: imei,
                            name: assetName,
                            buildingName: buildingName,
                            buildingSubGroup1Name: buildingSubName,
                            buildingSubGroup2Name: buildingSub2Name,
                            buildingSubGroup3Name: buildingSub3Name,

                            assetIcon: icon,
                            companyId: props.companyId,
                            sentinelPoint: sentinelPoint
                          };

                          if (props.createMode) {
                            props.createAsset(assetData);
                          } else {
                            props.updateAsset(getAssetId(), assetData);
                          }
                        }}

                      >
                        <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>&nbsp;Save
                      </Button>
                      <Button style={{width: 100}} className="text-left bg-danger" onClick={() => {
                        props.history.push("/company/" + props.companyId + "/admin/asset");
                      }}>
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>&nbsp;Cancel
                      </Button>

                    </Col>
                  </Row>

                </Container>
              </Card>
            </Col>
          </Row>
        </Container>

      </Homepage>
    );
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (nav) => dispatch(actions.setPageNavLinks(nav)),
    getAdminAsset: (assetId) => dispatch(actions.fetchAdminAsset(assetId)),
    getAdminBuildingList: (companyId) => dispatch(actions.fetchAdminBuildingList(companyId)),
    getAdminSubgroup1List: (companyId, buildingId) => dispatch(actions.fetchAdminSubGroup1List(companyId, buildingId)),
    getAdminSubgroup2List: (companyId, sub1Id) => dispatch(actions.fetchAdminSubGroup2List(companyId, sub1Id)),
    getAdminSubgroup3List: (companyId, sub2Id) => dispatch(actions.fetchAdminSubGroup3List(companyId, sub2Id)),
    getIconList: () => dispatch(actions.fetchIcons()),
    updateAsset: (assetId, assetData) => dispatch(actions.updateAsset(assetId, assetData)),
    setAdminAsset: (asset) => dispatch(actions.setAdminAsset(asset)),
    setAdminUpdateSuccess: (success, errorMessage) => dispatch(setAdminUpdateSuccess(success, errorMessage)),
    createAsset: (assetData) => dispatch(actions.createAsset(assetData))
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    role: state.authentication.role,
    selectedCompanyData: state.company.selectedCompanyData,
    companyId: state.page.companyId,
    asset: state.admin.asset,
    buildings: state.admin.buildings,
    subGroup1s: state.admin.subGroup1s,
    subGroup2s: state.admin.subGroup2s,
    subGroup3s: state.admin.subGroup3s,
    iconList: state.admin.iconList,
    loadingCounter: state.page.loadingCounter,
    success: state.admin.success,
    backendErrorMessage: state.admin.errorMessage
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminAssetEdit));
