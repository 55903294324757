import * as actionTypes from "./actionTypes";
import * as actions from './index';
import agent from "../../app/api/agent";

export const setAdminAssetData = (assetData, pagination) => {
  return {
    type: actionTypes.SET_ADMIN_ASSET_DATA,
    assetData: assetData,
    pagination: pagination
  }
}

export const setAdminAsset = (asset) => {
  return {
    type: actionTypes.SET_ADMIN_ASSET,
    asset: asset
  }
}

export const setAdminBuildingList = (buildings) => {
  return {
    type: actionTypes.SET_ADMIN_BUILDING_LIST,
    buildings: buildings
  }
}

export const setAdminSubgroup1List = (subGroup1s) => {
  return {
    type: actionTypes.SET_ADMIN_SUBGROUP1_LIST,
    subGroup1s: subGroup1s
  }
}

export const setAdminSubgroup2List = (subGroup2s) => {
  return {
    type: actionTypes.SET_ADMIN_SUBGROUP2_LIST,
    subGroup2s: subGroup2s
  }
}

export const setAdminSubgroup3List = (subGroup3s) => {
  return {
    type: actionTypes.SET_ADMIN_SUBGROUP3_LIST,
    subGroup3s: subGroup3s
  }
}

export const setAdminIconsList = (iconList) => {
  return {
    type: actionTypes.SET_ADMIN_ICONS_LIST,
    iconList: iconList
  }
}

export const setAdminUpdateSuccess = (success, errorMessage) => {
  return {
    type: actionTypes.SET_ADMIN_UPDATE_SUCCESS,
    success: success,
    errorMessage: errorMessage
  }
}
export const setAdminResellersList = (resellersList) => {
  return {
    type: actionTypes.SET_ADMIN_RESELLERS_LIST,
    resellersList: resellersList
  }
}

export const createCompany = (companyData) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.AdminCompany.createCompany(companyData)
      .then(
        response => {
          dispatch(actions.decrementPageLoading());
          dispatch(setAdminUpdateSuccess(true, null));
        }).catch(err => {
          dispatch(setAdminUpdateSuccess(false, err));
        });
  }
}

export const updateCompany = (companyId, companyData) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.AdminCompany.updateCompany(companyId, companyData)
      .then(
        response => {
          dispatch(actions.decrementPageLoading());
          dispatch(setAdminUpdateSuccess(true, null));
        }).catch(err => {
      dispatch(setAdminUpdateSuccess(false, err));
    });

  }
}

export const createBulkAsset = (assetDataArray, index) => {
  return dispatch => {

    if (assetDataArray.length === 0) {
      return;
    }

    dispatch(actions.incrementPageLoading());
    dispatch(setAdminBulkStatus(index, "Processing asset", null));

    agent.AdminAsset.createBulkAsset(assetDataArray.shift())
      .then(
        response => {
          dispatch(actions.decrementPageLoading());
          dispatch(setAdminBulkStatus(index, "Asset created", true));
          dispatch(createBulkAsset(assetDataArray, ++index));

        }).catch(err => {
          dispatch(setAdminBulkStatus(index, err, false));
          dispatch(actions.decrementPageLoading());
          dispatch(createBulkAsset(assetDataArray, ++index));
    });

  }
}

export const initialiseBulkStatus = (numberOfAssets) => {
  return {
    type: actionTypes.INIT_ADMIN_BULK_STATUS,
    numberOfAssets: numberOfAssets,
  }
}

export const setAdminBulkStatus = (index, status, success) => {
  return {
    type: actionTypes.SET_ADMIN_BULK_STATUS,
    success: success,
    status: status,
    index: index
  }
}

export const setAdminUserInviteList = (userInviteList, pagination) => {
  return {
    type: actionTypes.SET_ADMIN_USER_INVITE_LIST,
    userInviteList: userInviteList,
    pagination: pagination
  }
}

export const fetchAdminAssetData = (companyId, showDeleted, pageNo, pageSize) => {
  return dispatch => {

    let requestObject = {pageNumber: pageNo, pageSize: pageSize};
    dispatch(actions.incrementPageLoading());

    agent.AdminAsset.getAdminCompanyAssets(companyId, showDeleted, requestObject)
      .then(
        response => {
          dispatch(setAdminAssetData(response.data, response.pagination));
          dispatch(actions.decrementPageLoading());

        });

  }
}

export const fetchAdminUserList = (companyId, pageNo, pageSize) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize};
    dispatch(actions.incrementPageLoading());

    agent.AdminUser.listUsers(companyId, pageParams)
      .then(
        response => {
          dispatch(setAdminUserList(response.data, response.pagination));
          dispatch(actions.decrementPageLoading());

        });

  }
}

export const fetchAdminUserInviteList = (companyId, pageNo, pageSize) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize};
    dispatch(actions.incrementPageLoading());

    agent.AdminUser.listUserInvites(companyId, pageParams)
      .then(
        response => {
          dispatch(setAdminUserInviteList(response.data, response.pagination));
          dispatch(actions.decrementPageLoading());

        });

  }
}

export const updateBuilding = (companyId, buildingId, pageNo, pageSize, showDeleted, name) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize, showDeleted};
    dispatch(actions.incrementPageLoading());

    agent.AdminBuildings.updateBuilding(buildingId, name)
      .then(
        response => {
          dispatch(actions.fetchAdminBuildingEditList(companyId, pageNo, pageSize, showDeleted));
          dispatch(actions.decrementPageLoading());

        });

  }
}

export const deleteBuilding = (companyId, buildingId, pageNo, pageSize, showDeleted, value) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize, showDeleted};
    dispatch(actions.incrementPageLoading());

    agent.AdminBuildings.deleteBuilding(buildingId, value)
      .then(
        response => {
          dispatch(actions.fetchAdminBuildingEditList(companyId, pageNo, pageSize, showDeleted));
          dispatch(actions.decrementPageLoading());
        });

  }
}

export const deleteSub1 = (companyId, sub1Id, pageNo, pageSize, showDeleted, value) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize, showDeleted};
    dispatch(actions.incrementPageLoading());

    agent.AdminBuildings.deleteSub1(sub1Id, value)
      .then(
        response => {
          dispatch(actions.fetchAdminBuildingEditList(companyId, pageNo, pageSize, showDeleted));
          dispatch(actions.decrementPageLoading());
        });

  }
}

export const deleteSub2 = (companyId, sub2Id, pageNo, pageSize, showDeleted, value) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize, showDeleted};
    dispatch(actions.incrementPageLoading());

    agent.AdminBuildings.deleteSub2(sub2Id, value)
      .then(
        response => {
          dispatch(actions.fetchAdminBuildingEditList(companyId, pageNo, pageSize, showDeleted));
          dispatch(actions.decrementPageLoading());
        });

  }
}

export const deleteSub3 = (companyId, sub3Id, pageNo, pageSize, showDeleted, value) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize, showDeleted};
    dispatch(actions.incrementPageLoading());

    agent.AdminBuildings.deleteSub3(sub3Id, value)
      .then(
        response => {
          dispatch(actions.fetchAdminBuildingEditList(companyId, pageNo, pageSize, showDeleted));
          dispatch(actions.decrementPageLoading());
        });

  }
}

export const updateSub1 = (companyId, buildingId, pageNo, pageSize, showDeleted, name) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize, showDeleted};
    dispatch(actions.incrementPageLoading());

    agent.AdminBuildings.updateSub1(buildingId, name)
      .then(
        response => {
          dispatch(actions.fetchAdminBuildingEditList(companyId, pageNo, pageSize, showDeleted));
          dispatch(actions.decrementPageLoading());
        });

  }
}

export const updateSub2 = (companyId, buildingId, pageNo, pageSize, showDeleted, name) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize, showDeleted};
    dispatch(actions.incrementPageLoading());

    agent.AdminBuildings.updateSub2(buildingId, name)
      .then(
        response => {
          dispatch(actions.fetchAdminBuildingEditList(companyId, pageNo, pageSize, showDeleted));
          dispatch(actions.decrementPageLoading());
        });

  }
}

export const updateSub3 = (companyId, buildingId, pageNo, pageSize, showDeleted, name) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize, showDeleted};
    dispatch(actions.incrementPageLoading());

    agent.AdminBuildings.updateSub3(buildingId, name)
      .then(
        response => {
          dispatch(actions.fetchAdminBuildingEditList(companyId, pageNo, pageSize, showDeleted));
          dispatch(actions.decrementPageLoading());
        });

  }
}


export const fetchAdminBuildingEditList = (companyId, pageNo, pageSize, showDeleted) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize, showDeleted: showDeleted};
    dispatch(actions.incrementPageLoading());

    agent.AdminBuildings.listEditBuildings(companyId, pageParams)
      .then(
        response => {
          dispatch(setAdminBuildingEditList(response.data, response.pagination));
          dispatch(actions.decrementPageLoading());

        });

  }
}

export const fetchAdminCompanyList = (pageNo, pageSize, showDeleted) => {
  return dispatch => {

    let pageParams = {pageNumber: pageNo, pageSize: pageSize, showDeleted: showDeleted};
    dispatch(actions.incrementPageLoading());

    agent.AdminCompany.listCompanies(pageParams)
      .then(
        response => {
          dispatch(setAdminCompanyList(response.data, response.pagination));
          dispatch(actions.decrementPageLoading());

        });

  }
}

export const fetchAdminCompanyListAll = () => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.AdminCompany.getAdminCompanyListAll()
      .then(
        response => {
          dispatch(setAdminCompanyList(response, null));
          dispatch(actions.decrementPageLoading());
        });

  }
}


export const setAdminCompanyList = (list, pagination) => {
  return {
    type: actionTypes.SET_ADMIN_COMPANY_LIST,
    companyList: list,
    pagination: pagination
  }
}

export const setAdminUserList = (list, pagination) => {
  return {
    type: actionTypes.SET_ADMIN_USER_LIST,
    userList: list,
    pagination: pagination
  }
}

export const setAdminCompany = (company) => {
  return {
    type: actionTypes.SET_ADMIN_COMPANY,
    company: company
  }
}

export const setAdminBuildingEditList = (editBuildingList, pagination) => {
  return {
    type: actionTypes.SET_ADMIN_BUILDING_EDIT_LIST,
    editBuildingList: editBuildingList,
    pagination: pagination
  }
}


export const fetchAdminAsset = (assetId) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.AdminAsset.getAdminCompanyAsset(assetId)
      .then(
        response => {
          dispatch(actions.decrementPageLoading());

          dispatch(setAdminAsset(response));
        });

  }
}

export const fetchAdminBuildingList = (companyId) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.AdminBuildings.listBuildings(companyId)
      .then(
        response => {
          dispatch(actions.decrementPageLoading());
          dispatch(setAdminBuildingList(response));
        });

  }
}

export const fetchAdminSubGroup1List = (companyId, buildingId) => {
  return dispatch => {
    agent.AdminBuildings.listSubgroup1s(companyId, buildingId)
      .then(
        response => {
          dispatch(setAdminSubgroup1List(response));
        });

  }
}

export const fetchAdminSubGroup2List = (companyId, sub1Id) => {
  return dispatch => {
    agent.AdminBuildings.listSubgroup2s(companyId, sub1Id)
      .then(
        response => {
          dispatch(setAdminSubgroup2List(response));
        });

  }
}

export const fetchAdminSubGroup3List = (companyId, sub2Id) => {
  return dispatch => {
    agent.AdminBuildings.listSubgroup3s(companyId, sub2Id)
      .then(
        response => {
          dispatch(setAdminSubgroup3List(response));
        });

  }
}

export const fetchIcons = () => {
  return dispatch => {
    agent.AdminAsset.listIcons()
      .then(
        response => {
          dispatch(setAdminIconsList(response));
        });

  }
}

export const updateAsset = (assetId, assetData) => {

  return dispatch => {

    dispatch(setAdminUpdateSuccess(null, null));
    dispatch(actions.incrementPageLoading());

    agent.AdminAsset.updateAsset(assetId, assetData)
      .then(response => {
        dispatch(setAdminUpdateSuccess(true, null));
        dispatch(actions.decrementPageLoading());

      })
      .catch(err => {
        dispatch(setAdminUpdateSuccess(false, err));
      })
  }
}

export const updateUser = (userId, userData) => {

  return dispatch => {

    dispatch(setAdminUpdateSuccess(null, null));

    agent.AdminUser.updateUser(userId, userData)
      .then(response => {
        dispatch(setAdminUpdateSuccess(true, null));

      })
      .catch(err => {
        dispatch(setAdminUpdateSuccess(false, err));
      })
  }
}

export const createUser = (userData) => {

  return dispatch => {
    dispatch(setAdminUpdateSuccess(null, null));
    dispatch(actions.incrementPageLoading());

    agent.AdminUser.createUser(userData)
      .then(response => {
        dispatch(setAdminUpdateSuccess(true, null));
        dispatch(actions.decrementPageLoading());

      })
      .catch(err => {
        dispatch(setAdminUpdateSuccess(false, err));
      })
  }
}

export const createUserInvite = (userData) => {

  return dispatch => {
    dispatch(setAdminUpdateSuccess(null, null));
    dispatch(actions.incrementPageLoading());

    agent.AdminUser.createUserInvite(userData)
      .then(response => {
        dispatch(setAdminUpdateSuccess(true, null));
        dispatch(actions.decrementPageLoading());

      })
      .catch(err => {
        console.log(err);
        dispatch(setAdminUpdateSuccess(false, err));
      })
  }
}

export const createAccountFromInvite = (userData) => {

  return dispatch => {
    dispatch(setAdminUpdateSuccess(null, null));
    dispatch(actions.incrementPageLoading());

    agent.AdminUser.createAccountFromInvite(userData)
      .then(response => {
        dispatch(setAdminUpdateSuccess(true, null));
        dispatch(actions.decrementPageLoading());

      })
      .catch(err => {
        dispatch(setAdminUpdateSuccess(false, err));
      })
  }
}


export const createAsset = (assetData) => {

  return dispatch => {

    dispatch(setAdminUpdateSuccess(null, null));
    dispatch(actions.incrementPageLoading());

    agent.AdminAsset.createAsset(assetData)
      .then(
        response => {
          dispatch(setAdminUpdateSuccess(true, null));
          dispatch(actions.decrementPageLoading());
      })
      .catch(err => {
          dispatch(setAdminUpdateSuccess(false, err));
      })
  }
}

export const getResellersList = () => {
  return dispatch => {
    dispatch(actions.incrementPageLoading());

    agent.Admin.listResellers()
      .then(
        response => {
          dispatch(setAdminResellersList(response));
          dispatch(actions.decrementPageLoading());

        });
  }
}
export const getAdminCompany = (companyId) => {
  return dispatch => {
    dispatch(actions.incrementPageLoading());

    agent.AdminCompany.getAdminCompany(companyId)
      .then(
        response => {
          dispatch(setAdminCompany(response));
          dispatch(actions.decrementPageLoading());

        });
  }
}


export const deleteAsset = (companyId, assetId, deleteAsset) => {

  return (dispatch, getState) => {

    const {pagination} = getState().admin;
    dispatch(actions.incrementPageLoading());
    dispatch(setAdminUpdateSuccess(null, null));

    agent.AdminAsset.deleteAsset(assetId, deleteAsset)
      .then(
        response => {
          dispatch(setAdminUpdateSuccess(true, null));
          dispatch(fetchAdminAssetData(companyId, pagination.pageNumber, pagination.pageSize));
          dispatch(actions.decrementPageLoading());

        }
      )
      .catch(error => {
        setAdminUpdateSuccess(false, error);
      })
  }
}

export const deleteCompany = (companyId, deleteCompany) => {

  return (dispatch, getState) => {

    const {pagination} = getState().admin;
    dispatch(actions.incrementPageLoading());
    dispatch(setAdminUpdateSuccess(null, null));

    agent.AdminCompany.deleteCompany(companyId, deleteCompany)
      .then(
        response => {
          dispatch(setAdminUpdateSuccess(true, null));
          dispatch(fetchAdminCompanyList(pagination.pageNumber, pagination.pageSize));
          dispatch(actions.decrementPageLoading());

        }
      )
      .catch(error => {
        setAdminUpdateSuccess(false, error);
      })
  }
}



export const deleteUser = (companyId, userId, deleteUser) => {

  return (dispatch, getState) => {

    dispatch(setAdminUpdateSuccess(null, null));
    dispatch(actions.incrementPageLoading());


    agent.AdminUser.deleteUser(userId, deleteUser)
      .then(
        response => {

          const {pagination} = getState().admin;

          dispatch(actions.decrementPageLoading());

          dispatch(setAdminUpdateSuccess(true, null));
          dispatch(fetchAdminUserList(companyId, pagination.currentPage, pagination.itemsPerPage));
        }
      ).catch(error => {

        if (!error) {
          dispatch(setAdminUpdateSuccess(false, "Unknown error occurred"));

        } else {
          dispatch(setAdminUpdateSuccess(false, error));
        }

      })
  }
}

export const deleteUserInvitation = (companyId, userId) => {

  return (dispatch, getState) => {

    dispatch(setAdminUpdateSuccess(null, null));
    dispatch(actions.incrementPageLoading());


    agent.AdminUser.deleteUserInvitation(userId, deleteUser)
      .then(
        response => {
          const {pagination} = getState().admin;
          dispatch(actions.decrementPageLoading());
          dispatch(setAdminUpdateSuccess(true, null));
          dispatch(fetchAdminUserInviteList(companyId, pagination.currentPage, pagination.itemsPerPage));
        }
      ).catch(error => {

      if (!error) {
        dispatch(setAdminUpdateSuccess(false, "Unknown error occurred"));

      } else {
        dispatch(setAdminUpdateSuccess(false, error));
      }

    })
  }
}


export const fetchAdminUser = (userId) => {

  return dispatch => {

    agent.AdminUser.getAdminUser(userId)
      .then(
        response => {
          dispatch(setAdminUser(response));
        }
      )
  }
}

export const fetchAdminRoles = () => {
  return dispatch => {
    agent.AdminUser.getRoles()
      .then(
        response => {
          dispatch(setAdminRoles(response));
        }
      )
  }
}

export const setAdminRoles = (roles) => {
  return {
    type: actionTypes.SET_ADMIN_ROLES,
    roles: roles
  }
}

export const setAdminUser = (user) => {
  return {
    type: actionTypes.SET_ADMIN_USER,
    user: user
  }
}