import * as actionTypes from '../../store/actions/actionTypes';
import {collapseAll, expandAll} from "../../Feature/Company/Homepage/AssetSelector/ExpandCollapseFunctions";


export const storeData = (data) => {
  return (dispatch, getState) => {

    let state = getState();
    let {alertFilter, searchFilter, buildingId, sub1Id, sub2Id, sub3Id} = state.filter;

    dispatch(applyFilter(searchFilter, alertFilter, buildingId, sub1Id, sub2Id, sub3Id, data));
    dispatch(dispatchStoreData(data));
  }
}

export const dispatchStoreData = (data) => {

  return {
    type: actionTypes.STORE_DATA,
    data: data
  }

}

export const storeFilteredData = (filteredData) => {

  console.log("storeFilteredData", filteredData);

  return {
    type: actionTypes.STORE_FILTERED_DATA,
    filteredData: filteredData
  }

}

export const dispatchStoreFilteredData = (filteredData) => {

  return {
    type: actionTypes.STORE_FILTERED_DATA,
    filteredData: filteredData
  }

}

export const applyFilter = (searchFilter, alertFilter, buildingId, sub1Id, sub2Id, sub3Id, data, showOnlySentinel) => {

  return (dispatch) => {

    console.log("data:", data);
    console.log("alertFilter:", alertFilter);

    if (data) {

      let filteredData = JSON.parse(JSON.stringify(data));

      if (filteredData !== null && Object.keys(filteredData).length > 0) {

        let buildings = [...filteredData.buildings];

        buildings.forEach((building, outerIndex) => {

          filteredData.buildings[outerIndex].assets = building.assets.filter((t) => {
            if (building.assets.length === 0) {
              return false;
            }

            if (buildingId !== null && building.id !== buildingId) {
              return false;
            }

            if (alertFilter === "active" && parseInt(t.activeAlerts) === 0) {
              return false;
            }

            if (alertFilter === "new" && parseInt(t.newAlerts) === 0) {
              return false;
            }

            if (alertFilter === "closed" && parseInt(t.closedAlerts) === 0) {
              return false;
            }

            if (showOnlySentinel && !t.sentinelPoint) {
              return false;
            }

            return t.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
              t.imei.toUpperCase().includes(searchFilter.toUpperCase()) ||
              building.name.toUpperCase().includes(searchFilter.toUpperCase());
          });

          building.buildingSubGroup1.forEach((sub1Item, sub1Index) => {

            sub1Item.buildingSubGroup2.forEach((sub2Item, subIndex2) => {

              sub2Item.buildingSubGroup3.forEach((sub3Item, subIndex3) => {

                filteredData.buildings[outerIndex].buildingSubGroup1[sub1Index].buildingSubGroup2[subIndex2].buildingSubGroup3[subIndex3].assets = sub3Item.assets.filter(t => {

                  if (sub3Item.assets.length === 0) {
                    return false;
                  }

                  if (buildingId !== null && building.id !== buildingId) {
                    return false;
                  }

                  if (sub2Id !== null && sub2Item.id !== sub2Id) {
                    return false;
                  }

                  if (sub3Id !== null && sub3Item.id !== sub3Id) {
                    return false;
                  }

                  if (alertFilter === "active" && parseInt(t.activeAlerts) === 0) {
                    return false;
                  }

                  if (alertFilter === "new" && parseInt(t.newAlerts) === 0) {
                    return false;
                  }

                  if (alertFilter === "closed" && parseInt(t.closedAlerts) === 0) {
                    return false;
                  }

                  if (showOnlySentinel && !t.sentinelPoint) {
                    return false;
                  }

                  return t.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
                    t.imei.toUpperCase().includes(searchFilter.toUpperCase()) ||
                    building.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
                    sub1Item.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
                    sub2Item.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
                    sub3Item.name.toUpperCase().includes(searchFilter.toUpperCase());
                });

              });


              filteredData.buildings[outerIndex].buildingSubGroup1[sub1Index].buildingSubGroup2[subIndex2].assets = sub2Item.assets.filter(t => {

                if (sub2Item.assets.length === 0) {
                  return false;
                }

                if (buildingId !== null && building.id !== buildingId) {
                  return false;
                }

                if (sub2Id !== null && sub2Item.id !== sub2Id) {
                  return false;
                }

                if (alertFilter === "active" && parseInt(t.activeAlerts) === 0) {
                  return false;
                }

                if (alertFilter === "new" && parseInt(t.newAlerts) === 0) {
                  return false;
                }

                if (alertFilter === "closed" && parseInt(t.closedAlerts) === 0) {
                  return false;
                }

                if (showOnlySentinel && !t.sentinelPoint) {
                  return false;
                }

                return t.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
                  t.imei.toUpperCase().includes(searchFilter.toUpperCase()) ||
                  building.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
                  sub1Item.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
                  sub2Item.name.toUpperCase().includes(searchFilter.toUpperCase());
              });

            })

            filteredData.buildings[outerIndex].buildingSubGroup1[sub1Index].assets = sub1Item.assets.filter(t => {

              if (sub1Item.assets.length === 0) {
                return false;
              }

              if (buildingId !== null && building.id !== buildingId) {
                return false;
              }

              if (sub1Id !== null && sub1Item.id !== sub1Id) {
                return false;
              }

              if (alertFilter === "active" && parseInt(t.activeAlerts) === 0) {
                return false;
              }

              if (alertFilter === "new" && parseInt(t.newAlerts) === 0) {
                return false;
              }

              if (alertFilter === "closed" && parseInt(t.closedAlerts) === 0) {
                return false;
              }

              if (showOnlySentinel && !t.sentinelPoint) {
                return false;
              }

              return t.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
                t.imei.toUpperCase().includes(searchFilter.toUpperCase()) ||
                building.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
                sub1Item.name.toUpperCase().includes(searchFilter.toUpperCase());
            });


          })


        });
        filteredData.buildings = buildings;
        dispatch(filterItemsWithNoChildren(filteredData));

      }

    }

  }
}


export const filterItemsWithNoChildren = (filteredData) => {
  return (dispatch) => {

    filteredData.buildings.forEach((item, itemIndex) => {

      item.buildingSubGroup1.forEach((subItem, subIndex) => {

        filteredData.buildings[itemIndex].buildingSubGroup1[subIndex].buildingSubGroup2 = subItem.buildingSubGroup2.filter((subData2, subIndex) => {
          return subData2.assets.length > 0 || subData2.buildingSubGroup3.length > 0;
        });
      });
    });

    filteredData.buildings.forEach((item, itemIndex) => {

      filteredData.buildings[itemIndex].buildingSubGroup1 = item.buildingSubGroup1.filter((subData, subIndex) => {
        return subData.assets.length > 0 || subData.buildingSubGroup2.length > 0;
      });

    });

    filteredData.buildings = filteredData.buildings.filter((item, itemIndex) => {
      return item.buildingSubGroup1.length > 0 || item.assets.length > 0;
    });

    dispatch(recalculateAlerts(filteredData));
  }
}

export const recalculateAlerts = (filteredData) => {

  return (dispatch) => {

    filteredData.buildings.forEach((building, bldIndex) => {

      building.newAlerts = 0;
      building.closedAlerts = 0;
      building.activeAlerts = 0;

      building.assets.forEach((asset,assetIndex) => {
        building.newAlerts += parseInt(asset.newAlerts);
        building.activeAlerts += parseInt(asset.activeAlerts);
        building.closedAlerts += parseInt(asset.closedAlerts);
      });

      building.buildingSubGroup1.forEach((subGroup, subIndex) => {

        building.buildingSubGroup1[subIndex].newAlerts = 0;
        building.buildingSubGroup1[subIndex].activeAlerts = 0;
        building.buildingSubGroup1[subIndex].closedAlerts = 0;

        subGroup.assets.forEach((asset, assetIndex) => {

          building.buildingSubGroup1[subIndex].newAlerts += parseInt(asset.newAlerts);
          building.buildingSubGroup1[subIndex].activeAlerts += parseInt(asset.activeAlerts);
          building.buildingSubGroup1[subIndex].closedAlerts += parseInt(asset.closedAlerts);

        });

        subGroup.buildingSubGroup2.forEach((subGroup2, subIndex2) => {

          building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].newAlerts = 0;
          building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].activeAlerts = 0;
          building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].closedAlerts = 0;

          subGroup2.assets.forEach((asset, assetIndex) => {

            building.buildingSubGroup1[subIndex].newAlerts += parseInt(asset.newAlerts);
            building.buildingSubGroup1[subIndex].activeAlerts += parseInt(asset.activeAlerts);
            building.buildingSubGroup1[subIndex].closedAlerts += parseInt(asset.closedAlerts);


            building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].newAlerts += parseInt(asset.newAlerts);
            building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].activeAlerts += parseInt(asset.activeAlerts);
            building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].closedAlerts += parseInt(asset.closedAlerts);


          })

          subGroup2.buildingSubGroup3.forEach((subGroup3, subIndex3) => {

            building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].buildingSubGroup3[subIndex3].newAlerts = 0;
            building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].buildingSubGroup3[subIndex3].activeAlerts = 0;
            building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].buildingSubGroup3[subIndex3].closedAlerts = 0;


            subGroup3.assets.forEach((asset, assetIndex) => {

              building.buildingSubGroup1[subIndex].newAlerts += parseInt(asset.newAlerts);
              building.buildingSubGroup1[subIndex].activeAlerts += parseInt(asset.activeAlerts);
              building.buildingSubGroup1[subIndex].closedAlerts += parseInt(asset.closedAlerts);

              building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].newAlerts += parseInt(asset.newAlerts);
              building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].activeAlerts += parseInt(asset.activeAlerts);
              building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].closedAlerts += parseInt(asset.closedAlerts);

              building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].buildingSubGroup3[subIndex3].newAlerts += parseInt(asset.newAlerts);
              building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].buildingSubGroup3[subIndex3].activeAlerts += parseInt(asset.activeAlerts);
              building.buildingSubGroup1[subIndex].buildingSubGroup2[subIndex2].buildingSubGroup3[subIndex3].closedAlerts += parseInt(asset.closedAlerts);

            })

          });

        });

        building.newAlerts += parseInt(subGroup.newAlerts);
        building.activeAlerts += parseInt(subGroup.activeAlerts);
        building.closedAlerts += parseInt(subGroup.closedAlerts);

      });
    });

    console.log(filteredData);

    dispatch(storeFilteredData(filteredData));

  }

}

export const setSearchFilter = (searchFilter, showOnlySentinel, action) => {
  return (dispatch, getState) => {
    const {alertFilter, buildingId, sub1Id, sub2Id, sub3Id, data} = getState().filter;
    dispatch(applyFilter(searchFilter, alertFilter, buildingId, sub1Id, sub2Id, sub3Id, data, showOnlySentinel, action));
    dispatch(storeSearchFilter(searchFilter, showOnlySentinel));
  }
}

export const storeSearchFilter = (searchFilter) => {

  return {
    type: actionTypes.SET_SEARCH_FILTER,
    searchFilter: searchFilter
  }

}

export const setAlertFilter = (alertFilter) => {
  return (dispatch, getState) => {
    const {searchFilter, buildingId, sub1Id, sub2Id, sub3Id, data} = getState().filter;
    dispatch(applyFilter(searchFilter, alertFilter, buildingId, sub1Id, sub2Id, sub3Id, data));
    dispatch(storeAlertFilter(alertFilter));
  }
}

export const storeAlertFilter = (alertFilter) => {
  return {
    type: actionTypes.SET_ALERT_FILTER,
    alertFilter: alertFilter
  }
}

export const clearFilters = () => {
  return (dispatch, getState) => {
    let data = getState().filter.data;

    console.log("clearing filters action");

    dispatch(clearFiltersData());
    dispatch(applyFilter("", null, null, null, null, data));

  }
}

export const expandAllAction = () => {
  return (dispatch) => {
    expandAll();
  }
}

export const collapseAllAction = () => {
  return (dispatch) => {
    collapseAll();
  }
}

export const clearFiltersData = () => {
  return {
    type: actionTypes.CLEAR_FILTERS
  }
}

export const setBuildingFilter = (buildingId) => {
  return (dispatch, getState) => {
    const {searchFilter, alertFilter, subId, sub2Id, sub3Id, data} = getState();
    dispatch(applyFilter(searchFilter, alertFilter, buildingId, subId, sub2Id, sub3Id, data));
    dispatch(storeBuildingFilter(buildingId));
  }
}

export const storeBuildingFilter = (buildingId) => {
  return {
    type: actionTypes.SET_BUILDING_FILTER,
    buildingId: buildingId
  }
}

export const setSub1Filter = (sub1Id) => {
  return (dispatch, getState) => {
    const {searchFilter, alertFilter, buildingId, sub2Id, sub3Id, data} = getState();
    dispatch(applyFilter(searchFilter, alertFilter, buildingId, sub1Id, sub2Id, sub3Id, data));
    dispatch(storeSub1Filter(sub1Id));
  }
}

export const setSub2Filter = (sub2Id) => {
  return (dispatch, getState) => {
    const {searchFilter, alertFilter, buildingId, sub1Id, sub3Id, data} = getState();
    dispatch(applyFilter(searchFilter, alertFilter, buildingId, sub1Id, sub2Id, sub3Id, data));
    dispatch(storeSub2Filter(sub2Id));
  }
}

export const setSub3Filter = (sub3Id) => {
  return (dispatch, getState) => {
    const {searchFilter, alertFilter, buildingId, sub1Id, sub2Id, data} = getState();
    dispatch(applyFilter(searchFilter, alertFilter, buildingId, sub1Id, sub2Id, sub3Id, data));
    dispatch(storeSub3Filter(sub2Id));
  }
}

export const storeSub1Filter = (sub1Id) => {
  return {
    type: actionTypes.SET_SUB_1_FILTER,
    subId: sub1Id
  }
}

export const storeSub2Filter = (sub2Id) => {
  return {
    type: actionTypes.SET_SUB_2_FILTER,
    subId: sub2Id
  }
}

export const storeSub3Filter = (sub3Id) => {
  return {
    type: actionTypes.SET_SUB_3_FILTER,
    subId: sub3Id
  }
}