import '../../custom.scss';
import './Login.css'
import logo from '../../assets/logo.png';
import frankeLogo from '../../assets/franke-logo.png'
import informLogo from '../../assets/inform-logo.png'
import {Component} from 'react';
import {Col, Row, Container, Card, Spinner} from "react-bootstrap";
import {connect} from 'react-redux';

class LoginTemplate extends Component {


  render(props) {
    let error = null;
    let spinner = null;

    if (this.props.loading) {
      spinner = (
        <div className="d-flex overlay align-items-center justify-content-center">
            <Spinner animation="border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>

        </div>
      );
    }


    return (


    <div className="LoginContainer h-100 w-100">
      {spinner}

      <Container className="align-items-center h-100 w-100">


        <Row className="w-100 h-100 justify-content-center align-items-center m-0 p-0 bg-transparent">

          <Col md="8" className="d-flex align-self-center justify-content-center">
            <Card className="text-center border-light border-1 w-100">
              <Card.Header className="border-bottom d-block justify-content-between align-content-end">
                <Container>
                  <Row>
                    <Col className="col-12 col-sm-6 align-items-center d-flex justify-content-center justify-content-sm-start">
                      <img src={logo} alt="Dart Valley Systems Company Logo"/>
                    </Col>
                    <Col className="col-12 col-sm-6 align-items-center d-flex justify-content-center justify-content-sm-end">
                      <img src={frankeLogo} alt="a FRANKE Company"/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      <img src={informLogo} alt="inform logo" style={{width: 300}}/>
                  </Col>
                  </Row>
                </Container>
              </Card.Header>
              <Card.Body>
                {this.props.children}
              </Card.Body>
              <Card.Footer className="text-muted font-weight-light m-0 p-2">
                Copyright © 2020 Dart Valley Systems All rights reserved
              </Card.Footer>

            </Card>
          </Col>
        </Row>


      </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    errorShown: state.errors.errorShown,
    error: state.errors.errorMessage,
    isError: state.errors.isError,
    loading: state.page.loading,
    authenticated: state.authentication.authenticated
  }

}



export default connect(mapStateToProps)(LoginTemplate);