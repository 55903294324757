import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import SubGroup3Accordion from "./SubGroup3Accordion";

const SubGroupAccordion3s = (props) => {

  let noSearch = props.alertFilter === null;

  if (!props.data) {
    return null;
  }

  return (<div>
    {props.data.map((subGroup3, index) => {

      return (
        <SubGroup3Accordion subGroup3={subGroup3}
                           selectedSub3Id={props.selectedSub3Id}
                           noSearch={noSearch}
                           selectedAssetId={props.selectedAssetId}
                           alertFilter={props.alertFilter}
                           key={index}
        />
      );
    })

    }
  </div>)
}

const mapStateToProps = (state) => {

  return {
    selectedAssetId: state.page.selectedAssetId,
    alertFilter: state.filter.alertFilter,
    assetData: state.assets.assetData,
    selectedSub3Id: state.page.selectedSub3Id,
    companyId: state.page.companyId
  }
}

export default connect(mapStateToProps)(withRouter(SubGroupAccordion3s));