import agent from "../../app/api/agent";
import * as actions from "./index";
import * as actionTypes from '../actions/actionTypes';
import { saveAs } from 'file-saver';

export const fetchSiteStatusReport = (params) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());
    dispatch(actions.storeReport({data: null, pagination: null}));

    agent.Report.fetchSiteStatusReport(params)
      .then(
        response => {
          dispatch(actions.storeReport(response));
          dispatch(actions.decrementPageLoading());
        })

  }
}

export const fetchSiteStatusReportCsv = (params) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Report.fetchSiteStatusReportCsv(params)
      .then(
        response => {
          dispatch(actions.decrementPageLoading());
          saveAs(response, "file.csv");
        })
  }
}

export const fetchRawData = (params) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());
    dispatch(actions.storeReport({data: null, pagination: null}));

    agent.Report.rawMeasurements(params)
      .then(
        response => {
          dispatch(actions.storeReport(response));
          dispatch(actions.decrementPageLoading());
        })

  }
}

export const fetchAssetRawData = (params) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());
    dispatch(actions.storeReport({data: null, pagination: null}));

    agent.Report.rawAssetMeasurements(params)
      .then(
        response => {
          dispatch(actions.storeReport(response));
          dispatch(actions.decrementPageLoading());
        })

  }
}

export const fetchAssetRawDataCsv = (params) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Report.rawMeasurementsCsv(params)
      .then(
        response => {
          dispatch(actions.decrementPageLoading());
          saveAs(response, "file.csv");
        })
  }
}

export const fetchAlertHistoryReport = (params) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Report.fetchAlertHistoryReport(params)
      .then(
        response => {
          console.log(response);
          dispatch(actions.storeReport(response));
          dispatch(actions.decrementPageLoading());
        })
  }
}

export const fetchAlertHistoryReportCsv = (params) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Report.fetchAlertHistoryReportCsv(params)
      .then(
        response => {
          dispatch(actions.decrementPageLoading());
          saveAs(response, "file.csv");
        })
  }
}

export const storeReport = (data) => {

  return {
    type: actionTypes.STORE_REPORT,
    data: data.data,
    pagination: data.pagination
  }


}