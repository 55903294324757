import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {PasswordChange, User, UserFormValues, PasswordReset, PasswordResetRequest} from "../models/user";
import store from '../../store/store';
import * as actions from '../../store/actions';
import * as interfaces from "../models/companySelectAssets";
import {PaginatedResult} from "../models/pagination";
import {ReportParams} from "../models/Reports";
import {PagingParams} from "../models/pagination";

let isRefreshing :any = false;
// @ts-ignore
let failedQueue :any = [];

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

console.log("baseURL: ", process.env.REACT_APP_API_URL);
console.log("companyLogos: ", process.env.REACT_APP_COMPANY_LOGOS);

const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom :any) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}


axios.interceptors.response.use(async response => {
    const pagination = response.headers['pagination'];

    const contentDisposition = response.headers["content-disposition"];

    if (pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }

    if (contentDisposition) {
        const filename = contentDisposition
            .split('"')[1];
    }

    return response;
}, (error: any) => {

    const originalRequest = error.config;

    if (error.message && error.message.toLowerCase() === "network error") {
        store.dispatch(actions.clearLoading());
        store.dispatch(actions.setError(1, "Network error", "Unknown"));
        return Promise.reject(error);
    }

    console.log("error", error);
    console.log("response", error.response);

    const {data, status, config} = error.response!;
    store.dispatch(actions.clearLoading());

    switch (status) {
        case 400:
            if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                store.dispatch(actions.setError(404, "Requested resource does not exist", "Unknown"));
            }
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key])
                    }
                }
                throw modalStateErrors.flat();
            } else {
                store.dispatch(actions.setError(5000, data, "Unknown"));
            }
            break;
        case 401:

            console.log(config.url.search("login"));

            if (config.url.search("login") >= 0) {
                store.dispatch(actions.setError(401, "Invalid username or password", "Unknown"));
                return;
            }

            if (config.url.search("refreshToken") >= 0) {
                return;
            }

            if (!originalRequest._retry) {

                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({resolve, reject});
                    }).then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    }).catch(err => {
                        return Promise.reject((err));
                    });
                }


                originalRequest._retry = true;
                isRefreshing = true;

                const refreshToken = window.localStorage.getItem('refreshtoken');
                const userName = window.localStorage.getItem('username');
                return new Promise((resolve, reject) => {
                    axios.post( "/account/refreshToken", {}, {
                        params: {
                            userName,
                            refreshToken
                        }
                    })
                        .then(({data}) => {
                            window.localStorage.setItem('bearertoken', data.token);
                            window.localStorage.setItem('refreshtoken', data.refreshToken);
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
                            originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
                            processQueue(null, data.token);
                            resolve(axios(originalRequest));
                        })
                        .catch((err) => {

                            store.dispatch(actions.setError(401, "Login data no longer valid", "Unknown"));
                            store.dispatch(actions.logout());
                            reject(err);


                        }).finally(() => {
                        isRefreshing = false
                    })
                });

            } else {
                store.dispatch(actions.setError(401, "Login data no longer valid", "Unknown"));
                store.dispatch(actions.logout());
            }

            break;

            // store.dispatch(actions.setError(401, "Login data no longer valid", "Unknown"));
            // store.dispatch(actions.logout());
            // break;
        case 403:
            store.dispatch(actions.setError(403, "You are not authorised to access the requested resource", "Unknown"));

            // history.push('/forbidden');
            break;
        case 404:
            store.dispatch(actions.setError(404, "Requested resource does not exist", "Unknown"));

            // history.push('/not-found');
            break;
        case 500:
            store.dispatch(actions.setError(500, "ServerError", data));
            // history.push('/server-error');
            break;
    }
    return Promise.reject(data);
})

axios.interceptors.request.use(config => {

    const token = window.localStorage.getItem("bearertoken");

    console.log(config.baseURL);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config;
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}, config? :AxiosRequestConfig) => axios.post<T>(url, body, config).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/account/register', user),
    changePassword: (passwordChange: PasswordChange) => requests.post<any>('/account/changePassword', passwordChange),
    requestPasswordReset: (passwordResetRequest: PasswordResetRequest) => requests.post<any>('/account/requestResetPassword', passwordResetRequest),
    resetPassword: (id: string, passwordReset: PasswordReset) =>  requests.post<any>('/account/resetPassword/' + id, passwordReset),
    refreshToken: (userName: string, refreshToken: string) => requests.post<any>('/account/refreshToken', {}, {params: {userName, refreshToken}})
}

const Alert = {
    list: (assetId: number) => requests.get<Array<any>>('/alert/asset/' + assetId),
    addReading: (alertId: number, reading: any) => requests.post<any>('/alert/' + alertId + "/reading", reading),
    update: (alertId: number, update: any) => requests.put<any>("/alert/" + alertId, update),
    get: (alertId: number) => requests.get<Array<any>>('/alert/' + alertId),
    getHistory: (alertId: number) => requests.get<Array<any>>('/alert/' + alertId + "/history"),
}

const Asset = {
    // getAll: (companyId: number) => requests.get<any>('/asset/' + companyId),
    get: (assetId: number) => requests.get<any>('/asset/' + assetId),
    updateSettings: (assetId: number, settings: any) => requests.post('/asset/' + assetId + '/settings', settings)
}

const Company = {
    getAll: () => requests.get<any>('/company/'),
    getSelected: (companyId : number) => requests.get<interfaces.CompanySelectedAssets>('/company/selected/' + companyId),
}

const Measurement = {
    getLast12Hours: (assetId: number) => requests.get<any>('/measurement/Last12Hours/' + assetId),
    getPlotData: (assetId: number, params: any) => requests.post<any>('/measurement/' + assetId, params),
    getFirstMeasurementReceived: (assetId: number) => requests.get<any>('/measurement/FirstMeasurementReceived/' + assetId),
    getLastMeasurementReceived: (assetId: number) => requests.get<any>('/measurement/LastMeasurementReceived/' + assetId),
    getIncrementalUpdate: (assetId: number, sinceDate: string) => requests.get<any>('/measurement/ListMeasurementsIncremental/' + assetId + "?sinceFromDate=" + sinceDate)
}

const Report = {
    assetAlertsReport: (params: ReportParams) => requests.post<PaginatedResult<any>>('/report/assetAlert', params),
    rawMeasurements: (params: ReportParams) => requests.post<PaginatedResult<any>>('/report/rawData/', params),
    rawMeasurementsCsv: (params: ReportParams) => requests.post<any>('/report/rawDataCsv/', params, {responseType: "blob"}),
    rawAssetMeasurements: (params: ReportParams) => requests.post<PaginatedResult<any>>('/report/assetRawData', params),
    fetchAlertHistoryReport: (params: ReportParams) => requests.post<PaginatedResult<any>>('/report/alertHistory', params),
    fetchAlertHistoryReportCsv: (params: ReportParams) => requests.post<any>('/report/alertHistoryCsv', params, {responseType: "blob"}),
    fetchSiteStatusReport: (params: ReportParams) => requests.post<any>('/report/siteStatusReport', params),
    fetchSiteStatusReportCsv: (params: ReportParams) => requests.post<any>('/report/siteStatusReportCsv', params, {responseType: "blob"})
}

const AdminCompany = {
    listCompanies: (pagingParams: PagingParams) => requests.post<any>('/company/admin/list', pagingParams),
    deleteCompany: (companyId: number, deleteAsset: Boolean) => requests.del<any>('/company/delete/' + companyId + '/' + deleteAsset),
    getAdminCompany: (companyId: number) => requests.get<any>('/company/admin/' + companyId),
    createCompany: (companyData: any) => requests.post<any>('/company/admin/create', companyData),
    updateCompany: (companyId: number, companyData: any) => requests.put<any>('/company/admin/edit/' + companyId, companyData),
    getAdminCompanyListAll: () => requests.get<any>('/company/admin/listAll')
}

const AdminAsset = {
    createAsset: (assetData: any) => requests.post<any>('/asset/admin', assetData),
    createBulkAsset: (assetData: any) => requests.post<any>('/asset/admin/bulkCreate', assetData),
    updateAsset: (assetId: number, assetData: any) => requests.put<any>('/asset/' + assetId, assetData),
    deleteAsset: (assetId: number, deleteAsset: Boolean) => requests.del<any>('/asset/delete/' + assetId + '/' + deleteAsset),
    listIcons: () => requests.get<any>('/icons'),
    getAdminCompanyAssets: (companyId: number, showDeleted: boolean, pageParams: PagingParams) => requests.post<PaginatedResult<any>>('/asset/admin/list/' + companyId + '/' + showDeleted, pageParams),
    getAdminCompanyAsset: (assetId: number) => requests.get<any>('/asset/admin/get/' + assetId),
}

const AdminUser = {
    listUserInvites: (companyId: number, pageParams: PagingParams) => requests.post<PaginatedResult<any>>('/account/admin/list/' + companyId + "/invites", pageParams),
    listUsers: (companyId: number, pageParams: PagingParams) => requests.post<PaginatedResult<any>>('/account/admin/list/' + companyId, pageParams),
    getRoles: () => requests.get<any>('/account/admin/roles'),
    getAdminUser: (userId: number) => requests.get<any>('/account/admin/' + userId),
    createUser: (userData: any) => requests.put<any>('/account/create', userData),
    createUserInvite: (userData: any) => requests.put<any>('/account/invite/create', userData),
    deleteUserInvitation: (userId: string) => requests.del<any>('/account/delete/invite/' + userId),
    createAccountFromInvite: (userData: any) => requests.put('/account/create', userData),
    updateUser: (userId: string, userData: any) => requests.post<any>('/account/' + userId, userData),
    deleteUser: (userId: string, deleteUser: boolean) => requests.del<any>('/account/delete/' + userId + "/" + deleteUser),
}

const AdminBuildings = {
    listBuildings: (companyId: number) => requests.get<any>('/building/admin/list/' + companyId),
    listSubgroup1s: (companyId: number, buildingId: number) => requests.get<any>('/BuildingSubGroup1/admin/' + companyId + '/list/' + buildingId),
    listSubgroup2s: (companyId: number, subId: number) => requests.get<any>('/BuildingSubGroup2/admin/' + companyId + '/list/' + subId),
    listSubgroup3s: (companyId: number, subId: number) => requests.get<any>('/BuildingSubGroup3/admin/' + companyId + '/list/' + subId),
    listEditBuildings: (companyId: number, params: PagingParams) => requests.post<any>('/building/admin/editList/' + companyId, params),
    updateBuilding: (buildingId: number, value: string) => requests.put<any>('/building/' + buildingId + '/?name=' + value, {}),
    updateSub1: (sub1Id: number, value: string) => requests.put<any>('/buildingSubGroup1/' + sub1Id + '/?name=' + value, {}),
    updateSub2: (sub2Id: number, value: string) => requests.put<any>('/buildingSubGroup2/' + sub2Id + '/?name=' + value, {}),
    updateSub3: (sub3Id: number, value: string) => requests.put<any>('/buildingSubGroup3/' + sub3Id + '/?name=' + value, {}),
    deleteBuilding: (buildingId: number, value: boolean) => requests.del<any>('/building/' + buildingId + '/?delete=' + value),
    deleteSub1: (sub1Id: number, value: boolean) => requests.del<any>('/buildingSubGroup1/' + sub1Id + '/?delete=' + value),
    deleteSub2: (sub2Id: number, value: boolean) => requests.del<any>('/buildingSubGroup2/' + sub2Id + '/?delete=' + value),
    deleteSub3: (sub3Id: number, value: boolean) => requests.del<any>('/buildingSubGroup3/' + sub3Id + '/?delete=' + value),
}

const Admin = {
    listResellers: () => requests.get<any>('/company/resellers'),
}

const Subscriptions = {
    getSubscription: () => requests.get<any>('/subscription'),
    updateSubscription: (subscription: any) => requests.put<any>('/subscription', subscription),
    listBuildings: () => requests.get<any>('/subscription/buildings'),
    listCompanies: () => requests.get<any>('/subscription/companies')
}

const File = {
    create: (companyId : number, assetId : number, fileParams : any) => requests.post<any>('/file/' + companyId + (assetId ?  "/" + assetId : ""), fileParams),
    delete: (fileId: number) => requests.del<any>('/file/delete/' + fileId),
    download: (fileId: number) => requests.post<any>('/file/download/' + fileId, {},{responseType: "blob"}),
    getFile: (fileId: number) => requests.get<any>('/file/' + fileId),
    list: (companyId: number, assetId: number, pagingParams: PagingParams) => requests.post<any>('/file/list/' + companyId + (assetId ?  "/" + assetId : ""), pagingParams),
    listCompanyFiles: (companyId: number, pagingParams: PagingParams) => requests.post<any>('/file/listCompanyFiles/' + companyId, pagingParams),
    update: (fileId: number, fileParams: any)  => requests.put<any>('/file/' + fileId, fileParams),
}

const agent = {
    Account,
    Admin,
    AdminAsset,
    AdminBuildings,
    AdminCompany,
    AdminUser,
    Alert,
    Asset,
    Company,
    File,
    Measurement,
    Report,
    Subscriptions
}

export default agent;