import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import classes from './SensorCard.module.css';

const SensorCard = (props) => {

  return (
    <Card className={classes.tempSensorCard + " " +  (props.showPlot ? "bg-primary" : "bg-dark")}
          style={{border: props.sensorBorderColour + " solid 3px"}}>
      <Card.Body>
        <Container fluid className="p-0">
          <Row>
            <Col xs="12" className="d-flex justify-content-center text-center" style={{height: "40px"}}>
              <p className={classes.sensorTitle + " m-0 p-0"}>{props.sensorName}</p>
            </Col>
            <Col className="d-flex justify-content-center p-0 m-0">
              <p className={classes.sensorCardReadout + " p-0 m-0"}
                 onClick={() => {
                   props.onClickShowPlot(props.plotIndex);
                 }}
                 style={{ cursor: 'pointer' }}
              >
                {props.latestTemp}
              </p>
            </Col>
          </Row>
          <Row>
            <Col className={"d-flex justify-content-center p-0 m-0"} xs="4" onClick={() => props.onClickPlotType(props.plotIndex, "min")}>
              <div className={classes.plotSelector + " w-100" + (props.plotType === "min" ? " bg-secondary" : "")} style={{ cursor: 'pointer' }}>
                Min
              </div>
            </Col>
            <Col className={"d-flex justify-content-center p-0 m-0"}  xs="4" onClick={() => props.onClickPlotType(props.plotIndex, "mean")} style={{ cursor: 'pointer' }}>
              <div className={classes.plotSelector + " w-100" + (props.plotType === "mean" ? " bg-secondary" : "")}>
                Mean
              </div>
            </Col>
            <Col className="d-flex justify-content-center p-0 m-0" xs="4" onClick={() => props.onClickPlotType(props.plotIndex, "max")} style={{ cursor: 'pointer' }}>
              <div className={classes.plotSelector + " w-100" + (props.plotType === "max" ? " bg-secondary" : "")}>
                Max
              </div>
            </Col>
          </Row>

        </Container>

      </Card.Body>
    </Card>
  );

}

export default SensorCard;