import React, {useEffect} from 'react';
import Homepage from '../Company/Homepage/Homepage'
import {Container, Row, Col} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import NavCard from "../Company/Dashboard/NavCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding, faHospitalUser,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
import * as actions from "../../store/actions";

const SiteAdminMainPanel = (props) => {

  useEffect(() => {
    let nav = [];
    nav.push({url: process.env.PUBLIC_URL + "/#/admin/", title: "Admin"})
    props.setPageNavLinks(nav);
    props.setPageTitle("Company Administration");
  },[]);

  if (props.authenticated) {

    return (
      <Homepage {...props} data={props.data} show={true} title={"Company Administration"}
                setAssetSelection={false} showCompanyInfo={false}>
        <Container fluid className="align-self-start h-100 p-0 m-0">
          <Row className="p-0 m-0">
            <Col>
              <Container fluid className="p-0 m-0">
                <Row
                  className="d-flex flex-row h-100 w-100 row-cols-1 row-cols-md-2 row-cols-xl-3 align-content-between mx-auto p-0 m-0">
                  <NavCard clickFunction={() => props.history.push({pathname: "/admin/company/"})}>

                    <FontAwesomeIcon icon={faUserTie} className="me-2"></FontAwesomeIcon>
                    Companies
                  </NavCard>
                  <NavCard clickFunction={() => props.history.push({pathname: "/admin/initialisation"})}>
                    <FontAwesomeIcon icon={faBuilding} className="me-2"></FontAwesomeIcon>
                    Initialization
                  </NavCard>

                  <NavCard
                    clickFunction={() => props.history.push({pathname: "/company/" + props.usersCompanyId})}>
                    <FontAwesomeIcon icon={faHospitalUser} className="me-2"></FontAwesomeIcon>
                    Company View
                  </NavCard>

                </Row>
              </Container>

            </Col>
          </Row>
        </Container>
      </Homepage>
    );
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (nav) => dispatch(actions.setPageNavLinks(nav))

  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    selectedCompanyData: state.company.selectedCompanyData,
    companyId: state.page.companyId,
    assetId: state.page.assetId,
    usersCompanyId: state.authentication.companyId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SiteAdminMainPanel));
