import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import ReportController from "./ReportController";
import {useParams, withRouter} from "react-router-dom";

const RawDataReport = (props) => {
  const [pageParams, setPageParams] = useState(null);
  const {companyId} = useParams();

  useEffect(() => {
    let navLinks = [];
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/" + companyId, title: "Reports"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/rawData" + pageParams, title: "Raw Data Report"});

    props.setPageNavLinks(navLinks)

  }, [pageParams])


  const handleCsvDownload = (companyId, month, year, date) => {

    let params = {companyId: companyId,
      year: year,
      month: month,
      date: date
    }

    props.fetchAssetRawDataCsv(params);
  }

  const handleUpdateLink = (companyId, pageNo, pageSize, descending, month, year, date) => {
    props.history.push("/company/" + companyId + "/reports/rawData?pageNo=" + pageNo + "&pageSize=" + pageSize + "&descending=" + descending + "&month=" + month + "&year=" + year + "&date=" + date);
  }

  const updatePageParams = (pageParams) => {
    setPageParams(pageParams);
  }

  const handleUpdateData = (params) => {

    let navLinks = [];
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/" + companyId, title: "Reports"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/rawData", title: "Raw Data Report"});
    props.setPageNavLinks(navLinks)

    console.log(params);

    props.rawMeasurements(params);
  }

  return (<ReportController
    pageTitle="Raw Data Report"
    csvDownload={handleCsvDownload}
    updateLink={handleUpdateLink}
    updateData={handleUpdateData}
    updatePageParams={updatePageParams}
    useDatePicker={true}
    showDateOption={true}
  />);
};

const mapDispatchToProps = (dispatch) => {

  return {
    rawMeasurements: (params) => dispatch(actions.fetchRawData(params)),
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (links) => dispatch(actions.setPageNavLinks(links)),
    fetchAssetRawDataCsv: (params) => dispatch(actions.fetchAssetRawDataCsv(params))

  }

}

const mapStateToProps = (state) => {

  return {
    reportData: state.report.data,
    pagination: state.report.pagination,
    companyId: state.page.companyId
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RawDataReport));