import React, {useEffect} from 'react';
import Homepage from '../../Homepage/Homepage';
import {Container, Row, Col, Tabs, Tab} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {useParams, withRouter} from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import * as actions from "../../../../store/actions";
import {connect} from 'react-redux';

import AssetSensorTab from "./AssetSensorTab";
import AssetAlarmTab from "./AssetAlarmTab";
import AssetAlertTab from "./AssetAlertTab";

const AssetSettings = (props) => {

  const {companyId, assetId} = useParams();

  useEffect(() => {
    props.fetchAssetSettings(assetId);
    props.fetchAssetData(assetId);
  }, []);

  useEffect(() => {
    if (assetId) {
      props.fetchAssetSettings(assetId);
      props.fetchAssetData(assetId);
    }
  }, [assetId]);

  useEffect(() => {

    if (props.assetData) {
      props.setPageTitle("Asset Settings for " + props.assetData.name);

      let navLinks = [];
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/asset/" + assetId, title: props.assetData.name});
      navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/asset/" + assetId + "/settings", title: "Settings"});
      props.setPageNavLinks(navLinks)

    }

  }, [props.assetData])

    if (props.authenticated) {

      return (
        <Homepage {...props} data={props.data} setAssetSelection={false} showCompanyInfo={true}>
          <Container fluid className="align-self-start h-100">
            <Row className="h-100">
              <Col className="h-100">

                  <Tabs defaultActiveKey="sensor" id="alert-setting-tabs" className="alert-settings-tabs p-0">

                    <Tab eventKey="sensor" title="Sensors" className="alert-settings-tab p-0">
                      <AssetSensorTab settings={props.settings}
                                            editSettings={props.editSettings}
                                            storeAssetSettings={props.storeAssetSettings}
                                            updateSensorSettings={props.updateSensorSettings}
                                            updateAssetSettings={props.updateAssetSettings}
                                            assetData={props.assetData}
                      />
                    </Tab>
                    <Tab eventKey="alarms" title="Alerts On/Off" className="alert-settings-tab p-0">
                      <AssetAlarmTab assetData={props.assetData}/>
                    </Tab>
                    <Tab eventKey="alerts" title="Alerts Settings" className="alert-settings-tab p-0">
                      <AssetAlertTab/>
                    </Tab>

                  </Tabs>

              </Col>
            </Row>

          </Container>
        </Homepage>);
    } else {
      return (<Redirect to="/login"/>);
    }
}

const mapDispatchToProps = dispatch => {

  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (links) => dispatch(actions.setPageNavLinks(links)),
    fetchAssetData: (id) => dispatch(actions.fetchAssetData(id)),
    fetchAssetSettings: (id) => dispatch(actions.fetchAssetSettings(id)),
    storeAssetSettings: (settings) => dispatch(actions.storeAssetSettings(settings)),
    updateSensorSettings: (sensor) => dispatch(actions.updateSensorSettings(sensor)),
    updateAssetSettings: (id, settings) => dispatch(actions.updateAssetSettings(id, settings))
  }

};

const mapStateToProps = state => {
  return {
    assetData: state.assets.assetData,
    authenticated: state.authentication.authenticated,
    settings: state.settings.settings,
    editSettings: state.settings.editSettings,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssetSettings));
