import  {
  withRouter
} from "react-router-dom";
import store from "./store/store";
import * as actions from './store/actions';
import RoutingConfig from "./RoutingConfig";
import CacheBuster from "./CacheBuster";

const App = (props) => {

  let token = window.localStorage.getItem("bearertoken");

  if (token) {
    store.dispatch(actions.setAuthDetails(token));
  }

  return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload })  => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
              <RoutingConfig/>
          );
        }}
      </CacheBuster>

  )

}

export default withRouter(App);
