import React, {useEffect, useState} from 'react';
import {Button, Card, Container, Form} from "react-bootstrap";
import BuildingAccordion from './BuildingAccordion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions';
import {faStar} from "@fortawesome/free-solid-svg-icons/faStar";
import {faStar as faStar2} from "@fortawesome/free-regular-svg-icons/faStar";
import {expandAll, collapseAll} from "./ExpandCollapseFunctions";
import classes from './AssetSelection.module.css';

const AssetSelection = (props) => {

  const [showOnlySentinel, setShowOnlySentinel] = useState(false);
  const [search, setSearch] = useState("");
  const [pendingAction, setPendingAction] = useState(null);

  useEffect(() => {
    setPendingAction(showOnlySentinel ? "EXPAND" : "COLLAPSE");
    props.setSearchFilter(search, showOnlySentinel);
  }, [showOnlySentinel]);

  useEffect(() => {

    if (pendingAction !== null) {
      if (pendingAction === "EXPAND") {
        expandAll();
      } else if (pendingAction === "COLLAPSE") {
        collapseAll();
      }

      setPendingAction(null);
    }

  }, [props.filteredData])

  let filterOptions = null;
  let clearFilter = null;

  filterOptions = (

    <div className="d-block mb-3">
      <Form.Control type="text" placeholder="Filter" value={props.searchFilter}
                    onChange={(e) => {
                      props.setSearchFilter(e.target.value, showOnlySentinel);
                      setPendingAction("EXPAND");
                    }
                    } className="bg-primary text-white filter-field">
      </Form.Control>
    </div>

  );

  if (props.searchFilter.length > 0) {
    clearFilter = (<Button className="text-white" variant="link"
                           onClick={() => {
                             props.setSearchFilter("", showOnlySentinel);
                             setSearch("");
                           }}>Clear filter</Button>);
  }

  let buildings = null;

  if (props.filteredData !== null && Object.keys(props.filteredData).length > 0) {
    buildings = props.filteredData.buildings.map((building, index) => {
      return (<BuildingAccordion
        key={index}
        data={building}
        selectedAsset={props.selectedAsset}
        selectedSub={props.selectedSub}
        selectedBuilding={props.selectedBuilding}
      />);
    })
  }

  let sentinelIcon = showOnlySentinel ? faStar : faStar2;

  return (
    <Container fluid className="mb-auto p-0">
      <Card className="text-white bg-primary asset-selection mb-auto d-flex flex-auto">

        <Card.Body className={classes.AssetSelectionContainer}>
          <Card.Title className="mb-5">
            <div className="float-start">
              <h4 className={"justify-content-start " + classes.CardTitle}>Assets</h4>
            </div>
            <div className="float-end">
              <Button size="sm" onClick={() => {
                setShowOnlySentinel(!showOnlySentinel);
              }}><FontAwesomeIcon icon={sentinelIcon}/></Button>

              {clearFilter}

              <Button size="sm" className="text-white" onClick={() => {

                expandAll();

              }}>
                <FontAwesomeIcon className="justify-content-end" icon={faPlusCircle}/>
              </Button>

              <Button size="sm" className="text-white" onClick={() => {
                collapseAll();
              }}>
                <FontAwesomeIcon className="justify-content-end" icon={faMinusCircle}/>
              </Button>
            </div>
          </Card.Title>
          <div>
            {filterOptions}
          </div>

          <div className="scroll-assetlist">
            {buildings}
          </div>


        </Card.Body>

      </Card>

    </Container>

  );

}

const mapStateToProps = state => {
  return {
    alertFilter: state.filter.alertFilter,
    searchFilter: state.filter.searchFilter,
    buildingId: state.filter.buildingId,
    subId: state.filter.subId,
    data: state.company.data,
    filteredData: state.filter.filteredData
  }
}

const mapDispatchToProps = dispatch => {

  return {
    setSearchFilter: (searchFilter, showSentinelOnly, action) => dispatch(actions.setSearchFilter(searchFilter, showSentinelOnly, action)),
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(AssetSelection);