import React, {useState, useEffect} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ColourPickerButton from "./ColourPickerButton";
import * as actions from '../../../../store/actions';
import {connect} from 'react-redux';
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classes from './ReferenceLineForm.module.css';

const DEFAULT_COLOUR = "#F07116";

const ReferenceLineForm = (props) => {

  const [value, setValue] = useState("");
  const [label, setLabel] = useState("");
  const [valid, setValid] = useState(false);
  const [colour, setColour] = useState(DEFAULT_COLOUR);

  useEffect(() => {
    setValid(value !== "");
  }, [value]);

  return (
    <Form className="inline">
      <Form.Group>
        <Row>
          <Col xs={1}>
            <ColourPickerButton updateStrokeColour={setColour} style={{width: "20px"}}/>
          </Col>

          <Col xs={2}>
            <Form.Control size="sm" type="number" placeholder="Temp" inline value={value} onChange={(e) => {
              setValue(e.target.value);
            }}/>
          </Col>
          <Col xs={4}>
            <Form.Control size="sm" type="text" placeholder="Label" inline value={label}
                          onChange={(e) => {
                            setLabel(e.target.value);
                          }}
            />
          </Col>
          <Col xs={5}>
            <Button size="sm" disabled={!valid} className="bg-success me-2" onClick={() => {

              props.addReferenceLine(
                {
                  label: label,
                  y: value,
                  stroke: colour,
                  strokeDasharray: "1 0"
                });

              props.setShowRefLineForm(false);

            }}>
              <FontAwesomeIcon icon={faCheck} className="me-2"/>
              Add
            </Button>

            <Button size="sm" className={"bg-danger " + classes.CancelButton} onClick={() => {
              props.setShowRefLineForm(false);
            }}>
              <FontAwesomeIcon icon={faTimes} className="me-2"/>
              Cancel</Button>
          </Col>
        </Row>
      </Form.Group>
    </Form>

  );

}

const mapStateToProps = (state) => {
  return {
    referenceLines: state.plots.referenceLines
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addReferenceLine: (referenceLine) => dispatch(actions.addReferenceLine(referenceLine))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceLineForm)