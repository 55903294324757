import React, {Component} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEdit, faCheck, faTimes
} from "@fortawesome/free-solid-svg-icons";
import AlertDashboard from './AlertDashboard';
import {connect} from 'react-redux';

import * as actions from '../../../../store/actions';

class AssetAlertTab extends Component {

  state = {
    editMode: false,
    changed: false,
    valid: false
  }

  changeValue = (value, alertLimit, valueKey) => {

    let alertLimitSettings = JSON.parse(JSON.stringify(this.props.editAlertLimits));
    alertLimitSettings[alertLimit][valueKey] = value;
    this.props.updateAlertLimitsSettings(alertLimitSettings);
    this.setState({changed: true});
  }

  updateAlertLimitSensorValue = (index, key, val) => {

    const editAlertLimits = JSON.parse(JSON.stringify(this.props.editAlertLimits));

    editAlertLimits[key].sensors[index] = val;

    this.setState({changed: true});

    this.props.updateAlertLimitsSettings(editAlertLimits);

  };

  render = () => {

    if (!this.props.assetData) {
      return null;
    }

    let buttons = (<Col className="d-flex justify-content-center mb-2">
      <Button className="bg-secondary settings-button" onClick={() => {
        this.setState({editMode: true})
      }}>
        <FontAwesomeIcon className="me-2" icon={faEdit}/>
        Edit
      </Button>
    </Col>);

    if (this.state.editMode) {

      buttons = (<Col className="d-flex justify-content-center mb-2">
        <Button className="bg-success settings-button" onClick={() => {
          let settings = JSON.parse(JSON.stringify(this.props.settings));
          settings.alertLimitSettings = JSON.parse(JSON.stringify(this.props.editAlertLimits));
          this.props.updateAssetSettings(this.props.assetData.id, settings);
          this.props.storeAssetSettings(settings);
          this.setState({editMode: false});
        }}
                disabled={!this.state.changed}
        >
          <FontAwesomeIcon className="me-2" icon={faCheck}/>
          Save
        </Button>
        <Button className="bg-danger settings-button" onClick={() => {
          this.props.restoreAlertLimitsSettings();
          this.setState({editMode: false, changed: false});
        }}>
          <FontAwesomeIcon className="me-2" icon={faTimes}/>

          Cancel
        </Button>
      </Col>);

    }

    let component = (<AlertDashboard
      settings={this.props.settings}
      editMode={this.state.editMode}
      changeValue={this.changeValue}
      editAlertLimits={this.props.editAlertLimits}
      updateAlertLimitSensorValue={this.updateAlertLimitSensorValue}
    />);

    return (<Container fluid className="h-100 d-flex flex-fill">
        <Row className="d-flex flex-fill h-100">
          <Col className="d-flex flex-fill d-flex-row align-content-between p-0 m-0 h-100">
            <Card className="bg-primary w-100 text-white h-100 m-0">
              <h3 className="text-center">Alert Settings</h3>

              {component}

              <Row className="d-flex mt-auto">
                {buttons}
              </Row>

            </Card>
          </Col>

        </Row>
      </Container>
    );

  }


}

const mapDispatchToProps = (dispatch) => {

  return {
    restoreAlertLimitsSettings: () => dispatch(actions.restoreAlertLimitsSettings()),
    updateAlertLimitsSettings: (alertLimits) => dispatch(actions.updateAlertLimitsSettings(alertLimits)),
    updateAssetSettings: (id, settings) => dispatch(actions.updateAssetSettings(id, settings)),
    storeAssetSettings: (settings) => dispatch(actions.storeAssetSettings(settings))
  }

}

const mapStateToProps = (state) => {
  return {
    assetData: state.assets.assetData,
    editAlertLimits: state.settings.editAlertLimits,
    settings: state.settings.settings
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetAlertTab);