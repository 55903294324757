import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import AssetIcon from "./AssetIcon";
import classes from "./AssetItem.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons/faStar";
import {withRouter} from "react-router";

const AssetItem = (props) => {

  let indentationClassName = classes.IndentLevel1;

  switch (props.indentationLevel) {
    case 1:
      indentationClassName = classes.IndentLevel1;
      break;
    case 2:
      indentationClassName = classes.IndentLevel2;
      break;
    case 3:
      indentationClassName = classes.IndentLevel3;
      break;
    case 4:
      indentationClassName = classes.IndentLevel4;
      break;
  }


  return (
    <Row key={props.id} style={{height: "40px"}}>
      <Col xs="8">
        <AssetIcon iconType={props.iconType} className={"me-2 asset-selector-text " + (props.isSelected ? " active-asset" : "") + " " + indentationClassName}/>
        <Button variant="link"
                className={"asset-selector-text text-white" + (props.isSelected ? " active-asset" : "") + " " + classes.Text + " "  + classes.NoTextWrap}
                onClick={() => {

                  console.log(props.url);
                  props.history.push(props.url);
                }}>
          {props.name}
        </Button>
      </Col>

      <Col xs="1" sm="1" className={"align-items-center " + classes.Text}>
        {props.isSentinelPoint ? <FontAwesomeIcon icon={faStar}/> : null}
      </Col>
      <Col xs="1" sm="1" className="align-items-center">
        {props.newAlertsCount > 0 && (props.noSearch || props.alertFilter === "new") ?
          <div className="new-alerts-button-side">{props.newAlertsCount}</div> : null}
      </Col>
      <Col xs="1" sm="1" className="align-items-center">
        {props.activeAlertsCount > 0 && (
          props.noSearch || props.alertFilter === "active") ?
          <div className="active-alerts-button-side">{props.activeAlertsCount}</div> : null}
      </Col>
    </Row>

  );



}

export default withRouter(AssetItem);