import * as actionTypes from './actionTypes';

export const setPageAssetDetails = (id: number, name: string, identifier: string) => {

  return {
    type: actionTypes.SET_PAGE_ASSET_DETAILS,
    id: id,
    name: name,
    identifier: identifier
  }

}

export const setPageCompanyId = (companyId: number) => {

  return {
    type: actionTypes.SET_PAGE_COMPANY_ID,
    companyId: companyId,
  }

}

export const setPageAssetId = (assetId: number) => {

  return {
    type: actionTypes.SET_PAGE_ASSET_ID,
    assetId: assetId,
  }

}

export const setPageSubDetails = (id: number, name: string) => {

  return {
    type: actionTypes.SET_PAGE_SUB_DETAILS,
    id: id,
    name: name
  }

}

export const setPageBuildingDetails = (id: number, name: string) => {

  return {
    type: actionTypes.SET_PAGE_BUILDING_DETAILS,
    id: id,
    name: name
  }

}

export const setPageTitle = (title: string) => {
  return {
    type: actionTypes.SET_PAGE_TITLE,
    pageTitle: title
  }
}

export const setForcedPasswordChanged = (forced: any) => {
  return {
    type: actionTypes.SET_FORCED_PASSWORD_CHANGED,
    forcedPasswordChanged: forced
  }
}

export const setPageNavLinks = (navLinks: any) => {
  return {
    type: actionTypes.SET_PAGE_NAV_LINKS,
    pageNavLinks: navLinks
  }
}


export interface IIncrementLoading {
  type: string,
}

export type IDispatchIncrementLoading = (arg: IIncrementLoading) => (IIncrementLoading);

export const incrementPageLoading = () => {
  return {
    type: actionTypes.INCREMENT_LOADING,
  }
}

export interface IDecrementLoading {
  type: string,
}

export type IDispatchDecementLoading = (arg: IDecrementLoading) => (IDecrementLoading);

export const decrementPageLoading = () : IDecrementLoading => {
  return {
    type: actionTypes.DECREMENT_LOADING,
  }
}

export interface IClearLoading {
  type: string,
}

export interface IDispatchClearLoading {
  (): IClearLoading
}

export const clearLoading = () :IClearLoading => {
  return {
    type: actionTypes.CLEAR_LOADING,
  }
}

export interface IDispatchSetPageInfo {
  (buildingId: number, sub1Id: number, sub2Id: number, assetId: number): ISetPageInfo
}

export interface ISetPageInfo {
  type: string
  selectedBuildingId: number,
  selectedSub1Id: number,
  selectedSub2Id: number,
  selectedAssetId: number
}

export const setPageInfo = (buildingId: number, sub1Id: number, sub2Id: number, assetId: number) : ISetPageInfo => {

  return {
    type: actionTypes.SET_PAGE_INFO,
    selectedBuildingId: buildingId,
    selectedSub1Id: sub1Id,
    selectedSub2Id: sub2Id,
    selectedAssetId: assetId
  }

}

