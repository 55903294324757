import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Container, Form, FormGroup, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes, faUser, faAt, faPhone, faLock} from "@fortawesome/free-solid-svg-icons";
import LoginTemplate from "../Login/LoginTemplate";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {useParams, withRouter} from "react-router-dom";
import PasswordRequirements from "../ChangePassword/PasswordRequirements";
import ReCAPTCHA from "react-google-recaptcha";
import RevealablePasswordField from "../ChangePassword/RevealablePasswordField";
import classes from './UserInvite.module.css';

const ForgottenPassword = (props) => {

  const {inviteCode} = useParams();

  const [recaptcha, setRecaptcha] = useState(null);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [correctLength, setCorrectLength] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsUppercase, setContainsUppercase] = useState(false);
  const [containsLowercase, setContainsLowercase] = useState(false);
  const [containsSymbol, setContainsSymbol] = useState(false);
  const [valid, setValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    props.clearError();
    props.setAdminUpdateSuccess(null, "");
  }, []);

  const checkPassword = (password) => {
    setContainsNumber(password.match(/[0-9]/g));
    setContainsSymbol(password.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.#@\/]/g));
    setContainsUppercase(password.match(/[A-Z]/g));
    setContainsLowercase(password.match(/[a-z]/g));
    setCorrectLength(password.length >= 8 && password.length <= 16);
  }

  useEffect(() => {

    if (password !== verifyPassword) {
      setErrorMessage("Confirmed new password does not match new password");
    } else {
      setErrorMessage("");
    }

    let alert = null;

    setValid(correctLength && containsUppercase && containsSymbol && containsNumber && containsLowercase
      && password === verifyPassword && fullName.length > 0 && phoneNumber.length > 0 && fullName.length > 0 && recaptcha !== null);

  }, [password, verifyPassword, valid, setValid, correctLength, containsUppercase, containsSymbol, containsNumber,
    containsLowercase, fullName, verificationCode, phoneNumber, recaptcha, email]);

  if (props.authenticated) {

    return (
      <LoginTemplate>

        <form>

          <Card.Title>
            <h3 className={classes.Title}>Account creation</h3>
          </Card.Title>
          <Container>
            <Row>
              <Col>
                <Alert type="danger" className="alert-danger">
                  You must be logged out to be able to create the account.
                </Alert>
              </Col>
            </Row>
          </Container>
        </form>
      </LoginTemplate>

    );

  }


    let alert = null;

  if (props.isError && !props.errorShown) {
    alert = (<Alert variant="danger" dismissible={true} className="alert-dismissible"
                    onClose={props.clearError}>{props.error === "Unauthorised" ? "Username or password is not valid" : props.error}</Alert>);
  } else if (props.success) {
    alert = (<Alert variant="success" dismissible={false} className="alert-dismissible"
                    >Account has been created</Alert>);

  }

  const onChange = (value) => {
    setRecaptcha(value);
  }

  let button = null;

  if (!props.success) {
    button = (<Button variant="outline-primary mt-3" disabled={!valid} onClick={
      () => {

        let accountCreate = {
          id: inviteCode,
          email: email.trim(),
          password: password.trim(),
          phoneNumber: phoneNumber.trim(),
          fullName: fullName.trim(),
          verificationCode: verificationCode.trim(),
          recaptcha: recaptcha
        };

        props.clearError();
        props.createAccountFromInvite(accountCreate);
      }
    }>
      <FontAwesomeIcon icon={faCheck} className="me-2"/>
      Proceed
    </Button>);
  }

  let form = null;

  if (props.success !== true) {
    form = ([
        (<Row>
          <Col>
            <FormGroup>
              <InputGroup className="mb-1">
                <InputGroup.Text size="sm" id="userName">
                  <FontAwesomeIcon icon={faAt} className="text-primary"/>
                </InputGroup.Text>

                <Form.Control size="sm" className="form-control"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}/>
              </InputGroup>

            </FormGroup>

          </Col>
          <Col>
            <FormGroup>
              <InputGroup className="mb-1">
                <InputGroup.Text size="sm" id="fullName">
                  <FontAwesomeIcon icon={faUser} className="text-primary"/>
                </InputGroup.Text>

                <Form.Control size="sm" className="form-control"
                              placeholder="Full Name"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}/>
              </InputGroup>

            </FormGroup>

          </Col>
        </Row>),
        (<Row>
          <Col>
            <FormGroup>
              <InputGroup className="mb-1">
                <InputGroup.Text size="sm" id="phoneNumber">
                  <FontAwesomeIcon icon={faPhone} className="text-primary"/>
                </InputGroup.Text>

                <Form.Control size="sm" className="form-control"
                              placeholder="Phone Number"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}/>
              </InputGroup>

            </FormGroup>

          </Col>
          <Col>
            <FormGroup>
              <InputGroup className="mb-1">
                <InputGroup.Text size="sm" id="fullName">
                  <FontAwesomeIcon icon={faLock} className="text-primary"/>
                </InputGroup.Text>

                <Form.Control size="sm" className="form-control"
                              placeholder="Verification Code"
                              value={verificationCode}
                              onChange={(e) => setVerificationCode(e.target.value)}/>
              </InputGroup>

            </FormGroup>

          </Col>

        </Row>),
        (<Row>
          <Col className="mb-1">
                <RevealablePasswordField
                  onClickFunction={(e) => {
                    setPassword(e.target.value);
                    checkPassword(e.target.value);
                  }}
                  value={password}
                  placeholderText="Password"
                />
          </Col>
          <Col>
            <RevealablePasswordField
              onClickFunction={(e) => {
                setVerifyPassword(e.target.value);
                // checkPassword(e.target.value);
              }}
              value={verifyPassword}
              placeholderText="Confirm Password"
            />

          </Col>

        </Row>),

        (<Row>
          <Col className="d-flex justify-content-center">
            <ReCAPTCHA size="normal"
              sitekey="6Ld4GZsaAAAAAIoplBlWJoBzXMhuhIFG_XHcvJkr"
              onChange={(v) => onChange(v)}
            />
          </Col>
        </Row>)]
    )
  }


  return (

    <LoginTemplate>

      <form>

        <Card.Title>
          <h3 className={classes.Title}>Account creation</h3>
        </Card.Title>
        {alert}
        <Container>
          <Row>
            <Col>
              <PasswordRequirements
                correctLength={correctLength}
                containsLowercase={containsLowercase}
                containsUppercase={containsUppercase}
                containsNumber={containsNumber}
                containsSymbol={containsSymbol}
                // differentPassword={true}
                // untouched={true}
                // alert={alert}
              />
            </Col>
          </Row>
          {form}
          <Row>
            <Col>
              {button}
              <Button className="ms-2" style={{width: 120}} variant="outline-primary mt-3" onClick={
                () => {
                  props.history.push("/login");
                }
              }>
                <FontAwesomeIcon icon={props.success ? faCheck : faTimes} className="me-2"/>{props.success ? "Continue" : "Exit"}</Button>
            </Col>
          </Row>
        </Container>
      </form>
    </LoginTemplate>

  );

}

const mapStateToProps = (state) => {
  return {
    errorShown: state.errors.errorShown,
    error: state.errors.errorMessage,
    isError: state.errors.isError,
    loading: state.page.loading,
    authenticated: state.authentication.authenticated,
    success: state.admin.success
  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    login: (user, password) => dispatch(actions.login(user, password)),
    clearError: () => dispatch(actions.clearError()),
    createAccountFromInvite: (userData) => dispatch(actions.createAccountFromInvite(userData)),
    setAdminUpdateSuccess: (value, error) => dispatch(actions.setAdminUpdateSuccess(value, error))
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgottenPassword));