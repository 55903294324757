export let STORE_REPORT = "STORE_REPORT";
export const LOAD_COMPANY_DATA_FAILED : string  = "LOAD_COMPANY_DATA_FAILED";
export const STORE_COMPANY_DATA : string = "STORE_COMPANY_DATA";
export const SET_SELECTED_COMPANY_DATA : string = "SET_SELECTED_COMPANY_DATA";

export const LOAD_SELECTED_COMPANY_START : string = 'LOAD_SELECTED_COMPANY_START';
export const STORE_SELECTED_COMPANY_DATA : string  = "STORE_SELECTED_COMPANY_DATA";

export const SET_ALERT_FILTER : string = "SET_ALERT_FILTER";
export const SET_SEARCH_FILTER : string = "SET_SEARCH_FILTER";
export const STORE_FILTERED_DATA : string = "STORE_FILTERED_DATA";
export const SET_BUILDING_FILTER : string = "SET_BUILDING_FILTER";
export const SET_SUB_1_FILTER : string = "SET_SUB_1_FILTER";
export const SET_SUB_2_FILTER : string = "SET_SUB_2_FILTER";
export const SET_SUB_3_FILTER : string = "SET_SUB_3_FILTER";

export const CLEAR_FILTERS : string = "CLEAR_FILTERS";

export const SET_PLOT_DATA : string = "SET_PLOT_DATA";
export const ADD_REFERENCE_LINE : string = "ADD_REFERENCE_LINE";
export const REMOVE_REFERENCE_LINE : string = "REMOVE_REFERENCE_LINE";

export const SET_PAGE_ASSET_DETAILS : string = "SET_PAGE_ASSET_DETAILS";
export const SET_PAGE_BUILDING_DETAILS : string = "SET_PAGE_BUILDING_DETAILS";
export const SET_PAGE_SUB_DETAILS : string = "SET_PAGE_SUB_DETAILS";

export const SET_ASSET_DATA : string = "SET_ASSET_DATA";
export const STORE_DATA : string = "STORE_DATA";

export const SET_ALERT_DATA : string = "SET_ALERT_DATA";
export const STORE_ALERT_DATA : string = "STORE_ALERT_DATA";
export const SET_SELECTED_ALERT : string = "SET_SELECTED_ALERT";
export const MERGE_ENGINEER_READINGS : string = "MERGE_ENGINEER_READINGS";
export const UPDATE_SELECTED_ALERT : string = "UPDATE_SELECTED_ALERT";

export const SET_PAGE_TITLE : string = "SET_PAGE_TITLE";
export const SET_PAGE_NAV_LINKS : string = "SET_PAGE_NAV_LINKS";

export const DECREMENT_LOADING : string = "DECREMENT_LOADING";
export const INCREMENT_LOADING : string = "INCREMENT_LOADING";

export const SET_PAGE_INFO : string = "SET_PAGE_INFO";

export const SET_LOGIN : string = "SET_LOGIN";
export const LOGIN : string = "LOGIN";
export const LOGOUT : string = "LOGOUT";

export const SET_ERROR : string = "SET_ERROR";
export const CLEAR_ERROR : string = "CLEAR_ERROR";

export const STORE_ASSET_SETTINGS : string = "STORE_ASSET_SETTINGS";
export const RESTORE_SENSOR_SETTINGS : string  = "RESTORE_SENSOR_SETTINGS";
export const UPDATE_SENSOR_SETTINGS : string = "UPDATE_SENSOR_SETTINGS";
export const UPDATE_ALERT_SETTINGS : string = "UPDATE_ALERT_SETTINGS";
export const UPDATE_ALERT_LIMITS_SETTINGS : string = "UPDATE_ALERT_LIMITS_SETTINGS";
export const RESTORE_ALERT_SETTINGS : string = "RESTORE_ALERT_SETTINGS";
export const RESTORE_ALERT_LIMITS_SETTINGS : string = "RESTORE_ALERT_LIMITS_SETTINGS";

export const SET_AUTH_DETAILS : string = "SET_AUTH_DETAILS";
export const CLEAR_LOADING : string = "CLEAR_LOADING";
export const SET_PASSWORD_CHANGED: string = "SET_PASSWORD_CHANGED";
export const SET_PASSWORD_RESET_REQUESTED: string = "SET_PASSWORD_RESET_REQUESTED";

export const STORE_FIRST_MEASUREMENT_RECEIVED: string = "STORE_FIRST_MEASUREMENT_RECEIVED";

export const SET_PAGE_COMPANY_ID: string = "SET_PAGE_COMPANY_ID";
export const SET_PAGE_ASSET_ID: string = "SET_PAGE_ASSET_ID";
export const STORE_ALERT: string = "STORE_ALERT";
export const STORE_ALERT_LIST: string = "STORE_ALERT_LIST";

export const SET_ADMIN_ASSET_DATA: string = "SET_ADMIN_ASSET_DATA";
export const SET_ADMIN_ASSET: string = "SET_ADMIN_ASSET";
export const SET_ADMIN_BUILDING_LIST: string = "SET_ADMIN_BUILDING_LIST";
export const SET_ADMIN_SUBGROUP1_LIST: string = "SET_ADMIN_SUBGROUP1_LIST";
export const SET_ADMIN_SUBGROUP2_LIST: string = "SET_ADMIN_SUBGROUP2_LIST";
export const SET_ADMIN_SUBGROUP3_LIST: string = "SET_ADMIN_SUBGROUP3_LIST";

export const SET_ADMIN_ICONS_LIST: string = "SET_ADMIN_ICONS_LIST";
export const SET_ADMIN_RESELLERS_LIST: string = "SET_ADMIN_RESELLERS_LIST";
export const SET_ADMIN_UPDATE_SUCCESS: string = "SET_ADMIN_UPDATE_SUCCESS";
export const SET_ADMIN_USER_INVITE_LIST: string = "SET_ADMIN_USER_INVITE_LIST";
export const SET_ADMIN_USER_LIST: string = "SET_ADMIN_USER_LIST";
export const SET_ADMIN_USER: string = "SET_ADMIN_USER";
export const SET_ADMIN_ROLES: string = "SET_ADMIN_ROLES";
export const SET_ADMIN_BUILDING_EDIT_LIST: string = "SET_ADMIN_BUILDING_EDIT_LIST";
export const SET_ADMIN_COMPANY_LIST: string = "SET_ADMIN_COMPANY_LIST";
export const SET_ADMIN_COMPANY: string = "SET_ADMIN_COMPANY";
export const SET_ADMIN_BULK_STATUS: string = "SET_ADMIN_BULK_STATUS";
export const INIT_ADMIN_BULK_STATUS: string = "INIT_ADMIN_BULK_STATUS";

export const SET_SUBSCRIPTION_BUILDINGS: string = "SET_SUBSCRIPTION_BUILDINGS";
export const SET_FILE_LIST: string = "SET_FILE_LIST";
export const SET_FILE_DETAILS: string = "SET_FILE_DETAILS";
export const STORE_SUBSCRIPTION: string = "STORE_SUBSCRIPTION";
export const SET_COMPANY_LIST: string = "SET_COMPANY_LIST";

export const SET_FORCED_PASSWORD_CHANGED: string = "SET_FORCED_PASSWORD_CHANGED";
export const CLEAR_PLOT_DATA: string = "CLEAR_PLOT_DATA";
export const SET_PLOT_LOADING: string = "SET_PLOT_LOADING";
export const SET_CUSTOM_GRAPH: string = "SET_CUSTOM_GRAPH";

export const STORE_ALERT_HISTORY: string = "STORE_ALERT_HISTORY";