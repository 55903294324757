import * as actionTypes from "../actions/actionTypes";


const initialState = {
  fileList: null,
  fileDetails: null,
  pagination: null
}

const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.SET_FILE_LIST:
      return {
        ...state,
        fileList: action.fileList,
        pagination: action.pagination
      }
    case actionTypes.SET_FILE_DETAILS:
      return {
        ...state,
        fileDetails: action.fileDetails
      }
    default:
      return state;
  }
}

export default reducer;