import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Col, Container, Modal, Row, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as actions from '../../../store/actions';

const EditModal = (props) => {

  const [fileDescription, setFileDescription] = useState("");
  const [fileName, setFileName] = useState("");
  const [valid, setValid] = useState(false);

  useEffect(() => {

    if (props.fileId) {
      props.getFile(props.fileId);
    }

  }, [props.fileId]);

  useEffect(() => {

    let isValid = true;

    if (!fileName || fileName.length === 0) {
      isValid = false;
    }

    if (!fileDescription || fileDescription.length === 0) {
      isValid = false;
    }

    setValid(isValid);

  }, [fileName, fileDescription]);


  useEffect(() => {

    if (props.fileDetails) {
      setFileDescription(props.fileDetails.fileDescription);
      setFileName(props.fileDetails.fileName);
    }

  }, [props.fileDetails])

  return (
    <Modal show={props.showModal} centered onHide={() => props.setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>

      <Modal.Body className="d-flex justify-content-center">
        <Container fluid>

          <Row>
            <Col className="d-flex justify-content-start" xs={12}>
              <Form.Label>File Description</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start" xs={12}>
              <Form.Control as="textarea" placeholder="File Description" value={fileDescription} onChange={(e) => {
                setFileDescription(e.target.value);
              }}/>
            </Col>

          </Row>
          <Row>
            <Col className="d-flex justify-content-start" xs={12}>
              <Form.Label>File Name</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start" xs={12}>
              <Form.Control placeholder="File Description" value={fileName} onChange={(e) => {
                setFileName(e.target.value);
              }}/>
            </Col>


          </Row>          <Row className="mt-2">
            <Col className="d-flex justify-content-center" xs={12} >
              <Button disabled={!valid} className="bg-success me-2 text-left" style={{width: 120}} onClick={() => {

                props.setShowModal(false);

                let fileParams = {
                  companyId: props.companyId,
                  assetId: props.assetId,
                  fileDescription: fileDescription,
                  fileName: fileName
                };

                props.updateFile(props.fileId, props.companyId, props.assetId, fileParams, {pageNumber: props.pageNo, pageSize: props.pageSize});
              }}>
                <FontAwesomeIcon icon={faSave} className="me-2"/>Save</Button>
              <Button className="bg-danger bg-secondary text-left" style={{width: 120}} onClick={() => props.setShowModal(false)}>
                <FontAwesomeIcon icon={faTimes} className="me-2"/>Cancel</Button>
            </Col>
          </Row>
        </Container>

      </Modal.Body>
    </Modal>
  );


}

const mapStateToProps = (state) => {
  return {
    fileDetails: state.file.fileDetails
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFile: (fileId, companyId, assetId, fileParams, pagingParams) => dispatch(actions.updateFile(fileId, companyId, assetId, fileParams, pagingParams)),
    getFile: (fileId) => dispatch(actions.getFile(fileId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditModal));