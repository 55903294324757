import * as actionTypes from './actionTypes';
import * as actions from './index';
import agent from "../../app/api/agent";

export const setAssetData = (assetData) => {
  return {
    type: actionTypes.SET_ASSET_DATA,
    assetData: assetData
  }
}

export const fetchAssetData = (id) => {
  return dispatch => {

    agent.Asset.get(id)
      .then(
        response => {
          dispatch(actions.setPageAssetDetails(response.id, response.name, response.uniqueIdentifier));
          dispatch(actions.setPageSubDetails(response.subId, response.subFloorName));
          dispatch(actions.setPageBuildingDetails(response.buildingId, response.buildingName));
          dispatch(setAssetData(response));
          dispatch(actions.setPageInfo(response.buildingId, response.subGroup1Id, response.subGroup2Id, response.id));

        });

  }
}