import * as actionTypes from '../actions/actionTypes';
import {MERGE_ENGINEER_READINGS} from "../actions/actionTypes";

const initialState = {
  assetId: null,
  alertList: null,
  alertHistory: null,
  assetAlert: null,
  index: null,
  alertId: null
}

const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.STORE_ALERT:
      return {
        ...state,
        alertId: action.alertId,
        assetAlert: action.assetAlert,
      }
    case actionTypes.STORE_ALERT_HISTORY:
      return {
        ...state,
        alertId: action.alertId,
        alertHistory: action.alertHistory,
      }
    case actionTypes.STORE_ALERT_LIST:
      return {
        ...state,
        assetId: action.assetId,
        alertList: [...action.alertList],
      }
    case MERGE_ENGINEER_READINGS:
      const assetAlert = {...state.assetAlert};

      if (!assetAlert.engineerReading) {
        assetAlert.engineerReading = []
      }

      assetAlert.engineerReading.push(action.readings);

      return {
        ...state,
        assetAlert: assetAlert,
      }

    default:
      return state;
  }

}

export default reducer;