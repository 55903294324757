import React from 'react';
import {HashRouter as Router, Route, Switch, withRouter} from "react-router-dom";
import ForgottenPassword from "./Feature/ForgottenPassword/ForgottenPassword";
import PasswordReset from "./Feature/PasswordReset/PasswordReset";
import ChangePassword from "./Feature/ChangePassword/ChangePassword";
import {Redirect} from "react-router";
import Login from "./Feature/Login/Login";
import CompanyRoutingConfig from "./Feature/Company/CompanyRoutingConfig";
import {connect} from "react-redux";
import AdminRoutingConfig from "./Feature/Admin/AdminRoutingConfig";
import ManageSubsriptions from "./Feature/Subscriptions/ManageSubsriptions";
import UserInvite from "./Feature/UserInvite/UserInvite";

const RoutingConfig = (props) => {

  if (!props.authenticated) {
    return (
      <DebugRouter forceRefresh={true}>
        <Switch>
          <Route path={"/user-invite/:inviteCode"}><UserInvite {...props}/></Route>
          <Route path={"/forgotten-password"}><ForgottenPassword  {...props}/></Route>
          <Route path={"/reset-password/:resetCode"}><PasswordReset  {...props}/></Route>
          <Route path={"/login"} ><Login/></Route>
          <Route component={Login}/>
        </Switch>
      </DebugRouter>);
  }

  if (props.forcePasswordChange) {
    return (
      <DebugRouter forceRefresh={true}>
        <Switch>
          <Route path={"/change-password"} ><ChangePassword {...props}/></Route>
          <Redirect to='/change-password' />

        </Switch>
      </DebugRouter>);
  }


  return (

    <Router forceRefresh={true}>
      <Switch>
        <Route path={"/user-invite/:inviteCode"}><UserInvite {...props}/></Route>
        <Route path={"/admin/"}><AdminRoutingConfig {...props}/></Route>
        <Route path={"/forgotten-password"}><ForgottenPassword {...props}/></Route>
        <Route path={"/reset-password/:resetCode"}><PasswordReset {...props}/></Route>
        <Route path={"/change-password"} ><ChangePassword {...props}/></Route>
        <Route path={"/login"} ><Login/></Route>
        <Route path={"/manage-subscriptions"} ><ManageSubsriptions {...props}/></Route>
        <Route path={"/company/:companyId"}><CompanyRoutingConfig {...this}/></Route>
        <Route path={"/main"}>
          <RedirectNotAdmin {...props}/>
        </Route>
        <Route path={"/"}>
          <RedirectNotAdmin  {...props}/>
        </Route>
      </Switch>
    </Router>

  );


}

const RedirectNotAdmin = (props) => {

  if (props.role === "SiteAdmin" || props.role === "ResellerAdmin") {
    return (<Redirect to={"/admin/"}/>);
  } else {
    return (<Redirect to={"/company/" + props.companyId} />);
  }

}

class DebugRouter extends Router {
  constructor(props){
    super(props);
    this.history.listen((location, action)=>{
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
    });
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    role: state.authentication.role,
    companyId: state.authentication.companyId,
    forcePasswordChange: state.authentication.forcePasswordChange
  }
}


export default connect(mapStateToProps)((withRouter(RoutingConfig)));