import React, {useEffect, useState} from "react";
import {Accordion, AccordionCollapse} from "react-bootstrap";
import ExpandableItem from "./ExpandableItem";
import SubGroup2Accordions from "./SubGroup2Accordions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import AssetItem from "./AssetItem";

const SubGroupAccordion = (props) => {

  const [active, setActive] = useState(
    props.subGroup.id === props.selectedSub1Id ? "sub_" + props.selectedSub1Id : null
  );

  const [onlyOnce, setOnlyOnce] = useState(false);

  useEffect(() => {

    if (!onlyOnce) {
      if (props.subGroup && props.subGroup.id === props.selectedSub1Id && active !== "sub_" + props.selectedSub1Id) {
        setActive("sub_" + props.selectedSub1Id);
        setOnlyOnce(true);

      }

    }


  }, [active, props.subGroup, props.subGroup.id, props.selectedSub1Id, onlyOnce]);


  if (!onlyOnce && active !== null) {
    setOnlyOnce(true);
  }

  return (
    <Accordion className="text-white"
               key={"sub_" + props.subGroup.id}
               activeKey={active}
               onSelect={e => setActive(e)}>

      <ExpandableItem name={props.subGroup.name} newAlerts={props.subGroup.newAlerts}
                      activeAlerts={props.subGroup.activeAlerts}
                      eventKey={"sub_" + props.subGroup.id} indent={1} search={props.search}
                      linkClass={props.selectedSub1Id === props.subGroup.id ? "asset-selector-text text-white active-asset"
                        : "selector-text text-white"}
      />

      <AccordionCollapse eventKey={"sub_" + props.subGroup.id}>

        <div>


          {props.subGroup.buildingSubGroup2 != null ? <SubGroup2Accordions data={props.subGroup.buildingSubGroup2}
                                                                           search={props.search}
                                                                           selectedAsset={props.selectedAsset}
                                                                           selectedSub={props.selectedSub}
                                                                           selectedBuilding={props.selectedSub}
          /> : null}



          {
            props.subGroup.assets.map(
              (item, index) => {

                return (<AssetItem name={item.name} id={item.id}
                                   isSentinelPoint={item.sentinelPoint}
                                   isSelected={item.id === props.selectedAssetId}
                                   url={"/company/" + props.companyId + "/asset/" + item.id}
                                   newAlertsCount={item.newAlerts}
                                   activeAlertsCount={item.activeAlerts}
                                   alertFilter={props.alertFilter}
                                   noSearch={props.noSearch}
                                   indentationLevel={2}
                                   iconType={item.iconType}
                />);

                // return (
                //   <Row key={item.id} style={{height: "40px"}}>
                //     <Col xs="8">
                //
                //       <AssetIcon iconType={item.iconType} className={"asset-selector-text" + (props.selectedAssetId === item.id ? " active-asset" : "") + " " + classes.IndentLevel1}/>
                //
                //       <Button variant="link"
                //               className={"asset-selector-text text-white" + (props.selectedAssetId === item.id ? " active-asset" : "") + " " + classes.Text}
                //               onClick={() => {
                //                 props.history.push(`/company/${props.companyId}/asset/${item.id}/?name=${item.name}&assetIdentifier=${item.assetIdentifier}&buildingId=${item.buildingId}&subId=${item.subId}`);
                //               }}>
                //         {item.name}
                //       </Button>
                //     </Col>
                //     <Col xs="1" sm="1" className="align-items-center">
                //       {item.sentinelPoint ? <FontAwesomeIcon icon={faStar}/> : null}
                //     </Col>
                //
                //     <Col xs="1" sm="1" className="align-items-center">
                //       {item.newAlerts > 0 && (props.noSearch || props.alertFilter === "new") ?
                //         <div className="new-alerts-button-side">{item.newAlerts}</div> : null}
                //     </Col>
                //     <Col xs="1" sm="1" className="align-items-center">
                //       {item.activeAlerts > 0 && (props.noSearch || props.alertFilter === "active") ?
                //         <div className="active-alerts-button-side">{item.activeAlerts}</div> : null}
                //     </Col>
                //
                //   </Row>
                // );
              }
            )
          }

        </div>
      </AccordionCollapse>

    </Accordion>
  );

}

const mapStateToProps = (state) => {

  return {
    selectedAssetId: state.page.selectedAssetId,
    alertFilter: state.filter.alertFilter,
    assetData: state.assets.assetData,
    selectedSub1Id: state.page.selectedSub1Id,
    companyId: state.page.companyId
  }
}

export default connect(mapStateToProps)(withRouter(SubGroupAccordion));