import React, {useEffect, useState} from 'react';
import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import {connect} from 'react-redux';
import classes from './Breadcrumb.module.css';

const DvsBreadcrumb = (props) => {

  const [breadCrumbs, setBreadCrumbs] = useState(null);

  useEffect(() => {
    console.log(props.pageNavLinks);

    let breadCrumbs = []

    if (props.pageNavLinks) {
      breadCrumbs = props.pageNavLinks.map((item, index) => {
        return (<Breadcrumb.Item key={index} href={item.url}>{item.title}</Breadcrumb.Item>);
      })

      setBreadCrumbs(breadCrumbs);

    }

  }, [props.pageNavLinks]);

  // if (props.pageNavLinks) {
  //   breadCrumbs = props.pageNavLinks;
  // }

  return (
    [
      (
        <Container fluid className={"p-0 m-0 w-100 d-flex d-md-none d-lg-none d-xl-none bg-secondary " + (props.fixedHeader ? "fixed-breadcrumbs" : "nonfixed-breadcrumbs")}>
          <div className="col align-self-center justify-content-end bg-secondary text-white">
            {props.pageTitle}
          </div>

        </Container>
      ),
      (
        <Container fluid className={"p-0 m-0 d-none d-md-block bg-secondary "}>
          <Row className={classes.BreadcrumbContainer + " w-100  d-flex"}>
            <Col className="col-xl-6 col-6 offset-xl-2 text-white bg-secondary d-flex align-items-center">
              <p className={classes.BreadcrumbTitle + " ms-5"}>{props.pageTitle}</p>
            </Col>
            <Col className="align-items-end d-flex">
              <Breadcrumb className="align-content-end d-flex p-0 m-0 text-white breadcrumb-item">
                {breadCrumbs}
              </Breadcrumb>
            </Col>
          </Row>
        </Container>)
    ]
  );

}

const mapStateToProps = (state) => {
  return {
    pageTitle: state.page.pageTitle,
    pageNavLinks: state.page.pageNavLinks
  }
}

export default connect(mapStateToProps)(DvsBreadcrumb);