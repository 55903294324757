import * as actionTypes from '../actions/actionTypes';

const initialState = {
  companyData: [],
  selectedCompanyData: {},
};

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.STORE_COMPANY_DATA:
      return {
        ...state,
        companyData: [...action.companyData],
        selectedCompanyData: {...action.companyData[0]}
      }
    case actionTypes.SET_SELECTED_COMPANY_DATA:

      let companyData = [...state.companyData];
      let arrayIndex = null;
      companyData.forEach((company, index) => {
        if (company.id === action.companyId) {
          arrayIndex = index;
        }
      });

      if (arrayIndex !== null) {
        return {
          ...state,
          selectedCompanyData: {...state.companyData[arrayIndex]}
        }

      }

      return state;
    case actionTypes.LOAD_SELECTED_COMPANY_START:
      return {
        ...state,
        loading: true
      }
    case actionTypes.STORE_SELECTED_COMPANY_DATA:

      return {
        ...state,
        data: action.data
      }
    default:
      return state;

  }

}

export default reducer;