import React, {Component} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {faBellSlash, faBell, faEdit, faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import * as actions from '../../../../store/actions';
import Switch from "react-switch";
import {connect} from 'react-redux';

class AssetAlarmTab extends Component {

  state = {
    editMode: false,
    changed: false
  }

  handleChange = (key, checked) => {
    this.updateEnabled(key, checked);
    this.setState({changed: true});
  }

  updateEnabled = (key, newValue) => {

    let alerts = [...this.props.editAlert];

    alerts[key].enabled = newValue;
    this.props.updateAlertSettings(alerts);
  }

  render = () => {

    if (!this.props.assetData || !this.props.settings) {
      return null;
    }
    
    let buttons = (<Col className="d-flex justify-content-center mb-2">
      <Button className="bg-secondary settings-button" onClick={() => {
        this.setState({editMode: true})
      }}>
        <FontAwesomeIcon className="me-2" icon={faEdit}/>
        Edit
      </Button>
    </Col>);

    if (this.state.editMode) {

      buttons = (<Col className="d-flex justify-content-center mb-2">
        <Button className="bg-success settings-button" onClick={() => {
          let settings = {...this.props.settings};
          settings.alertSettings = [...this.props.editAlert];
          this.props.updateAssetSettings(this.props.assetData.id, settings);
          this.props.storeAssetSettings(settings);
          this.setState({editMode: false});
        }}
        disabled={!this.state.changed}
        >
          <FontAwesomeIcon className="me-2" icon={faCheck}/>
          Save
        </Button>
        <Button className="bg-danger settings-button" onClick={() => {
          this.props.restoreAlertSettings();
          this.setState({editMode: false, changed: false});
        }}>
          <FontAwesomeIcon className="me-2" icon={faTimes}/>

          Cancel
        </Button>
      </Col>);


    }

    let components = null;

    if (this.state.editMode) {

      components = Object.keys(this.props.editAlert).map((key, index) => {

        let alert = this.props.editAlert[key];

        return (<Row className="mt-auto">
          <Col xs={6} className="offset-3"><h3>{alert.name}</h3></Col>
          <Col xs={2}>
`            <Switch className="react-switch" onChange={(checked, event, id) => {
              this.handleChange(key, checked);
            }}
                    checked={alert.enabled} id={"asset-switch_" + index}
            />
`          </Col>
        </Row>);

      })

      components = Object.keys(this.props.editAlert).map((key, index) => {

        let alert = this.props.editAlert[key];
        return (<Row className="mt-auto">
          <Col xs={6} className="offset-3"><h3>{alert.displayName}</h3></Col>
          <Col xs={2}>
            <Switch className="react-switch" onChange={(checked, event, id) => {
              this.handleChange(key, checked);
            }}
            checked={alert.enabled === true} id={"asset-switch_" + index}
            />
          </Col>
        </Row>);

      })
    } else {

      components = Object.keys(this.props.editAlert).map((key, index) => {

        let alert = this.props.editAlert[key];

        return (<Row className="mt-auto d-flex align-items-center">
          <Col xs={6} className="offset-3"><h3 className={alert.enabled === true ? "text-white" : "text-muted"}>{alert.displayName}</h3></Col>
          <Col xs={2}>
            <h3 className={alert.enabled === true ? "text-white" : "text-muted"}>
              <FontAwesomeIcon icon={alert.enabled === true ? faBell : faBellSlash}/>
            </h3>
          </Col>
        </Row>);

      });
    }

    return (
      <Container fluid className="h-100 d-flex flex-fill">
        <Row className="d-flex flex-fill">
          <Col className="d-flex flex-fill d-flex-row align-content-between p-0 m-0">
            <Card className="bg-primary w-100 text-white h-100 m-0 justify-content-center align-items-center">
              <Container className="d-flex flex-column h-100">
                <h2 className="text-center">Alerts ON/OFF</h2>
                {components}

                <Row className="d-flex flex-column mt-auto">
                  {buttons}
                </Row>


              </Container>

            </Card>
          </Col>


        </Row>

      </Container>
    );

  }

}

const mapStateToProps = (state) => {
  return {
    assetData: state.assets.assetData,
    editAlert: state.settings.editAlert,
    settings: state.settings.settings
  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    restoreAlertSettings: () => dispatch(actions.restoreAlertSettings()),
    updateAlertSettings: (alerts) => dispatch(actions.updateAlertSettings(alerts)),
    updateAssetSettings: (id, settings) => dispatch(actions.updateAssetSettings(id, settings)),
    storeAssetSettings: (settings) => dispatch(actions.storeAssetSettings(settings))
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(AssetAlarmTab);