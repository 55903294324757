import React from 'react';
import {HashRouter as Router, matchPath, Route, Switch, withRouter} from "react-router-dom";
import AlertHistoryReport from "./Reports/AlertHistoryReport";
import RawDataReport from "./Reports/RawDataReport";
import Reports from "./Reports/Reports";
import Documents from "./Documents/Documents";
import Dashboard from "./Dashboard/Dashboard";
import {connect} from "react-redux";
import * as actions from '../../store/actions';
import AssetRouterConfig from "./Asset/AssetRouterConfig";
import CustomerAdminRoutingConfing from "./Admin/CustomerAdminRoutingConfig";
import RoutingConfig from "../../RoutingConfig";
import SiteStatusReport from "./Reports/SiteStatusReport";

const CompanyRoutingConfing = (props) => {

  const getParams = pathname => {
    const matchAsset = matchPath(pathname, {
      path: "/company/:companyId",
      exact: false
    });

    return (matchAsset && matchAsset.params) || {};
  }

  const getCompanyId = () => {
    let {companyId} = getParams(props.location.pathname);
    console.log(props.location.pathname);
    return parseInt(companyId);
  }

  let companyIdFromUrl = getCompanyId();
  props.setPageCompanyId(companyIdFromUrl);

  return (
    <Router forceRefresh={true}>
      <Switch>
        <Route path={"/company/:companyId/reports/alertHistory"}><AlertHistoryReport  {...props}/></Route>
        <Route path={"/company/:companyId/reports/rawData"}><RawDataReport {...props}/></Route>
        <Route path={"/company/:companyId/reports/siteStatusReport"}><SiteStatusReport {...props}/></Route>
        <Route path={"/company/:companyId/reports/"}><Reports {...props}/></Route>
        <Route path={"/company/:companyId/documents/"}><Documents {...props}/></Route>
        <Route path={"/company/:companyId/asset"}><AssetRouterConfig {...props}/></Route>
        <Route path={"/company/:companyId/main"}><Dashboard {...props}/></Route>
        <Route path={"/company/:companyId/admin"}><CustomerAdminRoutingConfing {...props}/></Route>
        <Route path={"/company/:companyId"}><Dashboard {...props}/></Route>
        <Route path={"/"}>
          <RoutingConfig/>
        </Route>
        <RoutingConfig/>
      </Switch>
    </Router>
  );

}

const mapDispatchToProps = (dispatch) => {

  return {

    setPageCompanyId: (companyId) => dispatch(actions.setPageCompanyId(companyId))

  }

}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    companyId: state.authentication.companyId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyRoutingConfing));
