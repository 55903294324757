const initialState = {
  filter: null
}

const reducer = (state = initialState, action) => {

  switch (action.type) {

    default:
      return state;
  }

}

export default reducer;