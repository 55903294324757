import React, {useState} from 'react';
import {Alert, Button, Card, Form, FormGroup, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignInAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import LoginTemplate from "./LoginTemplate";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import RevealablePasswordField from "../ChangePassword/RevealablePasswordField";

const Login = (props) => {

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      props.clearError();
      props.login(userName, password);
    }
  }

  let { from } = props.location.state || { from : { pathname: '/'} }

  if (props.authenticated) {
    return (<Redirect to={from} />);
  }

  let error = null;
  let forced = null;

  if (props.isError && !props.errorShown) {
    error = (<Alert variant="danger" dismissible={true} className="alert-dismissible"
                    onClose={props.clearError}>{props.error === "Unauthorised" ? "Email or password is not valid" : props.error}</Alert>);
  }

  if (props.forcedPasswordChanged) {
    forced = (<Alert variant="success" dismissible={true} className="alert-dismissible"
                    onClose={() => {props.setForcedPasswordChanged(false);}}>Password successfully changed, login with your new password</Alert>);
  }


  return (

    <LoginTemplate>

    <form>

      <Card.Title>
        <h4 className="lead">Login</h4>
      </Card.Title>
      {error}
      {forced}

      <FormGroup>
        <InputGroup className="my-4">
          <InputGroup.Text size="sm" id="userName">
            <FontAwesomeIcon icon={faUser} className="text-primary"/>
          </InputGroup.Text>

          <Form.Control size="sm" className="form-control"
                        placeholder="Email"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}/>

        </InputGroup>

        <RevealablePasswordField
          onClickFunction={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
          placeholderText="Password"
          onKeyDown={handleKeyDown}
        />

      </FormGroup>

      <div>
        <Button size="sm" className="mb-2" variant="link" onClick={() => {
          props.history.push("/forgotten-password");
        }}>Forgotten password</Button>
      </div>

      <Button size="sm" variant="outline-primary" onClick={
        () => {
          props.clearError();
          props.login(userName, password);
        }
      }>
        <FontAwesomeIcon icon={faSignInAlt} className="me-2"/>
        Sign In
      </Button>


    </form>
    </LoginTemplate>

  );

}

const mapStateToProps = (state) => {
  return {
    errorShown: state.errors.errorShown,
    error: state.errors.errorMessage,
    isError: state.errors.isError,
    loading: state.page.loading,
    authenticated: state.authentication.authenticated,
    forcedPasswordChanged: state.page.forcedPasswordChanged
  }
}

const mapDispatchToProps = (dispatch) => {

  return {

    login: (user, password) => dispatch(actions.login(user, password)),
    clearError: () => dispatch(actions.clearError()),
    setForcedPasswordChanged: (forced) => dispatch(actions.setForcedPasswordChanged(forced))

  }

}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));