import {Container, Row} from "react-bootstrap";
import {
  faBacteria,
  faBacterium,
  faBusinessTime,
  faFireAlt,
  faIcicles,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import AlertTempComponent from "./AlertTempComponent";
import AlertComponent from "./AlertComponent";

import ServiceComponent from "./ServiceComponent";

const AlertDashboard = (props) => {

  if (!props.settings) {
    return null;
  }

  return (
    <Container className="mt-auto">

      <Row className="text-dark text-center mt-auto">
        <AlertTempComponent icon={faIcicles} name="Freeze" className="cold-temp-div"
                            values={props.settings.alertLimitSettings.freeze}
                            editValues={props.editAlertLimits.freeze}
                            keyName="freeze"
                            editMode={props.editMode}
                            changeValue={props.changeValue}
                            type="single"
        />

        <AlertTempComponent icon={faBacteria} name="Bacterial" className="bacterial-temp-div"
                            values={props.settings.alertLimitSettings.bacterial}
                            editValues={props.editAlertLimits.bacterial}
                            keyName="bacterial"
                            editMode={props.editMode}
                            changeValue={props.changeValue}
                            type="multiple"
        />

        <AlertTempComponent icon={faFireAlt} name="Scald" className="hot-temp-div"
                            values={props.settings.alertLimitSettings.scald}
                            editValues={props.editAlertLimits.scald}
                            keyName="scald"
                            editMode={props.editMode}
                            changeValue={props.changeValue}
                            type="single"
                            sensorSelection={true}
                            updateAlertLimitSensorValue={props.updateAlertLimitSensorValue}
        />
      </Row>

      <Row className="text-dark text-center">

        <AlertTempComponent icon={faBacterium} name="Target Temp Exceeded" className="legionella-cold-div"
                            values={props.settings.alertLimitSettings.targetTempExceeded}
                            editValues={props.editAlertLimits.targetTempExceeded}
                            keyName="targetTempExceeded"
                            editMode={props.editMode}
                            changeValue={props.changeValue}
                            type="single"
                            sensorSelection={true}
                            updateAlertLimitSensorValue={props.updateAlertLimitSensorValue}
        />


        <AlertTempComponent icon={faBacterium} name="Target Temp Not Achieved" className="legionella-hot-div"
                            values={props.settings.alertLimitSettings.targetTempNotAchieved}
                            editValues={props.editAlertLimits.targetTempNotAchieved}
                            keyName="targetTempNotAchieved"
                            editMode={props.editMode}
                            changeValue={props.changeValue}
                            type="single"
                            sensorSelection={true}
                            updateAlertLimitSensorValue={props.updateAlertLimitSensorValue}
        />

      </Row>

      <Row className="text-dark text-center">
        <ServiceComponent icon={faBusinessTime} name="Service" className="asset-service-div"
                          values={props.settings.alertLimitSettings.service}
                          editValues={props.editAlertLimits.service}
                          keyName="service"
                          editMode={props.editMode}
                          changeValue={props.changeValue}
                          type="single"/>

      <AlertComponent icon={faUpload} name="No Data" className="asset-service-div"
                          values={props.settings.alertLimitSettings.noDeviceData}
                          editValues={props.editAlertLimits.noDeviceData}
                          keyName="noDeviceData"
                          editMode={props.editMode}
                          changeValue={props.changeValue}
                          type="single"
      />
      </Row>

      <Row className="d-flex flex-column mt-auto">
        {props.children}
      </Row>

    </Container>

  );

}

export default AlertDashboard;