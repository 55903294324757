import React, {useEffect, useState} from "react";
import {Accordion, AccordionCollapse} from "react-bootstrap";
import ExpandableItem from "./ExpandableItem";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import AssetItem from "./AssetItem";

const SubGroup3Accordion = (props) => {

  const [active, setActive] = useState(
    props.subGroup3.id === props.selectedSub3Id ? "sub_" + props.selectedSub3Id : null
  );

  const [onlyOnce, setOnlyOnce] = useState(false);

  useEffect(() => {

    if (!onlyOnce) {
      if (props.subGroup3 && props.subGroup3.id === props.selectedSub3Id && active !== "sub_" + props.selectedSub3Id) {
        setActive("sub_" + props.selectedSub3Id);
        setOnlyOnce(true);

      }

    }


  }, [active, props.subGroup3, props.subGroup3.id, props.selectedSub3Id, onlyOnce]);


  if (!onlyOnce && active !== null) {
    setOnlyOnce(true);
  }

  return (
    <Accordion className="text-white"
               key={"sub_" + props.subGroup3.id}
               activeKey={active}
               onSelect={e => setActive(e)}>

      <ExpandableItem name={props.subGroup3.name} newAlerts={props.subGroup3.newAlerts}
                      activeAlerts={props.subGroup3.activeAlerts}
                      eventKey={"sub_" + props.subGroup3.id} indent={3} search={props.search}
                      linkClass={props.selectedSub3Id === props.subGroup3.id ? "asset-selector-text text-white active-asset"
                        : "selector-text text-white"}
      />

      <AccordionCollapse eventKey={"sub_" + props.subGroup3.id}>

        <div>

          {
            props.subGroup3.assets.map(
              (item, index) => {
                return (<AssetItem name={item.name} id={item.id}
                                   isSentinelPoint={item.sentinelPoint}
                                   isSelected={item.id === props.selectedAssetId}
                                   url={"/company/" + props.companyId + "/asset/" + item.id}
                                   newAlertsCount={item.newAlerts}
                                   activeAlertsCount={item.activeAlerts}
                                   alertFilter={props.alertFilter}
                                   noSearch={props.noSearch}
                                   indentationLevel={4}
                                   iconType={item.iconType}
                />);
                  // return (
                  // <Row key={item.id} style={{height: "40px"}}>
                  //   <Col xs="8">
                  //
                  //     <AssetIcon iconType={item.iconType} className={"asset-selector-text" + (props.selectedAssetId === item.id ? " active-asset" : "") + " " + classes.IndentLevel3}/>
                  //
                  //     <Button variant="link"
                  //             className={"asset-selector-text text-white" + (props.selectedAssetId === item.id ? " active-asset" : "") + " " + classes.Text}
                  //             onClick={() => {
                  //               props.history.push("/company/" + props.companyId + "/asset/" + item.id + "/" + "?name=" + item.name + "&assetIdentifier=" + item.assetIdentifier +
                  //                 "&buildingId=" + item.buildingId +
                  //                 "&subId=" + item.subId);
                  //             }}>
                  //       {item.name}
                  //     </Button>
                  //   </Col>
                  //   <Col xs="1" sm="1" className="align-items-center">
                  //     {item.sentinelPoint ? <FontAwesomeIcon icon={faStar}/> : null}
                  //   </Col>
                  //
                  //   <Col xs="1" sm="1" className="align-items-center">
                  //     {item.newAlerts > 0 && (props.noSearch || props.alertFilter === "new") ?
                  //       <Badge className="new-alerts-button-side">{item.newAlerts}</Badge> : null}
                  //   </Col>
                  //   <Col xs="1" sm="1" className="align-items-center">
                  //     {item.activeAlerts > 0 && (props.noSearch || props.alertFilter === "active") ?
                  //       <Badge className="active-alerts-button-side">{item.activeAlerts}</Badge> : null}
                  //   </Col>
                  //
                  // </Row>
                  //);
              }
            )
          }

        </div>
      </AccordionCollapse>

    </Accordion>
  );

}

const mapStateToProps = (state) => {

  return {
    selectedAssetId: state.page.selectedAssetId,
    alertFilter: state.filter.alertFilter,
    assetData: state.assets.assetData,
    selectedSub3Id: state.page.selectedSub3Id,
    companyId: state.page.companyId
  }
}

export default connect(mapStateToProps)(withRouter(SubGroup3Accordion));