import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Container, Form, FormGroup, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes, faUser} from "@fortawesome/free-solid-svg-icons";
import LoginTemplate from "../Login/LoginTemplate";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const ForgottenPassword = (props) => {

  const [recaptcha, setRecaptcha] = useState(null);
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(false);

  useEffect(() => {
    props.setPasswordResetRequested(null);
    props.clearError();
  }, []);

  useEffect(() => {

    setValid(email.length > 0 && recaptcha !== null);

  }, [email, recaptcha]);

  if (props.authenticated) {
    return (<Redirect to={
      {
        pathname: "/main",
      }}/>);
  }

  let alert = null;

  if (props.isError && !props.errorShown) {
    alert = (<Alert variant="danger" dismissible={true} className="alert-dismissible"
                    onClose={props.clearError}>{props.error === "Unauthorised" ? "Username or password is not valid" : props.error}</Alert>);
  }

  if (props.passwordResetRequested) {
    alert = (<Alert variant="success">If a valid email has been entered, a password reset has been sent.  Please check your email.</Alert>);
  }

  const onChange = (value) => {
    setRecaptcha(value);
  }

  let button = null;

  if (!props.passwordResetRequested) {
    button = (<Button variant="outline-primary mt-3" disabled={!valid} onClick={
      () => {
        props.clearError();
        props.requestPasswordReset(email, recaptcha);
      }
    }>
      <FontAwesomeIcon icon={faCheck} className="me-2"/>
      Proceed
    </Button>);
  }

  let form = null;

  if (props.passwordResetRequested !== true) {
    form = ([
        (<Row>
          <Col>
            <FormGroup>
              <InputGroup className="my-4">
                <InputGroup.Text id="userName">
                  <FontAwesomeIcon icon={faUser} className="text-primary"/>
                </InputGroup.Text>

                <Form.Control className="form-control"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}/>
              </InputGroup>

            </FormGroup>

          </Col>
        </Row>),
        (<Row>
          <Col className="d-flex justify-content-center">
            <ReCAPTCHA
              sitekey="6Ld4GZsaAAAAAIoplBlWJoBzXMhuhIFG_XHcvJkr"
              onChange={(v) => onChange(v)}
            />
          </Col>
        </Row>)]
    )
  }


  return (

    <LoginTemplate>

      <form>

        <Card.Title>
          <h3>Forgotten Password</h3>
        </Card.Title>
        {alert}
        <Container>
          <Row>

            {props.passwordResetRequested ? null :
            <Col><p>Enter the email address of your account to reset the password. You will receive an email
              providing you instructions on how to set a new password.</p></Col>}
          </Row>
          {form}
          <Row>
            <Col>
              {button}
              <Button className="ms-2" style={{width: 120}} variant="outline-primary mt-3" onClick={
                () => {
                  props.history.push("/login");
                }
              }>
                <FontAwesomeIcon icon={faTimes} className="me-2"/>
                Exit</Button>

            </Col>
          </Row>


        </Container>
      </form>
    </LoginTemplate>

  );

}

const mapStateToProps = (state) => {
  return {
    errorShown: state.errors.errorShown,
    error: state.errors.errorMessage,
    isError: state.errors.isError,
    loading: state.page.loading,
    authenticated: state.authentication.authenticated,
    passwordResetRequested: state.authentication.passwordResetRequested

  }
}

const mapDispatchToProps = (dispatch) => {

  return {

    login: (user, password) => dispatch(actions.login(user, password)),
    clearError: () => dispatch(actions.clearError()),
    requestPasswordReset: (email, recaptcha) => dispatch(actions.requestPasswordReset(email, recaptcha)),
    setPasswordResetRequested: (passwordResetRequested) => dispatch(actions.setPasswordResetRequested(passwordResetRequested))

  }

}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgottenPassword));