import * as actionTypes from '../actions/actionTypes';
import {ErrorAction} from '../../app/models/errorAction';

interface State {
    isError: boolean;
    errorShown: boolean;
    errorCode: number | null;
    errorMessage: string | null;
    operation: string | null;
}

const initialState : State = {
    isError: false,
    errorShown: false,
    errorCode: null,
    errorMessage: null,
    operation: null
}

const reducer = (state = initialState, action : ErrorAction) => {

    switch (action.type) {

        case actionTypes.SET_ERROR:
            return {...state,
                errorShown: false,
                isError: true,
                errorCode: action.errorCode,
                errorMessage: action.errorMessage,
                operation: action.operation
            }
        case actionTypes.CLEAR_ERROR:
            return {...state,
                isError: false,
                errorShown: true
            }
        default:
            return state;
    }

}

export default reducer;