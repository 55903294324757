import * as actionTypes from './actionTypes';
import * as actions from './index';
import agent from '../../app/api/agent';
import {CompanySelectedAssets} from "../../app/models/companySelectAssets";

export const loadCompanyData = () => {

  return (dispatch: any) => {
    dispatch(actions.incrementPageLoading());

    agent.Company.getAll().then(response => {

      dispatch(storeCompanyData(response));
      dispatch(actions.decrementPageLoading());

    }).catch(error => {
      dispatch(loadCompanyDataFailed());
    });

  }

}

export const  loadSelectedCompanyDataStart = () => {
  return {
    type: actionTypes.LOAD_SELECTED_COMPANY_START
  }
}

export const storeSelectedCompanyData = (data: CompanySelectedAssets) => {
  return {
    type: actionTypes.STORE_SELECTED_COMPANY_DATA,
    data: data
  }
}

export const loadSelectedCompanyData = (id : number) => {

  return (dispatch: any) => {

    dispatch(actions.incrementPageLoading());

    agent.Company.getSelected(id).then(data => {
      dispatch(storeSelectedCompanyData(data));
      dispatch(actions.storeData(data));
      dispatch(actions.decrementPageLoading());
    });

    // axios.get("/companyData/" + id + ".json").then(response => {
    //
    //   dispatch(actions.incrementPageLoading());
    //   dispatch(actions.decrementPageLoading());
    //
    //   axios.get('/assets.json').then(responseAssets => {
    //
    //     //console.log(response.data);
    //
    //     response.data.buildings.forEach((building, buildingIndex) => {
    //
    //       //console.log(building);
    //
    //       building.subFloors.forEach((sub, subIndex) => {
    //
    //         sub.assets.forEach((asset, assetIndex) => {
    //           response.data.buildings[buildingIndex].subFloors[subIndex].assets[assetIndex] = {...responseAssets.data[asset.id]};
    //         });
    //
    //       });
    //     });
    //
    //     dispatch(storeSelectedCompanyData(response.data));
    //     dispatch(actions.storeData(response.data));
    //     dispatch(actions.decrementPageLoading());
    //
    //
    //   }).catch(error => {
    //     //console.log(error);
    //       dispatch(loadCompanyDataFailed());
    //   });
    //
    // }).catch(error => {
    //   //console.log(error);
    //   dispatch(loadCompanyDataFailed());
    // });

  }
}
export const  storeCompanyData = (companyData : CompanySelectedAssets) => {
  return {
    type: actionTypes.STORE_COMPANY_DATA,
    companyData: companyData
  }
}

export const loadCompanyDataFailed = () => {
  return {
    type: actionTypes.LOAD_COMPANY_DATA_FAILED
  }
}

export const setSelectedCompanyData = (companyId : number) => {
  return {
    type: actionTypes.SET_SELECTED_COMPANY_DATA,
    companyId: companyId
  }
}