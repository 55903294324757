import * as actionTypes from './actionTypes';
import * as actions from './index';
import agent from "../../app/api/agent";

export const storeAlertList = (assetId, alerts) => {

  return {
    type: actionTypes.STORE_ALERT_LIST,
    alertList: alerts,
    assetId: assetId
  }

}

export const storeAlert = (alertId, assetAlert) => {

  return {
    type: actionTypes.STORE_ALERT,
    assetAlert: assetAlert,
    alertId: alertId
  }

}

export const storeAlertHistory = (alertId, alertHistory) => {

  return {
    type: actionTypes.STORE_ALERT_HISTORY,
    alertHistory: alertHistory,
    alertId: alertId
  }

}

export const fetchAlertList = (assetId) => {

  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Alert.list(assetId).then(response => {
      dispatch(storeAlertList(assetId, response));
      dispatch(actions.decrementPageLoading());
    });

  }

}

export const fetchAlert = (alertId) => {

  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Alert.get(alertId).then(response => {
      dispatch(storeAlert(alertId, response));
      dispatch(actions.decrementPageLoading());
    });

  }

}

export const fetchAlertHistory = (alertId) => {

  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Alert.getHistory(alertId).then(response => {
      dispatch(storeAlertHistory(alertId, response));
      dispatch(actions.decrementPageLoading());
    });

  }

}

export const saveAlert = (assetId, alertId, updateData) => {

  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Alert.update(alertId, updateData).then(response => {
      dispatch(fetchAlert(alertId));
      dispatch(fetchAlertHistory(alertId));
      dispatch(actions.decrementPageLoading());
    });

  }

}

export const saveEngineerReading = (alertId, readings) => {

  return dispatch => {

    let readingsFloat = [];

    readings.forEach(item => {
      readingsFloat.push(parseFloat(item));
    });


    let readingsObj = {
      "datetime": new Date(),
      "readings": [...readingsFloat]
    };

    dispatch(actions.incrementPageLoading());

    agent.Alert.addReading(alertId, readingsObj)
    .then(response => {
      dispatch(mergeEngineerReadings(readingsObj));
      dispatch(actions.decrementPageLoading());
    });

  }

}

export const mergeEngineerReadings = (readings) => {

  return {
    type: actionTypes.MERGE_ENGINEER_READINGS,
    readings: readings
  }

}