import {connect} from 'react-redux';
import {withRouter} from "react-router";
import SubGroupAccordion from "./SubGroupAccordion";

const SubGroupAccordions = (props) => {

  let noSearch = props.alertFilter === null;

  if (!props.data) {
    return null;
  }

  return (<div>
    {props.data.map((subGroup, index) => {

      return (
        <SubGroupAccordion subGroup={subGroup}
                            selectedSub1Id={props.selectedSub1Id}
                            noSearch={noSearch}
                            selectedAssetId={props.selectedAssetId}
                            alertFilter={props.alertFilter}
                            key={index}
        />
      );
    })

    }
  </div>)
}

const mapStateToProps = (state) => {

  return {
    selectedAssetId: state.page.selectedAssetId,
    alertFilter: state.filter.alertFilter,
    assetData: state.assets.assetData,
    selectedSub1Id: state.page.selectedSub1Id,
    companyId: state.page.companyId
  }
}

export default connect(mapStateToProps)(withRouter(SubGroupAccordions));