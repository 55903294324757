import {Accordion, AccordionCollapse, Button, Row, Col, Badge} from "react-bootstrap";
import React from 'react';

const assetsAccordian = (props) => {
  return (
    <div>
          {props.data.forEach(item => {

        item.map((item, index) => {
          return (
            <Accordion className="text-white" defaultActiveKey={item.id}>

              <Accordion.Toggle eventKey={item.id} as={Button} variant="link" className="text-white">
                {item.name}
              </Accordion.Toggle>

              <AccordionCollapse eventKey={item.id}>
                <div>

                  {
                    item.data.forEach(
                      (item) => {
                        return (
                          <Row>
                            <Col xs="1" sm="4">
                              {item.name}
                            </Col>
                            <Col xs="4" sm="4">
                              {item.assetIdentifier}
                            </Col>
                            <Col  xs="1"  sm="1">
                              {item.newAlerts > 0 ? <Badge className="new-alerts-button-side" style={{color: "#000000", backgroundColor: "yellow"}}>45</Badge> : null}
                            </Col>
                            <Col xs="1"  sm="1">
                              {item.activeAlerts > 0 ? <Badge className="active-alerts-button-side" style={{color: "#000000", backgroundColor: "magenta"}}>45</Badge> : null}
                            </Col>
                          </Row>
                        );
                      }
                    )
                  }

                </div>
              </AccordionCollapse>

          </Accordion>);
        }


      )
      })}
    </div>
  )

}

export default assetsAccordian;