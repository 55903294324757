import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Line, ReferenceLine} from 'recharts';
import {Button, Col, Row, Container, Spinner} from "react-bootstrap";
import ReferenceLineForm from "./ReferenceLineForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import RechartGraphComponent from "./RechartGraphComponent";
import * as actions from '../../../../store/actions';
import CustomizeForm from "./CustomizeForm";

const createGraphLines = (props) => {
  let lines = [];

  for (let i = 0; i < props.assetData.settings.sensorSettings.length; i++) {

    if (props.assetData.settings.sensorSettings[i].enabled === true && props.showPlot[i]) {
      lines.push(<Line
        dot={false}
        type="monotone"
        dataKey={props.plotType[i] + "_" + (i + 1)}
        stroke={props.plotColour[i]}
        name={props.plotNames[i]}/>);
    }

  }

  return lines;
}

const createReferenceLines = (props) => {
  return props.referenceLines.map((refLine) => {
      return (<ReferenceLine y={refLine.y} label={refLine.label} stroke={refLine.stroke} />);
    }
  )
}

const AssetGraphComponent = (props) => {

  const updateGraph = (startDate, endDate) => {
    props.updateGraph(startDate, endDate);
    setHideButtons(false);
    setShowCustomizeForm(false);
  }

  const [hideButtons, setHideButtons] = useState(false);
  const [showCustomizeForm, setShowCustomizeForm] = useState(false);

  const [showRefLineForm, setShowRefLineForm] = useState(false);
  const lines = createGraphLines(props);
  const referenceLines = createReferenceLines(props);

  let refLineForm = null;
  let addRefLineButton = null;
  let customizeButton = null;


  if (!hideButtons) {
    addRefLineButton = (<Button size="sm" className="mx-2" onClick={() => {
      setShowRefLineForm(true);
      setHideButtons(true);
    }}>Add Ref Line</Button>);

    customizeButton = (<Button size="sm" className="mx-2" onClick={() => {
      setHideButtons(true);
      setShowCustomizeForm(true);
    }}>Customize</Button>);

  }

  if (showRefLineForm) {
    refLineForm = (<ReferenceLineForm setShowRefLineForm={() => {
      setShowRefLineForm(false);
      setHideButtons(false);
    }}/>);
  }

  if (showCustomizeForm) {
    refLineForm = (<CustomizeForm
      minDate={props.firstMeasurementDate}
      maxDate={new Date().toISOString()}
      updateGraph={updateGraph}
      reset={() => {
        setShowCustomizeForm(false);
        setHideButtons(false);
        props.resetGraph();
      }}
      cancel={() => {
        setShowCustomizeForm(false);
        setHideButtons(false);
    }}/>);
  }

  return (

    <div style={{backgroundColor: "#ffffff", color: "#000000", border: "1px solid #000"}}
         className=" w-100 my-3">

      <div className="m-0 p-0 justify-content-start">
        <p className="lead text-center m-0 p-0">{props.title}</p>
        <Container fluid className="p-0">

          {props.loading ?
          <Row>
            <Col className="d-flex justify-content-center flex-fill" xs={12}>
              <Spinner animation="border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          </Row> : null }

          {!props.loading ? <Row>

            <Col className="d-flex justify-content-start" xs={12}>

              <RechartGraphComponent data={props.data}
                                     lines={lines}
                                     referenceLines={referenceLines}
                                     graphStartDate={props.graphStartDate}
                                     graphEndDate={props.graphEndDate}
                                     custom={props.custom}
                                     popout={props.popout}

              />

            </Col>
          </Row> : null}
          <Row>
            <Col className="d-flex justify-content-center chart-ref-line-div">
              {props.referenceLines.map((refLine, index) => {
                return (
                  <p className="me-2" style={{color: refLine.stroke}} onClick={() => {
                    props.removeReferenceLine(index);
                  }}>
                    <FontAwesomeIcon icon={faTimes}/> <span>Ref Line {refLine.label === "" ? "No label" : refLine.label}:{refLine.y}</span>
                  </p>
                );
              })}

            </Col>

          </Row>
        </Container>
      </div>
      <Container fluid>
        <Row>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            {addRefLineButton}
            {customizeButton}
          </Col>
        </Row>
        <Row>
          <Col className="my-2">
            {refLineForm}
          </Col>
        </Row>
      </Container>
    </div>
  );

}

const mapDispatchToProps = dispatch => {
  return {
    removeReferenceLine: (index) => dispatch(actions.removeReferenceLine(index))
  }
}

const mapStateToProps = state => {
  return {
    data: state.plots.plotData,
    referenceLines: state.plots.referenceLines,
    assetData: state.assets.assetData,
    firstMeasurementDate: state.plots.firstMeasurementDate,
    graphStartDate: state.plots.graphStartDate,
    graphEndDate: state.plots.graphEndDate,
    loading: state.plots.loading
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetGraphComponent);