import * as actionTypes from '../actions/actionTypes';

const initialState = {
  data: null,
  filteredData: null,
  searchFilter: '',
  alertFilter: null,
  buildingId: null,
  sub1Id: null,
  sub2Id: null,
  sub3Id: null
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.CLEAR_FILTERS:
      return {
        ...state,
        searchFilter: '',
        alertFilter: null,
        buildingId: null,
        sub1Id: null,
        sub2Id: null,
        sub3Id: null
      }

    case actionTypes.SET_BUILDING_FILTER:
      return {
        ...state,
        buildingId: action.buildingId
      }
    case actionTypes.SET_SUB_1_FILTER:
      return {
        ...state,
        sub1Id: action.sub1Id
      }
    case actionTypes.SET_SUB_2_FILTER:
      return {
        ...state,
        sub2Id: action.sub2Id
      }
    case actionTypes.SET_SUB_3_FILTER:
      return {
        ...state,
        sub3Id: action.sub3Id
      }
    case actionTypes.STORE_FILTERED_DATA:

      return {
        ...state,
        filteredData: {...action.filteredData}
      }
    case actionTypes.STORE_DATA:
      return {
        ...state,
        data: {...action.data}
      }

    case actionTypes.SET_ALERT_FILTER:
      return {
        ...state,
        alertFilter: action.alertFilter
      }
    case actionTypes.SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.searchFilter
      }
    case actionTypes.SET_BUILDING_FILTER:
      return {
        ...state,
        buildingId: action.buildingId
      }
    default:
      return state;
  }

}

export default reducer;