import * as actionTypes from "./actionTypes";
import {ErrorAction} from '../../app/models/errorAction';


export const setError = (errorCode: number, errorMessage: string, operation: string) : ErrorAction => {
    return {
        type: actionTypes.SET_ERROR,
        errorCode: errorCode,
        errorMessage: errorMessage,
        operation: operation
    }
}

export const clearError = () => {
    return {
        type: actionTypes.CLEAR_ERROR
    }
}