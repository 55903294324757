import * as actionTypes from '../actions/actionTypes';
import {IAlertLimitSettings, IAlertSettings, ISensorSetting, ISettings} from "../../app/models/Settings";
import _ from "lodash";

const initialState : IState = {
  settings: null,
  editSensor: null,
  editAlert: null,
  editAlertLimits: null,
  assetId: null
}

interface IState {
  settings: ISettings | null,
  editSensor: Array<ISensorSetting> | null,
  editAlert: Array<IAlertSettings> | null,
  editAlertLimits: IAlertLimitSettings | null,
  assetId: number | null
}

interface IAction {

  type: string,
  assetId?: number,
  settings?: ISettings,
  editAlert?: Array<IAlertSettings>,
  editSensor?: Array<ISensorSetting>,
  editAlertLimits?: IAlertLimitSettings
}


const reducer = (state  = initialState, action : IAction) => {

  switch (action.type) {
    case actionTypes.STORE_ASSET_SETTINGS:

      return {
        ...state,
        assetId: action.assetId,
        settings: JSON.parse(JSON.stringify(action.settings)),
        editAlert: [...action.settings!.alertSettings],
        editSensor: _.values(_.cloneDeep(action.settings?.sensorSettings)),
        editAlertLimits:  JSON.parse(JSON.stringify(action.settings?.alertLimitSettings))
      }
    case actionTypes.RESTORE_SENSOR_SETTINGS:
      return {
        ...state,
        editSensor: JSON.parse(JSON.stringify(state.settings?.sensorSettings))
      }
    case actionTypes.RESTORE_ALERT_SETTINGS:
      return {
        ...state,
        editAlert: [...state.settings!.alertSettings]
      }
    case actionTypes.RESTORE_ALERT_LIMITS_SETTINGS:
      return {
        ...state,
        editAlertLimits: JSON.parse(JSON.stringify(state.settings?.alertLimitSettings))
      }

    case actionTypes.UPDATE_SENSOR_SETTINGS:
      return {
        ...state,
        editSensor: action.editSensor
      }
    case actionTypes.UPDATE_ALERT_SETTINGS:
      return {
        ...state,
        editAlert: [...action.editAlert!]
      }
    case actionTypes.UPDATE_ALERT_LIMITS_SETTINGS:
      return {
        ...state,
        editAlertLimits: action.editAlertLimits
      }
    default:
      return state;
  }

}

export default reducer;