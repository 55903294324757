import React from 'react';
import {Button, Col, Row} from "react-bootstrap";

const CounterCardRow = (props) => {

  return (
    <Row>
      <Col xs="1" className="align-self-center">
        {props.icon}
      </Col>
      <Col xs="6" className="align-self-center">
        <Button variant="link" className="text-white" onClick={props.onClick}>{props.title}</Button>
      </Col>
      <Col xs="1">
        <Button className={"btn-xs " + props.badgeClass}>{props.counter}</Button>
      </Col>
    </Row>
  );

}

export default CounterCardRow;