import React from 'react';
import {HashRouter as Router, matchPath, Route, Switch, withRouter} from "react-router-dom";
import AssetRawDataReport from "./Reports/AssetRawDataReport";
import AssetReports from "./Reports/AssetReports";
import AssetSettings from "./Settings/AssetSettings";
import AssetAlerts from "./AssetAlerts";
import AssetGraph from "./Graph/AssetGraph";
import Asset from "./Asset";
import {connect} from "react-redux";
import * as actions from '../../../store/actions';
import AssetAlertHistoryReport from "./Reports/AssetAlertHistoryReport";
import Documents from "./Files/Documents";
import CompanyRoutingConfig from "../CompanyRoutingConfig";

const AssetRoutingConfing = (props) => {

  const getParams = pathname => {
    const matchAsset = matchPath(pathname, {
      path: "/company/:companyId/asset/:assetId",
      exact: false
    });

    return (matchAsset && matchAsset.params) || {};
  }

  const getAssetId = () => {
    let {assetId} = getParams(props.location.pathname);
    return parseInt(assetId);
  }

  let assetIdFromUrl = getAssetId();
  props.setPageAssetId(assetIdFromUrl);

  return (
    <Router forceRefresh={true}>
      <Switch>
        <Route path={"/company/:companyId/asset/:assetId/documents"}><Documents  {...props}/></Route>
        <Route path={"/company/:companyId/asset/:assetId/reports/alertHistory"}><AssetAlertHistoryReport  {...props}/></Route>
        <Route path={"/company/:companyId/asset/:assetId/reports/rawData"}><AssetRawDataReport  {...props}/></Route>
        <Route path={"/company/:companyId/asset/:assetId/reports"}><AssetReports {...props}/></Route>
        <Route path={"/company/:companyId/asset/:assetId/settings"}><AssetSettings {...props}/></Route>
        <Route path={"/company/:companyId/asset/:assetId/alert/:alertId"}><AssetAlerts {...props}/></Route>
        <Route path={"/company/:companyId/asset/:assetId/graphics"}><AssetGraph {...props}/></Route>
        <Route path={"/company/:companyId/asset/:assetId"}><Asset {...props}/></Route>
        {/*<Route path={"/"}><Redirect to={"/company/" + props.companyId}/></Route>*/}

        <Route path={"/"}>
          <CompanyRoutingConfig/>
        </Route>


      </Switch>
    </Router>
  );

}

const mapDispatchToProps = (dispatch) => {
  return {

    setPageAssetId: (assetId) => dispatch(actions.setPageAssetId(assetId))

  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    companyId: state.page.companyId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssetRoutingConfing));
