import * as actionTypes from '../actions/actionTypes';

const initialState = {
  assetData: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ASSET_DATA:
      return {
        ...state,
        assetData: {...action.assetData}
      }
    default:
      return state;
  }

}

export default reducer;