import React from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import {connect} from 'react-redux';
import classes from './AlertComponent.module.css';

const AlertTempComponent = (props) => {

  const alertObject = props.settings.alertSettings.filter(as => as.keyName === props.keyName)[0];

  if (props.editMode && alertObject.enabled === true) {

    return (
      <Col className={props.className + " m-1"} style={{border: "1px solid black"}}>
        <h3 className={classes.Icon}><FontAwesomeIcon icon={props.icon} className="me-2"/>{props.name}</h3>
        <Form.Group>

          <Row>
            <Form.Label className={classes.LabelText} column sm={3}>Duration</Form.Label>
            <Col>
              <Form.Control className="form-control font-weight-bold"
                            placeholder="Duration"
                            type="number" value={props.editValues.duration}
                            onChange={(e) => props.changeValue(e.target.value, props.keyName, "duration")}
              />

            </Col>
            <Col>
              <Form.Control className="form-control font-weight-bold"
                            placeholder="Duration"
                            as="select" value={props.editValues.durationType}
                            onChange={(e) => props.changeValue(e.target.value, props.keyName, "durationUnits")}
              >

                <option value="minutes">Minutes</option>
                <option value="hours">Hours</option>
                <option value="days">Days</option>

              </Form.Control>
            </Col>
          </Row>
        </Form.Group>
      </Col>
    );

  } else {

    let className = alertObject.enabled === true ? props.className : "alert-disabled-div";

    return (
      <Col className={className + " m-1"} style={{border: "1px solid black"}}>
        <h3 className={classes.Icon}><FontAwesomeIcon icon={props.icon} className="me-2"/>{props.name}</h3>
        <p className={"lead " + classes.LabelText} style={{fontWeight: "bold"}}>
          <FontAwesomeIcon icon={faClock} className="me-2"/>{props.values.duration}
          &nbsp;{props.values.durationType}</p>
      </Col>

    );
  }

}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings
  }
}

export default connect(mapStateToProps)(AlertTempComponent);