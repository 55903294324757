import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Container, Form, FormGroup, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faKey, faUser} from "@fortawesome/free-solid-svg-icons";
import LoginTemplate from "../Login/LoginTemplate";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {matchPath, Redirect, withRouter} from "react-router-dom";
import PasswordRequirements from "../ChangePassword/PasswordRequirements";
import ReCAPTCHA from "react-google-recaptcha";

const PasswordReset = (props) => {

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [correctLength, setCorrectLength] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsUppercase, setContainsUppercase] = useState(false);
  const [containsLowercase, setContainsLowercase] = useState(false);
  const [containsSymbol, setContainsSymbol] = useState(false);
  const [untouched, setUntouched] = useState(true);
  const [valid, setValid] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);

  const getParams = pathname => {
    const matchAsset = matchPath(pathname, {
      path: '/reset-password/:resetCode'
    });

    return (matchAsset && matchAsset.params) || {};

  }

  const checkPassword = (password) => {
    setContainsNumber(password.match(/[0-9]/g));
    setContainsSymbol(password.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.#@\/]/g));
    setContainsUppercase(password.match(/[A-Z]/g));
    setContainsLowercase(password.match(/[a-z]/g));
    setCorrectLength(password.length >= 8 && password.length <= 16);
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      props.clearError();
      props.login(userName, password);
    }
  }

  const onChange = (value) => {
    setRecaptcha(value);
  }

  useEffect(() => {

    props.setPasswordChanged(null);

  }, []);

  useEffect(() => {

    if (untouched) {
      // props.setPasswordChanged(null);
    }

    if (password !== confirmPassword) {
      setErrorMessage("Confirmed new password does not match new password");
    } else {
      setErrorMessage("");
    }

    setValid(correctLength && containsUppercase && containsSymbol && containsNumber && containsLowercase && password === confirmPassword && userName.length > 0);

  }, [password, confirmPassword, errorMessage, valid, setValid, correctLength, containsUppercase, containsSymbol, containsNumber, containsLowercase, userName]);


  if (props.authenticated) {
    return (<Redirect to={
      {
        pathname: "/main",
      }} />);
  }

  let error = null;

  if (errorMessage) {
    error = (<Alert variant="danger">{errorMessage}</Alert>);
  }

  let alert = null;

  if (props.passwordChanged) {
    alert = (<Alert variant="success">Password reset successfully</Alert>);
  } else if (props.passwordChanged === false) {
    alert = (<Alert variant="danger">Password could not be reset</Alert>);

  }

  let resetButton = null;

  if (props.passwordChanged === null)  {
    resetButton = (<Button style={{width: 120}} variant="outline-primary" className="my-2 mx-2" disabled={!valid} onClick={
      () => {
        props.clearError();
        const {resetCode} = getParams(props.location.pathname);
        props.resetPassword(userName, resetCode, recaptcha, password);
      }
    }>
      <FontAwesomeIcon icon={faCheck} className="me-2"/>
      Reset
    </Button>);

  }




  let form = null;

  if (!props.passwordChanged) {
    form = (
      <Container>
        <Row>
          <Col>
            <PasswordRequirements
              correctLength={correctLength}
              containsLowercase={containsLowercase}
              containsUppercase={containsUppercase}
              containsNumber={containsNumber}
              containsSymbol={containsSymbol}
              untouched={untouched}
              alert={error}
              confirmPassword={false}
            />

          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <InputGroup className="my-4">
                <InputGroup.Text id="userName">
                  <FontAwesomeIcon icon={faUser} className="text-primary"/>
                </InputGroup.Text>

                <Form.Control className="form-control"
                              placeholder="Confirm email"
                              value={userName}
                              onChange={(e) => setUserName(e.target.value)}/>

              </InputGroup>

              <InputGroup className="my-4">
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faKey} className="text-primary"/>
                </InputGroup.Text>

                <Form.Control className="form-control"
                              placeholder="Password"
                              type="password"
                              value={password}
                              onChange={((e) => {
                                setPassword(e.target.value);
                                checkPassword(e.target.value);
                                setUntouched(false);

                              })}
                              onKeyDown={handleKeyDown}
                />

              </InputGroup>

              <InputGroup className="my-4">
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faKey} className="text-primary"/>
                </InputGroup.Text>

                <Form.Control className="form-control"
                              placeholder="Confirm password"
                              type="password"
                              value={confirmPassword}
                              onChange={((e) => {
                                setConfirmPassword(e.target.value)
                              })}
                              onKeyDown={handleKeyDown}
                />

              </InputGroup>


            </FormGroup>

          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <ReCAPTCHA
              sitekey="6Ld4GZsaAAAAAIoplBlWJoBzXMhuhIFG_XHcvJkr"
              onChange={onChange}
            />

          </Col>
        </Row>
      </Container>

    )
  }

  return (

    <LoginTemplate>

    <form>

      <Card.Title>
        <h3>Password reset</h3>
      </Card.Title>
      {props.passwordChanged !== null ? alert : null}

      {form}

    </form>

      <Container>
        <Row>
          <Col>
            {resetButton}
            <Button style={{width: 120}} variant="outline-primary" className="my-2 mx-2" onClick={
              () => {
                props.history.push("/login");
              }
            }>
              <FontAwesomeIcon icon={faCheck} className="me-2"/>
              Exit
            </Button>
          </Col>
        </Row>

      </Container>

    </LoginTemplate>

  );

}

const mapStateToProps = (state) => {
  return {
    errorShown: state.errors.errorShown,
    error: state.errors.errorMessage,
    isError: state.errors.isError,
    loading: state.page.loading,
    authenticated: state.authentication.authenticated,
    passwordChanged: state.authentication.passwordChanged

  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    login: (user, password) => dispatch(actions.login(user, password)),
    clearError: () => dispatch(actions.clearError()),
    resetPassword: (email, resetCode, recaptcha, password) => dispatch(actions.resetPassword(email, resetCode, recaptcha, password)),
    setPasswordChanged: (changed) => dispatch(actions.setPasswordChanged(changed))
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PasswordReset));