import React, {useEffect, useState} from 'react';
import Homepage from '../../../Homepage/Homepage'
import {Container, Row, Col, Card, Button, Form, Alert} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {withRouter, useParams} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../../../store/actions";
import {faPlus, faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setAdminUpdateSuccess} from "../../../../../store/actions/admin";

const AdminUserInvitationEdit = (props) => {

  const {companyId, userId} = useParams();

  const [pristine, setPristine] = useState(true);
  const [valid, setValid] = useState(false);
  const [buildingName, setBuildingName] = useState(null);
  const [buildingId, setBuildingId] = useState(null);
  const [email, setEmail] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [roleName, setRoleName] = useState(null);
  const [roleOptions, setRoleOptions] = useState(null);
  const [appBuildingPermissionEnabled, setAppBuildingPermissionEnabled] = useState(null);
  const [appBuildingPermissions, setAppBuildingPermissions] = useState(null);
  const [buildingOptions,setBuildingOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);

  useEffect(() => {

    setAdminUpdateSuccess(null, null);
    props.setAdminUpdateSuccess(false, null);
    props.setPageTitle("Company Administration");

    if (!props.createMode) {
      props.getAdminUser(userId);
    } else {
      props.setAdminUser(null);
    }

    props.getAdminBuildingList(props.companyId);
    props.getAdminRoles();

    props.setAdminUpdateSuccess(null, null);

  },[]);

  useEffect(() => {

    setAdminUpdateSuccess(null, null);
    props.setAdminUpdateSuccess(false, null);
    props.setPageTitle("Company Administration");

    if (!props.createMode) {
      console.log("create mode");
      props.getAdminUser(userId);
    } else {
      console.log("update mode");
      props.setAdminUser(null);
    }

    props.getAdminBuildingList(props.companyId);
    props.getAdminRoles();

    props.setAdminUpdateSuccess(null, null);

  },[props.location]);

  useEffect(() => {

    let localValid = true;
    let errorMessages = []

    console.log("useEffect changed");

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validEmail = re.test(String(email).toLowerCase());

    if (!email || email.length === 0) {
      localValid = false;
      errorMessages.push("Email must be supplied");
    } else if (!validEmail) {
      errorMessages.push("Email is not valid email address");
    }

    if (!roleName || roleName.length === 0) {
      localValid = false;
      errorMessages.push("Role must be selected");
    }

    setErrorMessage(errorMessages);
    setValid(localValid);

  }, [email, appBuildingPermissionEnabled, appBuildingPermissions, roleId, roleName]);

  useEffect(() => {
    if (props.buildings && props.buildings.length > 0) {
      setBuildingOptions(props.buildings.map(building => <option value={building.id}>{building.name}</option>));
      setBuildingName(props.buildings[0].name);
      setBuildingId(props.buildings[0].id);
    }
  }, [props.buildings]);

  useEffect(() => {


    if (props.user) {

      setEmail(props.user.email);
      setAppBuildingPermissionEnabled(props.user.appBuildingPermissionEnabled);
      setAppBuildingPermissions(props.user.appUserBuildingPermissions ? props.user.appUserBuildingPermissions : []);

      if (props.roles) {

        let roleRecord = props.roles.filter(r => r.name === props.user.role)[0];
        setRoleId(roleRecord.id);
        setRoleName(roleRecord.name);
      }

      let nav = [];
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId, title: "Dashboard"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin", title: "Admin"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/user", title: "User"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/user/invitations", title: "Invitations"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/user/invitations/create", title: "Create Invitation"});

      props.setPageNavLinks(nav);

    } else {
      setEmail("");
      setAppBuildingPermissionEnabled(false);
      setAppBuildingPermissions([]);

      if (props.roles) {
        setRoleId(props.roles[0].id);
        setRoleName(props.roles[0].name);
      }

      let nav = [];

      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId, title: "Dashboard"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin", title: "Admin"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/user", title: "User Admin"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/user/invitations", title: "User Invites"});
      nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/user/invitations/create", title: "Create Invite"});
      props.setPageNavLinks(nav);

    }

  }, [props.user])

  useEffect(() => {

    if (props.roles) {
      setRoleOptions(props.roles.map(role => <option value={role.id}>{role.name}</option>));

      if (props.createMode) {
        setRoleId(props.roles[0].id);
        setRoleName(props.roles[0].name);
      } else if (props.roles && props.user) {
        let roleRecord = props.roles.filter(r => r.name === props.user.role)[0];
        setRoleId(roleRecord.id);
        setRoleName(roleRecord.name);
      }

    }


  }, [props.roles]);

  if (props.authenticated) {

    let errors = null;

    if ((props.backendErrorMessage || (errorMessage && errorMessage.length > 0)) && props.loadingCounter === 0) {

      let errorOptions = errorMessage.map(message => <li>{message}</li>);

      if (props.backendErrorMessage) {
        errorOptions.push([<li>{props.backendErrorMessage}</li>]);
      }

      errors = (<Row>
        <Col xs={12}>
          <Alert variant="danger">
            <h4>The following errors exist: -</h4>
            <ul>
              {errorOptions}
            </ul>
          </Alert>
        </Col>
      </Row>)
    } else if (props.success) {
      errors = (<Row>
        <Col xs={12}>
          <Alert variant="success">
            <h4>User Invite Created</h4>
          </Alert>
        </Col>
      </Row>)
    }

    return (
      <Homepage {...props} data={props.data} show={true} title={"Company Administration"}
                showAssetSelection={false} showCompanyInfo={true}>
        <Container fluid className="align-items-start h-100">
          <Row className="align-items-start h-100">
            <Col className="align-items-start h-100">
              <Card className="h-100 bg-primary w-100 text-white align-items-start">
                <Container fluid className="h-100">
                  {!pristine ? errors : null}
                  <Row>

                    <Col xs={12} className="col-sm-6">
                      <Form.Group controlId="adminAsset.Email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control value={email} type="email" placeholder="Email" onChange={(e) => {
                          setPristine(false);
                          setEmail(e.target.value)
                        }}/>
                      </Form.Group>
                    </Col>
                    <Col xs={12} className="col-sm-6">
                      <Form.Group controlId="adminAsset.AssetNameControl">
                        <Form.Label>Role</Form.Label>
                        <Form.Control as="select" value={roleId} type="text" placeholder="Icon" onChange={(e) => {
                          setPristine(false);

                          let roleObj = props.roles.filter(r => r.id === e.target.value)[0];

                          setRoleId(roleObj.id);
                          setRoleName(roleObj.name);
                        }
                        }>
                          {roleOptions}
                        </Form.Control>
                      </Form.Group>

                    </Col>
                  </Row>
                  <Row className="mt-3">

                    <Col  xs={8} className="align-content-end col-sm-4">

                      <Form.Group controlId="adminAsset.AssetNameControl">
                        <Form.Label>Add Building Permission</Form.Label>
                        <Form.Control as="select" value={buildingId} type="text" placeholder="Building" onChange={(e) => {
                          setPristine(false);

                          var index = e.nativeEvent.target.selectedIndex;
                          setBuildingName(e.nativeEvent.target[index].text);
                          setBuildingId(parseInt(e.target.value));
                        }
                        }>
                          {buildingOptions}
                        </Form.Control>
                      </Form.Group>


                    </Col>

                    <Col xs={4} className="d-flex align-content-end col-sm-4">
                      <Button className="bg-secondary mt-auto"  disabled={roleName && roleName.search("Admin") !== -1} onClick={() => {
                        setPristine(false);

                        if (!appBuildingPermissions.find(b => b.buildingId === buildingId)) {
                          let buildingPerms = [...appBuildingPermissions];
                          buildingPerms.push({buildingName: buildingName, buildingId: buildingId});
                          setAppBuildingPermissions(buildingPerms);
                        }

                      }}><FontAwesomeIcon className="me-1" icon={faPlus}/>Add</Button>
                    </Col>

                    <Col className="col-sm-4">
                      <Container>
                        <Row>
                          <Col xs={12}>
                            Building permissions
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {appBuildingPermissions ? appBuildingPermissions.map(build => {
                              return (<Button className="bg-secondary"
                                      onClick={() => {
                                        setPristine(false);

                                        let buildingPerms = [...appBuildingPermissions];
                                        setAppBuildingPermissions(buildingPerms.filter(p => p.buildingId !== build.buildingId));
                                      }}>
                                <FontAwesomeIcon className="me-2" icon={faTimes}/>{build.buildingName}</Button>);
                            }) : null}
                          </Col>

                        </Row>
                      </Container>
                    </Col>

                  </Row>

                  <Row className="mt-3">
                    <Col className="d-flex justify-content-center mt-auto flex-auto flex-fill">
                      <Button style={{width: 100}} className="mt-auto text-left bg-success me-3" disabled={!valid && !props.loadingCounter > 0}
                        onClick={() => {

                          setPristine(false);


                          let buildingPermissions = appBuildingPermissions.map(abp => abp.buildingId);


                          let userData = {
                            companyId: props.companyId,
                            email: email,
                            role: roleName,
                            appBuildingPermissions: buildingPermissions,
                            appBuildingPermissionEnabled: (roleName.search("Admin") === -1 && appBuildingPermissions.length !== 0)
                          }

                          props.createUserInvite(userData);
                        }}

                      >
                        <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>&nbsp;Save
                      </Button>
                      <Button style={{width: 100}} className="text-left bg-danger mt-auto" onClick={() => {
                        props.history.push("/company/" + props.companyId + "/admin/user/invitations");
                      }}>
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>&nbsp;Cancel
                      </Button>

                    </Col>
                  </Row>

                </Container>
              </Card>
            </Col>
          </Row>
        </Container>

      </Homepage>
    );
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (nav) => dispatch(actions.setPageNavLinks(nav)),
    getAdminUser: (userId) => dispatch(actions.fetchAdminUser(userId)),
    getAdminRoles: () => dispatch(actions.fetchAdminRoles()),
    getAdminBuildingList: (companyId) => dispatch(actions.fetchAdminBuildingList(companyId)),
    setAdminUpdateSuccess: (success, errorMessage) => dispatch(actions.setAdminUpdateSuccess(success, errorMessage)),
    updateUser: (userId, userData) => dispatch(actions.updateUser(userId, userData)),
    createUserInvite: (userData) => dispatch(actions.createUserInvite(userData)),
    setAdminUser: (userData) => dispatch(actions.setAdminUser(userData))
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    role: state.authentication.role,
    selectedCompanyData: state.company.selectedCompanyData,
    companyId: state.page.companyId,
    buildings: state.admin.buildings,
    loadingCounter: state.page.loadingCounter,
    success: state.admin.success,
    backendErrorMessage: state.admin.errorMessage,
    roles: state.admin.roles,
    user: state.admin.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminUserInvitationEdit));
