import React, {useState} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from 'moment';

const CustomizeForm = (props) => {

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const updateStartDate = (date) => {

    if (endDate === null) {

      let targetEndDate = moment(date).add(7, 'days');

      if (moment(new Date()).isAfter(targetEndDate)) {
        setEndDate(targetEndDate.toDate());
      } else {
        setEndDate(new Date());
      }
    }

    setStartDate(date);
  }

  const updateEndDate = (date) => {
    if (startDate === null) {

      let targetStartDate = moment(date).add(-7, 'days');

      if (moment(props.minDate).isBefore(targetStartDate)) {
        setStartDate(targetStartDate.toDate());
      } else {
        setStartDate(moment(props.minDate).toDate());
      }

    }

    setEndDate(date);
  }

  return (

    <Container fluid className="p-0">
      <Row>
        <Col className="d-flex align-items-center">
          <Form.Label className="me-2" htmlFor="startDate" size="sm">Start</Form.Label>
          <DatePicker id="startDate"
                      selected={startDate}
                      minDate={moment(props.minDate).toDate()}
                      maxDate={moment(props.maxDate).toDate()}
                      onChange={(date) => {
                        updateStartDate(date);
                      }}
                      dateFormat="dd/MM/yyyy"
          />
        </Col>
        <Col className="d-flex align-items-center">
          <Form.Label className="me-2" size="sm" shtmlFor="startDate">End</Form.Label>
          <DatePicker id="startDate"
                      selected={endDate}
                      minDate={moment(props.minDate).toDate()}
                      maxDate={moment(props.maxDate).toDate()}
                      onChange={(date) => {

                        updateEndDate(date);

                      }}
                      dateFormat="dd/MM/yyyy"
          />

        </Col>
        <Col className="d-flex justify-content-center">
          <Button size="sm" className="mx-1 my-2" onClick={

            () => {
              props.updateGraph(startDate, endDate);
            }
          }>Apply</Button>
          <Button size="sm" className="mx-1 my-2" onClick={() => props.reset()}>Reset</Button>
          <Button size="sm" className="mx-1 my-2" onClick={() => {
            props.cancel();
          }}>Cancel</Button>

        </Col>
      </Row>
    </Container>

  );


}

export default CustomizeForm;