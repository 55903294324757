import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import ReportController from "./ReportController";
import {useParams, withRouter} from "react-router-dom";

const AlertHistoryReport = (props) => {

  const {companyId} = useParams();
  const [pageParams, setPageParams] = useState(null);

  useEffect(() => {
    let navLinks = [];
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/" + companyId, title: "Reports"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/alertHistory" + pageParams, title: "Alert History"});
    props.setPageNavLinks(navLinks)

  }, [pageParams])

  useEffect(() => {
    console.log(props.reportData);
  })

  const handleCsvDownload = (companyId, month, year) => {

    let params = {companyId: companyId, month: month, year: year}
    props.fetchAlertHistoryReportCsv(params);
  }

  const handleUpdateLink = (companyId, pageNo, pageSize, descending, month, year) => {
    props.history.push("/company/" + companyId + "/reports/alertHistory?pageNo=" + pageNo + " &pageSize=" + pageSize + "&descending=" + descending + "&month=" + month + "&year=" + year);
  }

  const updatePageParams = (pageParams) => {
    setPageParams(pageParams);
  }

  const handleUpdateData = (params) => {

    let navLinks = [];

    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/" + companyId, title: "Reports"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/alertHistory" + pageParams, title: "Alert History"});
    props.setPageNavLinks(navLinks)

    props.fetchAlertHistoryReport(params);
  }

  return (<ReportController
    pageTitle="Alert History Report"
    csvDownload={handleCsvDownload}
    downloadOptions={null}
    urlTemplate="/reports/:companyId/alertHistory"
    updateLink={handleUpdateLink}
    updateData={handleUpdateData}
    updatePageParams={updatePageParams}
    showDateOption={true}
    useDatePicker={false}
  />);
};

const mapDispatchToProps = (dispatch) => {

  return {
    rawMeasurements: (params) => dispatch(actions.fetchRawData(params)),
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (links) => dispatch(actions.setPageNavLinks(links)),
    fetchAlertHistoryReportCsv: (params) => dispatch(actions.fetchAlertHistoryReportCsv(params)),
    fetchAlertHistoryReport: (params) => dispatch(actions.fetchAlertHistoryReport(params))
  }

}

const mapStateToProps = (state) => {

  return {
    reportData: state.report.data,
    pagination: state.report.pagination,
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AlertHistoryReport));