import React, {useEffect, useState} from "react";
import {Card, Col, Row, Container, Form, FormGroup, Alert, Button} from "react-bootstrap";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import Homepage from "../Company/Homepage/Homepage";
import * as actions from '../../store/actions';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import RevealablePasswordField from "./RevealablePasswordField";
import {withRouter} from "react-router-dom";
import PasswordRequirements from './PasswordRequirements';

const DocumentContent = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [correctLength, setCorrectLength] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [containsUppercase, setContainsUppercase] = useState(false);
  const [containsLowercase, setContainsLowercase] = useState(false);
  const [containsSymbol, setContainsSymbol] = useState(false);
  const [untouched, setUntouched] = useState(true);
  const [differentPassword, setDifferentPassword] = useState(false);
  const [valid, setValid] = useState(false);

  const checkNewPassword = (password) => {
    setContainsNumber(password.match(/[0-9]/g));
    setContainsSymbol(password.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.#@\\\/]/g));
    setContainsUppercase(password.match(/[A-Z]/g));
    setContainsLowercase(password.match(/[a-z]/g));
    setCorrectLength(password.length >= 8 && password.length <= 16);
    setDifferentPassword(password !== oldPassword);
  }

  const checkOldPassword = (password) => {
    setDifferentPassword(newPassword !== password);
  }

  useEffect(() => {

    if (untouched) {
      props.setPasswordChanged(null);
    }

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Confirmed new password does not match new password");
    } else {
      setErrorMessage("");
    }

    setValid(correctLength && containsUppercase && containsSymbol && containsNumber && containsLowercase && differentPassword && newPassword === confirmNewPassword && oldPassword.length > 0);

  }, [newPassword, confirmNewPassword, errorMessage, valid, setValid, correctLength, containsUppercase, containsSymbol, containsNumber, containsLowercase, oldPassword, differentPassword]);

  if (props.authenticated) {

    props.setPageTitle("Change Password");

    let alert = null;

    if (errorMessage) {
      alert = (<Alert variant="danger">{errorMessage}</Alert>);
    }

    if (props.passwordChanged !== null) {

      if (props.passwordChanged) {
        alert =(<Alert variant="success">Password changed successfully.</Alert> );

        if (props.forcePasswordChange) {
          props.setForcedPasswordChanged(true);
          props.logout();
        } else {
          props.setForcedPasswordChanged(false);
        }


      } else {
        alert =(<Alert variant="danger">Could not change your password at this time.</Alert> );
      }

    }



    return (<Homepage {...props} data={props.data} showAssetSelection={false} showCompanyInfo={false}>

      <Container fluid>
        <Row className="d-flex flex-fill">
          <Col className="d-flex flex-fill d-flex-row align-content-between">

            <Card className="bg-primary w-100 text-white h-100">

              <Container fluid>

                <Row>
                  <Col className="d-flex justify-content-center align-items-center">

                    <Card className="bg-white text-dark">

                      <Row>
                        <Col>

                          <PasswordRequirements
                            correctLength={correctLength}
                            containsLowercase={containsLowercase}
                            containsUppercase={containsUppercase}
                            containsNumber={containsNumber}
                            containsSymbol={containsSymbol}
                            differentPassword={differentPassword}
                            untouched={untouched}
                            alert={alert}
                          />

                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="justify-content-center">
                    <Container>
                      <Row>
                        <Col>
                          <Form>
                            <FormGroup>

                              <RevealablePasswordField
                                onClickFunction={(e) => {
                                  setOldPassword(e.target.value);
                                  checkOldPassword(e.target.value);
                                }}
                                value={oldPassword}
                                placeholderText="Old password"
                              />

                              <RevealablePasswordField
                                onClickFunction={(e) => {
                                  setNewPassword(e.target.value);
                                  setUntouched(false);
                                  checkNewPassword(e.target.value);
                                }}
                                value={newPassword}
                                placeholderText="New password"
                              />

                              <RevealablePasswordField
                                onClickFunction={(e) => {
                                  setConfirmNewPassword(e.target.value);
                                }}
                                value={confirmNewPassword}
                                placeholderText="Confirm new password"
                              />
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex justify-content-center">
                          <Button className="bg-success my-3 mx-2" disabled={!valid}
                                  onClick={() => props.changePassword(oldPassword, newPassword)}>
                            <FontAwesomeIcon icon={faCheck} className="me-2"/>
                            Update
                          </Button>

                          <Button className="bg-danger my-3 mx-2" onClick={() =>{
                            props.history.push("/main");
                          }
                          }>
                            <FontAwesomeIcon icon={faTimes} className="me-2"/>
                            Cancel
                          </Button>

                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>


    </Homepage>);
  } else {
    return (<Redirect to="/login"/>);
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    changePassword: (oldPassword, newPassword) => dispatch(actions.changePassword(oldPassword, newPassword)),
    setPasswordChanged: (val) => dispatch(actions.setPasswordChanged(val)),
    logout: () => dispatch(actions.logout()),
    setForcedPasswordChanged: (forced) => dispatch(actions.setForcedPasswordChanged(forced))
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.authentication.authenticated,
    passwordChanged: state.authentication.passwordChanged,
    forcePasswordChange: state.authentication.forcePasswordChange
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentContent));