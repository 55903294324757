import * as actionTypes from './actionTypes';
import * as actions from './index';
import agent from "../../app/api/agent";
import jwt_decode from "jwt-decode";
import store from "../store";

export const login = (username, password) => {

  return async dispatch => {

    dispatch(actions.incrementPageLoading());

    try {
      const user = await agent.Account.login({email: username, password: password});
      dispatch(setAuthDetails(user.token, user.refreshToken));
      dispatch(actions.decrementPageLoading());
      window.localStorage.setItem("username", user.username);
      window.localStorage.setItem("bearertoken", user.token);
      window.localStorage.setItem("refreshtoken", user.refreshToken);

    } catch (error) {

      if (error && error === "Account has been disabled") {
        store.dispatch(actions.setError(403, "Account has been disabled", "Unknown"));
      } else {
        store.dispatch(actions.setError(401, "Either username or password not valid", "Unknown"));
      }

    }

  }

}

export const requestPasswordReset = (email, recaptcha) => {

  return async dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Account.requestPasswordReset({email: email, recaptcha: recaptcha})
      .then(response => {

        dispatch(actions.decrementPageLoading());
        dispatch(actions.setPasswordResetRequested(true));
      }).catch(err => {
      dispatch(actions.setPasswordResetRequested(false));
    })

  }

}

export const resetPassword = (email, resetCode, recaptcha, password) => {

  return async dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Account.resetPassword(resetCode, {email: email, recaptcha: recaptcha, password: password})
      .then(response => {
        dispatch(actions.decrementPageLoading());
        dispatch(actions.setPasswordChanged(true));
      })
      .catch(error => {
        dispatch(actions.setPasswordChanged(false));
      });

  }

}

export const setPasswordResetRequested = (passwordResetRequested) => {

  return {
    type: actionTypes.SET_PASSWORD_RESET_REQUESTED,
    passwordResetRequested: passwordResetRequested
  }

}

export const setLogin = (user) => {

  return {
    type: actionTypes.SET_LOGIN,
    user: user
  }

}

export const setAuthDetails = (token, refreshToken) => {

  let decodedToken = jwt_decode(token);

  return {
    type: actionTypes.SET_AUTH_DETAILS,
    username: decodedToken.email,
    tokenExpiry: decodedToken.exp,
    role: decodedToken.role,
    companyId: decodedToken.companyId,
    forcePasswordChange: decodedToken.forcePasswordChange,
    token: token,
    refreshToken: refreshToken
  };

}

export const changePassword = (oldPassword, newPassword) => {

  return dispatch => {

    dispatch(actions.incrementPageLoading());

    let param = {oldPassword: oldPassword, newPassword: newPassword};

    agent.Account.changePassword(param)
      .then(response => {
        dispatch(actions.decrementPageLoading());
        dispatch(setPasswordChanged(true));
      }).catch((error) => {
        dispatch(setPasswordChanged(false));
    });


  }

}

export const refreshToken = (userName, refreshToken) => {

  return dispatch => {

    return new Promise((resolve, reject) => {
      return agent.Account.refreshToken(userName, refreshToken)
        .then(response => {
          window.localStorage.setItem("bearertoken", response.token);
          window.localStorage.setItem("refreshtoken", response.refreshToken);
          dispatch(setAuthDetails(response.token, response.refreshToken));
          
          return resolve(response);
        }).catch((error) => {
          dispatch(logout());
          //reject(error);
          return Promise.reject(error);
        })
    });
  };

}


export const setPasswordChanged = (passwordChanged) => {
  return {
    type: actionTypes.SET_PASSWORD_CHANGED,
    passwordChanged: passwordChanged
  }
}

export const logout = () => {

  window.localStorage.removeItem("bearertoken");
  window.localStorage.removeItem("username");
  window.localStorage.removeItem("refreshtoken");

  return {
    type: actionTypes.LOGOUT
  }
}

