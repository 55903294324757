import React, {useEffect, useState} from "react";
import {Card, Col, Row, Container, Form, Button} from "react-bootstrap";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import Homepage from "../Company/Homepage/Homepage";
import * as actions from '../../store/actions';
import {withRouter} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faMinusSquare, faPlus, faPlusSquare} from "@fortawesome/free-solid-svg-icons";

const DocumentContent = (props) => {

  const [companyOptions, setCompanyOptions] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [buildingOptions, setBuildingOptions] = useState(null);
  const [existingBuildingSubs, setExistingBuildingSubs] = useState([]);
  const [existingCompanySubs, setExistingCompanySubs] = useState([]);

  const [selectedBuilding, setSelectedBuilding] = useState(null);

  useEffect(() => {

    props.fetchBuildingList();
    props.fetchCompanyList();
    props.getSubscription();

  }, []);

  useEffect(() => {

    if (props.subscription && props.subscription.buildingAlertsSubscriptions && props.subscription.buildingAlertsSubscriptions.length > 0) {
      console.log(props.subscription);

      setExistingBuildingSubs(props.subscription.buildingAlertsSubscriptions.map(building => {
        return (<Row>
          <Col xs={11}>
            {building.companyName} &gt; {building.name}
          </Col>
          <Col className="d-flex justify-content-end align-content-center my-auto">
            <Button className="bg-secondary text-left" style={{width: 120}} onClick={() => {
              let subscription = {...props.subscription};
              subscription.buildingAlertsSubscriptions = subscription.buildingAlertsSubscriptions.filter(b => b.id !== building.id);


              props.updateSubscription(subscription);

            }}><FontAwesomeIcon icon={faMinus}/>&nbsp;Delete</Button>
          </Col>
        </Row>);
      }));
    } else {
      setExistingBuildingSubs((<Row><Col>No building subscriptions</Col></Row>));
    }





    if (props.subscription && props.subscription.companyAlertsSubscriptions && props.subscription.companyAlertsSubscriptions.length > 0) {
      console.log(props.subscription);

      setExistingCompanySubs(props.subscription.companyAlertsSubscriptions.map(company => {
        return (<Row>
          <Col xs={11}>
            {company.name}
          </Col>
          <Col className="d-flex justify-content-end">
            <Button className="bg-secondary text-left" style={{width: 120}} onClick={() => {
              let subscription = {...props.subscription};
              subscription.companyAlertsSubscriptions = subscription.companyAlertsSubscriptions.filter(c => c.id !== company.id);
              props.updateSubscription(subscription);
            }}><FontAwesomeIcon icon={faMinus}/>&nbsp;Delete</Button>
          </Col>
        </Row>);
      }));
    } else {
      setExistingCompanySubs((<Row><Col>No company wide subscriptions</Col></Row>));
    }


    console.log(props.subscription);
  }, [props.subscription]);

  useEffect(() => {

    console.log(props.buildings);

    if (props.buildings && props.buildings.length > 0) {
      setBuildingOptions(props.buildings.map(b => {
        return (<option value={b.id}>{b.companyName} &gt; {b.name}</option>);
      }));

      setSelectedBuilding(0);

    }

  }, [props.buildings]);

  useEffect(() => {

    console.log(props.companies);

    if (props.companies && props.companies.length > 0) {
      setCompanyOptions(props.companies.map(c => {
        console.log(c);
        return (<option value={c.id}>{c.name}</option>);
      }));

      setSelectedCompany(0);

    }

  }, [props.companies]);


  const addAllBuildings = () => {
    let subscription = {...props.subscription};

    props.buildings.forEach((building, index) => {

      console.log(subscription.buildingAlertsSubscriptions.filter(b => b.id === building.id));

      if ((subscription.buildingAlertsSubscriptions.filter(b => b.id === building.id)).length === 0) {
        subscription.buildingAlertsSubscriptions.push({"id": building.id, "name": building.name});
      }

    });

    console.log(subscription);

    props.updateSubscription(subscription);

  }

  const addAllCompanies = () => {
    let subscription = {...props.subscription};

    props.companies.forEach((company, index) => {

      console.log(subscription.companyAlertsSubscriptions.filter(c => c.id === company.id));

      if ((subscription.companyAlertsSubscriptions.filter(b => b.id === company.id)).length === 0) {
        subscription.companyAlertsSubscriptions.push({"id": company.id, "name": company.name});
      }

    });

    console.log(subscription);

    props.updateSubscription(subscription);

  }


  if (props.authenticated) {

    props.setPageTitle("Manage Subscriptions");

    let alert = null;

    return (<Homepage {...props} data={props.data} show={true} title={props.title}  showCompanyInfo={false}
                              setAssetSelection={false}>

      <Container fluid className="h-100 d-flex flex-fill">
        <Row className="d-flex flex-fill">
          <Col className="d-flex flex-fill d-flex-row align-content-between">
            <Card className="bg-primary text-white h-100 w-100">

              <Container fluid>

                <Row>
                  <Col className="p-0 m-0 w-100 d-flex justify-content-start align-items-center">

                  <Container fluid className="h-100">

                    <Row>
                      <Col xs="12">
                        <h4>Company Wide Subscriptions</h4>
                      </Col>
                    </Row>

                    <Row>

                      <Col xs="8" className="mb-2">
                        <Form.Control as="select" onChange={(e) => {
                          setSelectedCompany(e.target.selectedIndex);
                        }}>
                          {companyOptions}
                        </Form.Control>
                      </Col>
                      <Col xs="2" className="d-flex justify-content-end align-content-center my-auto">
                        <Button className="bg-secondary text-left" style={{width: 120}} onClick={() => {
                          let subscription = {...props.subscription};
                          subscription.companyAlertsSubscriptions.push(props.companies[selectedCompany]);
                          props.updateSubscription(subscription);
                      }}>
                          <FontAwesomeIcon icon={faPlus}/>&nbsp;Add</Button></Col>
                      <Col xs="2" className="d-flex justify-content-end align-content-center my-auto">
                        <Button className="bg-secondary text-left" style={{width: 120}} onClick={() => {
                          addAllCompanies();
                        }}><FontAwesomeIcon icon={faPlusSquare}/>&nbsp;Add All</Button>
                      </Col>

                    </Row>
                    <Row>
                      <Col xs={8}><h4>Current Company subscriptions</h4></Col>
                      <Col xs={4} className="d-flex justify-content-end">
                        <Button disabled={!props.subscription || !props.subscription.companyAlertsSubscriptions.length > 0} className="bg-secondary text-left" style={{width: 120}} onClick={() => {
                          let subscription = {...props.subscription};
                          subscription.companyAlertsSubscriptions = [];
                          props.updateSubscription(subscription);
                        }}><FontAwesomeIcon icon={faMinusSquare}/>&nbsp;Delete All</Button></Col>
                    </Row>
                    {existingCompanySubs}



                    <Row>
                      <Col xs="12" className="mt-4">
                        <h4>Building Subscriptions</h4>
                      </Col>
                    </Row>
                    <Row>

                      <Col xs="8" className="mb-2">
                        <Form.Control as="select" onChange={(e) => {
                          setSelectedBuilding(e.target.selectedIndex);
                        }}>
                          {buildingOptions}
                        </Form.Control>
                      </Col>
                      <Col xs="2" className="d-flex justify-content-end align-content-center my-auto">
                        <Button className="bg-secondary text-left" style={{width: 120}} onClick={() => {
                          let subscription = {...props.subscription};
                          subscription.buildingAlertsSubscriptions.push(props.buildings[selectedBuilding]);
                          props.updateSubscription(subscription);
                        }}><FontAwesomeIcon icon={faPlus}/>&nbsp;Add</Button></Col>
                      <Col xs="2" className="d-flex justify-content-end align-content-center my-auto"><Button className="bg-secondary text-left" style={{width: 120}} onClick={() => {
                        addAllBuildings();
                      }}><FontAwesomeIcon icon={faPlusSquare}/>&nbsp;Add All</Button></Col>

                    </Row>
                    <Row>
                      <Col xs={10}><h4>Current subscriptions</h4></Col>
                      <Col className="d-flex justify-content-end align-content-center my-auto">
                        <Button disabled={!props.subscription || !props.subscription.buildingAlertsSubscriptions.length > 0} style={{width: 120}} className="bg-secondary" onClick={() => {
                          let subscription = {...props.subscription};
                          subscription.buildingAlertsSubscriptions = [];
                          props.updateSubscription(subscription);
                        }}><FontAwesomeIcon icon={faMinusSquare}/>&nbsp;Delete All</Button>
                      </Col>
                    </Row>
                    {existingBuildingSubs}

                  </Container>
                  </Col>
                </Row>

              </Container>
            </Card>
          </Col>
        </Row>
      </Container>


    </Homepage>);
  } else {
    return (<Redirect to="/login"/>);
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    fetchBuildingList: () => dispatch(actions.fetchSubscriptionBuildingList()),
    fetchCompanyList: () => dispatch(actions.fetchSubscriptionCompanyList()),
    getSubscription: () => dispatch(actions.fetchSubscription()),
    updateSubscription: (subcription) => dispatch(actions.updateSubscription(subcription))
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.authentication.authenticated,
    passwordChanged: state.authentication.passwordChanged,
    role: state.authentication.role,
    buildings: state.subscriptions.buildings,
    companies: state.subscriptions.companies,
    subscription: state.subscriptions.subscription
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentContent));