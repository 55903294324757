import React, {useState} from 'react';
import {Button, Card, Col, Container, Form, Row, Table} from "react-bootstrap";
import Switch from "react-switch";
import {connect} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTimes, faCheck} from "@fortawesome/free-solid-svg-icons";
import * as actions from '../../../../store/actions';
import CreatableSelect from 'react-select/creatable';

const AssetSensorTab = (props) => {

  const [editMode, setEditMode] = useState(false);
  const [changed, setChanged] = useState(false);
  const [validNames, setValidNames] = useState([]);

  const updateSettingName = (index, newValue) => {
    let sensors = [...props.editSensor];
    sensors[index].sensorDescription = newValue;
    props.updateSensorSettings(sensors);
    setChanged(true);
    validateNames();
  }

  const updateOffset = (index, newValue) => {
    let sensors = [...props.editSensor];
    sensors[index].offset = newValue;
    props.updateSensorSettings(sensors);
    setChanged(true);
    validateNames();
  }

  const updateEnabled = (index, newValue) => {
    let sensors = [...props.editSensor];
    sensors[index].enabled = newValue;
    props.updateSensorSettings(sensors);
    setChanged(true);
    validateNames();
  }

  const validateNames = () => {

    let validNamesArray = [];
    props.editSensor.forEach(sensor => {

      if (sensor.sensorDescription.trim() === "") {
        validNamesArray.push(false);
      } else  if (sensor.sensorDescription.startsWith("Sensor")) {
        validNamesArray.push(false);
      } else {
        validNamesArray.push(true);
      }

    });

    setValidNames([...validNamesArray]);
  }

  const handleChange = (index, checked) => {
    updateEnabled(index, checked);
  }

  const handleChange2 = (newValue, actionMeta) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();

    if (actionMeta.action === "select-option") {
    }

  };

  const handleInputChange = (inputValue, actionMeta) => {
    console.group('Input Changed');
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  let rows = null;
  let buttons = null;

    if (props.settings) {

      buttons = (<Col xs={12} className="align-items-center mt-auto mb-2 d-flex justify-content-center">
          <Button className="bg-secondary settings-button" onClick={() => {
            setEditMode(true);
          }}>
            <FontAwesomeIcon className="me-2" icon={faEdit}/>
            Edit
          </Button></Col>
      );

      if (editMode) {
        buttons = (<Col xs={12} className="align-items-center mt-auto mb-2 d-flex justify-content-center">
            <Button className="bg-success settings-button" onClick={() => {

              let settings = JSON.parse(JSON.stringify(props.settings));
              settings.sensorSettings = [...props.editSensor];
              props.updateAssetSettings(props.assetData.id, settings);
              props.storeAssetSettings(settings);
              setEditMode(false);
              setChanged(false);
              }} disabled={!changed}>
              <FontAwesomeIcon className="me-2" icon={faCheck}/>

              Save
            </Button>
            <Button className="bg-danger settings-button" onClick={() => {
              props.restoreSensorSettings();
              setEditMode(false);
              setChanged(false);
            }}>
              <FontAwesomeIcon className="me-2" icon={faTimes}/>
              Cancel
            </Button>
          </Col>)
      }

      rows = null;

      let options = [
        {value: "Hot supply", label: "Hot supply"},
        {value: "Cold supply", label: "Cold supply"},
        {value: "Hot supply to TMV", label: "Hot supply to TMV"},
        {value: "Cold Supply to TMV", label: "Cold Supply to TMV"},
        {value: "Mixed supply from TMV", label: "Mixed supply from TMV"},
        {value: "Hot flow from calorifier",label: "Hot flow from calorifier"},
        {value: "Hot return to calorifier",label: "Hot return to calorifier"},
        {value: "Incoming cold mains supply", label: "Incoming cold mains supply"},
        {value: "Supply to cold water tank", label: "Supply to cold water tank"},
        {value: "Flow from cold water tank", label:  "Flow from cold water tank"},
        {value: "Hot return at outlet", label: "Hot return at outlet"},
        {value: "Cold return at outlet", label: "Cold return at outlet"}
      ];

      if (editMode) {
        rows = props.editSensor.map( (settings, index) => {
          return (
            <tr>
              <td>
                {settings.sensorIndex}
              </td>
              <td>

                <CreatableSelect className="text-dark" style={{height: "48px"}}
                  isClearable
                  onChange={(newValue, actionMeta) => {
                    handleChange2(newValue, actionMeta);
                    if (actionMeta.action === "select-option") {
                      updateSettingName(index, newValue.value);
                    } else if (actionMeta.action === "clear") {
                      updateSettingName(index, "");
                    }
                  }}
                  onInputChange={(inputValue, actionMeta) => {
                    handleInputChange(inputValue, actionMeta);
                    if (actionMeta.action === "input-change") {
                      updateSettingName(index, inputValue);
                    } else if (actionMeta.action === "create-option") {
                      options.push(inputValue);
                      updateSettingName(index, inputValue.value);
                    }
                  }}
                  isValidNewOption  = {(inputValue, selectValue, selectOptions) => false}
                  getNewOptionData = {(inputValue, optionLabel) => optionLabel}
                  options={options}
                  value={{value: settings.sensorDescription, label: settings.sensorDescription}}
                />
              </td>
              <td width="200">
                <Form.Control className="form-control-lg font-weight-bold"
                              placeholder="Offset"
                              value={settings.offset}
                              type="number"
                              onChange={(e) => updateOffset(index, e.target.value)}/>
              </td>
              <td>
                <label htmlFor={"normal-switch_" + index}>

                <Switch className="react-switch" onChange={(checked, event, id) => {
                    handleChange(index, checked);
                  }}
                  checked={settings.enabled === true} id={"normal-switch_" + index}
                  disabled={!validNames[index] && !settings.enabled}
                />
                </label>
              </td>
            </tr>);

        });

      } else {

        rows = props.settings.sensorSettings.map( (settings, index) => {

          return (
            <tr className={settings.enabled ? "text-white" : "text-muted"}>
              <td className="align-middle" style={{ textDecorationLine: !settings.enabled ? 'line-through' : 'none' }}>
                <p style={{ textDecorationLine: !settings.enabled ? 'line-through' : 'none' }}>{settings.sensorIndex}</p>
              </td>
              <td className="align-middle">
                <p style={{ textDecorationLine: !settings.enabled ? 'line-through' : 'none' }}>{settings.sensorDescription}</p>
              </td>
              <td className="align-middle">
                <p style={{ textDecorationLine: !settings.enabled ? 'line-through' : 'none' }}>{settings.offset}</p>
              </td>
              <td className="align-middle">
                <p style={{ textDecorationLine: !settings.enabled ? 'line-through' : 'none' }}>
                  {settings.enabled ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/>}</p>
              </td>

            </tr>);

        });
      }
    }

    return (<Container fluid className="h-100 d-flex flex-fill">
        <Row className="d-flex flex-fill h-100">
          <Col className="d-flex flex-column align-content-between p-0 m-0">
            <Card className="bg-primary w-100 text-white h-100 m-0 d-flex flex-column">

              <Container fluid className="d-flex flex-column h-100 p-0">

                <Row>
                  <Col className="d-flex flex-column m-2">
                    <h3 className="text-center">Sensor Settings</h3>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="d-flex flex-column align-items-start" >
                    <Table striped className="text-white p-2 sensor-settings-table">
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>Sensor Description</th>
                        <th>Offset</th>
                        <th>Enabled</th>
                      </tr>
                      </thead>
                      <tbody>
                      {rows}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row className="d-flex flex-column mt-auto">
                  {buttons}
                </Row>
              </Container>

            </Card>
          </Col>
        </Row>
      </Container>
    );

}

const mapStateToProps = (state) => {
  return {
    editSensor: state.settings.editSensor,
    settings: state.settings.settings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAssetSettings: (id, settings) => dispatch(actions.updateAssetSettings(id, settings)),
    updateSensorSettings: (sensor) => dispatch(actions.updateSensorSettings(sensor)),
    restoreSensorSettings: () => dispatch(actions.restoreSensorSettings()),
    storeAssetSettings: (assets) => dispatch(actions.storeAssetSettings(assets))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AssetSensorTab);