import React from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faThermometer} from "@fortawesome/free-solid-svg-icons";
import {connect} from 'react-redux';
import classes from './AlertTempMultipleComponent.module.css';

const AlertTempMultipleComponent = (props) => {

  const alertObject = props.settings.alertSettings.filter(alert => alert.keyName === props.keyName)[0];

  if (props.editMode && alertObject.enabled === true) {
    return (

      <Col className="bacterial-temp-div m-1" style={{border: "1px solid black"}}>
        <h3 className={classes.Icon}><FontAwesomeIcon icon={props.icon} className="me-2"/>{props.name}</h3>

        <Form.Group>

        <Row>
          <Form.Label className={"lead " + classes.LabelText} column sm={3}>Temp &deg;C</Form.Label>
          <Col>
            <Form.Control size="sm"
                          className="form-control font-weight-bold"
                          placeholder="Temp 1"
                          type="number" value={props.editValues.lowerLimit}
                          onChange={(e) => props.changeValue(e.target.value, props.keyName, "lowerLimit")}
            />

          </Col>
          <Col>
            <Form.Control size="sm"
                          className="form-control font-weight-bold"
                          placeholder="Temp 2"
                          type="number" value={props.editValues.upperLimit}
                          onChange={(e) => props.changeValue(e.target.value, props.keyName, "upperLimit")}
            />

          </Col>

        </Row>
        <Row>
          <Form.Label className={"lead " + classes.LabelText} column sm={3}>Duration</Form.Label>
          <Col>
            <Form.Control size="sm"
                          className="form-control font-weight-bold"
                          placeholder="Duration"
                          type="number" value={props.editValues.duration}
                          onChange={(e) => props.changeValue(e.target.value, props.keyName, "duration")}
            />

          </Col>
          <Col>
            <Form.Control size="sm"
                          className="form-control font-weight-bold"
                          placeholder="Duration"
                          as="select" value={props.editValues.durationUnits}
                          onChange={(e) => props.changeValue(e.target.value, props.keyName, "durationUnits")}
            >
              <option value="minutes">Minutes</option>
              <option value="hours">Hours</option>
              <option value="days">Days</option>
            </Form.Control>
          </Col>
        </Row>
      </Form.Group>
      </Col>
    );

  } else {

    let className = alertObject.enabled === true ? props.className : "alert-disabled-div";
    let textClassName = alertObject.enabled === true ? "text-black" : "text-muted";

    return (
      <Col className={className + " m-1"} style={{border: "1px solid black"}}>
        <h3 className={classes.Icon}><FontAwesomeIcon icon={props.icon} className="me-2"/>{props.name}</h3>
        <p className={"lead " + classes.LabelText} style={{fontWeight: "bold"}}>
          <FontAwesomeIcon icon={faThermometer} className="me-xxl-2"/>{props.values.lowerLimit} &deg;C -&nbsp;
          {props.values.upperLimit} &deg;C
        </p>
        <p className={"lead " + classes.LabelText} style={{fontWeight: "bold"}}>
          <FontAwesomeIcon icon={faClock} className="me-xxl-2"/>{props.values.duration}
          &nbsp;{props.values.durationType}</p>
      </Col>
    );

  }

}

const mapStateToProps = (state) => {
  return {

    settings: state.settings.settings,
    editAlertLimits: state.settings.editAlertLimits

  }
}

export default connect(mapStateToProps)(AlertTempMultipleComponent);