import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import ReportTable from "./ReportTable";
import {connect} from "react-redux";
import moment from "moment";

const ReportController = (props) => {
  const [date, setDate] = useState(new Date());
  const [descending, setDescending] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [monthYear, setMonthYear] = useState(new Date().getMonth() + 1 + "|" + new Date().getFullYear());
  const [pageParams, setPageParams] = useState(null);

  const changeMonthYear = (e) => {

    let monthYear = e.target.value;
    console.log(monthYear);
    const [month, year] = monthYear.split("|");
    setMonth(month);
    setYear(year);
    setMonthYear(e.target.value);
    console.log(e.target.value);
    props.updateLink(props.companyId, 1, pageSize, descending, month, year);

  }

  const getMonthYearOptions = () => {

    let startDate = new Date(2021, 2, 1, 0, 0, 0);
    let nowMoment = moment();
    let startMoment = moment(startDate);
    let options = [];

    while (startMoment.isBefore(nowMoment)) {
      let val = {display: startMoment.format('MMM YYYY'), value: startMoment.format('M') + "|" + startMoment.format('YYYY') }
      options.push(<option value={val.value}>{val.display}</option>);
      startMoment = startMoment.add(1, 'month');
    }

    return options;

  }

  const initialiseFromQueryParams = () => {
    const params = new URLSearchParams(props.location.search);
    let pageNoFromParams = params.get("pageNo");
    let descendingFromParams = params.get("descending");
    let pageSizeFromParams = params.get("pageSize");
    let monthFromParams = params.get("month");
    let yearFromParams = params.get("year");
    let dateFromParams = params.get("date");

    if (pageNoFromParams === null || pageNoFromParams === undefined) {
      pageNoFromParams = 1;
    } else {
      pageNoFromParams = parseInt(pageNoFromParams);
    }

    if (descendingFromParams === null || descendingFromParams === undefined) {
      descendingFromParams = true;
    } else {
      descendingFromParams = String(descendingFromParams).toLowerCase() === 'true'
    }

    if (pageSizeFromParams === null || pageSizeFromParams === undefined) {
      pageSizeFromParams = 10;
    } else {
      pageSizeFromParams = parseInt(pageSizeFromParams);
    }

    if (monthFromParams === null || monthFromParams === undefined) {
      monthFromParams = (new Date().getMonth() + 1);
    } else {
      monthFromParams = parseInt(monthFromParams);
    }

    if (yearFromParams === null || yearFromParams === undefined) {
      yearFromParams = new Date().getFullYear();
    } else {
      yearFromParams = parseInt(yearFromParams);
    }

    if (props.useDatePicker) {
      if (dateFromParams === null) {
        dateFromParams = moment().toDate();
        dateFromParams.setHours(0,0,0,0);

      } else {
        dateFromParams = moment(dateFromParams).toDate();
        dateFromParams.setHours(0,0,0,0);
      }

      console.log(dateFromParams);

      setDate(dateFromParams);

    }

    setMonthYear(monthFromParams + "|" + yearFromParams);

    setPageNo(pageNoFromParams);
    setDescending(descendingFromParams);
    setPageSize(pageSizeFromParams);
    setMonth(monthFromParams);
    setYear(yearFromParams);

  }

  useEffect(() => {

    props.updateData({companyId: props.companyId, pageNumber: pageNo, pageSize: pageSize,
      descending: descending, month: props.useDatePicker ? null : month, year: props.useDatePicker ? null : year, date: props.useDatePicker ? date : null});
    setPageParams("?pageNo=" + pageNo + "&pageSize=" + pageSize +
      "&descending=" + descending + "&month=" + month + "&year=" + year + "&date=" + date);
  }, [pageNo, pageSize, descending, month, year, date]);

  useEffect(() => {
    props.updatePageParams(pageParams);
  }, [pageParams])

  const handlePageSizeChange = (e) => {
    let pageSize = e.target.value;
    setPageSize(pageSize);
    setPageNo(1); // Reset the page.

    props.updateLink(props.companyId, 1, pageSize, descending, month, year, date);
  }

  useEffect(() => {



  }, [props.startDate]);

  const handlePageChange = (pageNo) => {
    setPageNo(pageNo);
    props.updateLink(props.companyId, pageNo, pageSize, descending, month, year, date);
  }

  const toggleDescending = () => {
    props.updateLink(props.companyId, pageNo, pageSize, !descending, month, year, date);
  }

  useEffect(() => {

    initialiseFromQueryParams();

  },[props.location]);

  useEffect(() => {

    initialiseFromQueryParams();

  },[]);

  useEffect(() => {
      props.updateData({companyId: props.companyId, pageNumber: pageNo, pageSize: pageSize,
        descending: descending, month: props.useDatePicker ? null : month, year: props.useDatePicker ? null : year, date: props.useDatePicker ? date : null});
    }, [pageNo, pageSize, descending]);

  const wrapSetDate = (date) => {
    console.log(date);
    setDate(date);
  }

  const handleCsvDownload = () => {
    props.csvDownload(props.companyId, month, year, date);
  }

  return (<ReportTable changePage={handlePageChange}
                       title={props.pageTitle + " (Page " + (props.pagination === null ? 1 : props.pagination.currentPage) + ")"}
                       descending={descending} toggleDescending={toggleDescending}
                       pageSize={pageSize} changePageSize={handlePageSizeChange}
                       downloadCsv={handleCsvDownload}
                       monthYearOptions={getMonthYearOptions()}
                       modalText="Select the appropriate month you wish to download"
                       modalTitle="Download CSV"
                       changeMonthYear={changeMonthYear}
                       monthYear={monthYear}
                       useDatePicker={props.useDatePicker}
                       selectedDate={date}
                       updateDate={wrapSetDate}
                       showDateOption={props.showDateOption}
  />);


}

const mapStateToProps = (state) => {
  return {
    pagination: state.report.pagination,
    companyId: state.page.companyId,
    assetId: state.page.assetId
  }
}

export default connect(mapStateToProps)(withRouter(ReportController));