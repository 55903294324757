import * as actionTypes from "../actions/actionTypes";

const initialState = {

  buildings: [],
  companies: [],
  subscription: null

}

const subscriptions = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.SET_SUBSCRIPTION_BUILDINGS:
      return {
        ...state,
        buildings: [...action.buildings]
      }
    case actionTypes.STORE_SUBSCRIPTION:

      return {
        ...state,
        subscription: {...action.subscription}
      }
    case actionTypes.SET_COMPANY_LIST:
      return {
        ...state,
        companies: [...action.companies]
      }
    default:
      return state;
  }

  return state;
}

export default subscriptions;