import React, {useEffect} from 'react';
import Homepage from '../Homepage/Homepage'
import {Container, Row, Col} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import NavCard from "../Dashboard/NavCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBroadcastTower,
  faBuilding,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import * as actions from "../../../store/actions";

const AdminMainPanel = (props) => {

  useEffect(() => {
    let nav = [];
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId, title: "Dashboard"});
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin", title: "Admin"});
    props.setPageNavLinks(nav);
    props.setPageTitle("Company Administration");
  },[]);

  if (props.authenticated) {

    return (
      <Homepage {...props} data={props.data} show={true} title={"Company Administration"}
                setAssetSelection={false} showCompanyInfo={true}>
        <Container fluid className="align-self-start h-100 p-0 m-0">
          <Row className="p-0 m-0">
            <Col>
              <Container fluid className="p-0 m-0">
                <Row
                  className="d-flex flex-row h-100 w-100 row-cols-1 row-cols-md-2 row-cols-xl-3 align-content-between mx-auto">
                  <NavCard clickFunction={() => props.history.push({pathname: "/company/" + props.companyId + "/admin/asset/"})}>

                    <FontAwesomeIcon icon={faBroadcastTower} className="me-2"></FontAwesomeIcon>
                    Assets
                  </NavCard>
                  <NavCard
                    clickFunction={() => props.history.push({pathname: "/company/" + props.companyId + "/admin/building/"})}>
                    <FontAwesomeIcon icon={faBuilding} className="me-2"></FontAwesomeIcon>
                    Buildings
                  </NavCard>

                  <NavCard
                    clickFunction={() => props.history.push({pathname: "/company/" + props.companyId + "/admin/user/"})}>
                    <FontAwesomeIcon icon={faUsers} className="me-2"></FontAwesomeIcon>
                    Users
                  </NavCard>

                </Row>
              </Container>

            </Col>
          </Row>
        </Container>
      </Homepage>
    );
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (nav) => dispatch(actions.setPageNavLinks(nav))

  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    selectedCompanyData: state.company.selectedCompanyData,
    companyId: state.page.companyId,
    assetId: state.page.assetId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminMainPanel));
