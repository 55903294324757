import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import {withRouter} from "react-router-dom";
import classes from './CompanyInfo.module.css';

const CompanyInfo = (props) => {

  let select = null;

  const [value, setValue] = useState(null);
  const [options, setOptions] = useState(null);

  // useEffect(() => {
  //   props.loadSelectedData(props.companyId);
  // }, [value])
  //
  // useEffect(() => {
  //   props.setSelectedCompanyData(index);
  // }, [index]);


  useEffect(() => {
    // console.log(props.companyId);
    //
    //
    // setValue(props.companyId);
    // props.loadSelectedData(props.companyId);
  }, []);

  useEffect(() => {

    setOptions(props.companyData.map(companyData => {
      return <option key={companyData.id}
                     value={companyData.id}>{companyData.name}</option>
    }));

    if (props.companyData && props.companyData.length > 0) {
      props.setSelectedCompanyData(props.companyId);
      setValue(props.companyId);
    }

  }, [props.companyData]);

  useEffect(() => {

    if (props.companyData && props.companyData.length > 0) {
      // props.companyData.forEach((comp, index) => {
      //   if (comp.id === props.companyId) {
          props.setSelectedCompanyData(props.companyId);
        // }
      }

    props.loadSelectedData(props.companyId);
    setValue(props.companyId);
  }, [props.companyId])


  if (props.companyData !== undefined && props.companyData.length > 1 && options && options.length > 0) {

    select = <Form>

      <div className="d-block d-xxl-none">
        <Form.Select value={value} as="select" className="text-secondary font-weight-bold form-control-sm my-3"
                      onChange={(e) => {

                        console.log("opening companyId", e.target.value);

                        props.history.push("/company/" +  e.target.value);
                      }}
        >
          {options}
        </Form.Select>
      </div>

      <div className="d-none d-xxl-block">
        <Form.Select value={value} as="select" className="text-secondary font-weight-bold form-control-lg my-3"
                      onChange={(e) => {

                        console.log("opening companyId", e.target.value);

                        props.history.push("/company/" +  e.target.value);
                      }}
        >
          {options}
        </Form.Select>
      </div>

    </Form>
  }

  if (props.selectedCompanyData) {
    return (

      <div className="d-flex flex-column justify-content-between align-items-center h-75 w-100">
        <img src={process.env.REACT_APP_COMPANY_LOGOS + "/" + props.selectedCompanyData.imageUrl}
             className={"mb-3 " + classes.CompanyLogo}/>
        {select}
        <p className={"lead my-3 text-center " + classes.ContactDetails}>
          {props.selectedCompanyData.contactDetails}
        </p>
        <p className={"lead my-3 text-center " + classes.ContactPhoneNumber}>
          {props.selectedCompanyData.companyPhoneNumber}
        </p>
        <p className={"lead my-3 text-center " + classes.ContactAddress}>
          {props.selectedCompanyData.companyAddress}
        </p>

      </div>

    );

  } else {
    return null;
  }

}

const mapStateToProps = (state) => {
  return {
    companyData: state.company.companyData,
    selectedCompanyData: state.company.selectedCompanyData,
    companyId: state.page.companyId
  }
}

const mapDispatchToProps = dispatch => {

  return {
    selectCompanyData: (index) => dispatch({type: 'SELECT_COMPANY_INFO', selectedIndex: index}),
    setData: (data) => dispatch({type: "SET_DATA", data: data}),
    loadSelectedData: (id => dispatch(actions.loadSelectedCompanyData(id))),
    setSelectedCompanyData: (index => dispatch(actions.setSelectedCompanyData(index))),
  };

};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyInfo));