import * as actionTypes from '../../store/actions/actionTypes';
import moment from "moment";

import agent from "../../app/api/agent";

export const setPlotData = (assetId, plotData, startDate, endDate) => {

  let transformedPlotData = {data: []}

  let lastUpdateDateTime = null;

  let sensorMin = [Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE];
  let sensorMax = [-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE];
  let sensorTotals = [0.0, 0.0, 0.0, 0.0];

  if (plotData && plotData.data.length > 0) {

    plotData.data.forEach(data => {

      for (let i = 0; i < 4; i++) {
        if (data.min[i] < sensorMin[i]) {
          sensorMin[i] = data.min[i];
        }

        if (data.max[i] > sensorMax[i]) {
          sensorMax[i] = data.max[i];
        }

        sensorTotals[i] += data.mean[i];
      }


      transformedPlotData.data.push(
        {
          x: data.reportedTime, mean_1: data.mean[0],  max_1: data.max[0], min_1: data.min[0],
          mean_2: data.mean[1],  max_2: data.max[1], min_2: data.min[1],
          mean_3: data.mean[2],  max_3: data.max[2], min_3: data.min[2],
          mean_4: data.mean[3],  max_4: data.max[3], min_4: data.min[3]
        }

      )

    });

    transformedPlotData.data.sort( (a, b) => {

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }

    })

    lastUpdateDateTime = plotData.data[plotData.data.length - 1].receivedTime;
  }

  let sensorAve = [0.0, 0.0, 0.0, 0.0];

  if (plotData.data.length > 0) {
    for (let i = 0; i < 4; i++) {
      sensorAve[i] = sensorTotals[i] / parseFloat(plotData.data.length);
    }
  }

  return {
    type: actionTypes.SET_PLOT_DATA,
    plotData: transformedPlotData,
    originalData: plotData,
    assetId: assetId,
    lastUpdateDateTime: lastUpdateDateTime,
    graphStartDate: startDate,
    graphEndDate: endDate,
    sensorMax: sensorMax,
    sensorMin: sensorMin,
    sensorAve: sensorAve
  }

}

export const setCustomGraph = (custom) => {
  return {
    type: actionTypes.SET_CUSTOM_GRAPH,
    custom: custom
  }
}

export const getFirstMeasurement = (requestedAssetId) => {

  return (dispatch) => {

    agent.Measurement.getFirstMeasurementReceived(requestedAssetId)
      .then(response => {

        dispatch(storeFirstMeasurementReceived(response));

      });

  }

}

export const storeFirstMeasurementReceived = (firstDate) => {

  return {

    type: actionTypes.STORE_FIRST_MEASUREMENT_RECEIVED,
    firstMeasurementDate: firstDate

  }

}

export const incrementalUpdate = (requestedAssetId) => {

  return (dispatch, getState) => {

    let state = getState();
    const {assetId, lastUpdateDateTime, originalData} = state.plots;

    console.log("assetId", assetId);
    console.log("requestedAssetId", requestedAssetId);

    if (assetId !== requestedAssetId) {
      dispatch(getMeasurementsLast12Hours(assetId));
    } else {

      agent.Measurement.getLastMeasurementReceived(assetId)
        .then(response => {
          // let date = moment(response);
          //let lastUpdateDateTimeMoment = moment(lastUpdateDateTime);

          console.log("LastUpdate", response);
          console.log("LastReceived", lastUpdateDateTime)

          if (response > lastUpdateDateTime) {

            agent.Measurement.getIncrementalUpdate(assetId, lastUpdateDateTime)
              .then(update => {

                let mergedData = [...originalData.data, ...update];

                for (let i = 0; i < mergedData.length; i++) {

                  if (moment(mergedData[i].receivedDate).isBefore(moment().add(-12, 'hours'))) {
                    mergedData.shift();
                  } else {
                    break;
                  }

                }

                dispatch(setPlotData(assetId, {data: mergedData}, moment(mergedData[0].receivedDate).toDate(), moment(mergedData[mergedData.length - 1].receivedDate).toDate()));
              });
          }
        })
    }
  }
}

export const getMeasurementsLast12Hours = (assetId) => {

  return dispatch => {

    // dispatch(clearPlotData());
    dispatch(setPlotLoading(true));

    agent.Measurement.getLast12Hours(assetId).then(data => {
      dispatch(setPlotData(assetId, { data: data},
        moment(new Date()).add(-12, 'hours').toDate(), new Date()));
      dispatch(setPlotLoading(false));
    }
  );

  }

}

export const clearPlotData = () => {
  return {
    type: actionTypes.CLEAR_PLOT_DATA
  }
}

export const getPlotData = (assetId, startDate, endDate) => {

  return dispatch => {

    let params = {
      startDate: startDate,
      endDate: endDate,
      isReceiveDate: false
    }

    // dispatch(clearPlotData());
    dispatch(setPlotLoading(true));

    agent.Measurement.getPlotData(assetId, params).then(data => {
        dispatch(setPlotData(assetId, { data: data}, startDate, endDate));
        dispatch(setPlotLoading(false));

      }
    );

  }

}

export const addReferenceLine = (referenceLine) => {
  return {
    type: actionTypes.ADD_REFERENCE_LINE,
    referenceLine: referenceLine
  }
}

export const setPlotLoading = (loading) => {
  return {
    type: actionTypes.SET_PLOT_LOADING,
    loading: loading
  }
}

export const removeReferenceLine = (referenceLineIndex) => {
  return {
    type: actionTypes.REMOVE_REFERENCE_LINE,
    referenceLineIndex: referenceLineIndex
  }
}