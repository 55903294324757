import React from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import {connect} from 'react-redux';
import classes from './ServiceComponent.module.css';

const ServiceComponent = (props) => {

  const alertObject = props.settings.alertSettings.filter(setting => setting.keyName === props.keyName)[0];

  if (props.editMode && alertObject.enabled === true) {

    return (
      <Col className={props.className + " m-1"} style={{border: "1px solid black"}}>
        <h3 className={classes.Icon}><FontAwesomeIcon icon={props.icon} className="me-2"/>{props.name}</h3>
        <Form.Group>

          <Row>
            <Form.Label className={classes.LabelText} column sm={5}>Next service</Form.Label>
            <Col sm={7}>
              <DatePicker
                          selected={props.editValues.nextService ? new Date(props.editValues.nextService) : null}
                          onChange={(date) => {
                            props.changeValue(date.toISOString(), props.keyName, "nextService")
                           }}
                          dateFormat="dd/MM/yyyy"


              />
            </Col>


          </Row>


          <Row>
            <Form.Label className={classes.LabelText} column sm={3}>Frequency</Form.Label>
            <Col>
              <Form.Control size="sm"
                            className="form-control font-weight-bold"
                            placeholder="Frequency"
                            type="number" value={props.editValues.frequency}
                            onChange={(e) => props.changeValue(e.target.value, props.keyName, "frequency")}
              />

            </Col>
            <Col>
              <Form.Control size="sm"
                            className="form-control font-weight-bold"
                            placeholder="Duration"
                            as="select" value={props.editValues.frequencyUnits}
                            onChange={(e) => props.changeValue(e.target.value, props.keyName, "frequencyUnits")}
              >

                <option value="minutes">Minutes</option>
                <option value="hours">Hours</option>
                <option value="days">Days</option>

              </Form.Control>
            </Col>
          </Row>

          <Row>
            <Form.Label className={classes.LabelText} column sm={3}>Reminder</Form.Label>
            <Col>
              <Form.Control size="sm"
                            className="form-control font-weight-bold"
                            placeholder="Reminder"
                            type="number" value={props.editValues.reminder}
                            onChange={(e) => props.changeValue(e.target.value, props.keyName, "reminder")}
              />

            </Col>
            <Col>
              <Form.Control size="sm"
                            className="form-control font-weight-bold"
                            placeholder="Duration"
                            as="select" value={props.editValues.reminderUnits}
                            onChange={(e) => props.changeValue(e.target.value, props.keyName, "reminderUnits")}
              >

                <option value="minutes">Minutes</option>
                <option value="hours">Hours</option>
                <option value="days">Days</option>

              </Form.Control>
            </Col>
          </Row>


        </Form.Group>
      </Col>
    );

  } else {

    let className = alertObject.enabled === true ? props.className : "alert-disabled-div";

    return (
      <Col className={className + " m-1"} style={{border: "1px solid black"}}>
        <h3 className={classes.Icon} ><FontAwesomeIcon icon={props.icon} className="me-2"/>{props.name}</h3>
        <p className={"lead " + classes.LabelText} style={{fontWeight: "bold"}}>
          Next: {!props.values.nextService ? "Not set" : new Date(props.values.nextService).toDateString()}</p>
        <p className={"lead " + classes.LabelText} style={{fontWeight: "bold"}}>Frequency: {props.values.frequency} {props.values.frequencyUnits}</p>
        <p className={"lead " + classes.LabelText} style={{fontWeight: "bold"}}>Reminder: {props.values.reminder} {props.values.reminderUnits}</p>

      </Col>

    );
  }

}

const mapStateToProps = (state) => {

  return {
    settings: state.settings.settings
  }

}

export default connect(mapStateToProps)(ServiceComponent);