import React, {useContext, useEffect, useState} from 'react';
import Homepage from '../../Homepage/Homepage'
import {
  Accordion,
  AccordionCollapse,
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Form, Modal, useAccordionButton, AccordionContext
} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import Pagination from "react-js-pagination";
import {faMinus, faPlus, faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classes from './AdminBuildings.module.css';

const ContextAwareToggle = ({children, eventKey, callback}) => {
  const {activeEventKey} = useContext(AccordionContext);

  console.log(eventKey);
  console.log(callback);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isActiveEventKey = activeEventKey === eventKey;

  return (
    <Button onClick={decoratedOnClick}>
      <FontAwesomeIcon className="me-2" icon={isActiveEventKey ? faMinus : faPlus}/>
    </Button>
  );
}


const AdminBuildings = (props) => {

  const [showModal, setShowModal] = useState(false);
  const [buildingType, setBuildingType] = useState(null);
  const [editValue, setEditValue] = useState(null);
  const [editId, setEditId] = useState(null);
  const [showDeleted, setShowDeleted] = useState(false);
  const [rows, setRows] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {

    let nav = [];
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId, title: "Dashboard"});
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin", title: "Admin"});
    nav.push({url: process.env.PUBLIC_URL + "/#/company/" + props.companyId + "/admin/building", title: "Buildings"});

    props.setPageNavLinks(nav);
    props.setPageTitle("Building Administration");
    props.getAdminBuildingEditList(props.companyId, pageNo, pageSize, setShowDeleted);
    props.setAdminUpdateSuccess(null, null);
    initialiseFromQueryParams();
  }, []);

  useEffect(() => {
    initialiseFromQueryParams();
  }, [props.location])

  useEffect(() => {
    props.getAdminBuildingEditList(props.companyId, pageNo, pageSize, showDeleted);
  }, [pageNo, pageSize, showDeleted])

  const changePage = (pageNo) => {
    props.history.push("/company/" + props.companyId + "/admin/building/?pageNo=" + pageNo + "&showDeleted=" + showDeleted);
  }

  const editModal = () => {
    return (
      <Modal show={true} onHide={() => setShowModal(false)} centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit name</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Container>
            <Row>
              <Col>
                <Form.Control type="text" value={editValue} onChange={(e) => setEditValue(e.target.value)}/>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center my-2">
                <Button className="bg-success me-3 text-left" style={{width: 120}} onClick={() => {

                  if (buildingType === "BUILDING") {
                    props.updateBuilding(props.companyId, editId, pageNo, pageSize, showDeleted, editValue);
                  } else if (buildingType === "SUB1") {
                    props.updateSub1(props.companyId, editId, pageNo, pageSize, showDeleted, editValue);
                  } else if (buildingType === "SUB2") {
                    props.updateSub2(props.companyId, editId, pageNo, pageSize, showDeleted, editValue);
                  } else if (buildingType === "SUB3") {
                    props.updateSub3(props.companyId, editId, pageNo, pageSize, showDeleted, editValue);
                  }
                  setShowModal(false);

                }}><FontAwesomeIcon className="me-2" icon={faSave}/>Save</Button>
                <Button className="bg-danger text-left" style={{width: 120}} onClick={() => {
                  setShowModal(false);
                }}><FontAwesomeIcon className="me-2" icon={faTimes}/>Cancel</Button>
              </Col>
            </Row>
          </Container>

        </Modal.Body>
      </Modal>);
  }

  const initialiseFromQueryParams = () => {

    const params = new URLSearchParams(props.location.search);
    let pageNoFromParams = params.get("pageNo");

    if (pageNoFromParams === null || pageNoFromParams === undefined) {
      setPageNo(1);
    } else {
      setPageNo(parseInt(pageNoFromParams));
    }

  }

  useEffect(() => {

    if (props.editBuildingList) {
      let rowsTmp = props.editBuildingList.map(building => {

        return (<Row className="p-0 m-0">
          <Col xs={12}>

            <Container fluid className="p-0 m-0">

              <Accordion className="bg-primary text-white">

                <Row>

                  <Col xs="8" className="panel-title">

                    <p
                      className={classes.ExpandText + " " + (building.buildingSubGroup1s.length > 0 ? "text-white" : "text-secondary")}>

                      <ContextAwareToggle eventKey={"bld" + building.id}/>
                      {building.name}
                    </p>

                  </Col>
                  <Col xs={2}>
                    <Button className={"bg-secondary " + classes.Button} onClick={() => {
                      setShowModal(true);
                      setEditValue(building.name);
                      setEditId(building.id);
                      setBuildingType("BUILDING");
                    }}>Edit</Button>
                  </Col>
                  <Col xs={2}>
                    <Button style={{width: 120}} className={"bg-secondary " + classes.Button} onClick={() => {
                      props.deleteBuilding(props.companyId, building.id, pageNo, pageSize, showDeleted, !building.enabled);
                    }}>{building.enabled ? "Delete" : "Undelete"}</Button>
                  </Col>
                </Row>

                <AccordionCollapse eventKey={"bld" + building.id} key={"collapse_bld" + building.id}>

                  <Row>
                    <Col xs={12}>
                      {building.buildingSubGroup1s.map(sub1 => {

                        return (
                          <Accordion className="bg-primary text-white">

                            <Row>
                              <Col className="offset-1" xs={7}>
                                <p
                                  className={classes.ExpandText + " " + (sub1.buildingSubGroup2s.length > 0 ? "text-white" : "text-secondary")}>

                                  <ContextAwareToggle eventKey={sub1.id}/>
                                  {sub1.name}
                                </p>
                              </Col>
                              <Col xs={2}>
                                <Button style={{width: 120}} className={"bg-secondary " + classes.Button}
                                        onClick={() => {
                                          setShowModal(true);
                                          setEditValue(sub1.name);
                                          setEditId(sub1.id);
                                          setBuildingType("SUB1");

                                        }}>Edit</Button>
                              </Col>
                              <Col xs={2}>
                                <Button style={{width: 120}} className={"bg-secondary " + classes.Button}
                                        onClick={() => {
                                          props.deleteSub1(props.companyId, sub1.id, pageNo, pageSize, showDeleted, !sub1.enabled);
                                        }}>{sub1.enabled ? "Delete" : "Undelete"}</Button>
                              </Col>
                            </Row>

                            <AccordionCollapse eventKey={sub1.id} key={"collapse" + sub1.id}>

                              <Row>
                                <Col xs={12}>

                                  {sub1.buildingSubGroup2s.map(sub2 => {

                                    return (
                                      <Accordion className="bg-primary text-white">

                                        <Row>

                                          <Col className="offset-2" xs={6}>

                                            <p
                                              className={classes.ExpandText + " " + (sub2.buildingSubGroup3s.length > 0 ? "text-white" : "text-secondary")}>
                                              <ContextAwareToggle eventKey={"sub2_" + sub2.id}/>
                                              {sub2.name}
                                            </p>

                                          </Col>
                                          <Col xs={2}>
                                            <Button style={{width: 120}} className={"bg-secondary " + classes.Button}
                                                    onClick={() => {
                                                      setShowModal(true);
                                                      setEditValue(sub2.name);
                                                      setEditId(sub2.id);
                                                      setBuildingType("SUB2");

                                                    }}>Edit</Button>
                                          </Col>
                                          <Col xs={2}>
                                            <Button style={{width: 120}} className={"bg-secondary " + classes.Button}
                                                    onClick={() => {
                                                      props.deleteSub2(props.companyId, sub2.id, pageNo, pageSize, showDeleted, !sub2.enabled);
                                                    }}>{sub2.enabled ? "Delete" : "Undelete"}</Button>
                                          </Col>
                                        </Row>

                                        <AccordionCollapse eventKey={"sub2_" + sub2.id} key={"collapse" + sub2.id}>

                                          <Row>
                                            <Col xs={12}>
                                              {
                                                !sub2.buildingSubGroup3s ? null : sub2.buildingSubGroup3s.map(sub3 => {
                                                return (

                                                  <Row>

                                                    <Col className="offset-3" xs={5}>
                                                      <p className={classes.ExpandText}>{sub3.name}</p>
                                                    </Col>
                                                    <Col xs={2}>
                                                      <Button style={{width: 120}}
                                                              className={"bg-secondary " + classes.Button}
                                                              onClick={() => {
                                                                setShowModal(true);
                                                                setEditValue(sub3.name);
                                                                setEditId(sub3.id);
                                                                setBuildingType("SUB3");

                                                              }}>Edit</Button>
                                                    </Col>
                                                    <Col xs={2}>
                                                      <Button style={{width: 120}}
                                                              className={"bg-secondary " + classes.Button}
                                                              onClick={() => {
                                                                props.deleteSub3(props.companyId, sub3.id, pageNo, pageSize, showDeleted, !sub3.enabled);
                                                              }}>{sub3.enabled ? "Delete" : "Undelete"}</Button>
                                                    </Col>
                                                  </Row>


                                                );
                                              })
                                              }
                                            </Col>
                                          </Row>
                                        </AccordionCollapse>
                                      </Accordion>)

                                  })}
                                </Col>
                              </Row>


                            </AccordionCollapse>

                          </Accordion>
                        );

                      })}
                    </Col>
                  </Row>

                </AccordionCollapse>

              </Accordion>

            </Container>
          </Col>
        </Row>);

      });

      setRows(rowsTmp);

    }

  }, [props.editBuildingList]);

  if (props.authenticated) {

    let alert = null;

    if (props.success !== null) {

      if (props.success) {
        alert = (<Alert variant="success"><h4>User updated</h4></Alert>);
      } else {
        alert = (<Alert variant="danger"><h4>Error: {props.backendErrorMessage}</h4></Alert>);
      }

    }

    let pagination = null;

    if (props.pagination) {

      pagination = (
        <Pagination
          activePage={props.pagination.currentPage}
          itemsCountPerPage={props.pagination.itemsPerPage}
          totalItemsCount={props.pagination.totalItems}
          pageRangeDisplayed={5}
          itemClass="page-item"
          linkClass="page-link"
          onChange={changePage}
        />
      );
    }

    return (
      <Homepage {...props} data={props.data} show={true} title={"Company Administration"}
                setAssetSelection={false} showCompanyInfo={true}>
        {showModal ? editModal() : null}
        <Container fluid className="align-items-start h-100 p-0 m-0">
          <Row className="align-items-start h-100 p-0 m-0">
            <Col className="align-items-start h-100">
              <Card className="h-100 bg-primary w-100 text-white align-items-start">

                <Container fluid className="p-0 m-0">
                  <Row className="p-0 m-0">
                    <Col className="d-flex justify-content-end">
                      <Form.Check checked={showDeleted} type="checkbox" label="Show Deleted"
                                  onClick={() => setShowDeleted(!showDeleted)}/>
                    </Col>
                  </Row>
                </Container>
                <Container fluid className="p-0 m-0 scroll-contentpane">

                  {alert}

                  {rows}


                </Container>

                <Container className="mt-auto">
                  <Row>
                    <Col className="d-flex justify-content-center">
                      {pagination}

                    </Col>
                  </Row>
                </Container>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
            </Col>
          </Row>
        </Container>

      </Homepage>
    );
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (nav) => dispatch(actions.setPageNavLinks(nav)),
    getAdminBuildingEditList: (companyId, pageNo, pageSize, showDeleted) => dispatch(actions.fetchAdminBuildingEditList(companyId, pageNo, pageSize, showDeleted)),
    setAdminUpdateSuccess: (success, errorMessage) => dispatch(actions.setAdminUpdateSuccess(success, errorMessage)),
    updateBuilding: (companyId, buildingId, pageNo, pageSize, showDeleted, name) => dispatch(actions.updateBuilding(companyId, buildingId, pageNo, pageSize, showDeleted, name)),
    updateSub1: (companyId, buildingId, pageNo, pageSize, showDeleted, name) => dispatch(actions.updateSub1(companyId, buildingId, pageNo, pageSize, showDeleted, name)),
    updateSub2: (companyId, buildingId, pageNo, pageSize, showDeleted, name) => dispatch(actions.updateSub2(companyId, buildingId, pageNo, pageSize, showDeleted, name)),
    updateSub3: (companyId, buildingId, pageNo, pageSize, showDeleted, name) => dispatch(actions.updateSub3(companyId, buildingId, pageNo, pageSize, showDeleted, name)),
    deleteBuilding: (companyId, buildingId, pageNo, pageSize, showDeleted, value) => dispatch(actions.deleteBuilding(companyId, buildingId, pageNo, pageSize, showDeleted, value)),
    deleteSub1: (companyId, sub1Id, pageNo, pageSize, showDeleted, value) => dispatch(actions.deleteSub1(companyId, sub1Id, pageNo, pageSize, showDeleted, value)),
    deleteSub2: (companyId, sub2Id, pageNo, pageSize, showDeleted, value) => dispatch(actions.deleteSub2(companyId, sub2Id, pageNo, pageSize, showDeleted, value)),
    deleteSub3: (companyId, sub3Id, pageNo, pageSize, showDeleted, value) => dispatch(actions.deleteSub3(companyId, sub3Id, pageNo, pageSize, showDeleted, value)),

  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    selectedCompanyData: state.company.selectedCompanyData,
    companyId: state.page.companyId,
    userList: state.admin.userList,
    success: state.admin.success,
    backendErrorMessage: state.admin.errorMessage,
    pagination: state.admin.pagination,
    editBuildingList: state.admin.editBuildingList
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminBuildings));
