import React, {useEffect, useState} from 'react';
import Homepage from '../../Company/Homepage/Homepage'
import {Container, Row, Col, Card, Button, Form, Alert, Spinner} from 'react-bootstrap';
import {Redirect} from 'react-router';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import {faCheck,  faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import XLSX from 'xlsx';
import classes from './Initialisation.module.css';

const Initialisation = (props) => {

  const [errorMessages, setErrorMessages] = useState([]);
  const [valid, setValid] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [uploadFileName, setUploadFileName] = useState(null);
  const [finished, setFinished] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploading, setUploading] = useState(null);
  const [sheetContents, setSheetContents] = useState(null);
  const [companyOptions, setCompanyOptions] = useState(null);
  const [processingIndex, setProcessingIndex] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {

    props.setAdminUpdateSuccess(false, null);
    props.setPageTitle("Company Administration");
    props.getAdminCompanyList();
    props.setPageTitle("Initialisation");

    let nav = [];
    nav.push({url: process.env.PUBLIC_URL + "/#/admin", title: "Admin"});
    nav.push({url: process.env.PUBLIC_URL + "/#/admin/initialisation", title: "Initialisation"});
    props.setPageNavLinks(nav);
  },[]);

  useEffect(() => {

    if (props.companyList) {
      let options = [];

      options.push(
        props.companyList.map(company => <option value={company.id}>{company.name}</option>)
      );

      setCompanyOptions(options);

      if (props.companyList.length > 0) {
        setCompanyId(props.companyList[0].id);
      }

    }

  }, [props.companyList])

  useEffect(() => {

    let localValid = true;
    let errorMessages = []


    if (companyId === null ) {
      localValid = false;
      errorMessages.push("Company must be supplied");
    }

    if (uploadFile === null) {
      localValid = false;
      errorMessages.push("Asset file must be supplied");
    }

    setErrorMessages(errorMessages);
    setValid(localValid);

  }, [companyId, uploadFileName]);

  useEffect(() => {

    if (props.bulkStatus) {

      let finished = true;

      for (let i = 0; i < props.bulkStatus.length; i++) {

        if (props.bulkStatus.success === null) {
          finished = false;
        }

      }

      if (finished) {
        setFinished(true);
      }

    }

  }, [props.bulkStatus]);

  useEffect(() => {

    if (sheetContents && sheetContents.length > 0) {
      let dataLength = sheetContents.length;

      props.initialiseBulkStatus(dataLength);

      setUploading(true);
      let assets = sheetContents.map((item, index) => {

        let asset = {
          companyId: companyId,
          name: "" + item['Asset Name'],
          imei: "" +  item['Imei'],
          sentinelPoint: (item['SentinelPoint'] === "On"),
          assetIcon: item['Icon'],
          buildingName: item['Building'],
          buildingSubGroup1Name: item['Sub Heading 1'],
          buildingSubGroup2Name: item['Sub Heading 2'],
          buildingSubGroup3Name: item['Sub Heading 3'],
          scald: (item['Alarm - Scald'] === "On"),
          scaldSensor1: (item['Alarm - Scald Sensor 1'] === "On"),
          scaldSensor2: (item['Alarm - Scald Sensor 2'] === "On"),
          scaldSensor3: (item['Alarm - Scald Sensor 3'] === "On"),
          scaldSensor4: (item['Alarm - Scald Sensor 4'] === "On"),

          bacterial: (item['Alarm - Bacterial'] === "On"),
          freeze: (item['Alarm - Freeze'] === "On"),
          noDeviceData: (item['Alarm - No Device Data'] === "On"),
          targetTemp: (item['Alarm - Target Temp'] === "On"),
          targetTempExceeded1: (item['Alarm - Target Temp Exceeded Sensor 1'] === "On"),
          targetTempExceeded2: (item['Alarm - Target Temp Exceeded Sensor 2'] === "On"),
          targetTempExceeded3: (item['Alarm - Target Temp Exceeded Sensor 3'] === "On"),
          targetTempExceeded4: (item['Alarm - Target Temp Exceeded Sensor 4'] === "On"),
          targetTempAchieved1: (item['Alarm - Target Temp Not Achieved Sensor 1'] === "On"),
          targetTempAchieved2: (item['Alarm - Target Temp Not Achieved Sensor 2'] === "On"),
          targetTempAchieved3: (item['Alarm - Target Temp Not Achieved Sensor 3'] === "On"),
          targetTempAchieved4: (item['Alarm - Target Temp Not Achieved Sensor 4'] === "On"),
          offset1: item['Sensor 1 Offset'],
          offset2: item['Sensor 2 Offset'],
          offset3: item['Sensor 3 Offset'],
          offset4: item['Sensor 4 Offset'],
          sensorDescription1: item['Sensor 1 Description'],
          sensorDescription2: item['Sensor 2 Description'],
          sensorDescription3: item['Sensor 3 Description'],
          sensorDescription4: item['Sensor 4 Description']
        }


        return asset;
      });

      props.createBulkAsset(assets, 0);

    }

  }, [sheetContents]);


  if (props.authenticated) {
    let errors = null;

    if (formSubmitted && (props.backendErrorMessage || (errorMessages && errorMessages.length > 0)) && props.loadingCounter === 0) {
      let errorOptions = errorMessages.map(message => <li>{message}</li>);

      if (props.backendErrorMessage) {
        errorOptions.push([<li>{props.backendErrorMessage}</li>]);
      }

      errors = (<Row>
        <Col xs={12}>
          <Alert variant="danger">
            <h4>The following errors exist: -</h4>
            <ul>
              {errorOptions}
            </ul>
          </Alert>
        </Col>
      </Row>)
    } else if (props.success) {
      errors = (<Row>
        <Col xs={12}>
          <Alert variant="success">
            <h4>Company {props.createMode ? "created" : "updated"}</h4>
          </Alert>
        </Col>
      </Row>)

    }

    let content = null;

    if (uploading && props.bulkStatus) {

      let rows = props.bulkStatus.map((status, index) => {
        return (<Row>
            <Col className={status.success === true ? "text-secondary" : status.success === false ? "text-danger" : null}>Row {index + 1}</Col>
            <Col className={status.success === true ? "text-secondary" : status.success === false ? "text-danger" : null}>{status.status}</Col>
            <Col className={status.success === true ? "text-secondary" : status.success === false ? "text-danger" : null}>
              {finished ? (status.success ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/>) :
                processingIndex === index && !finished ? <Spinner animation="border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner> : null}
              </Col>
          </Row>);
      });

      content = (<Container fluid className="p-0 m-0">
        {rows}
        {finished ?
        <Row className="m-0 p-0">
          <Col className="mt-2 d-flex justify-content-center">
            <Button className="bg-secondary" onClick={() => setUploading(false)}>Finished</Button>
          </Col>
        </Row> : null}
      </Container>)

    } else {

      content = (
        <Container fluid className="h-100 p-0 m-0">
          {errors}
          <Row className="p-0 m-0">
            <Col className="mt-2">
              <p>To upload bulk assets, please download the Excel Spreadsheet template and complete the required
              fields.  To switch off sensors, leave the sensor description empty.  Download from <a className="h4 text-secondary" href={process.env.PUBLIC_URL + '/Asset Bulk Upload Template.xlsx'}  rel="noreferrer" target="_blank">here.</a></p>
            </Col>
          </Row>
          <Row className="p-0 m-0">
            <Col>
              <Form.Group controlId="adminAsset.AssetNameControl">
                <Form.Label>Company</Form.Label>
                <Form.Control as="select" value={companyId} type="text" placeholder="Company" onChange={(e) => setCompanyId(e.target.value)}>
                  {companyOptions}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-auto flex-auto m-0 p-0">
            <Col>
              <Form.Group controlId="adminAsset.AssetNameControl">
                <Form.Label>Bulk Asset File Upload</Form.Label>
                <Form.Control className={!uploadFile ? classes.FormControlError : ""} value={uploadFileName} type="file" placeholder="File" onChange={(e) => {

                  if (e.target.files && e.target.files.length > 0) {
                    setUploadFileName(e.target.files[0].Name);
                    setUploadFile(e.target.files[0]);
                  }

                }}/>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-auto align-self-end m-0 p-0">
            <Col className="d-flex justify-content-center">
              <Button className="bg-success me-4 mt-3" disabled={!valid} onClick={() => {
                const reader = new FileReader();
                reader.onload = (evt) => { // evt = on_file_select event
                  /* Parse data */
                  const bstr = evt.target.result;
                  const wb = XLSX.read(bstr, {type:'binary'});
                  /* Get first worksheet */
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  /* Convert array of arrays */
                  const data = XLSX.utils.sheet_to_json(ws, {raw:true});
                  /* Update state */
                  setSheetContents(data);
                };
                reader.readAsBinaryString(uploadFile);

              }}>Process</Button>
              <Button className="bg-danger mt-3">Cancel</Button>
            </Col>

          </Row>

        </Container>

      );

    }


    return (
      <Homepage {...props} data={props.data} show={true} title={"Company Administration"}
                showAssetSelection={false} showCompanyInfo={false}>
        <Container fluid className="align-items-start h-100 p-0 m-0">
          <Row className="align-items-start h-100 p-0 m-0">
            <Col className="align-items-start h-100">
              <Card className="h-100 bg-primary w-100 text-white align-items-start">
                {content}
              </Card>
            </Col>
          </Row>

        </Container>

      </Homepage>
    );
  } else {
    return (<Redirect to="/login"/>);
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (nav) => dispatch(actions.setPageNavLinks(nav)),
    getIconList: () => dispatch(actions.fetchIcons()),
    setAdminUpdateSuccess: (success, errorMessage) => dispatch(actions.setAdminUpdateSuccess(success, errorMessage)),
    getAdminCompany: (companyId) => dispatch(actions.getAdminCompany(companyId)),
    updateCompany: (companyId, companyData) => dispatch(actions.updateCompany(companyId, companyData)),
    createCompany: (companyData) => dispatch(actions.createCompany(companyData)),
    getAdminCompanyList: () => dispatch(actions.fetchAdminCompanyListAll()),
    initialiseBulkStatus: (size) => dispatch(actions.initialiseBulkStatus(size)),
    createBulkAsset: (assetDataArray, index) => dispatch(actions.createBulkAsset(assetDataArray, index))

  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    role: state.authentication.role,
    loadingCounter: state.page.loadingCounter,
    success: state.admin.success,
    backendErrorMessage: state.admin.errorMessage,
    companyList: state.admin.companyList,
    bulkStatus: state.admin.bulkStatus
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Initialisation));
