import React from "react";
import {Row, Container} from "react-bootstrap";
import NavCard from "./NavCard";
import MainCard from "./MainCard";
import ThreeCounterCard from "./ThreeCounterCard";
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/dashboard';
import adminIcon from '../../../assets/Inform icons_AdminX60.png';
import newAlertsIcon from '../../../assets/Inform icons_New alertsX80.png';
import activeAlertsIcon from '../../../assets/Inform icons_Active alertsX80.png';
import closedAlertsIcon from '../../../assets/Inform icons_Closed alertsX80.png';
import reportIcon from '../../../assets/Inform icons_ReportsX60.png';
import informationIcon from '../../../assets/Inform icons_InfoX60.png';

import classes from "./DashboardContent.module.css";


const DashboardContent = (props) => {

  let cards = null;

  if (props.data) {

    let admin = null;

    if (props.role.search("Admin") >= 0) {

      if (props.role === "ResellerAdmin" && (props.selectedCompanyData.resellerId !== props.userCompanyId || props.selectedCompanyData.resellerId === null)) {
        admin = null
      } else {
        admin = (<NavCard clickFunction={() => {
          props.history.push({pathname: "/company/" + props.companyId + "/admin/"});
        }}>
          <img src={adminIcon} alt="administration icon" className={classes.SecondaryImageIcon}/>
          Administration
        </NavCard>);

      }

    }


    cards = (
      <Row className="d-flex flex-row h-100 w-100 row-cols-1 row-cols-md-2 row-cols-xl-3 align-content-between mx-auto p-0 m-0">

      <ThreeCounterCard activeAlerts={props.data.activeAlerts}
                        newAlerts={props.data.newAlerts}
                        activeAlertsOnClick={() => props.setAlertFilter({activeAlerts: true})}
                        newAlertsOnClick={() => props.setAlertFilter({newAlerts: true})}
                        closedAlertsOnClick={() => props.setAlertFilter({newAlerts: true})}
      />

      <MainCard title="New Alerts"
                counter={props.data.newAlerts}
                badgeClass="new-alerts-badge-desktop"
                onClick={() => props.history.push({pathname: "/company/" + props.companyId + "/", search: "?alertFilter=new"})}
                selected={props.selectedAlertType === "new"}
      >
        <img src={newAlertsIcon} alt="new alert icon" className={classes.PrimaryImageIcon}/>

      </MainCard>


      <MainCard title="Active Alerts"
                counter={props.data.activeAlerts}
                badgeClass="active-alerts-badge-desktop"
                onClick={() => props.history.push({pathname: "/company/" + props.companyId + "/" , search: "?alertFilter=active"})}
                selected={props.selectedAlertType === "active"}
      >
        <img src={activeAlertsIcon} alt="active alert icon" className={classes.PrimaryImageIcon}/>
      </MainCard>

      <MainCard title="Closed Alerts"
                onClick={() => props.history.push({pathname: "/company/" + props.companyId + "/", search: "?alertFilter=closed"})}
                selected={props.selectedAlertType === "closed"}
      >
        <img src={closedAlertsIcon} alt="closed alert icon" className={classes.PrimaryImageIcon}/>
      </MainCard>

        {admin}

        <NavCard clickFunction={() => {
          props.history.push({pathname: "/company/" + props.companyId + "/reports/"});
        }}>
          <img className={"me-2 " + classes.SecondaryImageIcon} src={reportIcon} alt="report icon"/>

          Reports
        </NavCard>

        <NavCard clickFunction={() => {
          props.history.push({pathname: "/company/" + props.companyId + "/documents/"});
        }}>
          <img className={"me-2 " + classes.SecondaryImageIcon} src={informationIcon} alt="report icon"/>
          Information
        </NavCard>

      </Row>
    );
  }

    return (
      <Container fluid className="h-100 d-flex align-items-center p-0 m-0">
        {cards}
      </Container>
    );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDashboardFilter: (filter) =>dispatch(actions.setDashboardFilterSetting(filter))
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.authentication.role,
    userCompanyId: state.authentication.companyId,
    selectedCompanyData: state.company.selectedCompanyData,

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardContent));