import React, {useState, useEffect} from 'react';
import {Accordion} from "react-bootstrap";
import AssetsAccordion from './AssetsAccordion';
import ExpandableItem from './ExpandableItem';
import {connect} from 'react-redux';
import SubGroupAccordions from "./SubGroupAccordions";
import {withRouter} from "react-router-dom";
import AssetItem from "./AssetItem";

const BuildingAccordion = (props) => {

  let noSearch = props.alertFilter === null;

  const [active, setActive] = useState(
    props.data && props.data.id === props.selectedBuildingId ? "bld_" + props.selectedBuildingId : null
  );

  const [onlyOnce, setOnlyOnce] = useState(false);

  useEffect(() => {

    if (!onlyOnce) {
      if (props.data && props.data.id === props.selectedBuildingId && active !== "bld_" + props.selectedBuildingId) {
        setActive("bld_" + props.selectedBuildingId);
        setOnlyOnce(true);

      }

    }

  }, [active, props.data, props.data.id, props.selectedBuildingId, onlyOnce]);

  if (props.data === null) {
    return null;
  }


  if (!onlyOnce && active !== null) {
    setOnlyOnce(true);
  }

  return (
    <Accordion className="text-white"
               key={"bld_" + props.data.id}
               activeKey={active}
               onSelect={e => {
                 setActive(e);
               }}>

      <ExpandableItem newAlerts={props.data.newAlerts}
                      activeAlerts={props.data.activeAlerts}
                      name={props.data.name}
                      eventKey={"bld_" + props.data.id}
                      indent={0}
                      search={props.search}
                      linkClass={props.selectedBuildingId === props.data.id ?
                        "asset-selector-text text-white active-asset"
                        : "selector-text text-white"}
      >

      </ExpandableItem>

      <Accordion.Collapse eventKey={"bld_" + props.data.id}>
        <div>
          {props.data.buildingSubGroup1 !== null ? <SubGroupAccordions data={props.data.buildingSubGroup1}
                                                                       search={props.search}
                                                                       selectedAsset={props.selectedAsset}
                                                                       selectedSub={props.selectedSub}
                                                                       selectedBuilding={props.selectedSub}
            /> :
            <AssetsAccordion data={props.data.data} search={props.search}/>}
          {props.data.assets.length > 0 ?

            props.data.assets.map(
                          (item) => {

                            return (<AssetItem name={item.name} id={item.id}
                                               isSentinelPoint={item.sentinelPoint}
                                               isSelected={item.id === props.selectedAssetId}
                                               url={"/company/" + props.companyId + "/asset/" + item.id}
                                               newAlertsCount={item.newAlerts}
                                               activeAlertsCount={item.activeAlerts}
                                               alertFilter={props.alertFilter}
                                               noSearch={props.noSearch}
                                               indentationLevel={1}
                                               iconType={item.iconType}
                            />);
                            // return (
                            //   <Row key={item.id} style={{height: "40px"}}>
                            //     <Col xs="8">
                            //       <AssetIcon iconType={item.iconType} className={"me-2 asset-selector-text " + (props.selectedAssetId === item.id ? " active-asset" : "") + " " + classes.IndentLevel1}/>
                            //       <Button variant="link"
                            //               className={"asset-selector-text text-white" + (props.selectedAssetId === item.id ? " active-asset" : "") + " " + classes.Text}
                            //               onClick={() => {
                            //                 props.history.push("/company/" + props.companyId + "/asset/" + item.id + "/" + "?name=" + item.name + "&assetIdentifier=" + item.assetIdentifier +
                            //                   "&buildingId=" + item.buildingId +
                            //                   "&subId=" + item.subId);
                            //               }}>
                            //         {item.name}
                            //       </Button>
                            //     </Col>
                            //
                            //     <Col xs="1" sm="1" className={"align-items-center " + classes.Text}>
                            //       {item.sentinelPoint ? <FontAwesomeIcon icon={faStar}/> : null}
                            //     </Col>
                            //     <Col xs="1" sm="1" className="align-items-center">
                            //       {item.newAlerts > 0 && (noSearch || props.alertFilter === "new") ?
                            //         <div className="new-alerts-button-side">{item.newAlerts}</div> : null}
                            //     </Col>
                            //     <Col xs="1" sm="1" className="align-items-center">
                            //       {item.activeAlerts > 0 && (
                            //         noSearch || props.alertFilter === "active") ?
                            //         <div className="active-alerts-button-side">{item.activeAlerts}</div> : null}
                            //     </Col>
                            //   </Row>






                          })
            : null}

        </div>
      </Accordion.Collapse>

    </Accordion>

  );

}

const mapStateToProps = (state) => {

  return {
    selectedBuildingId: state.page.selectedBuildingId,
    selectedAssetId: state.page.selectedAssetId,
    alertFilter: state.filter.alertFilter,
    assetData: state.assets.assetData,
    selectedSub2Id: state.page.selectedSub2Id,
    companyId: state.page.companyId
  }
}

export default connect(mapStateToProps)(withRouter(BuildingAccordion));