'use strict'

import React from 'react'
import reactCSS from 'reactcss'
import { GithubPicker } from 'react-color'
import {rgba} from 'polished';

class ColourPickerButton extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    }

  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb }, () => {
      let rgbaString = rgba(
        parseInt(this.state.color.r),
        parseInt(this.state.color.g),
        parseInt(this.state.color.b),
        parseInt(this.state.color.a));
      this.props.updateStrokeColour(rgbaString);
      this.setState({ displayColorPicker: false })
    })
  };

  render() {

    const styles = reactCSS({
      'default': {
        color: {
          width: '20px',
          height: '20px',
          borderRadius: '2px',
          background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={ styles.swatch } onClick={ this.handleClick }>
          <div style={ styles.color } />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <GithubPicker color={ this.state.color } onChangeComplete={ this.handleChange } display={this.state.show}
                        colors={['#006400', '#000080', '#b03060', '#ffd700', '#911eb4','#a9a9a9']}

          />
        </div> : null }

      </div>
    )
  }
}

export default ColourPickerButton