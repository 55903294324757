import React, {useEffect, useState} from "react";
import {Card, Col, Row, Container, Table, Button} from "react-bootstrap";
import {connect} from 'react-redux';
import {useParams, withRouter} from 'react-router-dom';
import * as actions from '../../../../store/actions';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import CreateModal from "./CreateModal";
import EditModal from "./EditModal";
import Pagination from "react-js-pagination";
import moment from "moment";

const DocumentContent = (props) => {

  const {companyId, assetId} = useParams();
  const [listRows, setListRows] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);

  useEffect(() => {

    if (pageNo !== null && pageSize !== null) {
      props.list(companyId, assetId, {pageNumber: pageNo, pageSize: pageSize});
    }

  }, [pageNo, pageSize])

  useEffect(() => {
    initialiseFromQueryParams();
  }, [props.location])


  const initialiseFromQueryParams = () => {

    const params = new URLSearchParams(props.location.search);
    let pageNoFromParams = params.get("pageNo");

    if (pageNoFromParams === null || pageNoFromParams === undefined) {
      setPageNo(1);
    } else {
      setPageNo(parseInt(pageNoFromParams));
    }

  }

  const changePage = (pageNo) => {
    props.history.push("/company/" + companyId + "/asset/"  + assetId + "/documents/?pageNo=" + pageNo);
  }

  useEffect(() => {

    let requestObject = {pageNumber: pageNo, pageSize: pageSize};
    props.list(companyId, assetId, requestObject);

  }, []);

  useEffect(() => {

    if (props.fileList) {
      setListRows(
        props.fileList.map(file => {
          return (
            <tr>
              <td>{file.fileName}</td>
              <td>{file.fileDescription}</td>
              <td>{parseInt(file.fileSizeBytes).toLocaleString(undefined, {maximumFractionDigits:2})} bytes</td>
              <td>{moment(file.dateAdded).format("DD/MM/YYYY HH:mm:ss")}</td>

              <td>
                <Button size="sm" className="bg-secondary mx-1" onClick={() => props.download(file.id, file.fileName)}>Download</Button>
                {props.role === "SiteAdmin" || props.role === "ResellerAdmin" || (props.role === "CustomerAdmin" && parseInt(companyId) === parseInt(props.userCompanyId)) ?
                  <Button size="sm" className="bg-secondary mx-1" onClick={() => {
                    setShowEditForm(true);
                    setSelectedFileId(file.id);
                  }}>Edit</Button> : null}
                {props.role === "SiteAdmin" || props.role === "ResellerAdmin" || (props.role === "CustomerAdmin" && parseInt(companyId) === parseInt(props.userCompanyId)) ?
                  <Button size="sm" className="bg-secondary mx-1" onClick={() => {
                    props.delete(file.id, companyId, assetId, {pageNumber: pageNo, pageSize: pageSize});
                  }}>Delete</Button> : null}
              </td>

            </tr>
          );
        })
      );
    }

  }, [props.fileList]);

  let modal = null;

  if (showCreateForm) {
    modal = <CreateModal showModal={showCreateForm} setShowModal={setShowCreateForm} companyId={props.companyId} assetId={props.assetId} pageNo={pageNo} pageSize={pageSize}/>
  } else if (showEditForm) {
    modal = <EditModal showModal={showEditForm} setShowModal={setShowEditForm} companyId={props.companyId} assetId={props.assetId} pageNo={pageNo} pageSize={pageSize} fileId={selectedFileId}/>

  }

  let pagination = null;

  if (props.pagination && props.pagination.totalItems > props.pagination.itemsPerPage) {

    pagination = (
      <Pagination
        activePage={props.pagination.currentPage}
        itemsCountPerPage={props.pagination.itemsPerPage}
        totalItemsCount={props.pagination.totalItems}
        pageRangeDisplayed={5}
        itemClass="page-item"
        linkClass="page-link"
        onChange={changePage}
      />
    );
  }


  return (
    <Container fluid className="h-100 d-flex align-items-center p-0 m-0">
      {modal}
      <Container fluid className="h-100 d-flex flex-fill p-0 m-0">
        <Row className="d-flex flex-fill m-0 p-0">
          <Col className="d-flex flex-fill d-flex-row align-content-between">
            <Card className="bg-primary w-100 text-white h-100">

              {props.role === "SiteAdmin" || props.role === "CustomerAdmin" || props.role === "ResellerAdmin" ?
                <Container fluid className="p-0 m-0">
                  <Row className="m-0 p-0">
                    <Col className="my-2">
                      <Button size="sm" className="bg-secondary" onClick={() => {
                        setShowCreateForm(true);
                      }}><FontAwesomeIcon className="me-2" icon={faPlus}/>Upload</Button>
                    </Col>
                  </Row>
                </Container>

                : null}

              <Container fluid className="p-0 m-0">
                <Row className="m-0 p-0">
                  <Col xs={12}><h3>Documents</h3></Col>
                </Row>
                <Row className="m-0 p-0">
                  <Col xs={12} className="m-0 p-0">

                    <Table responsive striped className="text-white">
                      <thead>
                      <tr>
                        <th>File Name</th>
                        <th>Description</th>
                        <th>File Size</th>
                        <th>Date Added</th>
                        <th>Options</th>

                      </tr>
                      </thead>
                      <tbody>

                      {listRows && listRows.length > 0 ? listRows : <tr><td colSpan="5">No Files</td></tr>}

                      </tbody>
                    </Table>

                  </Col>
                </Row>
              </Container>
              <Container className="mt-auto">
                <Row>
                  <Col className="d-flex justify-content-center">
                    {pagination}
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    list: (companyId, assetId, pagingParams) => dispatch(actions.fetchFileList(companyId, assetId, pagingParams)),
    delete: (fileId, companyId, assetId, pagingParams) => dispatch(actions.deleteFile(fileId, companyId, assetId, pagingParams)),
    download: (fileId, fileName) => dispatch(actions.downloadFile(fileId, fileName)),
  }
}

const mapStateToProps = (state) => {
  return {
    fileList: state.file.fileList,
    role: state.authentication.role,
    userCompanyId: state.authentication.companyId,
    pagination: state.file.pagination,

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentContent));