import agent from "../../app/api/agent";
import * as actions from "./index";
import * as actionTypes from './actionTypes';

export const fetchSubscription = () => {
  return dispatch => {

    agent.Subscriptions.getSubscription()
      .then(
        response => {
          dispatch(storeSubscription(response));
        });

  }
}

export const updateSubscription = (subscription) => {
  return dispatch => {

    dispatch(actions.incrementPageLoading());

    agent.Subscriptions.updateSubscription(subscription)
      .then(
        response => {
          dispatch(fetchSubscription());
          dispatch(actions.decrementPageLoading());
        });
  }
}

export const fetchSubscriptionBuildingList = () => {
  return dispatch => {

    agent.Subscriptions.listBuildings()
      .then(
        response => {
          dispatch(setBuildingList(response));
        });

  }
}

export const fetchSubscriptionCompanyList = () => {
  return dispatch => {

    agent.Subscriptions.listCompanies()
      .then(
        response => {
          dispatch(setCompanyList(response));
        });

  }
}

export const setBuildingList = (buildings) => {

  return {
    type: actionTypes.SET_SUBSCRIPTION_BUILDINGS,
    buildings: buildings
  }

}


export const setCompanyList = (companies) => {

  return {
    type: actionTypes.SET_COMPANY_LIST,
    companies: companies
  }

}

export const storeSubscription = (subscription) => {

  return {
    type: actionTypes.STORE_SUBSCRIPTION,
    subscription: subscription
  }

}