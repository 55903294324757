import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import ReportController from "./ReportController";
import {useParams, withRouter} from "react-router-dom";

const SiteStatusReport = (props) => {

  const {companyId} = useParams();
  const [pageParams, setPageParams] = useState(null);

  useEffect(() => {
    let navLinks = [];
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/" + companyId, title: "Reports"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/siteStatusReport" + pageParams, title: "Site Status"});
    props.setPageNavLinks(navLinks)

  }, [pageParams])

  const handleCsvDownload = (companyId, month, year) => {

    let params = {companyId: companyId, month: month, year: year}
    props.fetchSiteStatusReportCsv(params);
  }

  const handleUpdateLink = (companyId, pageNo, pageSize, descending, month, year) => {
    props.history.push("/company/" + companyId + "/reports/siteStatusReport?pageNo=" + pageNo + " &pageSize=" + pageSize + "&descending=" + descending);
  }

  const updatePageParams = (pageParams) => {
    setPageParams(pageParams);
  }

  const handleUpdateData = (params) => {

    let navLinks = [];

    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId, title: "Dashboard"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/" + companyId, title: "Reports"});
    navLinks.push({url: process.env.PUBLIC_URL + "/#/company/" + companyId + "/reports/siteStatusReport" + pageParams, title: "Site Status Report"});
    props.setPageNavLinks(navLinks)


    props.fetchSiteStatusReport(params);
  }

  return (<ReportController
    pageTitle="Site Status Report"
    csvDownload={handleCsvDownload}
    // downloadOptions={null}
    urlTemplate="/reports/:companyId/siteStatusReport"
    updateLink={handleUpdateLink}
    updateData={handleUpdateData}
    updatePageParams={updatePageParams}
    useDatePicker={false}
    showDateOption={false}

  />);
};

const mapDispatchToProps = (dispatch) => {

  return {
    fetchSiteStatusReportCsv: (params) => dispatch(actions.fetchSiteStatusReportCsv(params)),
    fetchSiteStatusReport: (params) => dispatch(actions.fetchSiteStatusReport(params)),
    setPageTitle: (title) => dispatch(actions.setPageTitle(title)),
    setPageNavLinks: (links) => dispatch(actions.setPageNavLinks(links)),
  }

}

const mapStateToProps = (state) => {

  return {
    reportData: state.report.data,
    pagination: state.report.pagination,
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SiteStatusReport));